<template>
  <div>
    <v-card flat id="comments">
      <v-card-title class="pb-0">COMMENTS</v-card-title>
      <v-divider></v-divider>
      <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5"
            >Are you sure you want to delete the comment?</v-card-title
          >

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="deleteItemConfirm"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <div class="d-flex ml-4">
        <p>Comment ({{ filterComment(newComment).length }})</p>
      </div>
      <div v-for="comment in filterComment(newComment)" :key="comment.id">
        <div class="d-flex mt-2 ml-4">
          <p>{{ comment.comment }}</p>
        </div>
        <div class="d-flex caption ml-4 mt-0">
          <td
            v-for="user in filterUser(comment.createdBy)"
            :key="user.id"
            class="text-left"
          >
            <strong> {{ user.email }}</strong> &bull;
          </td>
          <td>{{ formatDate(comment.createdAt) }}</td>
          <!-- <strong>user@email.com</strong> &bull;  -->
          <!-- <span>3 years ago</span> -->

          <td>
            <v-btn
              v-if="management"
              tile
              x-small
              dark
              color="red"
              @click="deleteItem(comment)"
              class="ml-2"
              plain
              >Delete</v-btn
            >
          </td>
        </div>

        <div class="d-flex ml-4">
          <!-- <v-btn tile x-small class="primary">Update</v-btn> -->
        </div>

        <v-divider></v-divider>
      </div>
      <div class="d-flex ml-4">
        <v-card-text class="font-weight-black">Leave a comment</v-card-text>
      </div>
      <div class="d-flex ml-4">
        <v-textarea
          clearable
          counter
          auto-grow
          solo
          v-model="editedItem.comment"
          required
          placeholder="type in your comment"
          background-color="grey lighten-5"
        />
      </div>
      <v-btn
        tile
        x-small
        class="primary d-flex ml-4"
        @click="save"
        :disabled="isAddButtonDisabled"
        >Submit</v-btn
      >
    </v-card>
  </div>
</template>
<script>
import CommentDataService from "../../services/CommentDataService";
import MemberDataService from "../../services/MemberDataService";
import moment from "moment";

export default {
  name: "comments",
  props: ["casefile"],
  data() {
    return {
      dialogDelete: false,
      newComment: [],
      members: [],
      management: false,
      defaultItem: {
        id: null,
        caseId: null,
        comment: "",
        createdBy: "",
      },
      editedIndex: -1,
      editedItem: {
        id: null,
        caseId: null,
        comment: "",
        createdBy: "",
      },
    };
  },

  computed: {
    isAddButtonDisabled() {
      return !this.editedItem.comment;
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    formatDate: function (date) {
      return moment(date).format("DD/MM/YYYY, h:mm:ss a");
    },
    checkManagement() {
      if (
        this.$store.state.auth.user.roles.includes(`ADMIN`) ||
        this.$store.state.auth.user.roles.includes(`MANAGER`)
      ) {
        this.management = true;
      }
    },
    filterComment() {
      return this.newComment.filter(
        (comment) => comment.caseId === this.casefile.id
      );
    },
    filterUser(item) {
      return this.members.filter((member) => member.id === item);
    },
    retrieveComments() {
      CommentDataService.getAll()
        .then((response) => {
          this.newComment = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveUser() {
      MemberDataService.getAll()
        .then((response) => {
          this.members = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    refreshList() {
      this.retrieveComments();
    },
    deleteItem(item) {
      this.editedIndex = this.newComment.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      CommentDataService.delete(this.editedItem.id)
        .then((response) => {
          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "error",
            text:
              (response && response.data && response.data.message) ||
              toString(),
          });

          this.refreshList();
        })
        .catch((e) => {
          console.log(e);
        });

      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.editedIndex > -1) {
        CommentDataService.update(this.editedItem.id, this.editedItem)
          .then((response) => {
            this.$store.dispatch("setSnackbar", {
              showing: true,
              color: "warning",
              text:
                (response && response.data && response.data.message) ||
                toString(),
            });
            this.refreshList();
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        var data = {
          caseId: this.casefile.id,
          comment: this.editedItem.comment,
          createdBy: this.$store.state.auth.user.id,
        };

        CommentDataService.create(data)
          .then((response) => {
            this.editedItem.id = response.data.id;
            this.$store.dispatch("setSnackbar", {
              showing: true,
              color: "success",
              text:
                (response && response.data && response.data.message) ||
                toString(),
            });
            this.editedItem.comment = "";
            this.refreshList();
          })
          .catch((e) => {
            this.$store.dispatch("setSnackbar", {
              showing: true,
              text:
                (e.response && e.response.data && e.response.data.message) ||
                e.message ||
                toString(),
            });
          });
      }
    },
  },
  mounted() {
    this.retrieveComments();
    this.checkManagement();
    this.retrieveUser();
  },
};
</script>
