import Vue from "vue";
import App from "./App.vue";
import { router } from "./router";
import store from "./store";
// import "bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
import VeeValidate from "vee-validate";
import Vuex from "vuex";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";

import setupInterceptors from "./services/setupInterceptors";
import vuetify from "./plugins/vuetify";

// import JsonExcel from "vue-json-excel";
// Vue.component("downloadExcel", JsonExcel);

import VueExcelXlsx from "vue-excel-xlsx";
Vue.use(VueExcelXlsx);

library.add(faHome, faUser, faUserPlus, faSignInAlt, faSignOutAlt);

Vue.config.productionTip = false;

Vue.use(VeeValidate);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.use(Vuex);

setupInterceptors(store);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
