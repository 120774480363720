<template>
  <v-app>
    <div id="the-content">
      <v-main>
        <app-bar />
        <page-route-title
          v-if="
            !['login', 'forgot', 'reset', 'activate', 'NotFound'].includes(
              $route.name
            )
          "
        />
        <router-view />
      </v-main>
    </div>
    <v-overlay :opacity="1" :value="overlay">
      <v-progress-circular indeterminate size="64">
        Loading...
      </v-progress-circular>
    </v-overlay>
    <staticFooter />
    <v-scale-transition group>
      <template>
        <v-snackbar
          v-for="snackbar in snackbars.filter((s) => s.showing)"
          :key="snackbar.text + Math.random()"
          v-model="snackbar.showing"
          :color="snackbar.color"
          :timeout="timeout"
          :bottom="bottom"
          :left="left"
        >
          {{ snackbar.text }}

          <!-- <v-btn text @click="snackbar.showing = false"> Close </v-btn> -->
          <template v-slot:action="{}">
            <v-btn x-small fab text @click="snackbar.showing = false">
              <v-icon dark>mdi-close</v-icon></v-btn
            >
          </template>
        </v-snackbar>
      </template>
    </v-scale-transition>
    <!-- v-snackbar previous -->
    <!-- <template>
      <v-scale-transition>
        <v-snackbar
          v-for="(snackbar, index) in snackbars.filter((s) => s.showing)"
          :key="snackbar.text + Math.random()"
          v-model="snackbar.showing"
          :timeout="2000"
          :color="snackbar.color"
          :style="`bottom: ${index * 60 + 8}px`"
          transition="scale-transition"
        >
          {{ snackbar.text }}

          <v-btn text @click="snackbar.showing = false"> Close </v-btn>
        </v-snackbar>
      </v-scale-transition>
    </template> -->
    <!-- GO TO TOP SCROLLER -->
    <v-row>
      <v-fab-transition>
        <v-btn
          v-scroll="onScroll"
          v-show="fab"
          fab
          dark
          fixed
          bottom
          right
          color="indigo"
          @click="toTop"
        >
          <v-icon>mdi-apple-keyboard-shift</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-row>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import staticFooter from "./components/staticFooter.vue";
import appBar from "./components/appBar.vue";
import PageRouteTitle from "./components/page-route-title.vue";

export default {
  data: () => ({
    fab: false,
    overlay: true,
    timeout: 2000,
    left: true,
    bottom: true,
  }),
  mounted() {
    // hide the overlay when everything has loaded
    // you could choose some other event, e.g. if you're loading
    // data asynchronously, you could wait until that process returns
    this.overlay = false;
  },
  components: {
    staticFooter,
    appBar,
    PageRouteTitle,
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    ...mapState(["snackbars"]),
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
  // mounted() {
  //   if (!this.currentUser) {
  //     this.$router.push("/login");
  //   easy workaround but not solution
  //   this.$router.push("/login").catch(()=>{});
  //   }
  // },
};
</script>
<style>
.v-card--material {
  position: relative;
  /* top: -64px; */
  top: 20px;
  /* margin-bottom: -32px; */
}

.v-cardheading {
  position: relative;
  top: -25px;
  transition: 0.3s ease;
  z-index: 1;
  text-align: end;
}
</style>
