<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on: tooltip }">
        <v-btn
          :disabled="assignEditorDisabled"
          class="success mr-1"
          fab
          x-small
          v-on="{ ...tooltip }"
          @click="assignEditor()"
        >
          <v-icon>mdi-account-edit-outline</v-icon>
        </v-btn>
      </template>
      <span>ASSIGN EDITOR</span>
    </v-tooltip>

    <!-- THE DIALOG BEGINS HERE -->
    <v-dialog persistent v-model="dialogAssignEditor" max-width="750px">
      <v-card class="text-center">
        <v-card-title class="justify-center text-h5 grey lighten-2">
          <span class="text-h5">ASSIGN EDITOR</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="branch.brCode"
                  :items="filteredBranches"
                  item-text="name"
                  item-value="brCode"
                  :menu-props="{ maxHeight: '400' }"
                  label="BRANCH"
                  hint="Select Branch"
                  persistent-hint
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="currentEditor"
                  :items="filterEditorByBranch(branch.brCode)"
                  item-text="username"
                  item-value="id"
                  no-data-text="There is no Editor for the branch above"
                  :menu-props="{ maxHeight: '400' }"
                  label="EDITOR/ BRANCH CLERK / BRANCH MANAGER / CLERK / MANAGER"
                  hint="Assign Editor"
                  persistent-hint
                ></v-select>
              </v-col>
              <!-- Editors:
              <span v-for="editor in editors" :key="editor.id">
                <pre>
            {{ editor.branches }}
              </pre
                >
              </span> -->
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            tile
            dark
            small
            @click="closeAssignEditorDialog"
            class="mt-3 mr-2 error"
          >
            Cancel
          </v-btn>
          <v-btn
            :disabled="isAddFileButtonDisabled"
            tile
            small
            class="mt-3 success"
            @click="compAssignEditor"
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CasefileDataService from "../../services/CasefileDataService";
import MailDataService from "../../services/MailDataService";
import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "AssignEditor",
  props: [
    "branches",
    "branch",
    "editors",
    "management",
    "insurer",
    "refType",
    "subRefTypes",
    "members",
  ],
  components: {},

  data() {
    return {
      dialogAssignEditor: false,
      currentEditor: "",
    };
  },

  computed: {
    filteredBranches() {
      if (
        this.$store.state.auth.user.roles.includes("MANAGER") ||
        this.$store.state.auth.user.roles.includes("ADMIN") ||
        this.$store.state.auth.user.roles.includes("CLERK")
      ) {
        return this.branches;
      } else
        return this.branches.filter(
          (x) =>
            // this.$store.state.auth.user.branches.includes(x.id)
            // this.caseState.casefile.branch.includes(x.id)
            x.id === this.caseState.casefile.branch
        );
    },

    assignEditorDisabled() {
      return !(this.caseState.casefile.fileStatus == `PEDI` && this.management);
    },

    ...mapState(["caseState"]),

    isAddFileButtonDisabled() {
      return !(this.branch.brCode && this.currentEditor);
    },
  },
  watch: {
    assignEditor(val) {
      val || this.closeAssignEditorDialog();
    },
  },

  methods: {
    // moment: function (date) {
    //   return moment(date);
    // },
    filterBranchName(item) {
      if (!item) return "TBA";
      const filteredbranch = this.branches.filter(
        (branch) => branch.id === item
      );
      return filteredbranch[0].name;
    },
    filterBranches(item) {
      return this.branches.filter((branch) => branch.id === item);
    },

    findBrcode(item) {
      if (!item) return "TBA";
      const filteredbranch = this.branches.filter(
        (branch) => branch.id === item
      );
      return filteredbranch.map((b) => b.brCode);
    },

    filterDept(item) {
      return this.refType.filter((dept) => dept.id === item);
    },
    filterSubDept(item) {
      return this.subRefTypes.filter((subRef) => subRef.id === item);
    },
    filterIns(item) {
      return this.insurer.filter((insurer) => insurer.id === item);
    },
    // filterManagerkByBranch(item) {
    //   // console.log(JSON.stringify(item));
    //   const filter1 = this.clerks.filter(
    //     (a) =>
    //       a.branches.some((b) => b.brCode == item) &&
    //       a.roles.some(
    //         (c) => c.roleCode === "manager" || c.roleCode === "branchmanager"
    //       ) &&
    //       a.active
    //   );
    //   const email = filter1.map((d) => d.email);
    //   // console.log(email);
    //   return email;
    // },

    findUserEmail(item) {
      const user = this.members.filter((member) => member.id === item);
      return user[0] ? user[0].email : "sys.admin@associatedadjusters.com";
      // return user.map((b) => b.email);
    },
    filterEditorByBranch(item) {
      return this.editors.filter((r) =>
        r.branches.some((i) => i.brCode === item)
      );
    },
    assignEditor() {
      this.dialogAssignEditor = true;
    },

    compAssignEditor() {
      CasefileDataService.update(
        this.caseState.casefile.id,
        this.caseState.casefile,
        (this.caseState.casefile.editor = this.currentEditor),
        (this.caseState.casefile.fileStatus = `EDAC`),
        (this.caseState.casefile.dateOfEdi = moment().format()),
        (this.caseState.casefile.assignEdiBy = this.$store.state.auth.user.id)
      )
        .then((response) => {
          this.closeAssignEditorDialog();
          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "warning",
            text:
              (response && response.data && response.data.message) ||
              toString(),
          });
        })

        .then(() => {
          // const assignedBranch = this.caseState.casefile.branch;
          var dataEmail = {
            id: this.caseState.casefile.id,
            refType: this.filterDept(this.caseState.casefile.refType)[0]
              .description,
            refTypeCode: this.filterDept(this.caseState.casefile.refType)[0]
              .name,
            subRefType: this.filterSubDept(
              this.caseState.casefile.subRefType
            )[0].subCodeDesc,
            subRefCode: this.filterSubDept(
              this.caseState.casefile.subRefType
            )[0].subCode,
            branch: this.filterBranchName(this.caseState.casefile.branch),
            branchCode: this.filterBranches(this.caseState.casefile.branch)[0]
              .brCode,
            insurer: this.filterIns(this.caseState.casefile.insurer)[0].name,
            vehicleNo: this.caseState.casefile.vehicleNo
              .toLowerCase()
              .replace(/\s/g, ""),
            // recipient: this.filterManagerkByBranch(
            //   this.findBrcode(assignedBranch)
            // ),
            recipient: this.findUserEmail(this.currentEditor),
            createdBy: this.$store.state.auth.user.id,
          };
          MailDataService.editorAccept(dataEmail).then((response) => {
            this.$store.dispatch("setSnackbar", {
              showing: true,
              color: "warning",
              text:
                (response && response.data && response.data.message) ||
                toString(),
            });
          });
          // console.log(JSON.stringify(dataEmail));
          this.dataEmail = "";
        })

        .catch((e) => {
          console.log(e);
        });

      this.closeAssignEditorDialog();
    },

    closeAssignEditorDialog() {
      this.dialogAssignEditor = false;
      this.$nextTick(() => {});
    },
  },
};
</script>
