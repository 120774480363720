<template>
  <v-container fluid style="width: 80%">
    <div>
      <v-data-table
        dense
        :headers="headers"
        :items="states"
        :search="search"
        class="elevation-1"
        :footer-props="{
          showFirstLastPage: true,
          'items-per-page-options': [10, 20, 30, 40, 50, 100, -1],
        }"
        :items-per-page="50"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-dialog v-model="dialog" max-width="500px" persistent>
              <template v-slot:activator="{ on: dialog, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      fab
                      small
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="{ ...tooltip, ...dialog }"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>ADD STATE</span>
                </v-tooltip>
              </template>

              <v-card>
                <v-card-title class="justify-center text-h5 grey lighten-2">
                  <span>{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedItem.name"
                          label="Name"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedItem.stCode"
                          label="Code"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn outlined small tile @click="close"> Cancel </v-btn>
                  <v-btn
                    :disabled="isAddButtonDisabled"
                    small
                    tile
                    class="success"
                    @click="save"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-divider class="mx-4" inset vertical></v-divider>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="justify-center text-h5 grey lighten-2"
                  >Confirm delete?
                  <!-- {{ editedItem.name }}? -->
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn outlined small tile @click="closeDelete">No</v-btn>
                  <v-btn small tile class="error" @click="deleteItemConfirm"
                    >Yes</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              clearable
            ></v-text-field>
          </v-toolbar>
        </template>

        <template slot="item.numbering" slot-scope="props">
          {{ props.index + 1 }}
        </template>

        <!-- Trick to truncate text and show elipsis at the end  -->
        <!-- <template v-slot:[`item.name`]="{ value }">
          <div class="text-truncate" style="max-width: 40px">
            {{ value }}
          </div>
        </template> -->

        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left transition="fab-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span> edit</span>
          </v-tooltip>
          <v-tooltip right transition="fab-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                v-on="on"
                color="red darken-1"
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <span> delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import StateDataService from "../../services/StateDataService";
export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    states: [],
    search: "",
    defaultItem: {
      id: null,
      name: "",
      stCode: "",
    },
    editedIndex: -1,
    editedItem: {
      id: null,
      name: "",
      stCode: "",
    },
    headers: [
      {
        text: "NO.",
        align: "start",
        sortable: false,
        value: "numbering",
      },
      {
        text: "STATES",
        align: "start",
        sortable: true,
        value: "name",
      },
      {
        text: "STATE CODE",
        align: "center",
        value: "stCode",
        sortable: true,
      },
      {
        text: "ACTIONS",
        align: "center",
        value: "actions",
        sortable: false,
      },
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add State" : "Update State";
    },
    isAddButtonDisabled() {
      return !(this.editedItem.name && this.editedItem.stCode);
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    retrieveStates() {
      StateDataService.getAll()
        .then((response) => {
          this.states = response.data.map(this.getDisplayState);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    refreshList() {
      this.retrieveStates();
    },

    getDisplayState(state) {
      return {
        id: state.id,
        name:
          state.name.length > 30
            ? state.name.substr(0, 30) + "..."
            : state.name,
        stCode:
          state.stCode.length > 30
            ? state.stCode.substr(0, 30) + "..."
            : state.stCode,
      };
    },

    editItem(item) {
      this.editedIndex = this.states.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.states.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      StateDataService.delete(this.editedItem.id)
        .then((response) => {
          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "error",
            text:
              (response && response.data && response.data.message) ||
              toString(),
          });

          this.refreshList();
        })
        .catch((e) => {
          console.log(e);
        });

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        StateDataService.update(this.editedItem.id, this.editedItem)
          .then((response) => {
            this.close();
            this.$store.dispatch("setSnackbar", {
              showing: true,
              color: "warning",
              text:
                (response && response.data && response.data.message) ||
                toString(),
            });
            this.refreshList();
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        var data = {
          name: this.editedItem.name,
          stCode: this.editedItem.stCode,
        };

        const duplicateIndexName = this.states
          .map((item) => item.name.toLowerCase())
          .indexOf(this.editedItem.name.toLowerCase());

        const duplicateIndexCode = this.states
          .map((item) => item.stCode.toLowerCase())
          .indexOf(this.editedItem.stCode.toLowerCase());

        if (duplicateIndexName == 0) {
          this.close();
          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "error",
            text:
              "Duplicate State name =" + " " + "(" + this.editedItem.name + ")",
          });
          this.refreshList();
        } else if (duplicateIndexCode == 0) {
          this.close();
          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "error",
            text:
              "Duplicate State Code  =" +
              " " +
              "(" +
              this.editedItem.stCode +
              ")",
          });
          this.refreshList();
        } else {
          StateDataService.create(data)
            .then((response) => {
              this.editedItem.id = response.data.id;
              this.close();
              this.$store.dispatch("setSnackbar", {
                showing: true,
                color: "success",
                text:
                  (response && response.data && response.data.message) ||
                  toString(),
              });
              this.refreshList();
            })
            .catch((e) => {
              this.$store.dispatch("setSnackbar", {
                showing: true,
                text:
                  (e.response && e.response.data && e.response.data.message) ||
                  e.message ||
                  toString(),
              });
            });
        }
      }
      this.close();
    },
  },
  mounted() {
    this.retrieveStates();
  },
};
</script>
