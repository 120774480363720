import http from "../http-common";

class SubDeptDataService {
  getAll() {
    return http.get("/subDepts");
  }

  get(id) {
    return http.get(`/subDepts/${id}`);
  }

  create(data) {
    return http.post("/subDepts", data);
  }

  update(id, data) {
    return http.put(`/subDepts/${id}`, data);
  }

  delete(id) {
    return http.delete(`/subDepts/${id}`);
  }

  deleteAll() {
    return http.delete(`/subDepts`);
  }

  findBySubDept(name) {
    return http.get(`/subDepts?name=${name}`);
  }
}

export default new SubDeptDataService();
