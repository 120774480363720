<template>
  <!-- <div class="soonbox">
    <v-row>
      <v-col>
        <h1>404</h1>
      </v-col>
      <v-col>
        <p>The Page you're looking for isn't here.</p>
      </v-col>
    </v-row>
  </div> -->

  <div class="soonbox">
    <v-card flat class="text-center">
      <v-row class="pa-0 ma-auto">
        <!-- <v-card-text class="text-h2"> 404 </v-card-text> -->
        <v-card-text class="pa-0 ma-0">
          <v-row>
            <v-col class="text-h1"> 404 </v-col>
          </v-row>
        </v-card-text>
      </v-row>
      <v-row>
        <v-card-text class="pa-0 ma-0">
          <v-row>
            <v-col> The Page you're looking for isn't here. </v-col>
          </v-row>
        </v-card-text>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>
<style>
.soonbox {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
