import http from "../http-common";

class TatChartDataService {
  getAll() {
    return http.get("/tatcharts");
  }

  get(id) {
    return http.get(`/tatcharts/${id}`);
  }

  create(data) {
    return http.post("/tatcharts", data);
  }

  update(id, data) {
    return http.put(`/tatcharts/${id}`, data);
  }

  delete(id) {
    return http.delete(`/tatcharts/${id}`);
  }

  deleteAll() {
    return http.delete(`/tatcharts`);
  }

  findByRegion(name) {
    return http.get(`/tatcharts?name=${name}`);
  }
}

export default new TatChartDataService();
