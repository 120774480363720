<template>
  <v-tooltip bottom>
    <template #activator="{ on: tooltip }">
      <span small v-on="{ ...tooltip }" icon>
        <v-icon v-if="casefile.flag === 1" color="green lighten-1">
          mdi-shield-check
        </v-icon>
        <v-icon v-if="casefile.flag === 2" color="orange lighten-1">
          mdi-shield-half-full
        </v-icon>
        <v-icon v-if="casefile.flag === 3" color="red darken-5">
          mdi-shield-alert-outline
        </v-icon></span
      >
    </template>
    <span v-if="casefile.flag === 1">GENUINE</span>
    <span v-if="casefile.flag === 2">SUSPECTED FRAUD</span>
    <span v-if="casefile.flag === 3">FRAUD</span>
  </v-tooltip>
</template>
<script>
export default {
  name: "flagviewonly",
  props: ["casefile"],
  components: {},
  data() {
    return {
      flag: null,

      SelectOptions: [
        { name: "Genuine", value: 1 },
        { name: "Suspected Fraud", value: 2 },
        { name: "Fraud", value: 3 },
      ],
    };
  },
  computed: {},

  watch: {},

  methods: {},
};
</script>

<style scoped></style>
