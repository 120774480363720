<template>
  <div>
    <v-card-title class="pb-0">CASE DETAILS</v-card-title>
    <v-divider></v-divider>
    <!-- Card Box -->
    <v-row justify="center" align="center" class="ma-1">
      <v-col cols="12" lg="2" md="6" sm="12">
        <v-card>
          <v-card-text class="font-weight-black"> STATUS: </v-card-text>
          <v-card-text
            class="pt-0"
            v-for="fileStatus in filterStages(caseState.casefile.fileStatus)"
            :key="fileStatus.id"
            v-bind="fileStatus.casefile"
          >
            {{ fileStatus.name }}
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" lg="2" md="6" sm="12">
        <v-card v-if="caseState.casefile.branch">
          <v-card-text class="font-weight-black"> BRANCH: </v-card-text>
          <v-card-text
            class="pt-0"
            v-for="branch in filterBranches(caseState.casefile.branch)"
            :key="branch.id"
            v-bind="branch.casefile"
          >
            {{ branch.name }}
          </v-card-text>
        </v-card>
        <v-card v-else>
          <v-card-text class="font-weight-black"> BRANCH: </v-card-text>
          <v-card-text class="pt-0 font-italic text-caption">
            to be assigned
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" lg="2" md="6" sm="12">
        <v-card v-if="caseState.casefile.adjuster">
          <v-card-text class="font-weight-black"> ADJUSTER: </v-card-text>
          <v-card-text
            class="pt-0"
            v-for="adjuster in filterAdj(caseState.casefile.adjuster)"
            :key="adjuster.id"
            v-bind="adjuster.casefile"
          >
            {{ adjuster.username }}
          </v-card-text>
        </v-card>
        <v-card v-else>
          <v-card-text class="font-weight-black"> ADJUSTER: </v-card-text>
          <v-card-text class="pt-0 font-italic text-caption">
            to be assigned
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" lg="2" md="6" sm="12">
        <v-card v-if="caseState.casefile.editor">
          <v-card-text class="font-weight-black"> EDITOR: </v-card-text>
          <v-card-text
            class="pt-0"
            v-for="editor in filterEditor(caseState.casefile.editor)"
            :key="editor.id"
            v-bind="editor.casefile"
          >
            {{ editor.username }}
          </v-card-text>
        </v-card>
        <v-card v-else>
          <v-card-text class="font-weight-black"> EDITOR: </v-card-text>
          <v-card-text class="pt-0 font-italic text-caption">
            to be assigned
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" lg="2" md="6" sm="12">
        <v-card v-if="caseState.casefile.approvedBy">
          <v-card-text class="font-weight-black"> APPROVER: </v-card-text>
          <v-card-text
            class="pt-0"
            v-for="approver in filterApprover(caseState.casefile.approvedBy)"
            :key="approver.id"
            v-bind="approver.casefile"
          >
            {{ approver.username }}
          </v-card-text>
        </v-card>
        <v-card v-else>
          <v-card-text class="font-weight-black"> APPROVER: </v-card-text>
          <v-card-text class="pt-0 font-italic text-caption">
            to be assigned
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" lg="2" md="6" sm="12">
        <v-card v-if="caseState.casefile.clerkInCharge">
          <v-card-text class="font-weight-black"> CLERK: </v-card-text>
          <v-card-text
            class="pt-0"
            v-for="clerk in filterClerk(caseState.casefile.clerkInCharge)"
            :key="clerk.id"
            v-bind="clerk.casefile"
          >
            {{ clerk.username }}
          </v-card-text>
        </v-card>
        <v-card v-else>
          <v-card-text class="font-weight-black"> CLERK: </v-card-text>
          <v-card-text class="pt-0 font-italic text-caption">
            to be assigned
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Details Row -->

    <v-row no-gutters>
      <v-col cols="1"> </v-col>
      <v-col cols="11">
        <!--ROW 1 -->
        <v-row no-gutters class="d-flex">
          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">Vehicle No.:</v-card-text>
          </v-col>

          <v-col
            class="bottom-border"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text
              v-if="caseState.casefile.vehicleNo"
              class="ma-0 pa-0"
              >{{ caseState.casefile.vehicleNo.toUpperCase() }}</v-card-text
            >
          </v-col>

          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">Our Ref.:</v-card-text>
          </v-col>
          <v-col
            class="bottom-border"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">
              AA/<span
                v-for="(dept, index) in filterDept(caseState.casefile.refType)"
                :key="index"
                >{{ dept.name
                }}<span
                  v-for="(subDept, index) in filterSubDept(
                    caseState.casefile.subRefType
                  )"
                  :key="index"
                  >/{{ subDept.subCode }}</span
                ></span
              >/{{ caseState.casefile.id }}/{{
                yearOnly(caseState.casefile.createdAt)
              }}/<span
                v-for="branch in filterBranches(caseState.casefile.branch)"
                :key="branch.id"
                >{{ branch.brCode }}
              </span></v-card-text
            >
          </v-col>
        </v-row>

        <!--ROW 2 -->
        <v-row no-gutters class="d-flex">
          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">Date of Loss:</v-card-text>
          </v-col>

          <v-col
            class="bottom-border"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">{{
              formatDate(caseState.casefile.dateOfLoss)
            }}</v-card-text>
          </v-col>

          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">Place of Loss:</v-card-text>
          </v-col>
          <v-col
            class="bottom-border"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0" v-if="caseState.casefile.placeOfLoss"
              >{{ caseState.casefile.placeOfLoss }},
              <span
                v-for="state in filterStates(caseState.casefile.stateOfLoss)"
                :key="state.id"
                >{{ state.name }}
              </span></v-card-text
            >

            <v-card-text v-else class="ma-0 pa-0 font-italic text-caption">
              to be assigned
            </v-card-text>
          </v-col>
        </v-row>
        <!--ROW 3 -->
        <v-row no-gutters class="d-flex">
          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">Claim Type:</v-card-text>
          </v-col>

          <v-col
            class="bottom-border"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text
              class="ma-0 pa-0"
              v-for="(dept, index) in filterDept(caseState.casefile.refType)"
              :key="index"
              >{{ dept.name }}</v-card-text
            >
          </v-col>

          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">Assignment Date:</v-card-text>
          </v-col>
          <v-col
            class="bottom-border"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">{{
              formatDate(caseState.casefile.dateOfAssign)
            }}</v-card-text>
          </v-col>
        </v-row>
        <!--ROW 3a -->
        <v-row no-gutters class="d-flex">
          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0"></v-card-text>
          </v-col>

          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0"></v-card-text>
          </v-col>

          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">Acknowledgement Date:</v-card-text>
          </v-col>
          <v-col
            class="bottom-border"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">{{
              formatDate(caseState.casefile.createdAt)
            }}</v-card-text>
          </v-col>
        </v-row>
        <!--ROW 3-b -->
        <v-row no-gutters class="d-flex">
          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">Case Creator:</v-card-text>
          </v-col>

          <v-col
            class="bottom-border"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text
              class="ma-0 pa-0"
              v-for="(creator, index) in filterUser(
                caseState.casefile.createdBy
              )"
              :key="index"
              >{{ creator.username }}</v-card-text
            >
          </v-col>

          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">Creator Branch:</v-card-text>
          </v-col>
          <v-col
            class="bottom-border"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text
              class="ma-0 pa-0"
              v-for="(creator, index) in filterUser(
                caseState.casefile.createdBy
              )"
              :key="index"
              ><span v-for="(b, index) in creator.branches" :key="index"
                >{{ b.name }}
              </span></v-card-text
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!--INSURED DETAILS-->
    <v-row no-gutters class="d-flex">
      <v-col class="my-2" cols="12" lg="12" md="12" sm="12" align="left">
        <span class="font-weight-black"> INSURED DETAILS: </span>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="1"></v-col>
      <v-col cols="11">
        <!--ROW 4 -->
        <v-row no-gutters class="d-flex">
          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">Insured:</v-card-text>
          </v-col>

          <v-col
            class="bottom-border"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">{{
              caseState.casefile.insuredName
            }}</v-card-text>
          </v-col>

          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">NRIC:</v-card-text>
          </v-col>
          <v-col
            class="bottom-border"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">{{
              caseState.casefile.insuredIC
            }}</v-card-text>
          </v-col>
        </v-row>
        <!--ROW 5 -->
        <v-row no-gutters class="d-flex">
          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">Tel:</v-card-text>
          </v-col>

          <v-col
            class="bottom-border"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">{{
              caseState.casefile.insuredTel
            }}</v-card-text>
          </v-col>

          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">Email:</v-card-text>
          </v-col>
          <v-col
            class="bottom-border"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0"
              >{{ caseState.casefile.insuredEmail }}
            </v-card-text>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!--INSURED DRIVER -->
    <v-row no-gutters class="d-flex">
      <v-col class="my-2" cols="12" lg="12" md="12" sm="12" align="left">
        <span class="font-weight-black"> INSURED DRIVER DETAILS: </span>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="1"></v-col>
      <v-col cols="11">
        <!--ROW 6 -->
        <v-row no-gutters class="d-flex">
          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">Insured Driver:</v-card-text>
          </v-col>

          <v-col
            class="bottom-border"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">{{
              caseState.casefile.insuredDriverName
            }}</v-card-text>
          </v-col>

          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">NRIC:</v-card-text>
          </v-col>
          <v-col
            class="bottom-border"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">{{
              caseState.casefile.insuredDriverIc
            }}</v-card-text>
          </v-col>
        </v-row>
        <!--ROW 7 -->
        <v-row no-gutters class="d-flex">
          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">Tel: </v-card-text>
          </v-col>

          <v-col
            class="bottom-border"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0"
              >{{ caseState.casefile.insuredDriverTel }}
            </v-card-text>
          </v-col>

          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">Email: </v-card-text>
          </v-col>
          <v-col
            class="bottom-border"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0"
              >{{ caseState.casefile.insuredDriverEmail }}
            </v-card-text>
          </v-col>
        </v-row>
        <!--ROW 7a -->
        <v-row no-gutters class="d-flex">
          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">Police Report No. </v-card-text>
          </v-col>

          <v-col
            class="bottom-border"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0"
              >{{ caseState.casefile.policeReportNo }}
            </v-card-text>
          </v-col>

          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">Date: </v-card-text>
          </v-col>
          <v-col
            class="bottom-border"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0"
              >{{ formatDate(caseState.casefile.policeReportDate) }}
            </v-card-text>
          </v-col>
        </v-row>
        <!--ROW 8 -->
        <v-row no-gutters class="d-flex">
          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">Time: </v-card-text>
          </v-col>

          <v-col
            class="bottom-border"
            cols="12"
            lg="9"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0"
              >{{ formatTime(caseState.casefile.policeReportDate) }}
            </v-card-text>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!--INSURER DETAILS -->
    <v-row no-gutters class="d-flex">
      <v-col class="my-2" cols="12" lg="12" md="12" sm="12" align="left">
        <span class="font-weight-black"> INSURER DETAILS: </span>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="1"></v-col>
      <v-col cols="11">
        <!--ROW 9 -->
        <v-row no-gutters class="d-flex">
          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">Insurer: </v-card-text>
          </v-col>

          <v-col
            class="bottom-border"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text
              class="ma-0 pa-0"
              v-for="insurer in filterIns(caseState.casefile.insurer)"
              :key="insurer.id"
            >
              {{ insurer.name }}
            </v-card-text>
          </v-col>

          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">Claim No.: </v-card-text>
          </v-col>
          <v-col
            class="bottom-border"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0"
              >{{ caseState.casefile.claimNo }}
            </v-card-text>
          </v-col>
        </v-row>
        <!--ROW 10 -->
        <v-row no-gutters class="d-flex">
          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">Policy/ Cover No: </v-card-text>
          </v-col>

          <v-col
            class="bottom-border"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0"
              >{{ caseState.casefile.policyNo }}
            </v-card-text>
          </v-col>

          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">Coverage: </v-card-text>
          </v-col>
          <v-col
            class="bottom-border"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0"
              >{{ formatDate(caseState.casefile.coverFrom) }} –
              {{ formatDate(caseState.casefile.coverTo) }}
            </v-card-text>
          </v-col>
        </v-row>
        <!--ROW 11 -->
        <v-row no-gutters class="d-flex">
          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">Claims Handler: </v-card-text>
          </v-col>

          <v-col
            class="bottom-border"
            cols="12"
            lg="9"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text
              class="ma-0 pa-0"
              v-for="handler in filterHandler(caseState.casefile.handler)"
              :key="handler.id"
              >{{ handler.name }}
            </v-card-text>
          </v-col>

          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">Tel: </v-card-text>
          </v-col>
          <v-col
            class="bottom-border"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text
              v-for="handler in filterHandler(caseState.casefile.handler)"
              :key="handler.id"
              class="ma-0 pa-0"
              >{{ handler.offTel }}
            </v-card-text>
          </v-col>
          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">Mobile: </v-card-text>
          </v-col>
          <v-col
            class="bottom-border"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text
              v-for="handler in filterHandler(caseState.casefile.handler)"
              :key="handler.id"
              class="ma-0 pa-0"
              >{{ handler.mobile }}
            </v-card-text>
          </v-col>
        </v-row>
        <!--ROW 12 -->
        <v-row no-gutters class="d-flex">
          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">Email: </v-card-text>
          </v-col>
          <v-col
            class="bottom-border"
            cols="12"
            lg="9"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text
              v-for="handler in filterHandler(caseState.casefile.handler)"
              :key="handler.id"
              class="ma-0 pa-0"
              >{{ handler.email }}
            </v-card-text>
          </v-col>
        </v-row>
        <!--ROW 12-a -->
        <v-row no-gutters class="d-flex">
          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">Insurer’s remarks: </v-card-text>
          </v-col>
          <v-col
            class="bottom-border"
            cols="12"
            lg="9"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0"
              >{{ caseState.casefile.insComment }}
            </v-card-text>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!--INSURER SOLICITOR DETAILS -->
    <v-row no-gutters class="d-flex">
      <v-col class="my-2" cols="12" lg="12" md="12" sm="12" align="left">
        <span class="font-weight-black"> INSURER SOLICITOR DETAILS: </span>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="1"></v-col>
      <v-col cols="11">
        <!--ROW 13 -->
        <v-row no-gutters class="d-flex">
          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">Insurer’s Solicitor: </v-card-text>
          </v-col>

          <v-col
            class="bottom-border"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0"
              >{{ caseState.casefile.solicitorName }}
            </v-card-text>
          </v-col>

          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">Tel: </v-card-text>
          </v-col>
          <v-col
            class="bottom-border"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0"
              >{{ caseState.casefile.solicitorTel }}
            </v-card-text>
          </v-col>
        </v-row>
        <!--ROW 13-a -->
        <v-row no-gutters class="d-flex">
          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0"> </v-card-text>
          </v-col>

          <v-col
            class="bottom-border"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0 red--text"> </v-card-text>
          </v-col>

          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">Email: </v-card-text>
          </v-col>
          <v-col
            class="bottom-border"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0"
              >{{ caseState.casefile.solicitorEmail }}
            </v-card-text>
          </v-col>
        </v-row>
        <!--ROW 14-b -->
        <v-row no-gutters class="d-flex">
          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">Attending Counsel: </v-card-text>
          </v-col>

          <v-col
            class="bottom-border"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0"
              >{{ caseState.casefile.counselName }}
            </v-card-text>
          </v-col>

          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">Tel: </v-card-text>
          </v-col>
          <v-col
            class="bottom-border"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0"
              >{{ caseState.casefile.counselTel }}
            </v-card-text>
          </v-col>
        </v-row>
        <!--ROW 14-b -->
        <v-row no-gutters class="d-flex">
          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0"> </v-card-text>
          </v-col>

          <v-col
            class="bottom-border"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0 red--text"> </v-card-text>
          </v-col>

          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">Email: </v-card-text>
          </v-col>
          <v-col
            class="bottom-border"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0"
              >{{ caseState.casefile.counselEmail }}
            </v-card-text>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!--THIRD PARTY CLAIMANT -->
    <v-row no-gutters class="d-flex">
      <v-col class="my-2" cols="12" lg="12" md="12" sm="12" align="left">
        <span class="font-weight-black"> THIRD PARTY CLAIMANT DETAILS: </span>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="1"></v-col>
      <v-col cols="11">
        <!--ROW 15 -->
        <v-row no-gutters class="d-flex">
          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">Third Party Claimant: </v-card-text>
          </v-col>

          <v-col
            class="bottom-border"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0"
              >{{ caseState.casefile.thirdPartyClaimantName }}
            </v-card-text>
          </v-col>

          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">NRIC: </v-card-text>
          </v-col>
          <v-col
            class="bottom-border"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0"
              >{{ caseState.casefile.thirdPartyClaimantIc }}
            </v-card-text>
          </v-col>
        </v-row>
        <!--ROW 16 -->
        <v-row no-gutters class="d-flex">
          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">Tel: </v-card-text>
          </v-col>

          <v-col
            class="bottom-border"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0"
              >{{ caseState.casefile.thirdPartyClaimantTel }}
            </v-card-text>
          </v-col>

          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">Email: </v-card-text>
          </v-col>
          <v-col
            class="bottom-border"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0"
              >{{ caseState.casefile.thirdPartyClaimantEmail }}
            </v-card-text>
          </v-col>
        </v-row>
        <!--ROW 17 -->
        <v-row no-gutters class="d-flex">
          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">Vehicle Number: </v-card-text>
          </v-col>

          <v-col
            class="bottom-border"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0"
              >{{ caseState.casefile.thirdPartyClaimantVehicleNo }}
            </v-card-text>
          </v-col>
          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">Vehicle Model: </v-card-text>
          </v-col>

          <v-col
            class="bottom-border"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0"
              >{{ caseState.casefile.thirdPartyClaimantVehicleModel }}
            </v-card-text>
          </v-col>
        </v-row>
        <!--ROW 18 -->
        <v-row no-gutters class="d-flex">
          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">Claimant Solicitor: </v-card-text>
          </v-col>

          <v-col
            class="bottom-border"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0"
              >{{ caseState.casefile.thirdPartyClaimantSolicitor }}
            </v-card-text>
          </v-col>

          <v-col
            class="main-column"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0">Email: </v-card-text>
          </v-col>
          <v-col
            class="bottom-border"
            cols="12"
            lg="3"
            md="6"
            sm="6"
            align="left"
          >
            <v-card-text class="ma-0 pa-0"
              >{{ caseState.casefile.thirdPartyClaimantSolicitorEmail }}
            </v-card-text>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";

import { mapState } from "vuex";

export default {
  name: "detailTab",
  props: [
    "adjusters",
    "editors",
    "clerks",
    "branches",
    "insurer",
    "handlers",
    "refType",
    "subRefTypes",
    "members",
    "stages",
    "states",
  ],
  components: {},

  data() {
    return {
      // tab: null,
    };
  },

  computed: {
    ...mapState(["caseState"]),
  },

  watch: {},

  methods: {
    moment: function (date) {
      return moment(date);
    },
    // filterRegions(item) {
    //   return this.regions.filter((region) => region.branchId === item);
    // },
    // filtersubRefType(item) {
    //   return this.subRefTypes.filter(
    //     (subRefType) => subRefType.deptId === item
    //   );
    // },
    filterSubDept(item) {
      return this.subRefTypes.filter((subRef) => subRef.id === item);
    },

    yearOnly(value) {
      // return moment(value).format("YYYY");
      return moment(value).format("YY");
    },
    // filterAdjByBranch(item) {
    //   return this.adjusters.filter((r) =>
    //     r.branches.some((i) => i.brCode === item)
    //   );
    // },
    filterStages(item) {
      return this.stages.filter((stage) => stage.stageCode === item);
    },
    filterDept(item) {
      return this.refType.filter((dept) => dept.id === item);
    },

    filterIns(item) {
      return this.insurer.filter((insurer) => insurer.id === item);
    },
    filterAdj(item) {
      return this.adjusters.filter((adjuster) => adjuster.id === item);
    },
    filterEditor(item) {
      return this.editors.filter((editor) => editor.id === item);
    },
    filterApprover(item) {
      return this.members.filter((approver) => approver.id === item);
    },
    filterClerk(item) {
      return this.clerks.filter((clerk) => clerk.id === item);
    },
    filterBranches(item) {
      return this.branches.filter((branch) => branch.id === item);
    },

    // currentUserBranch() {
    //   if (
    //     this.$store.state.auth.user.roles.includes("MANAGER") ||
    //     this.$store.state.auth.user.roles.includes("ADMIN") ||
    //     this.$store.state.auth.user.roles.includes("CLERK")
    //   ) {
    //     return this.branches;
    //   } else
    //     return this.branches.filter(
    //       (x) => x.id === this.caseState.casefile.branch
    //     );
    // },

    // filterTest(item) {
    //   return this.branches
    //     .filter((branch) => branch.id === item)
    //     .map((branch) => branch.brCode);
    // },
    filterStates(item) {
      return this.states.filter((state) => state.id === item);
    },
    filterHandler(item) {
      return this.handlers.filter((handler) => handler.id === item);
    },
    filterUser(item) {
      return this.members.filter((member) => member.id === item);
    },

    formatDate: function (date) {
      if (!date) return "";
      return moment(date).format("DD/MM/YYYY");
    },
    formatTime: function (date) {
      if (!date) return "";
      return moment(date).format("h:mm:ss a");
    },

    dateDiff(startDateString, endDateString) {
      // Method 01
      let start = moment(startDateString);
      let end = moment(endDateString);
      let duration = moment.duration(end.diff(start));
      return Math.round(duration.asDays());

      // Method 02
      // var start = moment(startDateString); //todays date
      // var end = moment(endDateString); // another date
      // var duration = moment.duration(end.diff(start));
      // var days = duration.asDays();
      // return Math.round(days);
    },
    refreshList() {
      // this.retrieveCasefiles(this.$route.params.id);
      // this.retrieveAllCase();
    },

    // retrieveMembers() {
    //   MemberDataService.getAll()
    //     .then((response) => {
    //       this.adjusters = response.data.filter((item) =>
    //         item.roles.some((b) => b.roleCode === "adjuster")
    //       );
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //     });
    // },
    // retrieveEditors() {
    //   MemberDataService.getAll()
    //     .then((response) => {
    //       this.editors = response.data.filter((item) =>
    //         item.roles.some(
    //           (b) =>
    //             b.roleCode === "editor" ||
    //             b.roleCode === "clerk" ||
    //             b.roleCode === "manager" ||
    //             b.roleCode === "branchclerk" ||
    //             b.roleCode === "branchmanager"
    //         )
    //       );
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //     });
    // },
    // retrieveClerks() {
    //   MemberDataService.getAll()
    //     .then((response) => {
    //       this.clerks = response.data.filter((item) =>
    //         item.roles.some(
    //           (b) =>
    //             b.roleCode === "clerk" ||
    //             b.roleCode === "manager" ||
    //             b.roleCode === "branchclerk" ||
    //             b.roleCode === "branchmanager"
    //         )
    //       );
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //     });
    // },

    // retrieveBranches() {
    //   BranchDataService.getAll()
    //     .then((response) => {
    //       this.branches = response.data;
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //     });
    // },

    // retrieveCasefiles(id) {
    //   CasefileDataService.get(id)
    //     .then((response) => {
    //       this.caseState.casefile = response.data;
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //     });
    // },
  },
  mounted() {
    // this.retrieveCasefiles(this.$route.params.id);
  },
};
</script>

<style scoped>
.main-column {
  border-bottom: 1px solid grey;
  background-color: aliceblue;
}
.bottom-border {
  border-bottom: 1px solid grey;
}

.bottom-border div {
  font-weight: bold;
}
</style>
