<template>
  <v-container fluid style="width: 80%">
    <div>
      <v-data-table
        dense
        :headers="headers"
        :items="inss"
        :search="search"
        class="elevation-1"
        :footer-props="{
          showFirstLastPage: true,
          'items-per-page-options': [10, 20, 30, 40, 50, 100, -1],
        }"
        :items-per-page="50"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <!-- <v-toolbar-title>My CRUD</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider> -->

            <!-- <v-spacer></v-spacer> -->
            <v-dialog v-model="dialog" max-width="500px" persistent>
              <template v-slot:activator="{ on: dialog, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      fab
                      small
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="{ ...tooltip, ...dialog }"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>ADD INSURER</span>
                </v-tooltip>
              </template>

              <v-card tile>
                <v-card-title class="justify-center text-h5 grey lighten-2">
                  <span>{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.name"
                          label="Name [required]"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.formerName"
                          label="Former name"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          type="number"
                          suffix="Days"
                          v-model="editedItem.tatAlert"
                          label="TAT-ALERT"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          type="number"
                          suffix="Days"
                          v-model="editedItem.tatMax"
                          label="TAT-MAX"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-textarea
                          v-model="editedItem.insAddLine01"
                          label="Street Address"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-textarea
                          v-model="editedItem.insAddLine02"
                          label="Area"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedItem.insPostcode"
                          label="Postcode"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedItem.insTown"
                          label="Town/ City"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedItem.insState"
                          label="State"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn outlined small tile @click="close"> Cancel </v-btn>
                  <v-btn
                    :disabled="isAddButtonDisabled"
                    small
                    tile
                    class="success"
                    @click="save"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-divider class="mx-4" inset vertical></v-divider>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="justify-center text-h5 grey lighten-2"
                  >Confirm delete?
                  <!-- {{ editedItem.name }}? -->
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn outlined small tile @click="closeDelete">No</v-btn>
                  <v-btn small tile class="error" @click="deleteItemConfirm"
                    >Yes</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              clearable
            ></v-text-field>
          </v-toolbar>
        </template>

        <template slot="item.numbering" slot-scope="props">
          {{ props.index + 1 }}
        </template>
        <template v-slot:[`item.id`]="{ item }">
          <span>
            {{ item.insAddLine01 }}
            {{ item.insAddLine02 }}
            {{ item.insPostcode }}
            {{ item.insTown }}
            {{ item.insState }}
          </span>
        </template>

        <template v-slot:[`item.name`]="{ item }">
          <span>
            <!-- <div class="text-truncate" style="max-width: 100px"> -->
            {{ item.name }}
            <!-- </div> -->
          </span>
        </template>
        <!-- Trick to truncate text and show elipsis at the end  -->
        <!-- <template v-slot:[`item.name`]="{ value }">
          <div class="text-truncate" style="max-width: 40px">
            {{ value }}
          </div>
        </template> -->
        <template v-slot:[`item.tatAlert`]="{ item }">
          <div v-if="item.tatAlert">{{ item.tatAlert }} Days</div>
          <div v-else>TBA</div>
        </template>
        <template v-slot:[`item.tatMax`]="{ item }">
          <div v-if="item.tatMax">{{ item.tatMax }} Days</div>
          <div v-else>TBA</div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left transition="fab-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span> edit</span>
          </v-tooltip>
          <v-tooltip right transition="fab-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                v-on="on"
                color="red darken-1"
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <span> delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import InsDataService from "../../services/InsDataService";
export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    inss: [],
    search: "",
    defaultItem: {
      id: null,
      name: "",
      formerName: "",
      insAddLine01: "",
      insAddLine02: "",
      insTown: "",
      insState: "",
      insPostcode: "",
      tatAlert: "",
      tatMax: "",
    },
    editedIndex: -1,
    editedItem: {
      id: null,
      name: "",
      formerName: "",
      insAddLine01: "",
      insAddLine02: "",
      insTown: "",
      insState: "",
      insPostcode: "",
      tatAlert: "",
      tatMax: "",
    },
    headers: [
      {
        text: "NO.",
        align: "start",
        sortable: false,
        value: "numbering",
      },
      {
        text: "NAME",
        align: "start",
        sortable: true,
        value: "name",
      },
      {
        text: "ADDRESS",
        align: "start",
        value: "id",
        sortable: false,
      },
      {
        text: "TAT-ALERT",
        align: "start",
        value: "tatAlert",
        sortable: false,
      },
      {
        text: "TAT-MAX",
        align: "start",
        value: "tatMax",
        sortable: false,
      },
      {
        text: "ACTIONS",
        align: "center",
        value: "actions",
        sortable: false,
      },
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add Insurer" : "Update Insurer";
    },
    isAddButtonDisabled() {
      return !this.editedItem.name;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    retrieveInss() {
      InsDataService.getAll()
        .then((response) => {
          // this.inss = response.data.map(this.getDisplayIns);
          this.inss = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    refreshList() {
      this.retrieveInss();
    },

    getDisplayIns(insurer) {
      return {
        id: insurer.id,
        name:
          insurer.name.length > 20
            ? insurer.name.substr(0, 20) + "..."
            : insurer.name,
        formerName:
          insurer.formerName.length > 30
            ? insurer.formerName.substr(0, 30) + "..."
            : insurer.formerName,
        insAddLine01:
          insurer.insAddLine01.length > 30
            ? insurer.insAddLine01.substr(0, 30) + "..."
            : insurer.insAddLine01,
        insAddLine02:
          insurer.insAddLine02.length > 30
            ? insurer.insAddLine02.substr(0, 30) + "..."
            : insurer.insAddLine02,
        insTown: insurer.insTown,
        insState: insurer.insState,
        insPostcode: insurer.insPostcode,
        tatAlert: insurer.tatAlert,
        tatMax: insurer.tatMax,
      };
    },

    editItem(item) {
      this.editedIndex = this.inss.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.inss.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      InsDataService.delete(this.editedItem.id)
        .then((response) => {
          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "error",
            text:
              (response && response.data && response.data.message) ||
              toString(),
          });

          this.refreshList();
        })
        .catch((e) => {
          console.log(e);
        });

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        InsDataService.update(this.editedItem.id, this.editedItem)
          .then((response) => {
            this.close();
            this.$store.dispatch("setSnackbar", {
              showing: true,
              color: "warning",
              text:
                (response && response.data && response.data.message) ||
                toString(),
            });
            this.refreshList();
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        var data = {
          name: this.editedItem.name,
          formerName: this.editedItem.formerName,
          insAddLine01: this.editedItem.insAddLine01,
          insAddLine02: this.editedItem.insAddLine02,
          insTown: this.editedItem.insTown,
          insState: this.editedItem.insState,
          insPostcode: this.editedItem.insPostcode,
          tatAlert: this.editedItem.tatAlert,
          tatMax: this.editedItem.tatMax,
        };

        InsDataService.create(data)
          .then((response) => {
            this.editedItem.id = response.data.id;
            this.close();
            this.$store.dispatch("setSnackbar", {
              showing: true,
              color: "success",
              text:
                (response && response.data && response.data.message) ||
                toString(),
            });
            this.refreshList();
          })
          .catch((e) => {
            this.$store.dispatch("setSnackbar", {
              showing: true,
              text:
                (e.response && e.response.data && e.response.data.message) ||
                e.message ||
                toString(),
            });
          });
      }
      this.close();
    },
  },
  mounted() {
    this.retrieveInss();
  },
};
</script>
