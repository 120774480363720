<template>
  <div>
    <v-container fluid style="width: 80%">
      <div v-if="dropboxfiles.id && fileaccess">
        <!-- TOOLBAR START -->
        <v-row>
          <v-toolbar dense color="indigo" dark>
            <!-- ACCEPT FILE -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  class="success mr-1"
                  fab
                  x-small
                  :disabled="assignDisabled"
                  v-on="{ ...tooltip }"
                  @click="assignFile()"
                >
                  <v-icon>mdi-file-cabinet</v-icon>
                </v-btn>
              </template>
              <span>ACCEPT FILE</span>
            </v-tooltip>
            <!-- CANCEL INCOMING FILE -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  class="warning mr-1"
                  fab
                  x-small
                  :disabled="cancelIncDisabled"
                  v-on="{ ...tooltip }"
                  @click="cancelInc()"
                >
                  <v-icon>mdi-file-minus-outline</v-icon>
                </v-btn>
              </template>
              <span>CANCEL INCOMING</span>
            </v-tooltip>
            <v-spacer></v-spacer>

            <!-- DAYS-->
            <v-chip v-if="dateDiff" outlined small class="purple lighten-3"
              >{{
                dateDiff(
                  moment(dropboxfiles.createdAt).format(),
                  moment().format()
                )
              }}
              Day(s)</v-chip
            >
          </v-toolbar>

          <!-- GO TO TOP SCROLLER -->
          <v-btn
            v-scroll="onScroll"
            v-show="fab"
            fab
            dark
            fixed
            bottom
            right
            color="indigo"
            @click="toTop"
          >
            <v-icon>mdi-apple-keyboard-shift</v-icon>
          </v-btn>
        </v-row>
        <!-- TOOLBAR END -->
        <v-row>
          <v-col cols="12">
            <!-- Action Row -->
            <v-row no-gutters class="mt-2">
              <v-col cols="12" justify="center" align="center">
                <v-dialog v-model="dialogFile" max-width="750px">
                  <v-card tile>
                    <v-card-title class="justify-center text-h5 grey lighten-2">
                      <span class="text-h5">ACCEPT FILE</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-card width="100%">
                            <v-card-title>Department</v-card-title>
                            <v-col cols="12">
                              <v-select
                                v-model="dropboxfiles.refType"
                                :items="refType"
                                item-text="name"
                                item-value="id"
                                :menu-props="{ maxHeight: '400' }"
                                hint="Select Department"
                                persistent-hint
                                label="Department"
                              >
                                <template #label>
                                  <span class="red--text"
                                    ><strong>* </strong></span
                                  >Department
                                </template>
                              </v-select>
                            </v-col>
                            <v-col cols="12">
                              <v-select
                                v-model="dropboxfiles.subRefType"
                                :items="filtersubRefType(dropboxfiles.refType)"
                                item-text="subCode"
                                item-value="id"
                                no-data-text="There is no file-Classification option for selected department above"
                                :menu-props="{ maxHeight: '400' }"
                                label="File-Classification"
                                hint="Select File-Classification"
                                persistent-hint
                              ></v-select>
                            </v-col>
                          </v-card>
                          <v-col cols="12">
                            <v-autocomplete
                              v-model="dropboxfiles.insurer"
                              :items="insurer"
                              item-text="name"
                              item-value="id"
                              :menu-props="{ maxHeight: '400' }"
                              hint="Set the casefile insurer"
                              persistent-hint
                            >
                              <template #label>
                                <span class="red--text"
                                  ><strong>* </strong></span
                                >Insurer
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="12">
                            <v-select
                              v-model="dropboxfiles.handler"
                              :items="filterHandler(dropboxfiles.insurer)"
                              item-text="name"
                              item-value="id"
                              no-data-text="There is no handler option for selected insurer above"
                              :menu-props="{ maxHeight: '400' }"
                              label="Claim Handler"
                              hint="Select Handler"
                              persistent-hint
                            ></v-select>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="dropboxfiles.claimNo"
                              item-text="Insurer's Reference"
                              :rules="[
                                (v) => !!v || 'Insurers reference is required',
                              ]"
                            >
                              <template #label>
                                <span class="red--text"
                                  ><strong>* </strong></span
                                >Insurers Reference
                              </template></v-text-field
                            >
                          </v-col>

                          <v-col cols="12" sm="6" md="6">
                            <template>
                              <div>
                                <v-menu
                                  v-model="menu"
                                  :close-on-content-click="false"
                                  max-width="290"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="dropboxfiles.dateOfAssign"
                                      :value="computedDateFormattedMomentjs"
                                      clearable
                                      prepend-icon="mdi-calendar"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                      @click:clear="date = null"
                                    >
                                      <template #label>
                                        <span class="red--text"
                                          ><strong>* </strong></span
                                        >Date Of Assignment
                                      </template>
                                    </v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="dropboxfiles.dateOfAssign"
                                    :value="computedDateFormattedMomentjs"
                                    @change="menu = false"
                                  ></v-date-picker>
                                </v-menu>
                              </div>
                            </template>
                          </v-col>

                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="dropboxfiles.vehicleNo"
                              :rules="[
                                (v) => !!v || 'Vehicle Number is required',
                              ]"
                            >
                              <template #label>
                                <span class="red--text"
                                  ><strong>* </strong></span
                                >Vehicle Number
                              </template></v-text-field
                            >
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <template>
                              <div>
                                <v-menu
                                  v-model="menu1"
                                  :close-on-content-click="false"
                                  max-width="290"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="dropboxfiles.dateOfLoss"
                                      :value="computedDateFormattedMomentjs"
                                      clearable
                                      prepend-icon="mdi-calendar"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                      @click:clear="date = null"
                                    >
                                      <template #label>
                                        <span class="red--text"
                                          ><strong>* </strong></span
                                        >Date Of Loss
                                      </template>
                                    </v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="dropboxfiles.dateOfLoss"
                                    :value="computedDateFormattedMomentjs"
                                    @change="menu1 = false"
                                  ></v-date-picker>
                                </v-menu>
                              </div>
                            </template>
                          </v-col>
                          <v-col cols="12">
                            <v-spacer></v-spacer>
                            <v-btn
                              outlined
                              small
                              tile
                              @click="closeFile"
                              class="mt-3 mr-2"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              :disabled="isAddFileButtonDisabled"
                              small
                              tile
                              class="mt-3 success"
                              @click="addCasefile"
                              >Submit</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogIncCancelCase" max-width="500px">
                  <v-card>
                    <v-card-title class="justify-center text-h5 grey lighten-2"
                      >Cancel Incoming File?</v-card-title
                    >
                    <!-- <v-card-text> Are you sure? </v-card-text> -->
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn outlined small tile @click="closeCancelIncCase"
                        >Cancel</v-btn
                      >
                      <v-btn small tile class="error" @click="cancelIncConfirm"
                        >OK</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>

            <!-- Details Row -->
            <v-card class="mt-3 pa-3">
              <v-card-title class="pb-0">CASE DETAILS</v-card-title>
              <v-divider></v-divider>

              <!--ROW 1 -->
              <v-row no-gutters class="d-flex">
                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Vehicle No.:</v-card-text
                  >
                </v-col>

                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0">{{
                    dropboxfiles.vehicleNo
                  }}</v-card-text>
                </v-col>

                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Our Ref.:</v-card-text
                  >
                </v-col>
                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0"> </v-card-text>
                </v-col>
              </v-row>
              <!--ROW 2 -->
              <v-row no-gutters class="d-flex">
                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Date of Loss:</v-card-text
                  >
                </v-col>

                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0">{{
                    formatDate(dropboxfiles.dateOfLoss)
                  }}</v-card-text>
                </v-col>

                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Place of Loss:</v-card-text
                  >
                </v-col>
                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0" v-if="dropboxfiles.placeOfLoss"
                    >{{ dropboxfiles.placeOfLoss }},
                    <span
                      v-for="state in filterStates(dropboxfiles.stateOfLoss)"
                      :key="state.id"
                      >{{ state.name }}
                    </span></v-card-text
                  >

                  <v-card-text
                    v-else
                    class="ma-0 pa-0 font-italic text-caption"
                  >
                    to be assigned
                  </v-card-text>
                </v-col>
              </v-row>
              <!--ROW 3 -->
              <v-row no-gutters class="d-flex">
                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Claim Type:</v-card-text
                  >
                </v-col>

                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text
                    class="ma-0 pa-0"
                    v-for="(dept, index) in filterDept(dropboxfiles.refType)"
                    :key="index"
                    >{{ dept.name }}</v-card-text
                  >
                </v-col>

                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Assignment Date:</v-card-text
                  >
                </v-col>
                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0">{{
                    formatDate(dropboxfiles.dateOfAssign)
                  }}</v-card-text>
                </v-col>
              </v-row>
              <!--ROW 3a -->
              <v-row no-gutters class="d-flex">
                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text
                    class="ma-0 pa-0 font-weight-black"
                  ></v-card-text>
                </v-col>

                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0"></v-card-text>
                </v-col>

                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Acknowledgement Date:</v-card-text
                  >
                </v-col>
                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0">{{
                    formatDate(dropboxfiles.createdAt)
                  }}</v-card-text>
                </v-col>
              </v-row>
              <!--ROW 3-b -->
              <v-row no-gutters class="d-flex">
                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Case Creator:</v-card-text
                  >
                </v-col>

                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                </v-col>

                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Creator Branch:</v-card-text
                  >
                </v-col>
                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                </v-col>
              </v-row>
              <!--INSURER DETAILS -->
              <v-row no-gutters class="d-flex">
                <v-col
                  class="my-2"
                  cols="12"
                  lg="12"
                  md="12"
                  sm="12"
                  align="left"
                >
                  <span class="font-weight-black"> INSURER DETAILS: </span>
                </v-col>
              </v-row>
              <!--ROW 9 -->
              <v-row no-gutters class="d-flex">
                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Insurer:
                  </v-card-text>
                </v-col>

                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text
                    class="ma-0 pa-0"
                    v-for="insurer in filterIns(dropboxfiles.insurer)"
                    :key="insurer.id"
                  >
                    {{ insurer.name }}
                  </v-card-text>
                </v-col>

                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Claim No.:
                  </v-card-text>
                </v-col>
                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0"
                    >{{ dropboxfiles.claimNo }}
                  </v-card-text>
                </v-col>
              </v-row>
              <!--ROW 11 -->
              <v-row no-gutters class="d-flex">
                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Claims Handler:
                  </v-card-text>
                </v-col>

                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="9"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text
                    class="ma-0 pa-0"
                    v-for="handler in filterHandler(dropboxfiles.handler)"
                    :key="handler.id"
                    >{{ handler.name }}
                  </v-card-text>
                </v-col>

                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Tel:
                  </v-card-text>
                </v-col>
                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text
                    v-for="handler in filterHandler(dropboxfiles.handler)"
                    :key="handler.id"
                    class="ma-0 pa-0"
                    >{{ handler.offTel }}
                  </v-card-text>
                </v-col>
                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Mobile:
                  </v-card-text>
                </v-col>
                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text
                    v-for="handler in filterHandler(dropboxfiles.handler)"
                    :key="handler.id"
                    class="ma-0 pa-0"
                    >{{ handler.mobile }}
                  </v-card-text>
                </v-col>
              </v-row>
              <!--ROW 12 -->
              <v-row no-gutters class="d-flex">
                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Email:
                  </v-card-text>
                </v-col>
                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="9"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text
                    v-for="handler in filterHandler(dropboxfiles.handler)"
                    :key="handler.id"
                    class="ma-0 pa-0"
                    >{{ handler.email }}
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-layout class="justify-center mt-5">
          No result(s) to Display. Did you refresh the page?</v-layout
        >
      </div>
    </v-container>
  </div>
</template>

<script>
import DropboxfileDataService from "../../services/DropboxfileDataService";
import CasefileDataService from "../../services/CasefileDataService";
import StagesDataService from "../../services/StageDataService";
import DeptDataService from "../../services/DeptDataService";
import SubRefTypeDataService from "../../services/SubDeptDataService";
import InsDataService from "../../services/InsDataService";
import HandlerDataService from "../../services/HandlerDataService";

import moment from "moment";

export default {
  name: "dropboxDetail",
  components: {},

  data() {
    return {
      dropboxfiles: [],
      fileaccess: false,
      management: false,
      stages: [],
      refType: [],
      subRefTypes: [],
      insurer: [],
      handlers: [],

      dialogFile: false,
      dialogIncCancelCase: false,
      menu: false,
      menu1: false,
      fab: false,
    };
  },

  computed: {
    assignDisabled() {
      return !(
        (this.dropboxfiles.fileStatus == `INC` ||
          this.dropboxfiles.fileStatus == `NEW`) &&
        this.management
      );
    },
    cancelIncDisabled() {
      return !(this.dropboxfiles.fileStatus == `INC` && this.management);
    },
    isAddFileButtonDisabled() {
      return !(
        this.dropboxfiles.refType &&
        this.dropboxfiles.insurer &&
        this.dropboxfiles.claimNo &&
        this.dropboxfiles.vehicleNo &&
        this.dropboxfiles.dateOfLoss
      );
    },
    computedDateFormattedMomentjs() {
      return this.date ? moment(this.date).format("DD/MM/YY") : "";
    },
  },
  watch: {
    assignFile(val) {
      val || this.closeFile();
    },
    cancelInc(val) {
      val || this.closeCancelIncCase();
    },
  },

  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },

    getDeptCode(item) {
      return `${item.name} - (${item.subCode})`;
    },
    filtersubRefType(item) {
      return this.subRefTypes.filter(
        (subRefType) => subRefType.deptId === item
      );
    },
    moment: function (date) {
      return moment(date);
    },
    yearOnly(value) {
      return moment(value).format("YY");
    },
    filterStages(item) {
      return this.stages.filter((stage) => stage.stageCode === item);
    },
    filterDept(item) {
      return this.refType.filter((dept) => dept.id === item);
    },
    filterSubDept(item) {
      return this.subRefTypes.filter((subRef) => subRef.id === item);
    },
    filterIns(item) {
      return this.insurer.filter((insurer) => insurer.id === item);
    },
    filterHandler(item) {
      return this.handlers.filter((handler) => handler.insId === item);
    },
    checkManagement() {
      if (
        this.$store.state.auth.user.roles.includes(`ADMIN`) ||
        this.$store.state.auth.user.roles.includes(`MANAGER`) ||
        this.$store.state.auth.user.roles.includes(`BRANCHMANAGER`) ||
        this.$store.state.auth.user.roles.includes(`BRANCHCLERK`)
      ) {
        this.management = true;
      }
    },
    formatDate: function (date) {
      return moment(date).format("DD/MM/YYYY");
    },
    formatTime: function (date) {
      return moment(date).format("h:mm:ss a");
    },
    dateDiff(startDateString, endDateString) {
      // Method 01
      let start = moment(startDateString);
      let end = moment(endDateString);
      let duration = moment.duration(end.diff(start));
      return Math.round(duration.asDays());
    },
    refreshList() {
      this.retrieveCasefiles(this.$route.params.id);
    },
    retrieveDept() {
      DeptDataService.getAll()
        .then((response) => {
          this.refType = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveSubRefType() {
      SubRefTypeDataService.getAll()
        .then((response) => {
          this.subRefTypes = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveIns() {
      InsDataService.getAll()
        .then((response) => {
          this.insurer = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveHandler() {
      HandlerDataService.getAll()
        .then((response) => {
          this.handlers = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveStages() {
      StagesDataService.getAll()
        .then((response) => {
          this.stages = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveCasefiles(id) {
      DropboxfileDataService.get(id)
        .then((response) => {
          this.dropboxfiles = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    assignFile() {
      this.dialogFile = true;
    },
    cancelInc() {
      this.dialogIncCancelCase = true;
    },
    addCasefile() {
      var data = {
        refType: this.dropboxfiles.refType,
        subRefType: this.dropboxfiles.subRefType,
        insurer: this.dropboxfiles.insurer,
        handler: this.dropboxfiles.handler,
        claimNo: this.dropboxfiles.claimNo,
        dateOfAssign: this.dropboxfiles.dateOfAssign,
        vehicleNo: this.dropboxfiles.vehicleNo,
        dateOfLoss: this.dropboxfiles.dateOfLoss,
        createdBy: this.$store.state.auth.user.id,
        flag: 1,
        fileStatus: `NEW`,
        caseFrom: `MERIMEN`,
      };

      CasefileDataService.create(data)
        .then((response) => {
          // this.dropboxfiles.id = response.data.id;
          this.closeFile();
          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "success",
            text:
              (response && response.data && response.data.message) ||
              toString(),
          });
          // this.refreshList();
        })

        .then(() => {
          DropboxfileDataService.delete(this.dropboxfiles.id)
            .then((response) => {
              // this.closeFile();
              this.$store.dispatch("setSnackbar", {
                showing: true,
                color: "success",
                text:
                  (response && response.data && response.data.message) ||
                  toString(),
              });
              this.refreshList();
            })
            .catch((e) => {
              this.$store.dispatch("setSnackbar", {
                showing: true,
                color: "error",
                text: e || toString(),
              });
            });
          this.closeFile();
          this.$router.push("/dropboxfiles");
          // this.$router.go();
        })

        .catch((e) => {
          this.$store.dispatch("setSnackbar", {
            showing: true,
            text:
              (e.response && e.response.data && e.response.data.message) ||
              e.message ||
              toString(),
          });
        });
    },
    cancelIncConfirm() {
      DropboxfileDataService.update(
        this.dropboxfiles.id,
        this.dropboxfiles,
        (this.dropboxfiles.fileStatus = `CINC`)
      )
        .then((response) => {
          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "warning",
            text:
              (response && response.data && response.data.message) ||
              toString(),
          });

          this.refreshList();
        })
        .catch((e) => {
          console.log(e);
        });

      this.closeCancelIncCase();
      this.$router.push("/dropboxfiles");
    },
    closeFile() {
      this.dialogFile = false;
      this.$nextTick(() => {});
    },
    closeCancelIncCase() {
      this.dialogIncCancelCase = false;
      this.$nextTick(() => {
        this.dropboxfiles.fileStatus = "INC";
      });
    },
    checkFileaccess() {
      if (
        this.$store.state.auth.user.roles.includes("MANAGER") ||
        this.$store.state.auth.user.roles.includes("ADMIN")
      ) {
        this.fileaccess = true;
        // console.log("Admin");
      } else if (
        this.$store.state.auth.user.roles.includes("BRANCHCLERK") ||
        (this.$store.state.auth.user.roles.includes("BRANCHMANAGER") &&
          this.$store.state.auth.user.dept.includes(
            this.dropboxfiles.refType
          ) &&
          this.$store.state.auth.user.branches.includes(
            this.dropboxfiles.branch
          ))
      ) {
        this.fileaccess = true;
        // console.log("Branch Manager or Branch Clerk");
      } else if (
        this.$store.state.auth.user.roles.includes("ADJUSTER") &&
        this.$store.state.auth.user.dept.includes(this.dropboxfiles.refType) &&
        this.$store.state.auth.user.branches.includes(
          this.dropboxfiles.branch
        ) &&
        // this.$store.state.auth.user.adjuster.includes(
        //   this.dropboxfiles.id.toString()

        this.$store.state.auth.user.id === this.dropboxfiles.adjuster
      ) {
        this.fileaccess = true;
        // console.log("Adjuster");
      } else if (
        this.$store.state.auth.user.roles.includes("EDITOR") &&
        this.$store.state.auth.user.dept.includes(this.dropboxfiles.refType) &&
        this.$store.state.auth.user.branches.includes(
          this.dropboxfiles.branch
        ) &&
        // this.$store.state.auth.user.adjuster.includes(
        //   this.dropboxfiles.id.toString()

        this.$store.state.auth.user.id === this.dropboxfiles.editor
      ) {
        this.fileaccess = true;
        // console.log("Editor");
      }
      // else this.fileaccess = false;
    },
  },
  mounted() {
    this.retrieveCasefiles(this.$route.params.id);
    this.retrieveDept();
    this.retrieveSubRefType();
    this.retrieveIns();
    this.retrieveHandler();
    this.retrieveStages();
    this.checkManagement();
    this.checkFileaccess();
  },
};
</script>
<style scoped>
.main-column {
  /* border-right: 1px solid green; */
  /* border-left: 1px solid green; */
  border-bottom: 1px solid grey;
  /* border-right: 1px solid grey; */
  /* border-left: 1px solid green; */
  /* background-color: rgb(214, 232, 233); */
  background-color: aliceblue;
}

.bottom-border {
  /* border-left: 1px solid blue; */
  border-bottom: 1px solid grey;
}
</style>
