<template>
  <div>
    <!-- <v-container class="container--fluid grid-list-md text-center"> -->
    <v-container fluid style="width: 80%" class="text-center">
      <v-tabs>
        <v-tab
          @click="
            dept = true;
            branch = false;
          "
          >Department</v-tab
        >
        <v-tab
          @click="
            branch = true;
            dept = false;
          "
          >Branch</v-tab
        >
      </v-tabs>

      <deptTab v-if="dept" />
      <brTab v-if="branch" />
    </v-container>
  </div>
</template>

<script>
import deptTab from "../components/tabs/department.vue";
import brTab from "../components/tabs/branch.vue";

export default {
  name: "dashboard",
  components: {
    deptTab,
    brTab,
  },
  data() {
    return {
      dept: true,
      branch: false,
    };
  },
};
</script>
