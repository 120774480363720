import http from "../http-common";

class BulletinDataService {
  getAll() {
    return http.get("/bulletins");
  }

  get(id) {
    return http.get(`/bulletins/${id}`);
  }

  create(data) {
    return http.post("/bulletins", data);
  }

  update(id, data) {
    return http.put(`/bulletins/${id}`, data);
  }

  delete(id) {
    return http.delete(`/bulletins/${id}`);
  }

  deleteAll() {
    return http.delete(`/bulletins`);
  }

  findByBulletin(newsTitle) {
    return http.get(`/bulletins?newsTitle=${newsTitle}`);
  }
}

export default new BulletinDataService();
