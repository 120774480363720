<template>
  <v-dialog v-model="flag" width="800px">
    <template #activator="{ on: dialog }">
      <v-tooltip bottom>
        <template #activator="{ on: tooltip }">
          <v-btn small v-on="{ ...tooltip, ...dialog }" icon>
            <v-icon v-if="casefile.flag === 1" color="green lighten-1">
              <!-- mdi-check-decagram-outline -->
              mdi-shield-check
            </v-icon>
            <v-icon v-if="casefile.flag === 2" color="orange lighten-1">
              <!-- mdi-check-decagram-outline -->
              mdi-shield-half-full
            </v-icon>
            <v-icon v-if="casefile.flag === 3" color="red darken-5">
              <!-- mdi-alarm-light-off-outline -->
              mdi-shield-alert-outline
            </v-icon></v-btn
          >
        </template>
        <span v-if="casefile.flag === 1">GENUINE</span>
        <span v-if="casefile.flag === 2">SUSPECTED FRAUD</span>
        <span v-if="casefile.flag === 3">FRAUD</span>
        <!-- <span v-else>INDECISIVE</span> -->
        <!-- <span>Change Case Status</span> -->
      </v-tooltip>
    </template>

    <v-card>
      <v-card-title class="justify-center text-h5 grey lighten-2">
        <span class="text-h5">CHANGE CASE STATUS</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="SelectOptions.value"
                :items="SelectOptions"
                item-text="name"
                item-value="value"
                :menu-props="{ maxHeight: '400' }"
                label="Status"
                hint="Select Status"
                persistent-hint
              ></v-select>
            </v-col>

            <v-col cols="12">
              <v-textarea
                v-model="commentData.comment"
                item-text="Add a remark"
                label="Case remark"
                :rules="[(v) => !!v || 'Remark is required']"
              >
                <template #label>
                  <span class="red--text"><strong>* </strong></span>Remark
                </template></v-textarea
              >
            </v-col>

            <v-divider></v-divider>
            <v-card-actions>
              <v-btn tile small outlined @click="closeFlag"> Cancel </v-btn>
              <v-btn
                :disabled="setFlagRule"
                tile
                small
                class="primary"
                @click="setFlag"
                >Submit</v-btn
              >
            </v-card-actions>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import CasefileDataService from "../../services/CasefileDataService";
import CommentDataService from "../../services/CommentDataService";

export default {
  name: "flag",
  props: ["casefile"],
  components: {},
  data() {
    return {
      flag: null,
      message: "",
      SelectOptions: [
        { name: "Genuine", value: 1 },
        { name: "Suspected Fraud", value: 2 },
        { name: "Fraud", value: 3 },
      ],
      commentData: {
        id: null,
        caseId: null,
        comment: "",
        createdBy: "",
      },
    };
  },
  computed: {
    setFlagRule() {
      return !(this.SelectOptions.value && this.commentData.comment);
    },
  },

  watch: {
    flag(val) {
      val || this.closeFlag();
    },
  },

  methods: {
    closeFlag() {
      this.flag = null;
      this.$nextTick(() => {
        this.SelectOptions.value = "";
        this.commentData.comment = "";
      });
    },

    setFlag() {
      this.message = "";

      var flagData = {
        flag: this.SelectOptions.value,
      };
      CasefileDataService.update(this.casefile.id, flagData)
        .then((response) => {
          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "success",
            text:
              (response && response.data && response.data.message) ||
              toString(),
          });
        })
        .then(() => {
          var data = {
            caseId: this.casefile.id,
            comment: this.commentData.comment,
            createdBy: this.$store.state.auth.user.id,
          };
          CommentDataService.create(data)
            .then((response) => {
              this.$store.dispatch("setSnackbar", {
                showing: true,
                color: "success",
                text:
                  (response && response.data && response.data.message) ||
                  toString(),
              });
              this.closeFlag();
              this.$router.push("/home");
              // this.$router.push("/file/" + this.casefile.id);
              // this.$router.go();
            })
            .catch((e) => {
              this.$store.dispatch("setSnackbar", {
                showing: true,
                color: "error",
                text: e || toString(),
              });
            });
        })
        .catch(() => {
          this.message = "Could not update the status!";
        });
    },
  },
};
</script>

<style scoped></style>
