<template>
  <div>
    <v-app-bar v-if="currentUser" color="transparent" flat dense>
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

      <v-toolbar-title>
        <!-- Home Button -->
        <v-tooltip bottom transition="fab-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="indigo"
              to="/home"
              icon
              style="text-decoration: none"
            >
              <v-icon>mdi-home-circle</v-icon>
            </v-btn>
          </template>
          <span> Home</span>
        </v-tooltip>

        <!-- Dashboard Button -->
        <v-tooltip bottom transition="fab-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="indigo"
              to="/dashboard"
              icon
              style="text-decoration: none"
            >
              <v-icon>mdi-view-dashboard</v-icon></v-btn
            >
          </template>
          <span> Dashboard</span>
        </v-tooltip>

        <!-- Quickfind Button -->
        <!-- <v-tooltip bottom transition="fab-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="indigo"
              to="/find"
              icon
              style="text-decoration: none"
            >
              <v-icon>mdi-clipboard-search-outline</v-icon></v-btn
            >
          </template>
          <span> Quick Find</span>
        </v-tooltip> -->

        <!-- Dropbox Button -->
        <v-tooltip bottom transition="fab-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="indigo"
              to="/dropboxfiles"
              icon
              style="text-decoration: none"
              v-if="showDropBox"
            >
              <v-icon>mdi-dropbox</v-icon></v-btn
            >
          </template>
          <span> Drop Box</span>
        </v-tooltip>

        <!-- Report Button -->
        <v-tooltip bottom transition="fab-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="indigo"
              to="/reports"
              icon
              style="text-decoration: none"
            >
              <v-icon>mdi-clipboard-file</v-icon></v-btn
            >
          </template>
          <span>Basic Report Generator</span>
        </v-tooltip>

        <!-- Report Button Two-->
        <v-tooltip bottom transition="fab-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="indigo"
              to="/reportsTwo"
              icon
              style="text-decoration: none"
            >
              <v-icon>mdi-clipboard-search</v-icon></v-btn
            >
          </template>
          <span>Detail Report Generator</span>
        </v-tooltip>

        <!-- Claims Button -->
        <v-tooltip bottom transition="fab-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="indigo"
              to="/claims"
              icon
              style="text-decoration: none"
            >
              <v-icon>mdi-cash-multiple</v-icon></v-btn
            >
          </template>
          <span>Claims</span>
        </v-tooltip>
      </v-toolbar-title>

      <!-- <div v-if="showAdminBoard" class="d-none d-lg-flex d-xl-none">
            <v-btn to="/admin" class="text-decoration-none" plain
              >Admin Board
            </v-btn>
          </div> -->

      <!-- <div v-if="showModeratorBoard" class="d-none d-lg-flex d-xl-none">
        <v-btn to="/mod" class="text-decoration-none" plain
          >Moderator Board
        </v-btn>
      </div> -->

      <v-spacer></v-spacer>
      <!-- Quick Find Module -->
      <!-- <v-col class="d-none d-sm-block">
        <v-text-field
          label="Quick Find"
          prepend-inner-icon="mdi-magnify"
          hide-details
          single-line
          value="searchId"
          v-on:keyup.enter="onEnter"
          v-model="searchId"
        ></v-text-field>
      </v-col> -->

      <!-- <v-btn icon>
        <v-icon>mdi-email</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon>mdi-alert-box</v-icon>
      </v-btn> -->

      <!-- <v-btn to="/profile" class="text-decoration-none" icon> -->
      <!-- <v-btn to="/profile" class="text-decoration-none" icon>
        <v-icon>mdi-account</v-icon>
      </v-btn> -->

      <!-- For Development -->
      <!-- <v-btn to="/profile" class="text-decoration-none" icon small>
        <v-avatar size="36px">
          <img
            v-if="currentUser.profile"
            alt="Avatar"
            :src="'http://localhost:8080/files/' + this.currentUser.profile"
          />
          <v-icon v-else>mdi-account</v-icon>
        </v-avatar>
      </v-btn> -->
      <!-- For Production -->
      <v-btn to="/profile" class="text-decoration-none" icon small>
        <v-avatar size="36px">
          <img
            v-if="currentUser.profile"
            alt="Avatar"
            :src="currentUser.profile"
          />
          <v-icon v-else>mdi-account</v-icon>
        </v-avatar>
      </v-btn>
      <!-- <v-btn to="/profile" class="text-decoration-none" icon small>
        <v-avatar size="36px">
          <img
            v-if="currentUser.profile"
            alt="Avatar"
            :src="'../files/' + this.currentUser.profile"
          />
          <v-icon v-else>mdi-account</v-icon>
        </v-avatar>
      </v-btn> -->

      <!-- <div v-if="currentUser" class="d-none d-lg-flex d-xl-none">
            <v-btn to="/user" class="text-decoration-none" plain>User </v-btn>
            <v-btn to="/tutorials" class="text-decoration-none" plain
              >Tutorials
            </v-btn>
            <v-btn to="/add" class="text-decoration-none" plain>Add </v-btn>
          </div> -->

      <div v-if="!currentUser">
        <!-- <v-btn to="/register" class="text-decoration-none" plain
          ><font-awesome-icon icon="user-plus" />Sign Up
        </v-btn> -->

        <v-btn to="/login" class="text-decoration-none" plain
          ><font-awesome-icon icon="sign-in-alt" />Login
        </v-btn>
      </div>

      <div v-else>
        <!-- <v-btn to="/profile" class="text-decoration-none" plain
              >{{ currentUser.username }}
            </v-btn> -->

        <!-- <v-btn @click.prevent="logOut" class="text-decoration-none" plain>
          LogOut
        </v-btn> -->
        <!-- <v-btn @click.prevent="logOut" icon>
          <v-icon>mdi-logout-variant</v-icon>
        </v-btn> -->

        <v-tooltip bottom transition="fab-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click.prevent="logOut"
              v-bind="attrs"
              v-on="on"
              icon
              style="text-decoration: none"
            >
              <v-icon>mdi-logout-variant</v-icon></v-btn
            >
          </template>
          <span>Logout</span>
        </v-tooltip>
      </div>
    </v-app-bar>

    <v-navigation-drawer
      v-if="currentUser"
      v-model="drawer"
      app
      temporary
      expand-on-hover
      dark
      src="../assets/v-nav-bg.jpg"
    >
      <v-list>
        <!-- <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-img src="../assets/profile.jpg"></v-img>
          </v-list-item-avatar>
        </v-list-item> -->

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              {{ currentUser.username }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ currentUser.email }}
            </v-list-item-subtitle>
            <v-divider></v-divider>
            <v-list-item-subtitle class="text-h7">
              <strong>Access:</strong>
            </v-list-item-subtitle>

            <v-list-item-subtitle class="text-h8">
              <ul>
                <li v-for="(role, index) in currentUser.roles" :key="index">
                  {{ role }}
                </li>
              </ul>
            </v-list-item-subtitle>
            <v-divider></v-divider>
            <v-list-item-subtitle class="text-h7">
              <strong>Branch:</strong>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="text-h7">
              <ul>
                <li v-for="(j, index) in currentUser.branches" :key="index">
                  <div v-for="(k, index) in filterStates(j)" :key="index">
                    {{ k.name.toUpperCase() }}
                  </div>
                </li>
              </ul>
            </v-list-item-subtitle>

            <v-list-item-subtitle class="text-h7">
              <strong>Department:</strong>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="text-h7">
              <!-- <strong>Department: {{ currentUser.dept }}</strong> -->
              <ul>
                <li v-for="(i, index) in currentUser.dept" :key="index">
                  <div v-for="(m, index) in filterDept(i)" :key="index">
                    {{ m.name.toUpperCase() }}
                  </div>
                </li>
              </ul>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <!-- admin links -->
      <v-list v-if="showAdminBoard" nav dense>
        <div v-for="(link, i) in adminlinks" :key="i">
          <v-list-item
            v-if="!link.subLinks"
            :to="link.to"
            class="v-list-item text-decoration-none"
            active-class="white--text"
          >
            <v-list-item-icon>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title v-text="link.text" />
          </v-list-item>

          <v-list-group
            v-else
            :key="link.text"
            no-action
            :prepend-icon="link.icon"
            :value="false"
            active-class="white--text"
          >
            <template v-slot:activator>
              <v-list-item-title>{{ link.text }}</v-list-item-title>
            </template>

            <v-list-item
              v-for="sublink in link.subLinks"
              :to="sublink.to"
              :key="sublink.text"
              class="text-decoration-none pl-5"
              active-class="white--text"
            >
              <v-list-item-icon>
                <v-icon>{{ sublink.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ sublink.text }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </div>
      </v-list>

      <!-- User Links -->
      <v-list nav dense>
        <div v-for="(link, i) in links" :key="i">
          <v-list-item
            v-if="!link.subLinks"
            :to="link.to"
            class="v-list-item text-decoration-none"
            active-class="white--text"
          >
            <v-list-item-icon>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title v-text="link.text" />
          </v-list-item>

          <v-list-group
            v-else
            :key="link.text"
            no-action
            :prepend-icon="link.icon"
            :value="false"
            active-class="white--text"
          >
            <template v-slot:activator>
              <v-list-item-title>{{ link.text }}</v-list-item-title>
            </template>

            <v-list-item
              v-for="sublink in link.subLinks"
              :to="sublink.to"
              :key="sublink.text"
              class="text-decoration-none pl-5"
              active-class="white--text"
            >
              <v-list-item-icon>
                <v-icon>{{ sublink.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ sublink.text }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </div>
      </v-list>
      <!-- Special Links -->
      <v-list v-if="showDropBox" nav dense>
        <div v-for="(link, i) in specialLinks" :key="i">
          <v-list-item
            v-if="!link.subLinks"
            :to="link.to"
            class="v-list-item text-decoration-none"
            active-class="white--text"
          >
            <v-list-item-icon>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title v-text="link.text" />
          </v-list-item>

          <v-list-group
            v-else
            :key="link.text"
            no-action
            :prepend-icon="link.icon"
            :value="false"
            active-class="white--text"
          >
            <template v-slot:activator>
              <v-list-item-title>{{ link.text }}</v-list-item-title>
            </template>

            <v-list-item
              v-for="sublink in link.subLinks"
              :to="sublink.to"
              :key="sublink.text"
              class="text-decoration-none pl-5"
              active-class="white--text"
            >
              <v-list-item-icon>
                <v-icon>{{ sublink.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ sublink.text }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </div>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
// import StateDataService from "../services/StateDataService";
import BranchDataService from "../services/BranchDataService";
import DeptDataService from "../services/DeptDataService";

import EventBus from "../common/EventBus";

export default {
  components: {},
  name: "appBar",

  data: () => ({
    searchId: "",
    drawer: false,
    adminlinks: [
      {
        icon: "mdi-shield-lock-outline",
        text: "Admin",
        subLinks: [
          // {
          //   text: "Casefile List",
          //   to: "/casefiles",
          //   icon: "mdi-fire",
          // },
          {
            text: "User",
            to: "/members",
            icon: "mdi-fire",
          },
          {
            text: "Branch",
            to: "/branches",
            icon: "mdi-fire",
          },
          {
            text: "Department",
            to: "/depts",
            icon: "mdi-fire",
          },
          {
            text: "File Classification",
            to: "/subDepts",
            icon: "mdi-fire",
          },
          {
            text: "State",
            to: "/states",
            icon: "mdi-fire",
          },
          {
            text: "District",
            to: "/districts",
            icon: "mdi-fire",
          },

          {
            text: "Insurer",
            to: "/inss",
            icon: "mdi-fire",
          },
          {
            text: "TAT List",
            to: "/tatcharts",
            icon: "mdi-fire",
          },
          {
            text: "Handler",
            to: "/handlers",
            icon: "mdi-fire",
          },
          // {
          //   text: "Stage",
          //   to: "/stages",
          //   icon: "mdi-fire",
          // },
          {
            text: "Bulletin",
            to: "/bulletins",
            icon: "mdi-fire",
          },
        ],
      },
      // {
      //   to: "/admin",
      //   icon: "mdi-view-dashboard",
      //   text: "Admin",
      // },
      // {
      //   icon: "mdi-shield-lock-outline",
      //   text: "User",
      //   subLinks: [
      //     {
      //       text: "User List",
      //       to: "/members",
      //       icon: "mdi-fire",
      //     },
      //     {
      //       text: "Add User",
      //       to: "/addMember",
      //       icon: "mdi-fire",
      //     },
      //   ],
      // },
      // {
      //   icon: "mdi-shield-lock-outline",
      //   text: "Branches",
      //   subLinks: [
      //     {
      //       text: "Branch List",
      //       to: "/branches",
      //       icon: "mdi-fire",
      //     },
      //     {
      //       text: "Add Branch",
      //       to: "/addBranch",
      //       icon: "mdi-fire",
      //     },
      //   ],
      // },
      // {
      //   icon: "mdi-shield-lock-outline",
      //   text: "Department",
      //   subLinks: [
      //     {
      //       text: "Department List",
      //       to: "/depts",
      //       icon: "mdi-fire",
      //     },
      //     {
      //       text: "Add Department",
      //       to: "/addDept",
      //       icon: "mdi-fire",
      //     },
      //   ],
      // },
      // {
      //   icon: "mdi-shield-lock-outline",
      //   text: "Insurer",
      //   subLinks: [
      //     {
      //       text: "Insurer List",
      //       to: "/inss",
      //       icon: "mdi-fire",
      //     },
      //     {
      //       text: "Add Insurer",
      //       to: "/addIns",
      //       icon: "mdi-fire",
      //     },
      //   ],
      // },
      // {
      //   icon: "mdi-shield-lock-outline",
      //   text: "Stage",
      //   subLinks: [
      //     {
      //       text: "Stage List",
      //       to: "/stages",
      //       icon: "mdi-fire",
      //     },
      //     {
      //       text: "Add Stage",
      //       to: "/addStage",
      //       icon: "mdi-fire",
      //     },
      //   ],
      // },

      // {
      //   icon: "mdi-shield-lock-outline",
      //   text: "Casefile",
      //   subLinks: [
      //     {
      //       text: "Casefile List",
      //       to: "/casefiles",
      //       icon: "mdi-fire",
      //     },
      //     {
      //       text: "Add Casefile",
      //       to: "/addCasefile",
      //       icon: "mdi-fire",
      //     },
      //   ],
      // },
    ],

    links: [
      {
        to: "/home",
        icon: "mdi-home-circle-outline",
        text: "Home",
      },
      {
        to: "/dashboard",
        icon: "mdi-view-dashboard",
        text: "Dashboard",
      },
      // {
      //   to: "/find",
      //   icon: "mdi-clipboard-search-outline",
      //   text: "Quick Find",
      // },
      // {
      //   to: "/dropboxfiles",
      //   icon: "mdi-dropbox",
      //   text: "Dropbox",

      // },
      {
        to: "/reports",
        icon: "mdi-clipboard-file",
        text: "Basic Report Generator",
      },
      {
        to: "/reportsTwo",
        icon: "mdi-clipboard-search",
        text: "Detail Report Generator",
      },
      {
        to: "/claims",
        icon: "mdi-cash-multiple",
        text: "Claims",
      },
    ],
    specialLinks: [
      {
        to: "/dropboxfiles",
        icon: "mdi-dropbox",
        text: "Dropbox",
      },
    ],
    branches: [],
    departments: [],
  }),

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes("ADMIN");
      }

      return false;
    },
    showDropBox() {
      if (this.currentUser && this.currentUser.roles) {
        let result = this.currentUser.roles.filter(
          (data) =>
            data.includes("ADMIN") ||
            // data.includes("BRANCHMANAGER") ||
            // data.includes("BRANCHCLERK") ||
            data.includes("CLERK")
        );
        if (result.length > 0) {
          return true;
        }
      }

      return false;
    },

    // showManagerBoard() {
    //   if (this.currentUser && this.currentUser.roles) {
    //     return this.currentUser.roles.includes("MANAGER");
    //   }

    //   return false;
    // },
  },
  methods: {
    filterStates(item) {
      return this.branches.filter((branch) => branch.id === item);
    },
    filterDept(item) {
      return this.departments.filter((dept) => dept.id === item);
    },
    retrieveStates() {
      BranchDataService.getAll()
        .then((response) => {
          this.branches = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveDepts() {
      DeptDataService.getAll()
        .then((response) => {
          this.departments = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // onEnter: function (value) {
    //   this.$router.push({
    //     name: "file",
    //     params: { id: value },
    //   });
    // },
    logOut() {
      this.$store.dispatch("setSnackbar", {
        showing: true,
        color: "success",
        text: "Successfully Logged Out!",
      });
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  },
  mounted() {
    this.retrieveStates();
    this.retrieveDepts();
    EventBus.on("logout", () => {
      this.logOut();
    });
  },
  beforeDestroy() {
    EventBus.remove("logout");
  },
};
</script>
