<template>
  <v-container>
    <v-row class="fill-height" align="center" justify="center">
      <template v-for="(item, i) in filteredBranch">
        <v-col :key="i" align="center">
          <v-hover v-slot="{ hover }">
            <v-card
              width="300px"
              shaped
              @click="viewItem(item.id)"
              :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }"
            >
              <v-img src="../../assets/002.jpg" height="100px">
                <v-card-title class="text-h4 white--text">
                  <v-row
                    class="fill-height flex-column"
                    justify="space-between"
                  >
                    <p class="mt-4 subheading font-weight-bold">
                      {{ item.brCode }}
                    </p>

                    <div>
                      <p class="text-caption font-weight-medium font-italic">
                        {{ item.name }}
                      </p>
                    </div>
                  </v-row>
                </v-card-title>
              </v-img>
            </v-card>
          </v-hover>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import BranchDataService from "../../services/BranchDataService";
export default {
  data() {
    return {
      branch: [],
    };
  },
  mounted() {
    this.retrieveBr();
  },
  computed: {
    filteredBranch() {
      if (
        !this.$store.state.auth.user.roles.includes("ADMIN") &&
        !this.$store.state.auth.user.roles.includes("MANAGER")
      ) {
        return this.branch.filter((x) =>
          this.$store.state.auth.user.branches.includes(x.id)
        );
      } else return this.branch;
    },
  },
  methods: {
    retrieveBr() {
      BranchDataService.getAll()
        .then((response) => {
          this.branch = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    viewItem(id) {
      this.$router.push({ name: "brTabDetail", params: { id: id } });
    },
  },
};
</script>
<style scoped>
.v-card {
  transition: all 0.4s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.6;
}
</style>
