<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on: tooltip }">
        <v-btn
          :disabled="completeInvestigationDisabled"
          class="success mr-1"
          fab
          x-small
          v-on="{ ...tooltip }"
          @click="completeInv()"
        >
          <v-icon>mdi-account-arrow-right-outline</v-icon>
        </v-btn>
      </template>
      <span>COMPLETE INVESTIGATION</span>
    </v-tooltip>

    <!-- THE DIALOG BEGINS HERE -->
    <v-dialog v-model="dialogCompleteInv" max-width="750px">
      <v-card>
        <v-card-title class="justify-center text-h5 grey lighten-2">
          <span class="text-h5">COMPLETE INVESTIGATION</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <!-- <v-card width="100%" class="mt-3 mb-3 text-start">
                <v-list subheader two-line flat dense>
                  <v-subheader class="text-h6">I confirm that:</v-subheader>

                  <v-list-item-group multiple>
                    <v-list-item>
                      <template v-slot:default="{ active }">
                        <v-list-item-action>
                          <v-checkbox
                            :input-value="active"
                            color="primary"
                            v-model="checkboxComplInv"
                          ></v-checkbox>
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title>Investigation</v-list-item-title>
                          <v-list-item-subtitle
                            >I have completed the case
                            investigation.</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                    <v-divider inset></v-divider>
                    <v-list-item>
                      <template v-slot:default="{ active }">
                        <v-list-item-action>
                          <v-checkbox
                            :input-value="active"
                            color="primary"
                            v-model="checkboxComplInv1"
                          ></v-checkbox>
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title>Documents</v-list-item-title>
                          <v-list-item-subtitle
                            >I have uploaded the intial case
                            report.</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card> -->
              <v-card-title>SUBMIT YOUR CLAIM DETAILS BELOW</v-card-title>
              <v-card width="100%" class="mx-auto mt-3 pa-3 text-start">
                <!-- <v-card-title class="text-h5 ma-0 mb-3 grey lighten-2">
                  Below are the claims:
                </v-card-title> -->
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      :rules="rules"
                      dense
                      type="number"
                      hide-spin-buttons
                      prefix="MYR"
                      v-model="claim.travMileage"
                      label="Travelling / Mileage"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      :rules="rules"
                      dense
                      type="number"
                      hide-spin-buttons
                      prefix="MYR"
                      v-model="claim.additional"
                      label="Additional"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      :rules="rules"
                      dense
                      type="number"
                      hide-spin-buttons
                      prefix="MYR"
                      v-model="claim.sdAndStamps"
                      label="SD & Stamps"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      :rules="rules"
                      dense
                      type="number"
                      hide-spin-buttons
                      prefix="MYR"
                      v-model="claim.medicalReport"
                      label="Medical Report"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      :rules="rules"
                      dense
                      type="number"
                      hide-spin-buttons
                      prefix="MYR"
                      v-model="claim.policeDoc"
                      label="Police Document"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      :rules="rules"
                      dense
                      type="number"
                      hide-spin-buttons
                      prefix="MYR"
                      v-model="claim.jpjDoc"
                      label="JPJ Document"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      :rules="rules"
                      dense
                      type="number"
                      hide-spin-buttons
                      prefix="MYR"
                      v-model="claim.misc"
                      label="Miscellaneous"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      :rules="rules"
                      dense
                      type="number"
                      hide-spin-buttons
                      prefix="MYR"
                      v-model="claim.typing"
                      label="Typing"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-spacer></v-spacer>
                    <v-btn
                      tile
                      dark
                      small
                      @click="closeCompleteInvDialog"
                      class="mt-3 mr-2 error"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      tile
                      small
                      :disabled="isAddFileButtonDisabled"
                      class="mt-3 success"
                      @click="compInvestigation"
                      >Submit</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CasefileDataService from "../../services/CasefileDataService";
import ClaimDataService from "../../services/ClaimDataService";
import MailDataService from "../../services/MailDataService";

import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "compInvestigation",
  props: [
    "claims",
    "branches",
    "insurer",
    "refType",
    "subRefTypes",
    "clerks",
    "members",
  ],
  components: {},

  data() {
    return {
      dialogCompleteInv: false,
      // checkboxComplInv: false,
      // checkboxComplInv1: false,
      rules: [
        (v) => !!v || "This field is required. Only Numbers accepted",
        // (v) => /^\d+$/.test(v) || "This field only accept numbers",
      ],
      claim: {
        travMileage: "",
        additional: "",
        sdAndStamps: "",
        medicalReport: "",
        policeDoc: "",
        jpjDoc: "",
        misc: "",
        typing: "",
      },
    };
  },

  computed: {
    computedDateFormattedMomentjs() {
      return this.date ? moment(this.date).format("DD/MM/YY") : "";
    },
    completeInvestigationDisabled() {
      return !(
        this.caseState.casefile.fileStatus == `INVE` &&
        this.caseState.casefile.adjuster == this.$store.state.auth.user.id
      );
    },

    ...mapState(["caseState"]),

    isAddFileButtonDisabled() {
      return !(
        // this.checkboxComplInv &&
        // this.checkboxComplInv1 &&
        (
          this.claim.travMileage &&
          this.claim.additional &&
          this.claim.sdAndStamps &&
          this.claim.medicalReport &&
          this.claim.policeDoc &&
          this.claim.jpjDoc &&
          this.claim.misc &&
          this.claim.typing
        )
      );
    },
  },
  watch: {
    completeInv(val) {
      val || this.closeCompleteInvDialog();
    },
  },

  methods: {
    filterBranchName(item) {
      if (!item) return "TBA";
      const filteredbranch = this.branches.filter(
        (branch) => branch.id === item
      );
      return filteredbranch[0].name;
    },
    filterBranches(item) {
      return this.branches.filter((branch) => branch.id === item);
    },

    findBrcode(item) {
      if (!item) return "TBA";
      const filteredbranch = this.branches.filter(
        (branch) => branch.id === item
      );
      return filteredbranch.map((b) => b.brCode);
    },

    filterDept(item) {
      return this.refType.filter((dept) => dept.id === item);
    },
    filterSubDept(item) {
      return this.subRefTypes.filter((subRef) => subRef.id === item);
    },
    filterIns(item) {
      return this.insurer.filter((insurer) => insurer.id === item);
    },
    filterManagerkByBranch(item) {
      const filter1 = this.clerks.filter(
        (a) =>
          a.branches.some((b) => b.brCode == item) &&
          a.roles.some(
            (c) => c.roleCode === "manager" || c.roleCode === "branchmanager"
          ) &&
          a.active
      );
      const email = filter1.map((d) => d.email);
      return email;
    },

    filterPicID(item) {
      if (!item) return "TBA";
      const filteredDept = this.refType.filter((dept) => dept.id === item);
      // console.log(filteredDept);
      return filteredDept[0].picID;
    },
    findUserEmail(item) {
      const user = this.members.filter(
        (member) => member.id === item && member.active
      );
      return user[0] ? user[0].email : "sys.admin@associatedadjusters.com";
      // return user.map((b) => b.email);
    },

    moment: function (date) {
      return moment(date);
    },

    filterHandler(item) {
      return this.handlers.filter((handler) => handler.id === item);
    },

    completeInv() {
      this.dialogCompleteInv = true;
    },

    compInvestigation() {
      CasefileDataService.update(
        this.caseState.casefile.id,
        this.caseState.casefile,
        (this.caseState.casefile.fileStatus = `PEDI`),
        (this.caseState.casefile.dateEndInv = moment().format())
        // (this.caseState.casefile.registerBy = this.$store.state.auth.user.id),
        // (this.caseState.casefile.dateOfReg = moment().format())
      )
        .then((response) => {
          // console.log("Casefile Notification:" + response.data.message);
          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "warning",
            text:
              (response && response.data && response.data.message) ||
              toString(),
            timeout: 2000,
          });
        })
        .catch((e) => {
          this.$store.dispatch("setSnackbar", {
            showing: true,
            text:
              (e.response && e.response.data && e.response.data.message) ||
              e.message ||
              toString(),
          });
        })

        .then(() => {
          var data = {
            caseId: this.caseState.casefile.id,
            adjId: this.$store.state.auth.user.id,
            travMileage: this.claim.travMileage,
            additional: this.claim.additional,
            sdAndStamps: this.claim.sdAndStamps,
            medicalReport: this.claim.medicalReport,
            policeDoc: this.claim.policeDoc,
            jpjDoc: this.claim.jpjDoc,
            misc: this.claim.misc,
            typing: this.claim.typing,
          };

          ClaimDataService.create(data)
            .then((response) => {
              // console.log("Claim Notification:" + response.data.message);
              this.claims.id = response.data.id;
              this.closeCompleteInvDialog();
              this.$store.dispatch("setSnackbar", {
                showing: true,
                color: "success",
                text:
                  (response && response.data && response.data.message) ||
                  toString(),
                timeout: 4000,
              });
              // this.refreshList();
            })
            .catch((e) => {
              this.$store.dispatch("setSnackbar", {
                showing: true,
                text:
                  (e.response && e.response.data && e.response.data.message) ||
                  e.message ||
                  toString(),
              });
            });
        })

        .then(() => {
          const assignedBranch = this.caseState.casefile.branch;
          const branchManager = this.filterManagerkByBranch(
            this.findBrcode(assignedBranch)
          );
          // console.log(JSON.stringify(branchManager));
          const assignedDept = this.caseState.casefile.refType;
          const personInCharge = this.findUserEmail(
            this.filterPicID(assignedDept)
          );
          // console.log(JSON.stringify(personInCharge));

          //  recipient: this.findUserEmail(this.filterPicID(assignedDept)),

          var dataEmail = {
            id: this.caseState.casefile.id,
            refType: this.filterDept(this.caseState.casefile.refType)[0]
              .description,
            refTypeCode: this.filterDept(this.caseState.casefile.refType)[0]
              .name,
            subRefType: this.filterSubDept(
              this.caseState.casefile.subRefType
            )[0].subCodeDesc,
            subRefCode: this.filterSubDept(
              this.caseState.casefile.subRefType
            )[0].subCode,
            branch: this.filterBranchName(this.caseState.casefile.branch),
            branchCode: this.filterBranches(this.caseState.casefile.branch)[0]
              .brCode,
            insurer: this.filterIns(this.caseState.casefile.insurer)[0].name,
            vehicleNo: this.caseState.casefile.vehicleNo
              .toLowerCase()
              .replace(/\s/g, ""),
            recipient: branchManager + "," + personInCharge,

            // recipient: this.filterManagerkByBranch(
            //   this.findBrcode(assignedBranch)
            // ),
            createdBy: this.$store.state.auth.user.id,
          };
          MailDataService.assignEditor(dataEmail)

            .then((response) => {
              // console.log(response.data.message);
              this.$store.dispatch("setSnackbar", {
                showing: true,
                color: "warning",
                text:
                  (response && response.data && response.data.message) ||
                  toString(),
                timeout: 6000,
              });
            })
            .catch((e) => {
              this.$store.dispatch("setSnackbar", {
                showing: true,
                text:
                  (e.response && e.response.data && e.response.data.message) ||
                  e.message ||
                  toString(),
              });
            });
          // console.log(JSON.stringify(dataEmail.recipient));
          this.dataEmail = "";
        })

        .catch((e) => {
          console.log(e);
        });

      this.closeCompleteInvDialog();
    },

    closeCompleteInvDialog() {
      this.dialogCompleteInv = false;
      this.$nextTick(() => {
        // this.caseState.casefile.fileStatus = "ADAC";
      });
    },
  },
};
</script>
