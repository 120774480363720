<template>
  <v-container fluid style="width: 80%">
    <div>
      <v-row>
        <v-col cols="12">
          <v-data-table
            dense
            :headers="headers"
            :items="casefiles"
            :search="search"
            class="elevation-1"
            style="cursor: pointer"
            @click:row="viewItem"
            :loading="loadTable"
            loading-text="Working on it ......"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <!-- Search Field -->

                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  clearable
                ></v-text-field>
              </v-toolbar>
            </template>

            <template slot="item.numbering" slot-scope="props">
              {{ props.index + 1 }}
            </template>
            <template v-slot:[`item.insurer`]="{ item }">
              <div v-for="ins in filterIns(item.insurer)" :key="ins.id">
                <div class="text-truncate" style="max-width: 100px">
                  {{ ins.name }}
                </div>
              </div>
            </template>
            <template v-slot:[`item.id`]="{ item }">
              <tr class="d-flex">
                AA
                <div
                  v-for="(dept, index) in filterDept(item.refType)"
                  :key="index"
                >
                  /{{ dept.name
                  }}<span
                    v-for="(subDept, index) in filterSubDept(item.subRefType)"
                    :key="index"
                    >/{{ subDept.subCode }}</span
                  >
                </div>

                /{{
                  item.id
                }}/{{
                  yearOnly(item.createdAt)
                }}
                <div
                  v-for="branch in filterStates(item.branch)"
                  :key="branch.id"
                >
                  /{{ branch.brCode }}
                </div>
              </tr>
            </template>

            <template v-slot:[`item.dateOfLoss`]="{ item }">
              {{ formatDate(item.dateOfLoss) }}
            </template>

            <template v-slot:[`item.fileStatus`]="{ item }">
              <v-tooltip left transition="fab-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="item.flag === 1"
                    small
                    color="green lighten-1"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <!-- mdi-check-decagram-outline -->
                    mdi-shield-check
                  </v-icon>
                  <v-icon
                    v-if="item.flag === 2"
                    small
                    color="orange lighten-1"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <!-- mdi-alarm-light-off-outline -->
                    mdi-shield-half-full
                  </v-icon>
                  <v-icon
                    v-if="item.flag === 3"
                    small
                    color="red darken-5"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <!-- mdi-alarm-light-off-outline -->
                    mdi-shield-alert-outline
                  </v-icon>
                </template>
                <!-- <span> {{ item.flag ? "Genuine" : "Suspected Fraud" }}</span> -->
                <span v-if="item.flag === 1">GENUINE</span>
                <span v-if="item.flag === 2">SUSPECTED FRAUD</span>
                <span v-if="item.flag === 3">FRAUD</span>
              </v-tooltip>

              <span
                v-for="allStatus in filterStages(item.fileStatus)"
                :key="allStatus.id"
                v-bind="allStatus.item"
              >
                <v-chip label outlined block x-small>
                  {{ allStatus.name }}
                </v-chip>
              </span>
            </template>

            <!-- <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left transition="fab-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="mr-2"
                    @click:row="viewItem(item)"
                  >
                    mdi-folder-open-outline
                  </v-icon>
                </template>
                <span> View</span>
              </v-tooltip>
            </template> -->
          </v-data-table>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import BranchDataService from "../services/BranchDataService";
import StateDataService from "../services/StateDataService";
import RegionDataService from "../services/RegionDataService";
import DeptDataService from "../services/DeptDataService";
import SubRefTypeDataService from "../services/SubDeptDataService";
import InsDataService from "../services/InsDataService";
import CasefileDataService from "../services/CasefileDataService";
import StagesDataService from "../services/StageDataService";
import HandlerDataService from "../services/HandlerDataService";
import moment from "moment";

export default {
  name: "find",
  data: () => ({
    casefiles: [],
    search: "",
    loadTable: true,
    branch: [],
    state: [],
    regions: [],
    refType: [],
    subRefTypes: [],
    stages: [],
    insurer: [],
    handlers: [],
    newItem: {
      id: null,
      refType: "",
      subRefType: "",
      branch: "",
      insurer: "",
      handler: "",
      claimNo: "",
      dateOfAssign: "",
      vehicleNo: "",
      dateOfLoss: "",
      stateOfLoss: "",
      placeOfLoss: "",
      insuredName: "",
      insComment: "",
      createdBy: "",
      fileStatus: "",
      dateStartInv: "",
    },
    editItem: {
      id: null,
      refType: "",
      subRefType: "",
      branch: "",
      insurer: "",
      handler: "",
      claimNo: "",
      dateOfAssign: "",
      vehicleNo: "",
      dateOfLoss: "",
      stateOfLoss: "",
      placeOfLoss: "",
      insuredName: "",
      insComment: "",
      createdBy: "",
      fileStatus: "",
      dateStartInv: "",
    },
    headers: [
      {
        text: "NO.",
        align: "start",
        sortable: false,
        value: "numbering",
      },
      {
        text: "INSURER",
        align: "center",
        value: "insurer",
        sortable: false,
      },
      {
        text: "VEHICLE NO.",
        align: "center",
        value: "vehicleNo",
        sortable: false,
      },
      {
        text: "AASB REFERENCE",
        align: "start",
        value: "id",
        sortable: false,
      },
      {
        text: "CLAIM NO.",
        align: "start",
        sortable: false,
        value: "claimNo",
      },
      {
        text: "DATE OF LOSS",
        align: "start",
        sortable: false,
        value: "dateOfLoss",
      },

      {
        text: "STATUS",
        align: "center",
        value: "fileStatus",
        sortable: false,
      },
    ],
  }),

  computed: {},

  watch: {},

  methods: {
    filterStages(item) {
      return this.stages.filter((stage) => stage.stageCode === item);
    },
    filterRegions(item) {
      return this.regions.filter((region) => region.branchId === item);
    },
    filtersubRefType(item) {
      return this.subRefTypes.filter(
        (subRefType) => subRefType.deptId === item
      );
    },
    filterHandler(item) {
      return this.handlers.filter((handler) => handler.insId === item);
    },
    filterStates(item) {
      return this.branch.filter((branch) => branch.id === item);
    },
    filterIns(item) {
      return this.insurer.filter((insurer) => insurer.id === item);
    },
    filterDept(item) {
      return this.refType.filter((dept) => dept.id === item);
    },
    filterSubDept(item) {
      return this.subRefTypes.filter((subDept) => subDept.id === item);
    },

    viewItem(id) {
      this.$router.push({
        name: "findfile",
        params: { id: id.id },
      });
    },

    formatDate(value) {
      return moment(value).format("DD/MM/YYYY");
    },

    yearOnly(value) {
      return moment(value).format("YY");
    },

    retrieveCasefiles() {
      CasefileDataService.getAll()
        .then((response) => {
          // this.casefiles = response.data.map(this.getDisplayCasefile);
          this.casefiles = response.data;
          // console.log(response.data);
          this.loadTable = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    // getDisplayCasefile(casefile) {
    //   return {
    //     id: casefile.id,
    //     name:
    //       casefile.name.length > 30
    //         ? casefile.name.substr(0, 30) + "..."
    //         : casefile.name,
    //     id:
    //       casefile.id.length > 30
    //         ? casefile.id.substr(0, 30) + "..."
    //         : casefile.id,
    //   };
    // },
    refreshList() {
      this.retrieveCasefiles();
    },

    retrieveDept() {
      DeptDataService.getAll()
        .then((response) => {
          this.refType = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    retrieveSubRefType() {
      SubRefTypeDataService.getAll()
        .then((response) => {
          this.subRefTypes = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    retrieveStages() {
      StagesDataService.getAll()
        .then((response) => {
          this.stages = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    retrieveIns() {
      InsDataService.getAll()
        .then((response) => {
          this.insurer = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    retrieveBranches() {
      BranchDataService.getAll()
        .then((response) => {
          // this.branch = response.data;
          this.branch = response.data.map(this.getDisplayBranch);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    getDisplayBranch(branch) {
      return {
        id: branch.id,
        name:
          branch.name.length > 30
            ? branch.name.substr(0, 30) + "..."
            : branch.name,
        brCode:
          branch.brCode.length > 30
            ? branch.brCode.substr(0, 30) + "..."
            : branch.brCode,
      };
    },

    retrieveStates() {
      StateDataService.getAll()
        .then((response) => {
          // this.branch = response.data;
          this.state = response.data.map(this.getDisplayState);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    getDisplayState(state) {
      return {
        id: state.id,
        name:
          state.name.length > 30
            ? state.name.substr(0, 30) + "..."
            : state.name,
        stCode:
          state.stCode.length > 30
            ? state.stCode.substr(0, 30) + "..."
            : state.stCode,
      };
    },

    retrieveRegions() {
      RegionDataService.getAll()
        .then((response) => {
          this.regions = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    retrieveHandlers() {
      HandlerDataService.getAll()
        .then((response) => {
          this.handlers = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {
    this.retrieveBranches();
    this.retrieveStates();
    this.retrieveRegions();
    this.retrieveDept();
    this.retrieveSubRefType();
    this.retrieveStages();
    this.retrieveIns();
    this.retrieveCasefiles();
    this.retrieveHandlers();
  },
};
</script>
