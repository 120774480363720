import http from "../http-common";

class InsDataService {
  getAll() {
    return http.get("/inss");
  }

  get(id) {
    return http.get(`/inss/${id}`);
  }

  create(data) {
    return http.post("/inss", data);
  }

  update(id, data) {
    return http.put(`/inss/${id}`, data);
  }

  delete(id) {
    return http.delete(`/inss/${id}`);
  }

  deleteAll() {
    return http.delete(`/inss`);
  }
}

export default new InsDataService();
