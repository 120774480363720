import api from './api';

class UserService {
  getPublicContent() {
    return api.get('/test/all');
  }

  getUserBoard() {
    return api.get('/test/user');
  }

  // getModeratorBoard() {
  //   return api.get('/test/mod');
  // }

  getAdminBoard() {
    return api.get('/test/admin');
  }

  getManagerBoard() {
    return api.get('/test/manager');
  }
}

export default new UserService();
