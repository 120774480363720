<template>
  <div>
    <v-container fluid style="width: 80%">
      <v-tabs>
        <v-tab
          @click="
            incoming = true;
            cancelled = false;
          "
          >Incoming</v-tab
        >
        <v-tab
          @click="
            cancelled = true;
            incoming = false;
          "
          >Cancelled</v-tab
        >
      </v-tabs>

      <incTab v-if="incoming" />
      <cancTab v-if="cancelled" />
    </v-container>
  </div>
</template>

<script>
import incTab from "../dropboxfiles/incomingList.vue";
import cancTab from "../dropboxfiles/cancelledList.vue";

export default {
  name: "dropboxfiles",
  components: {
    incTab,
    cancTab,
  },
  data() {
    return {
      incoming: true,
      cancelled: false,
    };
  },
};
</script>
