<template>
  <div>
    <v-data-table
      v-model="currentClaims"
      dense
      :headers="headers"
      :items="filteredClaims"
      :search="search"
      class="elevation-1"
      :loading="loadTable"
      loading-text="Working on it ......"
      @current-items="currentItems"
      show-select
      @click:row="(item) => showItem(item)"
      style="cursor: pointer"
    >
      <template v-slot:top>
        <v-dialog v-model="dialog" max-width="500px">
          <!-- <template v-slot:activator="{ on: dialog, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      fab
                      small
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="{ ...tooltip, ...dialog }"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>ADD CLAIMS</span>
                </v-tooltip>
              </template> -->

          <v-card>
            <v-card-title class="justify-center text-h5 grey lighten-2">
              <span>{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.travMileage"
                      type="number"
                      hide-spin-buttons
                      prefix="MYR"
                      label="Travelling / Mileage"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.additional"
                      type="number"
                      hide-spin-buttons
                      prefix="MYR"
                      label="Additional"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.sdAndStamps"
                      type="number"
                      hide-spin-buttons
                      prefix="MYR"
                      label="SD & Stamps"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.medicalReport"
                      type="number"
                      hide-spin-buttons
                      prefix="MYR"
                      label="Medical Report"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.policeDoc"
                      type="number"
                      hide-spin-buttons
                      prefix="MYR"
                      label="Police Document"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.jpjDoc"
                      type="number"
                      hide-spin-buttons
                      prefix="MYR"
                      label="JPJ Document"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.misc"
                      type="number"
                      hide-spin-buttons
                      prefix="MYR"
                      label="Miscellaneous"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.typing"
                      type="number"
                      hide-spin-buttons
                      prefix="MYR"
                      label="Typing"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn tile small outlined @click="close"> Cancel </v-btn>
              <v-btn
                tile
                small
                :disabled="isAddButtonDisabled"
                class="primary"
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="approveDialog" max-width="500px" persistent>
          <v-card>
            <v-card-title class="justify-center text-h5 grey lighten-2"
              >Claim Details</v-card-title
            >
            <v-card-text>
              <v-container>
                <v-form>
                  <v-row>
                    <v-col
                      cols="12"
                      class="display-3 text-start font-weight-bold"
                    >
                      MYR {{ newTotal }}
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col class="subtitle-1 text-start" cols="6"
                      >Travelling
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        dense
                        placeholder="Travelling"
                        type="number"
                        hide-spin-buttons
                        prefix="MYR"
                        v-model="selectedClaim.travMileage"
                        class="subtitle-1"
                      ></v-text-field>
                    </v-col>
                    <v-col class="subtitle-1 text-start" cols="6"
                      >Additional
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        dense
                        placeholder="Additional"
                        type="number"
                        hide-spin-buttons
                        prefix="MYR"
                        v-model="selectedClaim.additional"
                        class="subtitle-1"
                      ></v-text-field>
                    </v-col>
                    <v-col class="subtitle-1 text-start" cols="6"
                      >SD & Stamps
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        dense
                        placeholder="SD & Stamps"
                        type="number"
                        hide-spin-buttons
                        prefix="MYR"
                        v-model="selectedClaim.sdAndStamps"
                        class="subtitle-1"
                      ></v-text-field>
                    </v-col>
                    <v-col class="subtitle-1 text-start" cols="6"
                      >Medical Reprot
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        dense
                        placeholder="Medical Reprot"
                        type="number"
                        hide-spin-buttons
                        prefix="MYR"
                        v-model="selectedClaim.medicalReport"
                        class="subtitle-1"
                      ></v-text-field>
                    </v-col>

                    <v-col class="subtitle-1 text-start" cols="6"
                      >Police Document
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        dense
                        placeholder="Police Document"
                        type="number"
                        hide-spin-buttons
                        prefix="MYR"
                        v-model="selectedClaim.policeDoc"
                        class="subtitle-1"
                      ></v-text-field>
                    </v-col>

                    <v-col class="subtitle-1 text-start" cols="6"
                      >JPJ Document
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        dense
                        placeholder="JPJ Document"
                        type="number"
                        hide-spin-buttons
                        prefix="MYR"
                        v-model="selectedClaim.jpjDoc"
                        class="subtitle-1"
                      ></v-text-field>
                    </v-col>
                    <v-col class="subtitle-1 text-start" cols="6"
                      >Miscellaneous
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        dense
                        placeholder="Miscellaneous"
                        type="number"
                        hide-spin-buttons
                        prefix="MYR"
                        v-model="selectedClaim.misc"
                        class="subtitle-1"
                      ></v-text-field>
                    </v-col>

                    <v-col class="subtitle-1 text-start" cols="6"
                      >Typing
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        dense
                        placeholder="Typing"
                        type="number"
                        hide-spin-buttons
                        prefix="MYR"
                        v-model="selectedClaim.typing"
                        class="subtitle-1"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        solo
                        dense
                        placeholder="Comment"
                        v-model="commentData.comment"
                        class="subtitle-1"
                      ></v-textarea>
                    </v-col>
                    <v-card-actions>
                      <v-btn class="success" small tile @click="submitAgain"
                        >SUBMIT AGAIN</v-btn
                      >
                      <!-- <v-btn
                        v-if="management"
                        class="warning"
                        small
                        tile
                        @click="declineConfirm"
                        >QUERY</v-btn
                      > -->
                      <v-btn outlined small tile @click="closeApproveDialog"
                        >CLOSE</v-btn
                      >
                    </v-card-actions>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="justify-center text-h5 grey lighten-2"
              >Are you sure you want to delete
              {{ editedItem.subCode }}?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn tile small outlined @click="closeDelete">Cancel</v-btn>
              <v-btn tile small class="error" @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-spacer></v-spacer>
        <v-row
          style="border: 1px dotted orange"
          class="ma-1"
          justify="center"
          align="center"
        >
          <v-col cols="12" md="3" sm="12">
            <v-autocomplete
              clearable
              v-model="adjColumn"
              :items="members"
              item-text="username"
              item-value="username"
              :menu-props="{ maxHeight: '400' }"
              hint="Filter by Adjuster"
              persistent-hint
              label="Adjuster"
              v-if="management"
            >
            </v-autocomplete>
          </v-col>

          <v-col cols="12" md="3" sm="12">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startDate"
                  :value="computedDateFormattedMomentjs"
                  clearable
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="date = null"
                  label="Start Date"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="startDate"
                :value="computedDateFormattedMomentjs"
                @change="menu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="3" sm="12">
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="endDate"
                  :value="computedDateFormattedMomentjs"
                  clearable
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="date = null"
                  label="End Date"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="endDate"
                :value="computedDateFormattedMomentjs"
                @change="menu1 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="3" sm="12" class="text-center">
            <ClaimForm
              :casefiles="casefiles"
              :refType="refType"
              :subRefTypes="subRefTypes"
              :branches="branches"
              :insurers="insurers"
              :members="members"
              :currentClaims="currentClaims"
            />
          </v-col>
        </v-row>
      </template>

      <template slot="item.numbering" slot-scope="props">
        {{ props.index + 1 }}
      </template>
      <template v-slot:[`item.caseId`]="{ item }">
        <tr class="d-flex">
          AA
          <div v-for="casefile in filterCase(item.caseId)" :key="casefile.id">
            <div
              v-for="(dept, index) in filterDept(casefile.refType)"
              :key="index"
            >
              /{{ dept.name
              }}<span
                v-for="(subDept, index) in filterSubDept(casefile.subRefType)"
                :key="index"
                >/{{ subDept.subCode }}</span
              ><span>/{{ casefile.id }}/{{ yearOnly(casefile.createdAt) }}</span
              ><span
                v-for="branch in filterBranches(casefile.branch)"
                :key="branch.id"
                >/{{ branch.brCode }}</span
              >
            </div>
          </div>
        </tr>
      </template>
      <template v-slot:[`item.adjId`]="{ item }">
        <div v-for="adjuster in filterAdj(item.adjId)" :key="adjuster.id">
          {{ adjuster.username }}
        </div>
      </template>
      <template v-slot:[`item.sumAll`]="{ item }">
        <span>MYR {{ total(item.id) }}</span>
      </template>

      <template v-slot:[`item.createdAt`]="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>
      <template v-slot:[`item.isRejectedOn`]="{ item }">
        {{ formatDate(item.isRejectedOn) }}
      </template>

      <template v-slot:[`item.isRejectedBy`]="{ item }">
        <div v-for="user in filterUser(item.isRejectedBy)" :key="user.id">
          {{ user.email }}
        </div>
      </template>
      <!-- <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left transition="fab-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              v-bind="attrs"
              v-on="on"
              class="mr-2"
              @click="showItem(item)"
            >
              mdi-eye
            </v-icon>
          </template>
          <span> View</span>
        </v-tooltip>
        <v-tooltip left transition="fab-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              v-bind="attrs"
              v-on="on"
              class="mr-2"
              v-if="management"
              @click.stop="editItem(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span> edit</span>
        </v-tooltip>
        <v-tooltip right transition="fab-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              v-bind="attrs"
              v-on="on"
              color="red darken-1"
              v-if="management"
              @click.stop="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <span> delete</span>
        </v-tooltip>
      </template> -->
    </v-data-table>
  </div>
</template>

<script>
import MemberDataService from "../../services/MemberDataService";
import CasefileDataService from "../../services/CasefileDataService";
import ClaimDataService from "../../services/ClaimDataService";
import CommentDataService from "../../services/CommentDataService";
import BranchDataService from "../../services/BranchDataService";
import InsDataService from "../../services/InsDataService";
import DeptDataService from "../../services/DeptDataService";
import SubRefTypeDataService from "../../services/SubDeptDataService";
import ClaimForm from "../../components/pdf/claimForm.vue";
import moment from "moment";

export default {
  name: "queryList",
  data: () => ({
    loadTable: true,
    currentClaims: [],
    menu: false,
    menu1: false,
    startDate: "",
    endDate: "",
    adjColumn: "",

    management: false,
    dialog: false,
    dialogDelete: false,
    approveDialog: false,
    casefiles: [],
    claims: [],
    selectedClaim: [],
    refType: [],
    subRefTypes: [],
    branches: [],
    insurers: [],
    members: [],
    allMembers: [],
    // departments: [],
    search: "",
    defaultItem: {
      id: null,
      caseId: null,
      adjId: null,
      travMileage: "",
      additional: "",
      sdAndStamps: "",
      medicalReport: "",
      policeDoc: "",
      jpjDoc: "",
      misc: "",
      typing: "",
    },
    editedIndex: -1,
    editedItem: {
      id: null,
      caseId: null,
      adjId: null,
      travMileage: "",
      additional: "",
      sdAndStamps: "",
      medicalReport: "",
      policeDoc: "",
      jpjDoc: "",
      misc: "",
      typing: "",
    },
    commentData: {
      id: null,
      caseId: null,
      comment: "",
      createdBy: "",
    },
  }),

  components: {
    ClaimForm,
  },

  computed: {
    computedDateFormattedMomentjs() {
      return this.date ? moment(this.date).format("DD/MM/YY") : "";
    },
    headers() {
      return [
        {
          text: "NO.",
          align: "start",
          sortable: false,
          value: "numbering",
        },
        {
          text: "AASB REFERENCE",
          align: "start",
          sortable: true,
          value: "caseId",
        },
        {
          text: "ADJUSTER",
          align: "start",
          sortable: false,
          value: "adjId",
          filter: (f) => {
            if (!this.adjColumn) return true;
            const adjname = this.filterAdj(f);

            return (adjname[0].username + "")
              .toLowerCase()
              .includes(this.adjColumn.toLowerCase());
          },
        },

        {
          text: "SUBMITTED ON",
          align: "start",
          sortable: false,
          value: "createdAt",
          filter: (value) => {
            if (this.startDate && !this.endDate)
              return moment(value) >= moment(this.startDate);
            else if (!this.startDate && this.endDate)
              return moment(value) <= moment(this.endDate);
            else if (this.startDate && this.endDate)
              return (
                moment(value) >= moment(this.startDate) &&
                moment(value) <= moment(this.endDate)
              );
            else return true;
          },
        },
        {
          text: "QUERIED ON",
          align: "start",
          sortable: true,
          value: "isRejectedOn",
        },
        {
          text: "QUERIED BY",
          align: "start",
          sortable: true,
          value: "isRejectedBy",
        },
        {
          text: "CLAIMS TOTAL",
          align: "start",
          sortable: true,
          value: "sumAll",
        },
        // {
        //   text: "ACTIONS",
        //   align: "center",
        //   value: "actions",
        //   sortable: false,
        // },

        // {
        //   text: "STATUS",
        //   align: "center",
        //   value: "fileStatus",
        //   sortable: false,
        // },

        // {
        //   text: "DETAIL",
        //   align: "center",
        //   value: "actions",
        //   sortable: false,
        // },
      ];
    },
    filteredClaims() {
      if (
        this.$store.state.auth.user.roles.includes("MANAGER") ||
        this.$store.state.auth.user.roles.includes("ADMIN")
      ) {
        return this.claims;
      } else if (
        this.$store.state.auth.user.roles.includes("BRANCHCLERK") ||
        this.$store.state.auth.user.roles.includes("BRANCHMANAGER")
      ) {
        return this.claims.filter((x) =>
          this.$store.state.auth.user.branches.includes(
            this.filterCase(x.caseId)[0].branch
          )
        );
      } else if (this.$store.state.auth.user.roles.includes("ADJUSTER")) {
        return this.claims.filter(
          (x) =>
            this.$store.state.auth.user.branches.includes(
              this.filterCase(x.caseId)[0].branch
            ) && this.$store.state.auth.user.id.toString().includes(x.adjId)
        );
      } else if (this.$store.state.auth.user.roles.includes("EDITOR")) {
        return [];
      } else return [];
    },
    newTotal() {
      const total =
        this.selectedClaim.travMileage * 1 +
        this.selectedClaim.additional * 1 +
        this.selectedClaim.sdAndStamps * 1 +
        this.selectedClaim.medicalReport * 1 +
        this.selectedClaim.policeDoc * 1 +
        this.selectedClaim.jpjDoc * 1 +
        this.selectedClaim.misc * 1 +
        this.selectedClaim.typing * 1;
      return total.toFixed(2);
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    isAddButtonDisabled() {
      return !(
        this.editedItem.travMileage &&
        this.editedItem.additional &&
        this.editedItem.sdAndStamps &&
        this.editedItem.medicalReport &&
        this.editedItem.policeDoc &&
        this.editedItem.jpjDoc &&
        this.editedItem.misc &&
        this.editedItem.typing
      );
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    currentItems: function (value) {
      return (this.currentClaims = value);
    },
    checkManagement() {
      if (
        this.$store.state.auth.user.roles.includes(`ADMIN`) ||
        this.$store.state.auth.user.roles.includes(`MANAGER`)
      ) {
        this.management = true;
      }
    },
    total(item) {
      const thisclaim = this.claims.filter((claim) => claim.id === item);
      const total = thisclaim.reduce(
        (sum, row) =>
          sum +
          row.travMileage * 1 +
          row.additional * 1 +
          row.sdAndStamps * 1 +
          row.medicalReport * 1 +
          row.policeDoc * 1 +
          row.jpjDoc * 1 +
          row.misc * 1 +
          row.typing * 1,
        0
      );
      return total.toFixed(2);
    },

    formatDate(value) {
      return moment(value).format("DD/MM/YYYY LT");
    },
    yearOnly(value) {
      return moment(value).format("YY");
    },
    filterCase(item) {
      return this.casefiles.filter((casefile) => casefile.id === item);
    },
    filterDept(item) {
      return this.refType.filter((dept) => dept.id === item);
    },
    filterSubDept(item) {
      return this.subRefTypes.filter((subDept) => subDept.id === item);
    },
    filterBranches(item) {
      return this.branches.filter((branch) => branch.id === item);
    },
    filterUser(item) {
      return this.allMembers.filter((member) => member.id === item);
    },
    filterAdj(item) {
      return this.members.filter((member) => member.id === item);
    },

    retrieveClaims() {
      ClaimDataService.getAll()
        .then((response) => {
          this.claims = response.data.filter((claim) => claim.isApproved === 1);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    retrieveDept() {
      DeptDataService.getAll()
        .then((response) => {
          this.refType = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveSubRefType() {
      SubRefTypeDataService.getAll()
        .then((response) => {
          this.subRefTypes = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveBranches() {
      BranchDataService.getAll()
        .then((response) => {
          // this.branch = response.data;
          this.branches = response.data.map(this.getDisplayBranch);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    getDisplayBranch(branch) {
      return {
        id: branch.id,
        name:
          branch.name.length > 30
            ? branch.name.substr(0, 30) + "..."
            : branch.name,
        brCode:
          branch.brCode.length > 30
            ? branch.brCode.substr(0, 30) + "..."
            : branch.brCode,
      };
    },
    retrieveInsurer() {
      InsDataService.getAll()
        .then((response) => {
          this.insurers = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveMembers() {
      MemberDataService.getAll()
        .then((response) => {
          this.members = response.data.filter((item) =>
            item.roles.some((b) => b.roleCode === "adjuster")
          );
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveUsers() {
      MemberDataService.getAll()
        .then((response) => {
          this.allMembers = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // getDisplayDept(dept) {
    //   return {
    //     id: dept.id,
    //     name:
    //       dept.name.length > 30 ? dept.name.substr(0, 30) + "..." : dept.name,
    //     branchCode:
    //       dept.stCode.length > 30
    //         ? dept.stCode.substr(0, 30) + "..."
    //         : dept.stCode,
    //   };
    // },
    retrieveCasefiles() {
      CasefileDataService.getAll()
        .then((response) => {
          // this.casefiles = response.data.map(this.getDisplayCasefile);
          this.casefiles = response.data;
          // console.log(response.data);
          this.loadTable = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    refreshList() {
      this.retrieveClaims();
    },

    // showItem(item) {
    //   this.editedIndex = this.claims.indexOf(item);
    //   this.editedItem = Object.assign({}, item);
    //   this.dialog = true;
    // },
    showItem(item) {
      // this.$router.push({
      //   name: "claimDetail",
      //   params: { id: id.id },
      // });
      // this.editedIndex = this.claims.indexOf(item);
      this.selectedClaim = Object.assign({}, item);
      this.approveDialog = true;
    },
    closeApproveDialog() {
      this.selectedClaim = Object.assign({});
      this.approveDialog = false;
    },
    editItem(item) {
      this.editedIndex = this.claims.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.claims.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      ClaimDataService.delete(this.editedItem.id)
        .then((response) => {
          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "error",
            text:
              (response && response.data && response.data.message) ||
              toString(),
          });

          this.refreshList();
        })
        .catch((e) => {
          console.log(e);
        });

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        ClaimDataService.update(this.editedItem.id, this.editedItem)
          .then((response) => {
            this.close();
            this.$store.dispatch("setSnackbar", {
              showing: true,
              color: "warning",
              text:
                (response && response.data && response.data.message) ||
                toString(),
            });
            this.refreshList();
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        var data = {
          caseId: this.editedItem.caseId,
          adjId: this.editedItem.adjId,
          travMileage: this.editedItem.travMileage,
          additional: this.editedItem.additional,
          sdAndStamps: this.editedItem.sdAndStamps,
          medicalReport: this.editedItem.medicalReport,
          policeDoc: this.editedItem.policeDoc,
          jpjDoc: this.editedItem.jpjDoc,
          misc: this.editedItem.misc,
          typing: this.editedItem.typing,
        };

        ClaimDataService.create(data)
          .then((response) => {
            this.editedItem.id = response.data.id;
            this.close();
            this.$store.dispatch("setSnackbar", {
              showing: true,
              color: "success",
              text:
                (response && response.data && response.data.message) ||
                toString(),
            });
            this.refreshList();
          })
          .catch((e) => {
            this.$store.dispatch("setSnackbar", {
              showing: true,
              text:
                (e.response && e.response.data && e.response.data.message) ||
                e.message ||
                toString(),
            });
          });
      }
      this.close();
    },

    submitAgain() {
      ClaimDataService.update(
        this.selectedClaim.id,
        this.selectedClaim,
        (this.selectedClaim.isApproved = 0)
        // (this.selectedClaim.createdAt = moment().format()),
        // (this.selectedClaim.createdBy = this.$store.state.auth.user.id)
      )
        .then((response) => {
          this.claims.id = response.data.id;
          this.closeApproveDialog();
          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "success",
            text:
              (response && response.data && response.data.message) ||
              toString(),
          });
          this.refreshList();
        })

        .catch((e) => {
          this.$store.dispatch("setSnackbar", {
            showing: true,
            text:
              (e.response && e.response.data && e.response.data.message) ||
              e.message ||
              toString(),
          });
        });

      var data = {
        caseId: this.selectedClaim.caseId,
        comment: this.commentData.comment,
        createdBy: this.$store.state.auth.user.id,
      };
      if (this.commentData.comment) {
        setTimeout(() => {
          CommentDataService.create(data)
            .then((response) => {
              this.$store.dispatch("setSnackbar", {
                showing: true,
                color: "success",
                text:
                  (response && response.data && response.data.message) ||
                  toString(),
              });
            })
            .catch((e) => {
              this.$store.dispatch("setSnackbar", {
                showing: true,
                color: "error",
                text: e || toString(),
              });
            });
        }, 2500);
      }

      this.closeApproveDialog();
    },
  },
  mounted() {
    this.retrieveCasefiles();
    this.retrieveClaims();
    this.retrieveBranches();
    this.retrieveInsurer();
    this.retrieveDept();
    this.retrieveSubRefType();
    this.retrieveMembers();
    this.retrieveUsers();
    this.checkManagement();
  },
};
</script>
