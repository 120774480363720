<template>
  <!-- set items in the middle of the screen -->
  <!-- <v-container class="fullview">
    <v-card tile flat class="fill-height" align="center">
      <v-row class="fill-height" align="center" justify="center" no-gutters>
        <v-col cols="12">
          <template v-for="(item, i) in filteredRefType">
            <v-col :key="i" cols="12" md="4">
              <v-hover v-slot="{ hover }">
                <v-card
                  shaped
                  @click="viewItem(item.id)"
                  :elevation="hover ? 12 : 2"
                  :class="{ 'on-hover': hover }"
                >
                  <v-img src="../../assets/001.jpg" height="100px">
                    <v-card-title class="text-h4 white--text">
                      <v-row
                        class="fill-height flex-column"
                        justify="space-between"
                      >
                        <p class="mt-4 subheading font-weight-bold">
                          {{ item.name }}
                        </p>

                        <div>
                          <p
                            class="text-caption font-weight-medium font-italic"
                          >
                            {{ item.description }}
                          </p>
                        </div>
                      </v-row>
                    </v-card-title>
                  </v-img>
                </v-card>
              </v-hover>
            </v-col>
          </template>
        </v-col>
      </v-row>
    </v-card>
  </v-container> -->
  <v-container>
    <v-row class="fill-height" align="center" justify="center">
      <template v-for="(item, i) in filteredRefType">
        <v-col :key="i" align="center">
          <v-hover v-slot="{ hover }">
            <v-card
              width="300px"
              shaped
              @click="viewItem(item.id)"
              :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }"
            >
              <v-img src="../../assets/001.jpg" height="100px">
                <v-card-title class="text-h4 white--text">
                  <v-row
                    class="fill-height flex-column"
                    justify="space-between"
                  >
                    <p class="mt-4 subheading font-weight-bold">
                      {{ item.name }}
                    </p>

                    <div>
                      <p class="text-caption font-weight-medium font-italic">
                        {{ item.description }}
                      </p>
                    </div>
                  </v-row>
                </v-card-title>
              </v-img>
            </v-card>
          </v-hover>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import DeptDataService from "../../services/DeptDataService";
export default {
  data() {
    return {
      refType: [],
    };
  },
  mounted() {
    this.retrieveDept();
  },
  computed: {
    //Two condition filter by branch
    // filteredRefType: function () {
    //   return this.refType.filter((ref) => {
    //     return ref.name == this.$store.state.auth.user.dept;

    //     // return (
    //     //   ref.branch == this.$store.state.auth.user.branch &&
    //     //   ref.refType == this.$store.state.auth.user.dept
    //     // );
    //   });
    // },

    filteredRefType() {
      if (
        !this.$store.state.auth.user.roles.includes("ADMIN") &&
        !this.$store.state.auth.user.roles.includes("MANAGER")
      ) {
        return this.refType.filter((x) =>
          this.$store.state.auth.user.dept.includes(x.id)
        );
      } else return this.refType;
    },

    // filteredRefType() {
    //   if (
    //     !this.$store.state.auth.user.roles.includes("ADMIN") &&
    //     !this.$store.state.auth.user.roles.includes("MANAGER")
    //   ) {
    //     return this.refType
    //       .reduce((seed, current) => {
    //         return Object.assign(seed, { [current.name]: current });
    //       }, {})
    //       .filter((x) => this.$store.state.auth.user.dept.includes(x.id));
    //   } else
    //     return this.refType.reduce((seed, current) => {
    //       return Object.assign(seed, { [current.name]: current });
    //     }, {});
    // },
  },
  methods: {
    retrieveDept() {
      DeptDataService.getAll()
        .then((response) => {
          this.refType = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    viewItem(id) {
      this.$router.push({ name: "refTabDetail", params: { id: id } });
    },
  },
};
</script>

<style scoped>
/* .fullview {
  background: url(../assets/login-bg.jpg) no-repeat;
  width: 100%;
  height: 100vh;
  background-size: cover;
} */
.v-card {
  transition: all 0.4s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.6;
}
</style>
