<template>
  <div>
    <v-card-title class="pb-0">HISTORY</v-card-title>
    <v-divider></v-divider>
    <v-simple-table dense v-if="cases.length > 1">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center">AASB REF</th>
            <th class="text-center">Vehicle No</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in cases"
            :key="item.id"
            @click="showDetail(item)"
            style="cursor: pointer"
          >
            <td>
              AA/<span
                v-for="(dept, index) in filterDept(item.refType)"
                :key="index"
                >{{ dept.name
                }}<span
                  v-for="(subRef, index) in filterSubDept(item.subRefType)"
                  :key="index"
                  >/{{ subRef.subCode }}</span
                > </span
              >/{{ item.id }}/{{ yearOnly(item.createdAt) }}/<span
                v-for="branch in filterBranches(item.branch)"
                :key="branch.id"
                >{{ branch.brCode }}
              </span>
            </td>
            <td>{{ item.vehicleNo.toUpperCase() }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div v-else>
      <span style="text-align: center"> No History of Vehicle Number! </span>
    </div>
    <v-dialog v-model="dialogDetail" width="600px">
      <v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDetail = false">
            Close
          </v-btn>
        </v-card-actions>
        <v-card-title>
          <v-spacer></v-spacer>
          <span class="caption text-decoration-overline"
            >Details of AA/<span
              v-for="(dept, index) in filterDept(caseDetail.refType)"
              :key="index"
              >{{ dept.name }}
              <span
                v-for="(subRef, index) in filterSubDept(caseDetail.subRefType)"
                :key="index"
                >/{{ subRef.subCode }}</span
              > </span
            >/{{ caseDetail.id }}/{{ yearOnly(caseDetail.createdAt) }}/<span
              v-for="branch in filterBranches(caseDetail.branch)"
              :key="branch.id"
              >{{ branch.brCode }}
            </span>
          </span>
        </v-card-title>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center font-weight-black">Name</th>
                <th class="text-left font-weight-black">Description</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="caseDetail.fileStatus">
                <td class="text-center">Status</td>
                <td
                  v-for="fileStatus in filterStages(caseDetail.fileStatus)"
                  :key="fileStatus.id"
                  class="text-left font-italic font-weight-black"
                >
                  {{ fileStatus.name }}
                </td>
              </tr>
              <tr v-if="caseDetail.createdAt">
                <td class="text-center">Created On:</td>
                <td class="text-left font-italic">
                  {{ formatDate(caseDetail.createdAt) }}
                </td>
              </tr>
              <tr v-if="caseDetail.createdAt">
                <td class="text-center">Created By:</td>
                <td class="text-left font-italic">
                  {{ filterUser(caseDetail.createdBy) }}
                </td>
              </tr>
              <tr v-if="caseDetail.transferDate">
                <td class="text-center">Transfer Date</td>
                <td class="text-left font-italic">
                  {{ formatDate(caseDetail.transferDate) }}
                </td>
              </tr>
              <tr v-if="caseDetail.transferDate">
                <td class="text-center">Transfered By:</td>
                <td class="text-left font-italic">
                  {{ filterUser(caseDetail.transferBy) }}
                </td>
              </tr>
              <tr v-if="caseDetail.dateOfCancel">
                <td class="text-center">Cancellation Date</td>
                <td class="text-left font-italic">
                  {{ formatDate(caseDetail.dateOfCancel) }}
                </td>
              </tr>
              <tr v-if="caseDetail.dateOfCancel">
                <td class="text-center">Cancellation By:</td>
                <td class="text-left font-italic">
                  {{ filterUser(caseDetail.cancelBy) }}
                </td>
              </tr>
              <tr v-if="caseDetail.caseFrom">
                <td class="text-center">Type</td>
                <td class="text-left font-italic">{{ caseDetail.caseFrom }}</td>
              </tr>

              <tr v-if="caseDetail.refType">
                <td class="text-center">Department</td>
                <td class="text-left font-italic">
                  {{ filterDept(caseDetail.refType)[0].name }}, ({{
                    filterDept(caseDetail.refType)[0].description
                  }})
                </td>
              </tr>
              <tr v-if="caseDetail.subRefType">
                <td class="text-center">File Classification</td>
                <td class="text-left font-italic">
                  {{ filterSubDept(caseDetail.subRefType)[0].subCode }}
                </td>
              </tr>
              <tr v-if="caseDetail.branch">
                <td class="text-center">Branch</td>
                <td class="text-left font-italic">
                  {{ filterBranches(caseDetail.branch)[0].brCode }}, ({{
                    filterBranches(caseDetail.branch)[0].name
                  }})
                </td>
              </tr>
              <tr v-if="caseDetail.insurer">
                <td class="text-center">Insurer</td>
                <td class="text-left font-italic">
                  {{ filterIns(caseDetail.insurer)[0].name }}
                </td>
              </tr>
              <tr v-if="caseDetail.handler">
                <td class="text-center">Handler</td>
                <td class="text-left font-italic">
                  {{ filterHandler(caseDetail.handler)[0].name }}, ({{
                    filterHandler(caseDetail.handler)[0].email
                  }})
                </td>
              </tr>
              <tr v-if="caseDetail.adjuster">
                <td class="text-center">Adjuster</td>
                <td class="text-left font-italic">
                  {{ filterUser(caseDetail.adjuster) }}
                </td>
              </tr>
              <tr v-if="caseDetail.editor">
                <td class="text-center">Editor</td>
                <td class="text-left font-italic">
                  {{ filterUser(caseDetail.editor) }}
                </td>
              </tr>
              <tr v-if="caseDetail.clerkInCharge">
                <td class="text-center">Clerk In Charge</td>
                <td class="text-left font-italic">
                  {{ filterUser(caseDetail.clerkInCharge) }}
                </td>
              </tr>
              <tr v-if="caseDetail.claimNo">
                <td class="text-center">Insurer's Ref No.</td>
                <td class="text-left font-italic">{{ caseDetail.claimNo }}</td>
              </tr>
              <tr v-if="caseDetail.vehicleNo">
                <td class="text-center">Vehicle No.</td>
                <td class="text-left font-italic">
                  {{ caseDetail.vehicleNo.toUpperCase() }}
                </td>
              </tr>
              <tr v-if="caseDetail.dateOfAssign">
                <td class="text-center">Date Of Assignment</td>
                <td class="text-left font-italic">
                  {{ formatDate(caseDetail.dateOfAssign) }}
                </td>
              </tr>
              <tr v-if="caseDetail.dateOfLoss">
                <td class="text-center">Date Of Loss</td>
                <td class="text-left font-italic">
                  {{ formatDate(caseDetail.dateOfLoss) }}
                </td>
              </tr>
              <tr v-if="caseDetail.stateOfLoss">
                <td class="text-center">State Of Loss</td>
                <td class="text-left font-italic">
                  {{ filterStates(caseDetail.stateOfLoss)[0].stCode }}, ({{
                    filterStates(caseDetail.stateOfLoss)[0].name
                  }})
                </td>
              </tr>
              <tr v-if="caseDetail.placeOfLoss">
                <td class="text-center">Place Of Loss</td>
                <td class="text-left font-italic">
                  {{ caseDetail.placeOfLoss }}
                </td>
              </tr>
              <tr v-if="caseDetail.insuredName">
                <td class="text-center">Insured Name</td>
                <td class="text-left font-italic">
                  {{ caseDetail.insuredName }}
                </td>
              </tr>
              <tr v-if="caseDetail.insuredIC">
                <td class="text-center">Insured IC No.</td>
                <td class="text-left font-italic">
                  {{ caseDetail.insuredIC }}
                </td>
              </tr>
              <tr v-if="caseDetail.InsuredTel">
                <td class="text-center">Insured Tel. No.</td>
                <td class="text-left font-italic">
                  {{ caseDetail.InsuredTel }}
                </td>
              </tr>
              <tr v-if="caseDetail.insuredEmail">
                <td class="text-center">Insured Email</td>
                <td class="text-left font-italic">
                  {{ caseDetail.insuredEmail }}
                </td>
              </tr>
              <tr v-if="caseDetail.insuredDriverName">
                <td class="text-center">Insured Driver Name</td>
                <td class="text-left font-italic">
                  {{ caseDetail.insuredDriverName }}
                </td>
              </tr>
              <tr v-if="caseDetail.insuredDriverIc">
                <td class="text-center">Insured Driver IC No.</td>
                <td class="text-left font-italic">
                  {{ caseDetail.insuredDriverIc }}
                </td>
              </tr>
              <tr v-if="caseDetail.insuredDriverEmail">
                <td class="text-center">Insured Driver Email</td>
                <td class="text-left font-italic">
                  {{ caseDetail.insuredDriverEmail }}
                </td>
              </tr>
              <tr v-if="caseDetail.policeReportNo">
                <td class="text-center">Police Report No.</td>
                <td class="text-left font-italic">
                  {{ caseDetail.policeReportNo }}
                </td>
              </tr>
              <tr v-if="caseDetail.policeReportDate">
                <td class="text-center">Police Report Date</td>
                <td class="text-left font-italic">
                  {{ caseDetail.policeReportDate }}
                </td>
              </tr>
              <tr v-if="caseDetail.policyNo">
                <td class="text-center">Policy Number</td>
                <td class="text-left font-italic">
                  {{ caseDetail.policyNo }}
                </td>
              </tr>
              <tr v-if="caseDetail.coverFrom || caseDetail.coverTo">
                <td class="text-center">Cover Period</td>
                <td class="text-left font-italic">
                  {{ formatDate(caseDetail.coverFrom) }} -
                  {{ formatDate(caseDetail.coverTo) }}
                </td>
              </tr>
              <tr v-if="caseDetail.insComment">
                <td class="text-center">Insurer's Comment</td>
                <td class="text-left font-italic">
                  {{ caseDetail.insComment }}
                </td>
              </tr>
              <tr v-if="caseDetail.solicitorName">
                <td class="text-center">Solicitor Name</td>
                <td class="text-left font-italic">
                  {{ caseDetail.solicitorName }}
                </td>
              </tr>
              <tr v-if="caseDetail.solicitorTel">
                <td class="text-center">Solicitor Tel.</td>
                <td class="text-left font-italic">
                  {{ caseDetail.solicitorTel }}
                </td>
              </tr>
              <tr v-if="caseDetail.solicitorEmail">
                <td class="text-center">Solicitor Email</td>
                <td class="text-left font-italic">
                  {{ caseDetail.solicitorEmail }}
                </td>
              </tr>
              <tr v-if="caseDetail.counselName">
                <td class="text-center">Counsel Name</td>
                <td class="text-left font-italic">
                  {{ caseDetail.counselName }}
                </td>
              </tr>
              <tr v-if="caseDetail.counselTel">
                <td class="text-center">Counsel Tel</td>
                <td class="text-left font-italic">
                  {{ caseDetail.counselTel }}
                </td>
              </tr>
              <tr v-if="caseDetail.counselEmail">
                <td class="text-center">Counsel Email</td>
                <td class="text-left font-italic">
                  {{ caseDetail.counselEmail }}
                </td>
              </tr>
              <tr v-if="caseDetail.thirdPartyClaimantName">
                <td class="text-center">Third Party Claimant Name</td>
                <td class="text-left font-italic">
                  {{ caseDetail.thirdPartyClaimantName }}
                </td>
              </tr>
              <tr v-if="caseDetail.thirdPartyClaimantIc">
                <td class="text-center">Third Party Claimant IC No.</td>
                <td class="text-left font-italic">
                  {{ caseDetail.thirdPartyClaimantIc }}
                </td>
              </tr>
              <tr v-if="caseDetail.thirdPartyClaimantTel">
                <td class="text-center">Third Party Claimant Tel No.</td>
                <td class="text-left font-italic">
                  {{ caseDetail.thirdPartyClaimantTel }}
                </td>
              </tr>
              <tr v-if="caseDetail.thirdPartyClaimantEmail">
                <td class="text-center">Third Party Claimant Email</td>
                <td class="text-left font-italic">
                  {{ caseDetail.thirdPartyClaimantEmail }}
                </td>
              </tr>
              <tr v-if="caseDetail.thirdPartyClaimantVehicleNo">
                <td class="text-center">Third Party Claimant Vehicle No.</td>
                <td class="text-left font-italic">
                  {{ caseDetail.thirdPartyClaimantVehicleNo }}
                </td>
              </tr>
              <tr v-if="caseDetail.thirdPartyClaimantVehicleModel">
                <td class="text-center">Third Party Claimant Vehicle Model</td>
                <td class="text-left font-italic">
                  {{ caseDetail.thirdPartyClaimantVehicleModel }}
                </td>
              </tr>
              <tr v-if="caseDetail.thirdPartyClaimantVehicleSolicitor">
                <td class="text-center">Third Party Claimant Solicitor</td>
                <td class="text-left font-italic">
                  {{ caseDetail.thirdPartyClaimantVehicleSolicitor }}
                </td>
              </tr>
              <tr v-if="caseDetail.thirdPartyClaimantVehicleSolicitorEmail">
                <td class="text-center">
                  Third Party Claimant Solicitor Email
                </td>
                <td class="text-left font-italic">
                  {{ caseDetail.thirdPartyClaimantVehicleSolicitorEmail }}
                </td>
              </tr>

              <tr v-if="caseDetail.applyDocDate">
                <td class="text-center">Document Application Date</td>
                <td class="text-left font-italic">
                  {{ formatDate(caseDetail.applyDocDate) }}
                </td>
              </tr>
              <tr v-if="caseDetail.applyDocDate">
                <td class="text-center">Document Application By:</td>
                <td class="text-left font-italic">
                  {{ filterUser(caseDetail.applyDocBy) }}
                </td>
              </tr>
              <tr v-if="caseDetail.dateOfReg">
                <td class="text-center">Branch Assigning Date</td>
                <td class="text-left font-italic">
                  {{ formatDate(caseDetail.dateOfReg) }}
                </td>
              </tr>
              <tr v-if="caseDetail.dateOfReg">
                <td class="text-center">Branch Assigned By:</td>
                <td class="text-left font-italic">
                  {{ filterUser(caseDetail.assignBranchBy) }}
                </td>
              </tr>
              <tr v-if="caseDetail.dateOfAdj">
                <td class="text-center">Adjuster Assigning Date</td>
                <td class="text-left font-italic">
                  {{ formatDate(caseDetail.dateOfAdj) }}
                </td>
              </tr>
              <tr v-if="caseDetail.dateOfAdj">
                <td class="text-center">Adjuster Assigning By:</td>
                <td class="text-left font-italic">
                  {{ filterUser(caseDetail.assignAdjBy) }}
                </td>
              </tr>
              <tr v-if="caseDetail.dateStartInv">
                <td class="text-center">Start Investigation Date</td>
                <td class="text-left font-italic">
                  {{ formatDate(caseDetail.dateStartInv) }}
                </td>
              </tr>
              <tr v-if="caseDetail.dateEndInv">
                <td class="text-center">Complete Investigation Date</td>
                <td class="text-left font-italic">
                  {{ formatDate(caseDetail.dateEndInv) }}
                </td>
              </tr>
              <tr v-if="caseDetail.dateOfEdi">
                <td class="text-center">Editor Assigned Date</td>
                <td class="text-left font-italic">
                  {{ formatDate(caseDetail.dateOfEdi) }}
                </td>
              </tr>
              <tr v-if="caseDetail.dateOfEdi">
                <td class="text-center">Editor Assigned By:</td>
                <td class="text-left font-italic">
                  {{ filterUser(caseDetail.assignEdiBy) }}
                </td>
              </tr>
              <tr v-if="caseDetail.dateStartEdi">
                <td class="text-center">Start Editing Date</td>
                <td class="text-left font-italic">
                  {{ formatDate(caseDetail.dateStartEdi) }}
                </td>
              </tr>
              <tr v-if="caseDetail.dateEndEdi">
                <td class="text-center">Complete Editing Date</td>
                <td class="text-left font-italic">
                  {{ formatDate(caseDetail.dateEndEdi) }}
                </td>
              </tr>
              <tr v-if="caseDetail.dateOfApproval">
                <td class="text-center">Approval Date</td>
                <td class="text-left font-italic">
                  {{ formatDate(caseDetail.dateOfApproval) }}
                </td>
              </tr>
              <tr v-if="caseDetail.dateOfApproval">
                <td class="text-center">Approval By:</td>
                <td class="text-left font-italic">
                  {{ filterUser(caseDetail.approvedBy) }}
                </td>
              </tr>
              <tr v-if="caseDetail.dateAssignFormatting">
                <td class="text-center">Clerk Assigned Date(Formatting)</td>
                <td class="text-left font-italic">
                  {{ formatDate(caseDetail.dateAssignFormatting) }}
                </td>
              </tr>
              <tr v-if="caseDetail.dateAssignFormatting">
                <td class="text-center">Clerk Assigned (Formatting) By:</td>
                <td class="text-left font-italic">
                  {{ filterUser(caseDetail.assignClerkBy) }}
                </td>
              </tr>
              <tr v-if="caseDetail.dateStartFormatting">
                <td class="text-center">Start Formatting Date</td>
                <td class="text-left font-italic">
                  {{ formatDate(caseDetail.dateStartFormatting) }}
                </td>
              </tr>
              <tr v-if="caseDetail.dateEndFormatting">
                <td class="text-center">Complete Formatting Date</td>
                <td class="text-left font-italic">
                  {{ formatDate(caseDetail.dateEndFormatting) }}
                </td>
              </tr>
              <tr v-if="caseDetail.dateFinal">
                <td class="text-center">Invoising & Finalisation Date</td>
                <td class="text-left font-italic">
                  {{ formatDate(caseDetail.dateFinal) }}
                </td>
              </tr>
              <tr v-if="caseDetail.dateFinal">
                <td class="text-center">Invoising & Finalisation By:</td>
                <td class="text-left font-italic">
                  {{ filterUser(caseDetail.finalBy) }}
                </td>
              </tr>
              <tr v-if="caseDetail.dateFinal">
                <td class="text-center">Invoice Nombor</td>
                <td class="text-left font-italic">
                  {{ caseDetail.invNo }}
                </td>
              </tr>
              <tr v-if="caseDetail.dateFinal">
                <td class="text-center">Invoice Fee Note</td>
                <td class="text-left font-italic">
                  {{ caseDetail.invFeeNote }}
                </td>
              </tr>
              <tr v-if="caseDetail.dateFinal">
                <td class="text-center">Invoice Total</td>
                <td class="text-left font-italic">
                  RM {{ caseDetail.invTotal }}
                </td>
              </tr>
              <tr v-if="caseDetail.dateDispatch">
                <td class="text-center">Dispatch Date</td>
                <td class="text-left font-italic">
                  {{ formatDate(caseDetail.dateDispatch) }}
                </td>
              </tr>
              <tr v-if="caseDetail.dateDispatch">
                <td class="text-center">Dispatch By:</td>
                <td class="text-left font-italic">
                  {{ filterUser(caseDetail.dispatchBy) }}
                </td>
              </tr>
              <tr v-if="caseDetail.dateClosed">
                <td class="text-center">Closed Date</td>
                <td class="text-left font-italic">
                  {{ formatDate(caseDetail.dateClosed) }}
                </td>
              </tr>
              <tr v-if="caseDetail.dateClosed">
                <td class="text-center">Closed By:</td>
                <td class="text-left font-italic">
                  {{ filterUser(caseDetail.closedBy) }}
                </td>
              </tr>

              <!-- <tr v-for="(item, index) in caseDetail" :key="index">
                <td>{{ index }}</td>
                <td>{{ item }}</td>
              </tr> -->
            </tbody>
          </template>
        </v-simple-table>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDetail = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import CasefileDataService from "../../services/CasefileDataService";
import { mapState } from "vuex";
import moment from "moment";

export default {
  name: "history",
  props: [
    // "adjusters",
    // "editors",
    // "clerks",
    "branches",
    "states",
    "insurer",
    "handlers",
    "refType",
    "subRefTypes",
    "members",
    "stages",
  ],
  data: () => ({
    cases: [],
    dialogDetail: false,
    caseDetail: "",
  }),

  computed: {
    ...mapState(["caseState"]),
  },

  methods: {
    formatDate: function (date) {
      if (!date) return "";
      return moment(date).format("DD/MM/YYYY");
    },
    showDetail(item) {
      this.caseDetail = Object.assign({}, item);
      this.dialogDetail = true;
    },
    yearOnly(value) {
      // return moment(value).format("YYYY");
      return moment(value).format("YY");
    },
    filterCase(item) {
      return this.cases.filter((file) => file.id === item);
    },
    filterDept(item) {
      return this.refType.filter((dept) => dept.id === item);
    },
    filterSubDept(item) {
      return this.subRefTypes.filter((subRef) => subRef.id === item);
    },

    filterBranches(item) {
      return this.branches.filter((branch) => branch.id === item);
    },
    filterStates(item) {
      return this.states.filter((state) => state.id === item);
    },
    filterIns(item) {
      return this.insurer.filter((insurer) => insurer.id === item);
    },
    filterStages(item) {
      return this.stages.filter((stage) => stage.stageCode === item);
    },
    filterUser(item) {
      // return this.members.filter((member) => member.id === item);
      const myArray = this.members.filter((member) => member.id === item);
      let result = myArray.map((a) => a.username + ", " + a.email);
      return result[0];
    },
    filterHandler(item) {
      return this.handlers.filter((handler) => handler.id === item);
    },
    showFileStatus(item) {
      const myArray = this.stages.filter((stage) => stage.stageCode === item);
      let result = myArray.map((a) => a.name);
      return result;
    },

    retrieveCases() {
      CasefileDataService.getAll()
        .then((response) => {
          let thisCasefile = this.caseState.casefile.vehicleNo;
          // this.cases = response.data;
          if (!thisCasefile) return "";
          this.cases = response.data.filter(
            (item) => item.vehicleNo === thisCasefile
          );
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {
    this.retrieveCases();
  },
};
</script>
