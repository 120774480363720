import Vue from "vue";
import Vuex from "vuex";

import { auth } from "./auth.module";
import { fileModule } from "./file.module";
// import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // snackbars: {}
    snackbars: [],
    caseState: fileModule.state,
  },

  mutations: {
    SET_SNACKBAR(state, snackbar) {
      // state.snackbar = snackbar;
      state.snackbars = state.snackbars.concat(snackbar);
    },
  },
  actions: {
    setSnackbar({ commit }, snackbar) {
      commit("SET_SNACKBAR", snackbar);
    },
  },

  modules: {
    auth,
    fileModule,
    // snackbar,
  },

  getters: {
    getCaseState: function (state) {
      return state.caseState;
    },
  },
  // plugins: [createPersistedState()],
});
