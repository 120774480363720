<template>
  <div>
    <!-- <li v-for="member in this.members" :key="member.id">
      {{ member.username }}
    </li> -->

    <v-container fluid style="width: 80%" class="text-center">
      <div v-if="caseState.casefile.id && fileaccess">
        <!-- TOOLBAR 1 -->
        <v-row>
          <v-toolbar dense color="indigo lighten-3" dark>
            <!-- APPLY DOC -->
            <ApplyDoc
              :casefile="caseState.casefile"
              :refType="refType"
              :branches="branches"
              :subRefTypes="subRefTypes"
              :insurer="insurer"
              :management="management"
            />
            <!-- ASSIGN BRANCH -->
            <AssignBranch
              :casefile="caseState.casefile"
              :refType="refType"
              :branches="branches"
              :subRefTypes="subRefTypes"
              :insurer="insurer"
              :management="management"
              :states="states"
              :regions="regions"
              :handlers="handlers"
              :members="members"
            />

            <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  :disabled="newPendDisabled"
                  class="success mr-1"
                  fab
                  x-small
                  v-on="{ ...tooltip }"
                  @click="assignBranch()"
                >
                  <v-icon>mdi-file-cabinet</v-icon>
                </v-btn>
              </template>
              <span>ASSIGN BRANCH</span>
            </v-tooltip> -->

            <!-- BRANCH ACCEPT & ASSIGN ADJUSTER -->
            <BranchAccept
              :casefile="caseState.casefile"
              :refType="refType"
              :branches="branches"
              :subRefTypes="subRefTypes"
              :insurer="insurer"
              :management="management"
              :states="states"
              :regions="regions"
              :handlers="handlers"
              :members="members"
              :adjusters="adjusters"
            />

            <!-- ACCEPT ASSIGNMENT -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  :disabled="acceptAssignmentDisabled"
                  class="success mr-1"
                  fab
                  x-small
                  v-on="{ ...tooltip }"
                  @click="acceptCase()"
                >
                  <v-icon>mdi-account-arrow-left-outline</v-icon>
                </v-btn>
              </template>
              <span>ACCEPT ASSIGNMENT</span>
            </v-tooltip>

            <!-- COMPLETE INVESTIGATION -->
            <CompleteInvestigation
              :casefile="caseState.casefile"
              :claims="claims"
              :refType="refType"
              :branches="branches"
              :branch="branch"
              :subRefTypes="subRefTypes"
              :insurer="insurer"
              :clerks="clerks"
              :members="members"
            />
            <!-- ASSIGN EDITOR -->
            <AssignEditor
              :casefile="caseState.casefile"
              :branches="branches"
              :branch="branch"
              :management="management"
              :editors="editors"
              :refType="refType"
              :subRefTypes="subRefTypes"
              :insurer="insurer"
              :members="members"
            />

            <!-- ACCEPT EDITING -->
            <AcceptEditing :casefile="caseState.casefile" />

            <!-- COMPLETE EDITING -->
            <CompleteEditing
              :casefile="caseState.casefile"
              :refType="refType"
              :branches="branches"
              :branch="branch"
              :subRefTypes="subRefTypes"
              :insurer="insurer"
              :clerks="clerks"
              :members="members"
            />
          </v-toolbar>
        </v-row>
        <!-- TOOLBAR END -->

        <!-- TOOLBAR 2 -->
        <v-row>
          <v-toolbar dense color="indigo lighten-2" dark>
            <!-- HOLD APPROVAL -->
            <HoldApproval :approvingManager="approvingManager" />

            <!-- APPROVAL REPORT -->
            <ApprovalReport :holdingManager="holdingManager" />

            <!-- RE-INVESTIGATE -->
            <ReInvestigate :holdingManager="holdingManager" />

            <!-- RE-INVESTIGATE -->
            <ReEdit :holdingManager="holdingManager" />

            <!-- ASSIGN CLERK -->
            <AssignClerk
              :casefile="caseState.casefile"
              :branches="branches"
              :branch="branch"
              :refType="refType"
              :subRefTypes="subRefTypes"
              :insurer="insurer"
              :members="members"
              :management="management"
              :clerks="clerks"
            />

            <!-- ACCEPT FORMATTING -->
            <AcceptFormatting :casefile="caseState.casefile" />

            <!-- COMPLETE FORMATTING -->
            <CompleteFormatting :casefile="caseState.casefile" />
            <v-spacer></v-spacer>

            <!-- TRANSFER FILE -->
            <TransferCase
              :approvingManager="approvingManager"
              :casefile="caseState.casefile"
              :refType="refType"
              :subRefTypes="subRefTypes"
              :insurer="insurer"
              :branches="branches"
              :members="members"
              :states="states"
              :regions="regions"
            />
            <!-- TRANSFER ADJUSTER -->
            <TransferAdj
              :casefile="caseState.casefile"
              :refType="refType"
              :branches="branches"
              :subRefTypes="subRefTypes"
              :insurer="insurer"
              :management="management"
              :states="states"
              :regions="regions"
              :handlers="handlers"
              :members="members"
              :adjusters="adjusters"
            />
          </v-toolbar>
        </v-row>
        <!-- TOOLBAR END -->

        <!-- TOOLBAR 3 -->
        <v-row>
          <v-toolbar dense color="indigo lighten-1" dark>
            <!-- INVOISING -->
            <Invoising :management="management" />

            <!-- DISPATCH -->
            <Dispatch :management="management" />

            <!-- DONE DISPATCH -->
            <DoneDispatch :management="management" />

            <!-- CLOSE -->
            <Close :management="management" />
            <v-spacer></v-spacer>

            <!-- CANCEL FILE -->
            <CancelCase
              :casefile="caseState.casefile"
              :approvingManager="approvingManager"
              :refType="refType"
              :branches="branches"
              :subRefTypes="subRefTypes"
              :insurer="insurer"
            />

            <!-- EDIT FILE -->
            <EditCase
              :casefile="caseState.casefile"
              :management="management"
              :refType="refType"
              :branches="branches"
              :subRefTypes="subRefTypes"
              :handlers="handlers"
              :states="states"
              :regions="regions"
              :stages="stages"
              :insurer="insurer"
            />

            <!-- DAYS-->
            <v-chip v-if="dateDiff" outlined small class="purple lighten-3"
              >{{
                dateDiff(
                  moment(caseState.casefile.createdAt).format(),
                  moment().format()
                )
              }}
              Day(s)</v-chip
            >

            <!-- ACKNOWLEDGEMENT -->
            <acknowledgement
              :casefile="caseState.casefile"
              :refType="refType"
              :subRefTypes="subRefTypes"
              :insurer="insurer"
              :branches="branches"
              :states="states"
            />

            <!-- CASE GENUINITY -->
            <flag :casefile="caseState.casefile" />
          </v-toolbar>
        </v-row>
        <!-- TOOLBAR END -->

        <v-row>
          <v-tabs show-arrows grow v-model="tab" class="mb-2">
            <v-tabs-slider color="teal lighten-3"></v-tabs-slider>
            <v-tab>Details</v-tab>
            <v-tab>Documents</v-tab>
            <v-tab>Comments</v-tab>
            <v-tab>History</v-tab>
            <v-tab>Audit</v-tab>
            <v-tab>Claims</v-tab>
          </v-tabs>
        </v-row>

        <!-- Tab Items -->
        <v-tabs-items class="mt-1" v-model="tab">
          <v-tab-item>
            <DetailTab
              :adjusters="adjusters"
              :members="members"
              :editors="editors"
              :clerks="clerks"
              :branches="branches"
              :insurer="insurer"
              :handlers="handlers"
              :refType="refType"
              :subRefTypes="subRefTypes"
              :stages="stages"
              :states="states"
            />
          </v-tab-item>
          <v-tab-item>
            <Documents :casefile="caseState.casefile" />
          </v-tab-item>
          <v-tab-item>
            <!-- <v-card flat id="comments" class="mt-5 my-2 pa-2"> -->
            <!-- <v-card-title class="pb-0">COMMENTS</v-card-title>
              <v-divider></v-divider> -->
            <comments :casefile="caseState.casefile" />
            <!-- </v-card> -->
          </v-tab-item>
          <v-tab-item>
            <History
              :branches="branches"
              :refType="refType"
              :subRefTypes="subRefTypes"
              :stages="stages"
              :members="members"
              :insurer="insurer"
              :states="states"
              :handlers="handlers"
            />
          </v-tab-item>
          <v-tab-item>
            <Audit :members="members" />
          </v-tab-item>
          <v-tab-item>
            <Claims
              :branches="branches"
              :refType="refType"
              :subRefTypes="subRefTypes"
              :members="members"
            />
          </v-tab-item>
        </v-tabs-items>

        <v-row>
          <v-col cols="12">
            <!-- Action Row -->
            <v-row no-gutters class="mt-2">
              <v-col cols="12" justify="center" align="center">
                <v-dialog v-model="dialogAcceptCase" max-width="500px">
                  <v-card class="text-center">
                    <v-card-title class="justify-center text-h5 grey lighten-2"
                      >ACCEPT ASSIGNMENT?</v-card-title
                    >
                    <v-card-text class="mt-3 pa-0">
                      CASEFILE NUMBER: AA<span
                        v-for="(dept, index) in filterDept(
                          caseState.casefile.refType
                        )"
                        :key="index"
                        >/{{ dept.name
                        }}<span
                          v-for="(subDept, index) in filterSubDept(
                            caseState.casefile.subRefType
                          )"
                          :key="index"
                          >/{{ subDept.subCode }}</span
                        ></span
                      >/{{ caseState.casefile.id }}/{{
                        yearOnly(caseState.casefile.createdAt)
                      }}<span
                        v-for="branch in filterBranches(
                          caseState.casefile.branch
                        )"
                        :key="branch.id"
                        >/{{ branch.brCode }}
                      </span>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        tile
                        small
                        dark
                        class="mt-3 mr-2 error"
                        @click="closeAcceptCase"
                        >Cancel</v-btn
                      >
                      <v-btn
                        tile
                        small
                        @click="acceptCaseConfirm"
                        class="mt-3 mr-2 success"
                        >OK</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <!-- <div v-else>
        <v-card flat class="pa-0 ma-auto">
          <h1>
            You might not have enough permission to view this content? Try
            refreshing .....
          </h1>
        </v-card>
      </div> -->
    </v-container>
  </div>
</template>

<script>
import CasefileDataService from "../../services/CasefileDataService";
import MemberDataService from "../../services/MemberDataService";
import StagesDataService from "../../services/StageDataService";
import BranchDataService from "../../services/BranchDataService";
import StateDataService from "../../services/StateDataService";
import RegionDataService from "../../services/RegionDataService";
import DeptDataService from "../../services/DeptDataService";
import SubRefTypeDataService from "../../services/SubDeptDataService";
import InsDataService from "../../services/InsDataService";
import HandlerDataService from "../../services/HandlerDataService";
import Acknowledgement from "../../components/pdf/acknowledgement";
import Documents from "../../components/uploads/documents";
import Comments from "../../components/comment/comments";
import ApplyDoc from "../../components/detailView/applyDoc.vue";
import AssignBranch from "../../components/detailView/assignBranch.vue";
import BranchAccept from "../../components/detailView/branchAccept.vue";
import CompleteInvestigation from "../../components/detailView/completeInvestigation";
import CancelCase from "../../components/detailView/cancelCase";
import TransferCase from "../../components/detailView/transferCase";
import TransferAdj from "../../components/detailView/transferAdj";
import EditCase from "../../components/detailView/editCase";
import AssignEditor from "../../components/detailView/assignEditor";
import AcceptEditing from "../../components/detailView/acceptEditing";
import CompleteEditing from "../../components/detailView/completeEditing";
import HoldApproval from "../../components/detailView/holdApproval";
import ApprovalReport from "../../components/detailView/approvalReport";
import ReInvestigate from "../../components/detailView/reInvestigate";
import ReEdit from "../../components/detailView/reEdit";
import AssignClerk from "../../components/detailView/assignClerk";
import AcceptFormatting from "../../components/detailView/acceptFormatting";
import CompleteFormatting from "../../components/detailView/completeFormatting";
import Invoising from "../../components/detailView/invoising";
import Dispatch from "../../components/detailView/dispatch";
import DoneDispatch from "../../components/detailView/doneDispatch";
import Close from "../../components/detailView/close";
import DetailTab from "../../components/detailView/detailTab.vue";
import History from "../../components/detailView/history.vue";
import Audit from "../../components/detailView/audit.vue";

import ClaimDataService from "../../services/ClaimDataService";

import moment from "moment";
import Flag from "../../components/flag/flag";
import { mapState } from "vuex";
import Claims from "../../components/detailView/detailClaims.vue";
// import MailDataService from "../../services/MailDataService";

export default {
  name: "vuexFile",
  components: {
    Acknowledgement,
    Documents,
    Comments,
    Flag,
    ApplyDoc,
    AssignBranch,
    BranchAccept,
    CompleteInvestigation,
    AssignEditor,
    AcceptEditing,
    CompleteEditing,
    CancelCase,
    TransferCase,
    TransferAdj,
    EditCase,
    HoldApproval,
    ApprovalReport,
    ReInvestigate,
    ReEdit,
    AssignClerk,
    AcceptFormatting,
    CompleteFormatting,
    Invoising,
    Dispatch,
    DoneDispatch,
    Close,
    Audit,
    DetailTab,
    History,
    Claims,
  },

  data() {
    return {
      overlay: true,
      tab: null,

      fileaccess: false,
      management: false,
      newCase: false,
      approvingManager: false,
      holdingManager: false,
      claims: [],
      stages: [],
      adjusters: [],
      editors: [],
      clerks: [],
      members: [],
      branches: [],
      regions: [],
      refType: [],
      subRefTypes: [],
      insurer: [],
      handlers: [],
      branch: {
        name: "",
        brCode: "",
      },
      states: [],

      // dialogAdj: false,
      // dialogAssignBranch: false,
      dialogAcceptCase: false,

      acknowledgement: false,
      menu: false,
      menu1: false,
      // checkboxAcceptCase: false,
    };
  },

  computed: {
    ...mapState(["caseState"]),

    newPendDisabled() {
      return !(
        (this.caseState.casefile.fileStatus == `NEW` ||
          this.caseState.casefile.fileStatus == `PEND`) &&
        this.management
      );
    },
    // assignAdjDisabled() {
    //   return !(this.caseState.casefile.fileStatus == `BRAC` && this.management);
    // },
    acceptAssignmentDisabled() {
      return !(
        this.caseState.casefile.fileStatus == `ADAC` &&
        this.caseState.casefile.adjuster == this.$store.state.auth.user.id
      );
    },

    isAddFileButtonDisabled() {
      return !(
        this.caseState.casefile.refType &&
        this.caseState.casefile.branch &&
        this.caseState.casefile.insurer &&
        this.caseState.casefile.claimNo &&
        this.caseState.casefile.vehicleNo &&
        this.caseState.casefile.dateOfLoss &&
        this.caseState.casefile.insComment
      );
    },
    computedDateFormattedMomentjs() {
      return this.date ? moment(this.date).format("DD/MM/YY") : "";
    },
    // assignAdjRule() {
    //   return !(
    //     // this.checkboxAcceptCase &&
    //     (this.branch.brCode && this.caseState.casefile.adjuster)
    //   );
    // },
  },
  watch: {
    // assignAdj(val) {
    //   val || this.closeAdj();
    // },
    // assignBranch(val) {
    //   val || this.closeAssignBranchDialog();
    // },
    acceptCase(val) {
      val || this.closeAcceptCase();
    },
  },

  methods: {
    moment: function (date) {
      return moment(date);
    },
    yearOnly(value) {
      // return moment(value).format("YYYY");
      return moment(value).format("YY");
    },
    filterRegions(item) {
      return this.regions.filter((region) => region.branchId === item);
    },
    filtersubRefType(item) {
      return this.subRefTypes.filter(
        (subRefType) => subRefType.deptId === item
      );
    },
    filterSubDept(item) {
      return this.subRefTypes.filter((subRef) => subRef.id === item);
    },
    filterAdjByBranch(item) {
      return this.adjusters.filter((r) =>
        r.branches.some((i) => i.brCode === item)
      );
    },

    filterClerkByBranch(item) {
      return this.clerks.filter((r) =>
        r.branches.some((i) => i.brCode === item)
      );
    },

    // findClerkByBranch(item) {
    //   return this.clerks.map((clerk) => {
    //     clerk.branches = clerk.branches.filter(
    //       (branch) => branch.brCode === item
    //     );
    //     // return clerk;
    //   });

    findClerkByBranch(item) {
      // if (!item) return 2;
      const myArray = this.clerks.filter((r) =>
        r.branches.some((i) => i.brCode === item)
      );
      return myArray.map((b) => b.email) || "sys.admin@associatedadjusters.com";
    },
    findAdjByBranch(item) {
      // if (!item) return 2;
      const myArray = this.adjusters.filter((r) =>
        r.branches.some((i) => i.brCode === item)
      );
      return myArray.map((b) => b.email) || "sys.admin@associatedadjusters.com";
    },
    findAdjEmail(item) {
      // console.log(item);
      const adjuster = this.adjusters.filter(
        (adjuster) => adjuster.id === item
      );
      return (
        adjuster.map((b) => b.email) || "sys.admin@associatedadjusters.com"
      );
    },
    // },
    // findAdjEmail(item) {
    //   console.log(item);
    //   const adjuster = this.adjusters.filter(
    //     (adjuster) => adjuster.id === item
    //   );
    //   console.log(adjuster);
    //   return adjuster.email;
    // },
    // findAdjEmail(item) {
    //   const myArray = this.adjusters.filter((r) =>
    //     r.branches.some((i) => i.id === item)
    //   );
    //   return myArray.map((b) => b.email) || "sys.admin@associatedadjusters.com";
    // },

    // filtersubRefTypeMap(item) {
    //   const myArray = this.subRefTypes.filter((subRef) => subRef.id === item);
    //   let result = myArray.map((a) => a.subCode);
    //   if (!item) return "";
    //   return result + "/";
    // },

    // filterClerkByBranch(item) {
    //   const myArray = this.clerks.filter((clerk) =>
    //     clerk.branches.some((i) => i.brCode === item)
    //   );
    //   console.log(myArray);
    //   let result = myArray.map((a) => a);
    //   if (!item) return "";
    //   return result + ";";
    // },

    filterStages(item) {
      return this.stages.filter((stage) => stage.stageCode === item);
    },
    filterDept(item) {
      return this.refType.filter((dept) => dept.id === item);
    },
    filterIns(item) {
      return this.insurer.filter((insurer) => insurer.id === item);
    },
    filterBranchName(item) {
      if (!item) return "TBA";
      const filteredbranch = this.branches.filter(
        (branch) => branch.id === item
      );
      return filteredbranch[0].name;
    },
    filterPicID(item) {
      if (!item) return "TBA";
      const filteredDept = this.refType.filter((dept) => dept.id === item);
      return filteredDept[0].picID;
    },
    filterAdj(item) {
      return this.adjusters.filter((adjuster) => adjuster.id === item);
    },
    filterEditor(item) {
      return this.editors.filter((editor) => editor.id === item);
    },
    filterrecipient(item) {
      if (!item) return "TBA";
      return this.clerks.filter((clerks) => clerks.id === item);
    },
    filterApprover(item) {
      return this.members.filter((approver) => approver.id === item);
    },
    filterClerk(item) {
      return this.clerks.filter((clerk) => clerk.id === item);
    },
    filterBranches(item) {
      return this.branches.filter((branch) => branch.id === item);
    },
    findBrcode(item) {
      if (!item) return "TBA";
      const filteredbranch = this.branches.filter(
        (branch) => branch.id === item
      );
      return filteredbranch[0].brCode;
      // return this.branches.filter((branch) => branch.id === item);
    },
    filterStates(item) {
      return this.states.filter((state) => state.id === item);
    },
    filterHandler(item) {
      return this.handlers.filter((handler) => handler.insId === item);
    },
    filterUser(item) {
      return this.members.filter((member) => member.id === item);
    },
    findUserEmail(item) {
      const user = this.members.filter((member) => member.id === item);
      return user[0] ? user[0].email : "sys.admin@associatedadjusters.com";
      // return user.map((b) => b.email);
    },
    currentUserBranch() {
      if (
        this.$store.state.auth.user.roles.includes("MANAGER") ||
        this.$store.state.auth.user.roles.includes("ADMIN") ||
        this.$store.state.auth.user.roles.includes("CLERK")
      ) {
        return this.branches;
      } else
        return this.branches.filter(
          (x) => x.id === this.caseState.casefile.branch
        );
    },
    checkManagement() {
      if (
        this.$store.state.auth.user.roles.includes(`ADMIN`) ||
        this.$store.state.auth.user.roles.includes(`MANAGER`) ||
        this.$store.state.auth.user.roles.includes(`CLERK`) ||
        this.$store.state.auth.user.roles.includes(`BRANCHMANAGER`) ||
        this.$store.state.auth.user.roles.includes(`BRANCHCLERK`)
      ) {
        this.management = true;
      }
    },
    checkApproval() {
      if (
        this.$store.state.auth.user.roles.includes(`ADMIN`) ||
        this.$store.state.auth.user.roles.includes(`MANAGER`) ||
        this.$store.state.auth.user.roles.includes(`BRANCHMANAGER`)
      ) {
        this.approvingManager = true;
      }
    },
    checkApprovingManager() {
      if (
        this.caseState.casefile.approvedBy == this.$store.state.auth.user.id
      ) {
        this.holdingManager = true;
      }
    },

    formatDate: function (date) {
      if (!date) return "";
      return moment(date).format("DD/MM/YYYY");
    },
    formatTime: function (date) {
      return moment(date).format("h:mm:ss a");
    },

    dateDiff(startDateString, endDateString) {
      // Method 01
      let start = moment(startDateString);
      let end = moment(endDateString);
      let duration = moment.duration(end.diff(start));
      return Math.round(duration.asDays());

      // Method 02
      // var start = moment(startDateString); //todays date
      // var end = moment(endDateString); // another date
      // var duration = moment.duration(end.diff(start));
      // var days = duration.asDays();
      // return Math.round(days);
    },
    refreshList() {
      this.retrieveAdjusters();
      this.retrieveEditors();
      this.retrieveClerks();
      this.retrieveUsers();
      this.retrieveBranches();
      this.retrieveStates();
      this.retrieveCasefiles(this.$route.params.id);
    },

    retrieveClaims() {
      ClaimDataService.getAll()
        .then((response) => {
          this.claims = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    retrieveDept() {
      DeptDataService.getAll()
        .then((response) => {
          this.refType = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveSubRefType() {
      SubRefTypeDataService.getAll()
        .then((response) => {
          this.subRefTypes = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveIns() {
      InsDataService.getAll()
        .then((response) => {
          this.insurer = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveHandler() {
      HandlerDataService.getAll()
        .then((response) => {
          this.handlers = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveStages() {
      StagesDataService.getAll()
        .then((response) => {
          this.stages = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveAdjusters() {
      MemberDataService.getAll()
        .then((response) => {
          this.adjusters = response.data.filter(
            (item) =>
              item.roles.some((b) => b.roleCode === "adjuster") && item.active
          );
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveEditors() {
      MemberDataService.getAll()
        .then((response) => {
          this.editors = response.data.filter(
            (item) =>
              item.roles.some(
                (b) =>
                  b.roleCode === "editor" ||
                  // b.roleCode === "clerk" ||
                  b.roleCode === "manager" ||
                  // b.roleCode === "branchclerk" ||
                  b.roleCode === "branchmanager"
              ) && item.active
          );
        })
        .catch((e) => {
          console.log(e);
        });
    },

    retrieveClerks() {
      MemberDataService.getAll()
        .then((response) => {
          this.clerks = response.data.filter(
            (item) =>
              item.roles.some(
                (b) =>
                  b.roleCode === "clerk" ||
                  b.roleCode === "manager" ||
                  b.roleCode === "branchclerk" ||
                  b.roleCode === "branchmanager"
              ) && item.active
          );
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveUsers() {
      MemberDataService.getAll()
        .then((response) => {
          this.members = response.data.filter((item) => item.active);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveBranches() {
      BranchDataService.getAll()
        .then((response) => {
          this.branches = response.data;
          // this.branches = response.data.map(this.getDisplayBranch);
          // this.branches = response.data.filter(
          //   (item) => item.roles[0].roleCode === "adjuster"
          // );
          // this.adjusters = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // getDisplayBranch(branch) {
    //   return {
    //     id: branch.id,
    //     name:
    //       branch.name.length > 30
    //         ? branch.name.substr(0, 30) + "..."
    //         : branch.name,
    //     brCode:
    //       branch.brCode.length > 30
    //         ? branch.brCode.substr(0, 30) + "..."
    //         : branch.brCode,
    //   };
    // },
    retrieveStates() {
      StateDataService.getAll()
        .then((response) => {
          this.states = response.data.map(this.getDisplayStates);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getDisplayStates(state) {
      return {
        id: state.id,
        name:
          state.name.length > 30
            ? state.name.substr(0, 30) + "..."
            : state.name,
        stCode:
          state.stCode.length > 30
            ? state.stCode.substr(0, 30) + "..."
            : state.stCode,
      };
    },
    retrieveRegions() {
      RegionDataService.getAll()
        .then((response) => {
          this.regions = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveCasefiles(id) {
      CasefileDataService.get(id)
        .then((response) => {
          this.caseState.casefile = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    applyDoc() {
      this.dialogApplyDoc = true;
    },

    acceptCase() {
      this.dialogAcceptCase = true;
    },

    acceptCaseConfirm() {
      CasefileDataService.update(
        this.caseState.casefile.id,
        this.caseState.casefile,
        (this.caseState.casefile.fileStatus = `INVE`),
        (this.caseState.casefile.dateStartInv = moment().format())
      )
        .then((response) => {
          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "green",
            text:
              (response && response.data && response.data.message) ||
              toString(),
          });

          this.refreshList();
        })
        .catch((e) => {
          console.log(e);
        });

      this.closeAcceptCase();
    },

    closeAcceptCase() {
      this.dialogAcceptCase = false;
      this.$nextTick(() => {
        // this.caseState.casefile.fileStatus = "ADAC";
      });
    },

    checkFileaccess() {
      if (
        this.$store.state.auth.user.roles.includes("MANAGER") ||
        this.$store.state.auth.user.roles.includes("ADMIN") ||
        this.$store.state.auth.user.roles.includes("CLERK")
      ) {
        this.fileaccess = true;
        // console.log("Admin");
      } else if (
        this.$store.state.auth.user.roles.includes("BRANCHCLERK") ||
        (this.$store.state.auth.user.roles.includes("BRANCHMANAGER") &&
          this.$store.state.auth.user.dept.includes(
            this.caseState.casefile.refType
          ) &&
          this.$store.state.auth.user.branches.includes(
            this.caseState.casefile.branch
          ))
      ) {
        this.fileaccess = true;
        // console.log("Branch Manager or Branch Clerk");
      } else if (
        this.$store.state.auth.user.roles.includes("ADJUSTER") &&
        this.$store.state.auth.user.dept.includes(
          this.caseState.casefile.refType
        ) &&
        this.$store.state.auth.user.branches.includes(
          this.caseState.casefile.branch
        ) &&
        // this.$store.state.auth.user.adjuster.includes(
        //   this.caseState.casefile.id.toString()

        this.$store.state.auth.user.id === this.caseState.casefile.adjuster
      ) {
        this.fileaccess = true;
        // console.log("Adjuster");
      } else if (
        this.$store.state.auth.user.roles.includes("EDITOR") &&
        this.$store.state.auth.user.dept.includes(
          this.caseState.casefile.refType
        ) &&
        this.$store.state.auth.user.branches.includes(
          this.caseState.casefile.branch
        ) &&
        // this.$store.state.auth.user.adjuster.includes(
        //   this.caseState.casefile.id.toString()

        this.$store.state.auth.user.id === this.caseState.casefile.editor
      ) {
        this.fileaccess = true;
        // console.log("Editor");
      }
      // else this.fileaccess = false;
    },
  },
  mounted() {
    this.overlay = false;
    this.retrieveCasefiles(this.$route.params.id);
    this.retrieveClaims();
    this.retrieveAdjusters();
    this.retrieveEditors();
    this.retrieveClerks();
    this.retrieveUsers();
    this.retrieveBranches();
    this.retrieveStates();
    this.retrieveRegions();
    this.retrieveDept();
    this.retrieveSubRefType();
    this.retrieveIns();
    this.retrieveHandler();
    this.retrieveStages();
    this.checkManagement();
    this.checkApproval();
    this.checkApprovingManager();
    this.checkFileaccess();
  },
};
</script>
<style scoped>
.main-column {
  /* border-right: 1px solid green; */
  /* border-left: 1px solid green; */
  border-bottom: 1px solid grey;
  /* border-right: 1px solid grey; */
  /* border-left: 1px solid green; */
  /* background-color: rgb(214, 232, 233); */
  background-color: aliceblue;
}

.bottom-border {
  /* border-left: 1px solid blue; */
  border-bottom: 1px solid grey;
}
</style>
