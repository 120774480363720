<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on: tooltip }">
        <v-btn
          :disabled="holdApprovalDisabled"
          class="success mr-1"
          fab
          x-small
          v-on="{ ...tooltip }"
          @click="holdApproval()"
        >
          <v-icon>mdi-account-convert-outline</v-icon>
        </v-btn>
      </template>
      <span>HOLD APPROVAL</span>
    </v-tooltip>

    <!-- THE DIALOG BEGINS HERE -->
    <v-dialog persistent v-model="dialogHoldApproval" max-width="750px">
      <v-card>
        <v-card-title class="justify-center text-h5 grey lighten-2">
          <span class="text-h5">HOLD APPROVAL</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-card width="100%" class="mt-3 mb-3 text-start">
                <v-card-title>I accept this file.</v-card-title>
                <!-- <v-list subheader two-line flat dense>
                  <v-subheader class="text-h6">I agree:</v-subheader>

                  <v-list-item-group multiple>
                    <v-list-item>
                      <template v-slot:default="{ active }">
                        <v-list-item-action>
                          <v-checkbox
                            :input-value="active"
                            color="primary"
                            v-model="checkboxHoldApproval"
                          ></v-checkbox>
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title>Holding</v-list-item-title>
                          <v-list-item-subtitle
                            >I accept this file.</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                    <v-divider inset></v-divider>
                    <v-list-item>
                      <template v-slot:default="{ active }">
                        <v-list-item-action>
                          <v-checkbox
                            :input-value="active"
                            color="primary"
                            v-model="checkboxHoldApproval1"
                          ></v-checkbox>
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title>Documents</v-list-item-title>
                          <v-list-item-subtitle
                            >I will maintain document
                            privacy.</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </v-list-item-group>
                </v-list> -->
                <v-divider></v-divider>
                <v-card-actions>
                  <v-btn
                    tile
                    dark
                    small
                    @click="closeHoldApprovalDialog"
                    class="mt-3 mr-2 error"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    tile
                    small
                    class="mt-3 success"
                    @click="compHoldApproval"
                    >Submit</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CasefileDataService from "../../services/CasefileDataService";
// import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "holdApproval",
  props: ["approvingManager"],
  components: {},

  data() {
    return {
      dialogHoldApproval: false,
      // checkboxHoldApproval: false,
      // checkboxHoldApproval1: false,
    };
  },

  computed: {
    holdApprovalDisabled() {
      return !(
        (this.caseState.casefile.fileStatus == `PAPP` && this.approvingManager)
        // && this.caseState.casefile.editor == this.$store.state.auth.user.id
      );
    },

    ...mapState(["caseState"]),

    // isFileButtonDisabled() {
    //   return !(this.checkboxHoldApproval && this.checkboxHoldApproval1);
    // },
  },
  watch: {
    holdApproval(val) {
      val || this.closeHoldApprovalDialog();
    },
  },

  methods: {
    holdApproval() {
      this.dialogHoldApproval = true;
    },

    compHoldApproval() {
      CasefileDataService.update(
        this.caseState.casefile.id,
        this.caseState.casefile,
        (this.caseState.casefile.fileStatus = `HOLD`),
        (this.caseState.casefile.approvedBy = this.$store.state.auth.user.id)
        // (this.caseState.casefile.dateStartEdi = moment().format())
      )
        .then((response) => {
          this.closeHoldApprovalDialog();
          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "warning",
            text:
              (response && response.data && response.data.message) ||
              toString(),
          });
        })
        .catch((e) => {
          console.log(e);
        });

      this.closeHoldApprovalDialog();
    },

    closeHoldApprovalDialog() {
      this.dialogHoldApproval = false;
      this.$nextTick(() => {});
    },
  },
};
</script>
