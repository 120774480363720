<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on: tooltip }">
        <v-btn
          :disabled="dispatchDisabled"
          class="success mr-1"
          fab
          x-small
          v-on="{ ...tooltip }"
          @click="completeDispatch()"
        >
          <v-icon>mdi-truck</v-icon>
        </v-btn>
      </template>
      <span>DISPATCH</span>
    </v-tooltip>

    <!-- THE DIALOG BEGINS HERE -->
    <v-dialog persistent v-model="dialogCompleteDispatch" max-width="750px">
      <v-card>
        <v-card-title class="justify-center text-h5 grey lighten-2">
          <span class="text-h5">DISPATCH</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-card width="100%" class="mt-3 mb-3 text-start">
                <v-card-title
                  >This client requires physical report along with
                  attachments.</v-card-title
                >
                <!-- <v-list subheader two-line flat dense>
                  <v-subheader class="text-h6">I confirm:</v-subheader>

                  <v-list-item-group multiple>
                    <v-list-item>
                      <template v-slot:default="{ active }">
                        <v-list-item-action>
                          <v-checkbox
                            :input-value="active"
                            color="primary"
                            v-model="checkboxDispatch"
                          ></v-checkbox>
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title>Dispatch</v-list-item-title>
                          <v-list-item-subtitle
                            >This client requires physical report along with
                            attachments.</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </v-list-item-group>
                </v-list> -->
                <v-divider></v-divider>
                <v-card-actions>
                  <v-btn
                    tile
                    dark
                    small
                    @click="closeDispatchDialog"
                    class="mt-3 mr-2 error"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    tile
                    :disabled="isFileButtonDisabled"
                    small
                    class="mt-3 success"
                    @click="compDispatchSubmit"
                    >Submit</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CasefileDataService from "../../services/CasefileDataService";

import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "dispatch",
  props: ["management"],
  components: {},

  data() {
    return {
      // checkboxDispatch: false,
      dialogCompleteDispatch: false,
    };
  },

  computed: {
    dispatchDisabled() {
      return !(this.caseState.casefile.fileStatus == `DISP` && this.management);
    },

    ...mapState(["caseState"]),

    isFileButtonDisabled() {
      return !(
        // this.checkboxDispatch &&
        this.management
      );
    },
  },
  watch: {
    completeDispatch(val) {
      val || this.closeDispatchDialog();
    },
  },

  methods: {
    // moment: function (date) {
    //   return moment(date);
    // },

    completeDispatch() {
      this.dialogCompleteDispatch = true;
    },

    compDispatchSubmit() {
      CasefileDataService.update(
        this.caseState.casefile.id,
        this.caseState.casefile,
        (this.caseState.casefile.fileStatus = `PDISP`),
        (this.caseState.casefile.dateDispatch = moment().format()),
        (this.caseState.casefile.dispatchBy = this.$store.state.auth.user.id)
      )
        // (this.caseState.casefile.dateOfReg = moment().format())

        .then((response) => {
          this.closeDispatchDialog();
          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "warning",
            text:
              (response && response.data && response.data.message) ||
              toString(),
          });
        })
        .catch((e) => {
          console.log(e);
        });

      this.closeDispatchDialog();
    },

    closeDispatchDialog() {
      this.dialogCompleteDispatch = false;
      this.$nextTick(() => {
        // this.caseState.casefile.fileStatus = "ADAC";
      });
    },
  },
};
</script>
