import http from "../http-common";

class MailDataService {
  mailData(data) {
    // return http.post("/send", data);
    return http.post(
      `/send`,
      data,

      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }
  assignBranch(data) {
    return http.post(
      `/send/branch`,
      data,

      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }
  assignAdj(data) {
    return http.post(
      `/send/adjuster`,
      data,

      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }
  assignEditor(data) {
    return http.post(
      `/send/editor`,
      data,

      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }
  editorAccept(data) {
    return http.post(
      `/send/editorAccept`,
      data,

      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }
  managerApproval(data) {
    return http.post(
      `/send/managerApproval`,
      data,

      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }
  assignClerk(data) {
    return http.post(
      `/send/assignClerk`,
      data,

      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }
}

export default new MailDataService();
