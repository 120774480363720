<template>
  <v-btn small tile class="success" @mousedown="downloadFile">Download</v-btn>
</template>

<script>
import html2pdf from "html2pdf.js";

export default {
  props: ["dom", "name"],
  methods: {
    downloadFile() {
      const me = this;

      const letter = document.querySelector(me.dom);
      var opt = {
        margin: 15,
        filename: me.name,
        // jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      html2pdf().from(letter).set(opt).save();
    },
  },
};
</script>
