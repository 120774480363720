import http from "../http-common";

class StageDataService {
  getAll() {
    return http.get("/stages");
  }

  get(id) {
    return http.get(`/stages/${id}`);
  }

  create(data) {
    return http.post("/stages", data);
  }

  update(id, data) {
    return http.put(`/stages/${id}`, data);
  }

  delete(id) {
    return http.delete(`/stages/${id}`);
  }

  deleteAll() {
    return http.delete(`/stages`);
  }
}

export default new StageDataService();
