import http from "../http-common";

class CasefileDataService {
  getAll() {
    return http.get("/casefiles");
  }

  getAllRefType(id) {
    return http.get(`/byref/${id}`);
  }

  getAllBranch(branch) {
    return http.get(`/bybranch/${branch}`);
  }
  getAllStatusRefType(fileStatus, refType) {
    return http.get(`/detailByRef/${fileStatus}/${refType}`);
  }

  getAllStatusBranch(fileStatus, branch) {
    return http.get(`/detailByBranch/${fileStatus}/${branch}`);
  }

  get(id) {
    return http.get(`/casefiles/${id}`);
  }

  create(data) {
    return http.post("/casefiles", data);
  }

  update(id, data) {
    return http.put(`/casefiles/${id}`, data);
  }

  delete(id) {
    return http.delete(`/casefiles/${id}`);
  }

  deleteAll() {
    return http.delete(`/casefiles`);
  }
}

export default new CasefileDataService();
