import http from "../http-common";

class ClaimDataService {
  getAll() {
    return http.get("/claims");
  }

  get(id) {
    return http.get(`/claims/${id}`);
  }

  create(data) {
    return http.post("/claims", data);
  }

  update(id, data) {
    return http.put(`/claims/${id}`, data);
  }

  updateAll(id, data) {
    return http.put(`/claims/items/${id}`, data);
  }

  delete(id) {
    return http.delete(`/claims/${id}`);
  }

  deleteAll() {
    return http.delete(`/claims`);
  }

  findBySubDept(name) {
    return http.get(`/claims?name=${name}`);
  }
}

export default new ClaimDataService();
