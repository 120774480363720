import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Archive from "./views/Archive.vue";
import Dashboard from "./views/Dashboard.vue";
import Find from "./views/QuickFind.vue";
import Forgot from "./components/user/forgot";
import Reset from "./components/user/reset";
import Activate from "./components/user/activate";
import Login from "./views/Login.vue";
import NotFound from "./components/NotFound";

Vue.use(Router);

// export default new Router({
export const router = new Router({
  // mode: "history",
  routes: [
    // Home
    {
      path: "/",
      meta: {
        title: "Welcome",
      },
      component: Home,
      title: Home,
    },
    {
      path: "/home",
      meta: {
        title: "Welcome",
      },
      component: Home,
      title: Home,
    },
    {
      path: "/archive",
      meta: {
        title: "Archive",
      },
      component: Archive,
      title: Archive,
    },
    // Dashboard
    {
      path: "/dashboard",
      meta: {
        title: "Dashboard",
      },
      component: Dashboard,
      title: Dashboard,
    },

    // Quickfind
    {
      path: "/find",
      name: "find",
      meta: {
        title: "Quick Find",
      },
      component: Find,
    },
    // Login
    {
      path: "/login",
      name: "login",
      component: Login,
    },
    // Forgot
    {
      path: "/forgot",
      name: "forgot",
      component: Forgot,
      // title: Forgot,
    },
    // Reset
    {
      path: "/reset",
      name: "reset",
      component: Reset,
      // title: Reset,
    },
    // Activate
    {
      path: "/activate",
      name: "activate",
      component: Activate,
      // title: Activate,
    },
    // Profile
    {
      path: "/profile",
      meta: {
        title: "Profile",
      },
      name: "profile",
      // lazy-loaded
      component: () => import("./views/Profile.vue"),
    },
    // Admin
    {
      path: "/admin",
      meta: {
        title: "Admin",
      },
      name: "admin",
      // lazy-loaded
      component: () => import("./views/BoardAdmin.vue"),
    },
    // Manager
    {
      path: "/manager",
      meta: {
        title: "Manager",
      },
      name: "manager",
      // lazy-loaded
      component: () => import("./views/Manager.vue"),
    },
    // User
    {
      path: "/user",
      meta: {
        title: "User",
      },
      name: "user",
      // lazy-loaded
      component: () => import("./views/BoardUser.vue"),
    },
    // MEMBERS ROUTES
    {
      path: "/members",
      meta: {
        title: "Members",
      },
      // alias: '/members',
      name: "members",
      component: () => import("./components/members/MemberList"),
    },
    // BRANCHES ROUTES
    {
      path: "/branches",
      meta: {
        title: "Branches",
      },
      name: "branches",
      component: () => import("./components/branches/BranchList"),
    },
    // STATES ROUTES
    {
      path: "/states",
      meta: {
        title: "States",
      },
      name: "states",
      component: () => import("./components/states/StateList"),
    },
    // REGION ROUTES
    {
      path: "/districts",
      meta: {
        title: "Districts",
      },
      name: "districts",
      component: () => import("./components/regions/RegionList"),
    },

    // TATCHART ROUTES
    {
      path: "/tatcharts",
      meta: {
        title: "TAT Charts",
      },
      name: "tatcharts",
      component: () => import("./components/tatcharts/TatChartList"),
    },

    // HANDLER ROUTES
    {
      path: "/handlers",
      meta: {
        title: "Claim Handler",
      },
      name: "handler",
      component: () => import("./components/handler/handler"),
    },
    // // DOCUMENT ROUTES
    // {
    //   path: "/documents",
    //   meta: {
    //     title: "Documents",
    //   },
    //   name: "documents",
    //   component: () => import("./components/uploads/documents"),
    // },
    // BULLETIN ROUTES
    {
      path: "/bulletins",
      meta: {
        title: "Bulletin",
      },
      name: "bulletins",
      component: () => import("./components/bulletins/bulletinList"),
    },
    // DEPARTMENTS ROUTES
    {
      path: "/depts",
      meta: {
        title: "Departments",
      },
      name: "depts",
      component: () => import("./components/depts/DeptList"),
    },

    // FILE CLASSIFICATION ROUTES ROUTES
    {
      path: "/subDepts",
      meta: {
        title: "File Classification",
      },
      name: "subDepartments",
      component: () => import("./components/subDepts/subDeptList"),
    },

    // INSURER ROUTES
    {
      path: "/inss",
      meta: {
        title: "Insurer",
      },
      name: "inss",
      component: () => import("./components/inss/InsList"),
    },
    // // STAGE ROUTES
    // {
    //   path: "/stages",
    //   meta: {
    //     title: "Stages",
    //   },
    //   name: "stages",
    //   component: () => import("./components/stages/StageList"),
    // },

    // CLAIMS
    {
      path: "/claims",
      meta: {
        title: "Claims",
      },
      name: "claimsList",
      component: () => import("./components/claims/claimsList"),
    },

    // CLAIM FORM
    // {
    //   path: "/claimform",
    //   meta: {
    //     title: "Claim Form",
    //   },
    //   name: "claimForm",
    //   component: () => import("./components/claims/claimForm"),
    // },

    // // CLAIMS DETAIL
    // {
    //   path: "/claimdetail",
    //   meta: {
    //     title: "Claim Detail",
    //   },
    //   name: "claimDetail",
    //   component: () => import("./components/claims/claimDetail"),
    // },
    // DROPBOX FILE ROUTES
    {
      path: "/dropboxfiles",
      meta: {
        title: "Dropbox Files",
      },
      name: "dropboxfiles",
      component: () => import("./components/dropboxfiles/DropboxfileList"),
    },
    // REPORTS ROUTES
    {
      path: "/reports",
      meta: {
        title: "Basic Reports",
      },
      name: "reports",
      component: () => import("./components/reports/reports"),
    },
    {
      path: "/reportsTwo",
      meta: {
        title: "Detail Reports",
      },
      name: "reportsTwo",
      component: () => import("./components/reports/reportsTwo"),
    },
    {
      path: "/byref/:id",
      meta: {
        title: "Detail By Department",
      },
      name: "refTabDetail",
      component: () => import("./views/detailView/refTabDetail"),
    },
    {
      path: "/bybranch/:id",
      meta: {
        title: "Detail by Branch",
      },
      name: "brTabDetail",
      component: () => import("./views/detailView/brTabDetail"),
    },
    {
      path: "/detailByRef/:fileStatus/:refType",
      meta: {
        title: "Detail By Reference",
      },
      name: "detail-by-ref",
      component: () => import("./views/detailView/detailByRef"),
    },
    {
      path: "/detailByBranch/:fileStatus/:branch",
      meta: {
        title: "Detail By Branch",
      },
      name: "detail-by-branch",
      component: () => import("./views/detailView/detailByBranch"),
    },
    // File

    // {
    //   path: "/file",
    //   meta: {
    //     title: "Casefile Details",
    //   },
    //   name: "file",
    //   component: () => import("./views/detailView/file"),
    // },

    {
      path: "/file/:id",
      meta: {
        title: "Casefile Details",
      },
      name: "vuexFile",
      component: () => import("./views/detailView/vuexFile"),
    },
    {
      path: "/dopFile/:id",
      meta: {
        title: "Dropbox File Details",
      },
      name: "dropboxDetail",
      component: () => import("./views/detailView/dropboxDetail"),
    },

    {
      path: "/findfile/:id",
      meta: {
        title: "Casefile Details",
      },
      name: "findfile",
      component: () => import("./views/detailView/quickFindDetail.vue"),
    },

    // MISC ROUTES
    {
      path: "/soon",
      meta: {
        title: "Soon",
      },
      name: "soon",
      component: () => import("./components/Soon"),
    },

    {
      // path: "*",
      path: "/:catchAll(.*)",
      name: "NotFound",
      component: NotFound,
    },

    // Acknowledgement Letter
    // {
    //   path: "/acknowledgement",
    //   meta: {
    //     title: "Acknowledgement",
    //   },
    //   name: "acknowledgement",
    //   component: () => import("./components/pdf/acknowledgement"),
    // },
  ],
});
router.beforeEach((to, from, next) => {
  // const publicPages = ['/login', '/register', '/home'];
  const publicPages = ["/login", "/forgot", "/reset", "/activate"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next("/login");
  } else {
    next();
  }
});
