import http from "../http-common";

class DropboxfileDataService {
  getAll() {
    return http.get("/dropboxfiles");
  }

  getAllInc() {
    return http.get("/dropboxfiles/incoming");
  }

  getAllCanc() {
    return http.get("/dropboxfiles/cancelled");
  }

  getAllRefType(id) {
    return http.get(`/byref/${id}`);
  }

  getAllBranch(branch) {
    return http.get(`/bybranch/${branch}`);
  }
  getAllStatusRefType(fileStatus, refType) {
    return http.get(`/detailByRef/${fileStatus}/${refType}`);
  }

  getAllStatusBranch(fileStatus, branch) {
    return http.get(`/detailByBranch/${fileStatus}/${branch}`);
  }

  get(id) {
    return http.get(`/dropboxfiles/${id}`);
  }

  create(data) {
    return http.post("/dropboxfiles", data);
  }

  update(id, data) {
    return http.put(`/dropboxfiles/${id}`, data);
  }

  delete(id) {
    return http.delete(`/dropboxfiles/${id}`);
  }

  deleteids(data) {
    return http.delete(`/dropboxfiles/delete/selected/`, data);
  }

  deleteAll() {
    return http.delete(`/dropboxfiles`);
  }
}

export default new DropboxfileDataService();
