<template>
  <!--Claim Form Start -->

  <v-dialog v-model="claimform" width="auto">
    <template #activator="{ on: dialog }">
      <v-col cols="12" class="d-none d-sm-none d-md-flex justify-center">
        <v-tooltip bottom>
          <template #activator="{ on: tooltip }">
            <v-btn
              fab
              small
              class="success"
              dark
              v-on="{ ...tooltip, ...dialog }"
            >
              <v-icon>mdi-file-pdf-box</v-icon>
            </v-btn>
          </template>
          <span>Claim Form</span>
        </v-tooltip>
      </v-col>
      <v-col cols="12" class="pb-5 d-md-none justify-center">
        <v-tooltip bottom>
          <template #activator="{ on: tooltip }">
            <v-btn
              tile
              block
              class="success"
              dark
              v-on="{ ...tooltip, ...dialog }"
            >
              <!-- <v-icon>mdi-file-pdf-box</v-icon> -->
              CLAIM FORM
            </v-btn>
          </template>
          <span>Claim Form</span>
        </v-tooltip>
      </v-col>
    </template>

    <v-card tile class="html">
      <v-spacer></v-spacer>
      <div class="pa-2" align="end">
        <v-btn outlined small tile @click="closeDownloadAck" class="mr-1">
          Cancel
        </v-btn>
        <download-button dom="#letter" />
      </div>
      <div id="letter">
        <table
          border="0"
          cellpadding="0"
          cellspacing="0"
          id="sheet0"
          class="sheet0 gridlines"
        >
          <thead>
            <tr class="row0">
              <th class="column0 style68 s style68" colspan="18">
                ASSOCIATED ADJUSTERS SDN BHD
                <!-- <span>{{ getClaimStatus(currentClaims.isApproved) }} </span> -->
              </th>
            </tr>
            <tr class="row1">
              <th class="column0 style7 null"></th>
              <th class="column1 style7 null"></th>
              <th class="column2 style7 null"></th>
              <th class="column3 style7 null"></th>
              <th class="column4 style7 null"></th>
              <th class="column5 style7 null"></th>
              <th class="column6 style7 null"></th>
              <th class="column7 style7 null"></th>
              <th class="column8 style7 null"></th>
              <th class="column9 style7 null"></th>
              <th class="column10 style7 null"></th>
              <th class="column11 style7 null"></th>
              <th class="column12 style7 null"></th>
              <th class="column13 style7 null"></th>
              <th class="column14 style7 null"></th>
              <th class="column15 style7 null"></th>
              <th class="column16 style7 null"></th>
              <th class="column17 style7 null"></th>
            </tr>
            <tr class="row2">
              <th class="header">NAME:</th>
              <th class="header"></th>
              <th class="headerborder" colspan="5">
                <span v-if="currentClaims[0]">
                  {{ filterUser(currentClaims[0].adjId) }}
                </span>
              </th>
              <th class="header" colspan="3">MONTHLY REIMBURSEMENT CLAIMS</th>
              <th class="header"></th>
              <th class="header"></th>
              <th class="header">MONTH / YEAR:</th>
              <th class="headerborder" colspan="2">
                <span v-if="currentClaims[0]">
                  {{ monthOnly(currentClaims[0].createdAt) }}
                </span>
              </th>
              <th class="headerborder" colspan="2">
                <span v-if="currentClaims[0]">
                  {{ yearOnly(currentClaims[0].createdAt) }}
                </span>
              </th>
              <th class="column17 style1 null"></th>
            </tr>
            <tr class="row3">
              <th class="column0 style69 null style69" colspan="18"></th>
            </tr>
            <tr class="row4 grey lighten-1 text-caption text-center">
              <th class="boxheader" style="border: 1px solid black" rowspan="2">
                NO.
              </th>
              <th class="boxheader" style="border: 1px solid black" colspan="2">
                REFERENCE
              </th>
              <th class="boxheader" style="border: 1px solid black" rowspan="2">
                VEHICLE<br />
                NO.
              </th>
              <th class="boxheader" style="border: 1px solid black" rowspan="2">
                INSURER
              </th>
              <th class="boxheader" style="border: 1px solid black" rowspan="2">
                INVOICE<br />
                NO.
              </th>
              <th class="boxheader" style="border: 1px solid black" rowspan="2">
                INVOICE AMOUNT
              </th>
              <th class="boxheader" style="border: 1px solid black" rowspan="2">
                ADJUSTING FEES
              </th>
              <th class="boxheader" style="border: 1px solid black" rowspan="2">
                PLACE OF LOSS
              </th>
              <th class="boxheader" style="border: 1px solid black" rowspan="2">
                <!-- <a class="comment-indicator"></a>
                <div class="comment">KINDLY FOLLOW CLAIMS SCHEME<br /></div> -->
                TRAVELLING / MILEAGE
              </th>
              <th class="boxheader" style="border: 1px solid black" rowspan="2">
                <!-- <a class="comment-indicator"></a>
                <div class="comment">* ONLY ADDITIONAL TRAVELLING<br /></div> -->
                ADD.
              </th>
              <th class="boxheader" style="border: 1px solid black" rowspan="2">
                <!-- <a class="comment-indicator"></a>
                <div class="comment">STAMPS HASIL</div> -->
                SD &amp; STAMPS
              </th>
              <th class="boxheader" style="border: 1px solid black" rowspan="2">
                MEDICAL REPORT
              </th>
              <th class="boxheader" style="border: 1px solid black" rowspan="2">
                <!-- <a class="comment-indicator"></a>
                <div class="comment">
                  POLICE DOC.<br />
                  & PHOTO<br />
                </div> -->
                POLICE DOC.
              </th>
              <th class="boxheader" style="border: 1px solid black" rowspan="2">
                JPJ DOC.
              </th>
              <th class="boxheader" style="border: 1px solid black" rowspan="2">
                <!-- <a class="comment-indicator"></a>
                <div class="comment">
                  SSM & MISC DOCS / SEARCHES <br />
                  & CD & etc
                </div> -->
                MISC
              </th>
              <th class="boxheader" style="border: 1px solid black" rowspan="2">
                TYPING
              </th>
              <th class="boxheader" style="border: 1px solid black" rowspan="2">
                TOTAL
              </th>
            </tr>
            <tr>
              <th class="boxheader" style="border: 1px solid black">TYPE</th>
              <th class="boxheader" style="border: 1px solid black">
                FILE NO.
              </th>
            </tr>
          </thead>
          <tbody v-if="currentClaims">
            <tr
              class="row6 text-no-wrap"
              v-for="(claim, index) in currentClaims"
              :key="claim.id"
            >
              <td class="tabletext">{{ index + 1 }}</td>
              <td v-if="claim" class="tabletext">
                {{ filterDept(filterCaseByReftype(claim.caseId)) }}
              </td>
              <td class="tabletext">
                {{ filterCaseById(claim.caseId) }}
              </td>
              <td class="tabletext">
                {{ filterCaseByVehicleNo(claim.caseId) }}
              </td>
              <td class="tabletext">
                {{ filterIns(filterCaseByInsurer(claim.caseId)) }}
              </td>
              <td class="tabletext">
                {{ filterCaseByInvno(claim.caseId) }}
              </td>
              <td class="tabletext">
                {{ (filterCaseByInvtotal(claim.caseId) * 1).toFixed(2) }}
              </td>
              <td class="tabletext">
                {{ (filterCaseByAdjFee(claim.caseId) * 1).toFixed(2) }}
              </td>
              <td class="tabletext">
                {{ filterCasePlaceofloss(claim.caseId) }}
              </td>
              <td class="tabletext">{{ claim.travMileage }}</td>
              <td class="tabletext">{{ claim.additional }}</td>
              <td class="tabletext">{{ claim.sdAndStamps }}</td>
              <td class="tabletext">{{ claim.medicalReport }}</td>
              <td class="tabletext">{{ claim.policeDoc }}</td>
              <td class="tabletext">{{ claim.jpjDoc }}</td>
              <td class="tabletext">{{ claim.misc }}</td>
              <td class="tabletext">{{ claim.typing }}</td>
              <td class="tabletotalyellow">{{ total(claim.id) }}</td>
            </tr>
            <tr class="row36">
              <td class="column0 style15 null"></td>
              <td class="column1 style15 null"></td>
              <td class="column2 style16 null"></td>
              <td class="column3 style17 null"></td>
              <td class="column4 style15 null"></td>
              <td class="column5 style15 null"></td>
              <td class="column6 style18 null"></td>
              <td class="column7 style18 null"></td>
              <td class="column8 style17 null"></td>
              <td class="column9 style19 null"></td>
              <td class="column10 style19 null"></td>
              <td class="column11 style19 null"></td>
              <td class="column12 style19 null"></td>
              <td class="column13 style19 null"></td>
              <td class="column14 style19 null"></td>
              <td class="column15 style19 null"></td>
              <td class="column16 style19 null"></td>
              <td class="column17 style19 null"></td>
            </tr>
            <tr class="row37">
              <td class="column0 style36 null"></td>
              <td class="column1 style67 s style67" colspan="3">
                FOLLOW UP / SHARED FILES
              </td>
              <td class="column4 style15 null"></td>
              <td class="column5 style15 null"></td>
              <td class="column6 style18 null"></td>
              <td class="column7 style18 null"></td>
              <td class="column8 style17 null"></td>
              <td class="column9 style19 null"></td>
              <td class="column10 style19 null"></td>
              <td class="column11 style19 null"></td>
              <td class="column12 style19 null"></td>
              <td class="column13 style19 null"></td>
              <td class="column14 style19 null"></td>
              <td class="column15 style19 null"></td>
              <td class="column16 style19 null"></td>
              <td class="column17 style19 null"></td>
            </tr>
            <tr class="row38">
              <td class="column0 style15 n">1</td>
              <td class="column1 style15 null"></td>
              <td class="column2 style20 null"></td>
              <td class="column3 style17 null"></td>
              <td class="column4 style15 null"></td>
              <td class="column5 style36 null"></td>
              <td class="column6 style37 null"></td>
              <td class="column7 style37 null"></td>
              <td class="column8 style17 null"></td>
              <td class="column9 style19 null"></td>
              <td class="column10 style19 null"></td>
              <td class="column11 style19 null"></td>
              <td class="column12 style19 null"></td>
              <td class="column13 style19 null"></td>
              <td class="column14 style19 null"></td>
              <td class="column15 style19 null"></td>
              <td class="column16 style19 null"></td>
              <td class="column17 style27 f">0.00</td>
            </tr>
            <tr class="row39">
              <td class="column0 style15 n">2</td>
              <td class="column1 style15 null"></td>
              <td class="column2 style20 null"></td>
              <td class="column3 style17 null"></td>
              <td class="column4 style15 null"></td>
              <td class="column5 style36 null"></td>
              <td class="column6 style37 null"></td>
              <td class="column7 style37 null"></td>
              <td class="column8 style17 null"></td>
              <td class="column9 style19 null"></td>
              <td class="column10 style19 null"></td>
              <td class="column11 style19 null"></td>
              <td class="column12 style19 null"></td>
              <td class="column13 style19 null"></td>
              <td class="column14 style19 null"></td>
              <td class="column15 style19 null"></td>
              <td class="column16 style19 null"></td>
              <td class="column17 style27 f">0.00</td>
            </tr>
            <tr class="row40">
              <td class="column0 style15 n">3</td>
              <td class="column1 style15 null"></td>
              <td class="column2 style20 null"></td>
              <td class="column3 style17 null"></td>
              <td class="column4 style15 null"></td>
              <td class="column5 style36 null"></td>
              <td class="column6 style37 null"></td>
              <td class="column7 style37 null"></td>
              <td class="column8 style17 null"></td>
              <td class="column9 style19 null"></td>
              <td class="column10 style19 null"></td>
              <td class="column11 style19 null"></td>
              <td class="column12 style19 null"></td>
              <td class="column13 style19 null"></td>
              <td class="column14 style19 null"></td>
              <td class="column15 style19 null"></td>
              <td class="column16 style19 null"></td>
              <td class="column17 style27 f">0.00</td>
            </tr>
            <tr class="row41">
              <td class="column0 style21 null"></td>
              <td class="column1 style21 null"></td>
              <td class="column2 style22 null"></td>
              <td class="column3 style23 null"></td>
              <td class="column4 style21 null"></td>
              <td class="column5 style38 null"></td>
              <td class="column6 style39 null"></td>
              <td class="column7 style39 null"></td>
              <td class="column8 style23 null"></td>
              <td class="column9 style24 null"></td>
              <td class="column10 style24 null"></td>
              <td class="column11 style24 null"></td>
              <td class="column12 style24 null"></td>
              <td class="column13 style24 null"></td>
              <td class="column14 style24 null"></td>
              <td class="column15 style24 null"></td>
              <td class="column16 style24 null"></td>
              <td class="column17 style27 f">0.00</td>
            </tr>
            <tr class="row42">
              <td class="column0 style58 s style58" colspan="6">TOTAL</td>
              <td class="column6 style47 f">0</td>
              <td class="column7 style47 f">0</td>
              <td class="column8 style48 null"></td>
              <td class="tabletotalpink">
                <span>
                  {{ sumTravMileage }}
                </span>
              </td>
              <td class="tabletotalpink">
                <span>
                  {{ sumAdditional }}
                </span>
              </td>
              <td class="tabletotalpink">
                <span>
                  {{ sumSdAndStamps }}
                </span>
              </td>
              <td class="tabletotalpink">
                <span>
                  {{ sumMedicalReport }}
                </span>
              </td>
              <td class="tabletotalpink">
                <span>
                  {{ sumPoliceDoc }}
                </span>
              </td>
              <td class="tabletotalpink">
                <span>
                  {{ sumJpjDoc }}
                </span>
              </td>
              <td class="tabletotalpink">
                <span>
                  {{ sumMisc }}
                </span>
              </td>
              <td class="tabletotalpink">
                <span>
                  {{ sumTyping }}
                </span>
              </td>

              <td class="column17 style27 f">0.00</td>
            </tr>
            <tr class="row43">
              <td class="column0 style25 null"></td>
              <td class="column1 style25 null"></td>
              <td class="column2 style25 null"></td>
              <td class="column3 style25 null"></td>
              <td class="column4 style25 null"></td>
              <td class="column5 style25 null"></td>
              <td class="column6 style25 null"></td>
              <td class="column7 style25 null"></td>
              <td class="column8 style25 null"></td>
              <td class="column9 style25 null"></td>
              <td class="column10 style25 null"></td>
              <td class="column11 style25 null"></td>
              <td class="column12 style25 null"></td>
              <td class="column13 style25 null"></td>
              <td class="column14 style25 null"></td>
              <td class="column15 style25 null"></td>
              <td class="column16 style25 null"></td>
              <td class="column17 style25 null"></td>
            </tr>
            <tr class="row44">
              <td class="tablegreyborder" colspan="3" rowspan="3">
                <br />
                CLAIMANT
              </td>
              <td class="tablegreyborder" colspan="5" rowspan="3">
                <br />
                CHECKED &amp; APPROVED
              </td>
              <td class="column8 style50 s style50" colspan="7">SUB - TOTAL</td>
              <td class="tabletotalpink" colspan="2">
                {{ sumAll }}
              </td>
              <td class="column17 style5 null"></td>
            </tr>
            <tr class="row45">
              <td class="column8 style30 s">@ RM</td>
              <td class="column9 style31 n">{{ accRate }}</td>
              <td class="column10 style32 s">X</td>
              <td class="column11 style31 n">
                <input class="input" type="text" v-model="accDays" />
              </td>
              <td class="column12 style33 s">(DAYS)</td>
              <td class="column13 style34 null"></td>
              <td class="column14 style29 s">ACCOMODATION</td>
              <td class="column15 style60 f style60" colspan="2">
                {{ sumAccomodation }}
              </td>
              <td class="column17 style5 null"></td>
            </tr>
            <tr class="row46">
              <td class="column8 style50 s style50" colspan="7">
                HANDPHONE BILL
              </td>
              <td class="column15 style52 null style52" colspan="2">
                <input class="input" type="text" v-model="handPhoneBill" />
              </td>
              <td class="column17 style5 null"></td>
            </tr>
            <tr class="row47">
              <td
                class="column0 style72 null style72"
                colspan="3"
                rowspan="5"
              ></td>
              <td
                class="column3 style56 null style57"
                colspan="5"
                rowspan="5"
              ></td>
              <td class="column8 style35 s text-no-wrap">PLEASE SPECIFY:</td>
              <td class="column9 style51 s style51" colspan="6">
                <!-- MONTHLY PARKING FEES -->
                <select>
                  <option value=""></option>
                  <option
                    v-for="(option, index) in specifySelect"
                    :value="option.text"
                    :key="index"
                  >
                    {{ option.text }}
                  </option>
                </select>
              </td>
              <td class="column15 style52 null style52" colspan="2">
                <input class="input" type="text" v-model="parkingBill" />
              </td>
              <td class="column17 style5 null"></td>
            </tr>
            <tr class="row48">
              <td class="column8 style35 s text-no-wrap">PLEASE SPECIFY:</td>
              <td class="column9 style51 null style51" colspan="6">
                <!-- <input class="inputText" type="text" value="-" /> -->
                <!-- <v-select
                  style=""
                  dense
                  :items="specifySelect"
                  class="selectText ma-0 pa-0"
                >
                </v-select> -->
                <select>
                  <option value=""></option>
                  <option
                    v-for="(option, index) in specifySelect"
                    :value="option.text"
                    :key="index"
                  >
                    {{ option.text }}
                  </option>
                </select>
              </td>
              <td class="column15 style52 null style52" colspan="2">
                <input class="input" type="text" v-model="miscBill1" />
              </td>
              <td class="column17 style5 null"></td>
            </tr>
            <tr class="row49">
              <td class="column8 style35 s text-no-wrap">PLEASE SPECIFY:</td>
              <td class="column9 style51 null style51" colspan="6">
                <!-- <input class="inputText" type="text" value="-" /> -->
                <select>
                  <option value=""></option>
                  <option
                    v-for="(option, index) in specifySelect"
                    :value="option.text"
                    :key="index"
                  >
                    {{ option.text }}
                  </option>
                </select>
              </td>
              <td class="column15 style52 null style52" colspan="2">
                <input class="input" type="text" v-model="miscBill2" />
              </td>
              <td class="column17 style5 null"></td>
            </tr>
            <tr class="row50">
              <td class="column8 style35 s text-no-wrap">PLEASE SPECIFY:</td>
              <td class="column9 style51 null style51" colspan="6">
                <!-- <input class="inputText" type="text" value="-" /> -->
                <select>
                  <option value=""></option>
                  <option
                    v-for="(option, index) in specifySelect"
                    :value="option.text"
                    :key="index"
                  >
                    {{ option.text }}
                  </option>
                </select>
              </td>
              <td class="column15 style52 null style52" colspan="2">
                <input class="input" type="text" v-model="miscBill3" />
              </td>
              <td class="column17 style5 null"></td>
            </tr>
            <tr class="row51">
              <td class="column8 style50 s style50" colspan="7">
                ( LESS ) TRAVELLING ADVANCE
              </td>
              <td class="column15 style52 null style52" colspan="2">
                <input class="input" type="text" v-model="travAdvance" />
              </td>
              <td class="column17 style5 null"></td>
            </tr>
            <tr class="row52">
              <td class="tablegreyborder" colspan="3">ADJUSTER</td>
              <td class="tablegreyborder" colspan="5">MANAGER</td>
              <td class="column8 style50 s style50" colspan="7">
                AMOUNT PAYABLE
              </td>
              <td class="column15 style53 f style54" colspan="2">
                {{ totalAmountPayable }}
              </td>
              <td class="column17 style5 null"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </v-card>
  </v-dialog>

  <!-- Acknowledgement Letter End -->
  <!-- Action Row Continue -->
</template>
<script>
import DownloadButton from "../../components/DownloadClaimForm.vue";
import moment from "moment";

export default {
  name: "claimform",
  props: [
    "casefiles",
    "refType",
    "subRefTypes",
    "branches",
    "insurers",
    "members",
    "currentClaims",
    "menu",
    "menu1",
  ],
  components: {
    DownloadButton,
  },
  data() {
    return {
      claimform: false,
      accRate: 80,
      accDays: 0,
      handPhoneBill: 0,
      parkingBill: 0,
      miscBill1: 0,
      miscBill2: 0,
      miscBill3: 0,
      travAdvance: 0,
      amountPayable: 0,
      specifySelect: [
        {
          text: "ACCOMODATION",
        },
        {
          text: "CAR RENTAL",
        },
        { text: "CLEANER SERVICES" },
        { text: "COURRIER SERVICE" },
        { text: "FLIGHT TICKET" },
        { text: "INTERNET BILL" },
        { text: "MARKETING EXPENSES" },
        { text: "MONTHLY PARKING FEES" },
        { text: "STATIONERIES" },
        { text: "TOLL" },
        { text: "UP KEEP COMPUTER" },
        { text: "UP KEEP OFFICE" },
      ],
    };
  },
  computed: {
    sumTravMileage() {
      const fielditem = this.currentClaims.map((a) => a.travMileage);
      return fielditem.reduce((acc, cur) => acc + cur * 1, 0).toFixed(2);
    },
    sumAdditional() {
      const fielditem = this.currentClaims.map((a) => a.additional);
      return fielditem.reduce((acc, cur) => acc + cur * 1, 0).toFixed(2);
    },
    sumSdAndStamps() {
      const fielditem = this.currentClaims.map((a) => a.sdAndStamps);
      return fielditem.reduce((acc, cur) => acc + cur * 1, 0).toFixed(2);
    },
    sumMedicalReport() {
      const fielditem = this.currentClaims.map((a) => a.medicalReport);
      return fielditem.reduce((acc, cur) => acc + cur * 1, 0).toFixed(2);
    },
    sumPoliceDoc() {
      const fielditem = this.currentClaims.map((a) => a.policeDoc);
      return fielditem.reduce((acc, cur) => acc + cur * 1, 0).toFixed(2);
    },
    sumJpjDoc() {
      const fielditem = this.currentClaims.map((a) => a.jpjDoc);
      return fielditem.reduce((acc, cur) => acc + cur * 1, 0).toFixed(2);
    },
    sumMisc() {
      const fielditem = this.currentClaims.map((a) => a.misc);
      return fielditem.reduce((acc, cur) => acc + cur * 1, 0).toFixed(2);
    },
    sumTyping() {
      const fielditem = this.currentClaims.map((a) => a.typing);
      return fielditem.reduce((acc, cur) => acc + cur * 1, 0).toFixed(2);
    },
    sumAll() {
      return this.currentClaims
        .reduce(
          (sum, row) =>
            sum +
            row.travMileage * 1 +
            row.additional * 1 +
            row.sdAndStamps * 1 +
            row.medicalReport * 1 +
            row.policeDoc * 1 +
            row.jpjDoc * 1 +
            row.misc * 1 +
            row.typing * 1,
          0
        )
        .toFixed(2);
      // return total.toFixed(2);
    },
    sumAccomodation() {
      return (this.accDays * this.accRate).toFixed(2);
      // return total.toFixed(2);
    },
    totalAmountPayable() {
      return (
        this.sumAll * 1 +
        this.sumAccomodation * 1 +
        this.handPhoneBill * 1 +
        this.parkingBill * 1 +
        this.miscBill1 * 1 +
        this.miscBill2 * 1 +
        this.miscBill3 * 1 -
        this.travAdvance * 1
      ).toFixed(2);
      // return total.toFixed(2);
    },
    // totalAmountPayable() {
    //   const totalAmount =
    //     this.handPhoneBill +
    //     this.parkingBill +
    //     this.miscBill1 +
    //     this.miscBill2 +
    //     this.miscBill3;
    //   return totalAmount.toFixed(2);
    //   // return total.toFixed(2);
    // },
  },

  methods: {
    getClaimStatus(item) {
      if (item == null) return "(NEW)";
      if (item == 0) return "(QUERY)";
      if (item == 1) return "(APPROVED)";
      else return "green darken-3";
    },
    total(item) {
      const thisclaim = this.currentClaims.filter((claim) => claim.id === item);
      const total = thisclaim.reduce(
        (sum, row) =>
          sum +
          row.travMileage * 1 +
          row.additional * 1 +
          row.sdAndStamps * 1 +
          row.medicalReport * 1 +
          row.policeDoc * 1 +
          row.jpjDoc * 1 +
          row.misc * 1 +
          row.typing * 1,
        0
      );
      return total.toFixed(2);
    },
    monthOnly(value) {
      // return moment(value).format("YYYY");
      return moment(value).format("MMM").toUpperCase();
    },
    yearOnly(value) {
      return moment(value).format("YYYY");
    },
    filterUser(item) {
      // return this.members.filter((member) => member.id === item);

      const myArray = this.members.filter((member) => member.id === item);
      let result = myArray.map(
        (a) => a.username + " " + "(" + a.usercode + ")"
      );
      return result[0];
    },
    filterCase(item) {
      return this.casefiles.filter((casefile) => casefile.id === item);
    },
    filterCaseByReftype(item) {
      const myArray = this.casefiles.filter((dept) => dept.id === item);
      let result = myArray.map((a) => a.refType);
      return result[0];
    },
    filterCaseById(item) {
      const myArray = this.casefiles.filter((dept) => dept.id === item);
      let result = myArray.map((a) => a.id);
      return result[0];
    },
    filterCaseByVehicleNo(item) {
      const myArray = this.casefiles.filter((dept) => dept.id === item);
      let result = myArray.map((a) => a.vehicleNo);
      return result[0].toUpperCase();
    },
    filterCaseByInsurer(item) {
      const myArray = this.casefiles.filter((dept) => dept.id === item);
      let result = myArray.map((a) => a.insurer);
      return result[0];
    },
    filterCaseByInvno(item) {
      const myArray = this.casefiles.filter((dept) => dept.id === item);
      let result = myArray.map((a) => a.invNo);
      return result[0];
    },
    filterCaseByInvtotal(item) {
      const myArray = this.casefiles.filter((dept) => dept.id === item);
      let result = myArray.map((a) => a.invTotal);
      return result[0];
    },
    filterCaseByAdjFee(item) {
      const myArray = this.casefiles.filter((dept) => dept.id === item);
      let result = myArray.map((a) => a.invFeeNote);
      return result[0];
    },
    filterCasePlaceofloss(item) {
      const myArray = this.casefiles.filter((dept) => dept.id === item);
      let result = myArray.map((a) => a.placeOfLoss);
      return result[0];
    },
    filterDept(item) {
      const myArray = this.refType.filter((dept) => dept.id === item);
      let result = myArray.map((a) => a.name);
      return result[0];
    },
    filterIns(item) {
      // return this.insurers.filter((insurer) => insurer.id === item);
      const myArray = this.insurers.filter((insurer) => insurer.id === item);
      let result = myArray.map((a) => a.name);
      return result[0];
    },
    closeDownloadAck() {
      this.claimform = false;
      this.$nextTick(() => {});
    },
  },
};
</script>

<style scoped>
html {
  font-family: Calibri, Arial, Helvetica, sans-serif;
  font-size: 11pt;
  background-color: white;
}
.input {
  text-align: center;
}
.inputText {
  text-align: center;
  /* background-color: #76e7be; */
  /* color: white; */
  text-align: right;
  padding-right: 2px;
  width: 300px;
}
.selectText {
  width: 100%;
  text-align: right;
  font-family: Calibri, Arial, Helvetica, sans-serif;
  font-size: 10pt;

  /* text-align: center; */
  /* text-align: right; */
  /* padding-right: 2px; */
  /* width: 300px; */
}
select,
option {
  text-align: right;
}
div.comment {
  display: none;
}
table {
  border-collapse: collapse;
  page-break-after: always;
}
.gridlines td {
  border: 1px dotted black;
}
.gridlines th {
  border: 1px dotted black;
}
.b {
  text-align: center;
}
.e {
  text-align: center;
}
.f {
  text-align: right;
}
.inlineStr {
  text-align: left;
}
.n {
  text-align: right;
}
.s {
  text-align: left;
}
td.style0 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Arial";
  font-size: 10pt;
  background-color: white;
}
th.style0 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Arial";
  font-size: 10pt;
  background-color: white;
}
td.style1 {
  vertical-align: middle;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
th.style1 {
  vertical-align: middle;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
td.style2 {
  vertical-align: middle;
  text-align: right;
  padding-right: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
th.style2 {
  vertical-align: middle;
  text-align: right;
  padding-right: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
td.style3 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Arial";
  font-size: 10pt;
  background-color: white;
}
th.style3 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Arial";
  font-size: 10pt;
  background-color: white;
}
td.style4 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
th.style4 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
td.style5 {
  vertical-align: bottom;
  text-align: right;
  padding-right: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Arial";
  font-size: 10pt;
  background-color: white;
}
th.style5 {
  vertical-align: bottom;
  text-align: right;
  padding-right: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Arial";
  font-size: 10pt;
  background-color: white;
}
td.style6 {
  vertical-align: middle;
  text-align: right;
  padding-right: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
th.style6 {
  vertical-align: middle;
  text-align: right;
  padding-right: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
td.style7 {
  vertical-align: middle;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
th.style7 {
  vertical-align: middle;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
td.style8 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Arial";
  font-size: 10pt;
  background-color: white;
}
th.style8 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Arial";
  font-size: 10pt;
  background-color: white;
}
td.style9 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  text-decoration: underline;
  color: #000000;
  font-family: "Arial";
  font-size: 10pt;
  background-color: white;
}
th.style9 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  text-decoration: underline;
  color: #000000;
  font-family: "Arial";
  font-size: 10pt;
  background-color: white;
}
td.style10 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Calibri";
  font-size: 11pt;
  background-color: white;
}
th.style10 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Calibri";
  font-size: 11pt;
  background-color: white;
}
td.style11 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Calibri";
  font-size: 11pt;
  background-color: white;
}
th.style11 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Calibri";
  font-size: 11pt;
  background-color: white;
}
td.style12 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Calibri";
  font-size: 11pt;
  background-color: white;
}
th.style12 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Calibri";
  font-size: 11pt;
  background-color: white;
}
td.style13 {
  vertical-align: middle;
  border-bottom: none #000000;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
th.style13 {
  vertical-align: middle;
  border-bottom: none #000000;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
td.style14 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Arial";
  font-size: 10pt;
  background-color: white;
}
th.style14 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Arial";
  font-size: 10pt;
  background-color: white;
}
td.style15 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px dotted #969696 !important;
  border-top: 1px dotted #969696 !important;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
th.style15 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px dotted #969696 !important;
  border-top: 1px dotted #969696 !important;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
td.style16 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px dotted #969696 !important;
  border-top: 1px dotted #969696 !important;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
th.style16 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px dotted #969696 !important;
  border-top: 1px dotted #969696 !important;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
td.style17 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px dotted #969696 !important;
  border-top: 1px dotted #969696 !important;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 8pt;
  background-color: white;
}
th.style17 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px dotted #969696 !important;
  border-top: 1px dotted #969696 !important;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 8pt;
  background-color: white;
}
td.style18 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px dotted #969696 !important;
  border-top: 1px dotted #969696 !important;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
th.style18 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px dotted #969696 !important;
  border-top: 1px dotted #969696 !important;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
td.style19 {
  vertical-align: middle;
  text-align: right;
  padding-right: 0px;
  border-bottom: 1px dotted #969696 !important;
  border-top: 1px dotted #969696 !important;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
th.style19 {
  vertical-align: middle;
  text-align: right;
  padding-right: 0px;
  border-bottom: 1px dotted #969696 !important;
  border-top: 1px dotted #969696 !important;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
td.style20 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px dotted #969696 !important;
  border-top: 1px dotted #969696 !important;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
th.style20 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px dotted #969696 !important;
  border-top: 1px dotted #969696 !important;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
td.style21 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 1px dotted #969696 !important;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
th.style21 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 1px dotted #969696 !important;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
td.style22 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: 1px dotted #969696 !important;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
th.style22 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: 1px dotted #969696 !important;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
td.style23 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 1px dotted #969696 !important;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 8pt;
  background-color: white;
}
th.style23 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 1px dotted #969696 !important;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 8pt;
  background-color: white;
}
td.style24 {
  vertical-align: middle;
  text-align: right;
  padding-right: 0px;
  border-bottom: none #000000;
  border-top: 1px dotted #969696 !important;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
th.style24 {
  vertical-align: middle;
  text-align: right;
  padding-right: 0px;
  border-bottom: none #000000;
  border-top: 1px dotted #969696 !important;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
td.style25 {
  vertical-align: middle;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
th.style25 {
  vertical-align: middle;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
td.style26 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: #c0c0c0;
}
th.style26 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: #c0c0c0;
}
td.style27 {
  vertical-align: middle;
  text-align: right;
  padding-right: 0px;
  border-bottom: 1px dotted #969696 !important;
  border-top: none #000000;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: #ccffff;
}
th.style27 {
  vertical-align: middle;
  text-align: right;
  padding-right: 0px;
  border-bottom: 1px dotted #969696 !important;
  border-top: none #000000;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: #ccffff;
}
td.style28 {
  vertical-align: middle;
  text-align: right;
  padding-right: 0px;
  border-bottom: 1px dotted #969696 !important;
  border-top: none #000000;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: #ffff00;
}
th.style28 {
  vertical-align: middle;
  text-align: right;
  padding-right: 0px;
  border-bottom: 1px dotted #969696 !important;
  border-top: none #000000;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: #ffff00;
}
td.style29 {
  vertical-align: middle;
  text-align: right;
  padding-right: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
th.style29 {
  vertical-align: middle;
  text-align: right;
  padding-right: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
td.style30 {
  vertical-align: middle;
  text-align: right;
  padding-right: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
th.style30 {
  vertical-align: middle;
  text-align: right;
  padding-right: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
td.style31 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #ff0000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
th.style31 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #ff0000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
td.style32 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
th.style32 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
td.style33 {
  vertical-align: middle;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
th.style33 {
  vertical-align: middle;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
td.style34 {
  vertical-align: bottom;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  color: #000000;
  font-family: "Arial";
  font-size: 10pt;
  background-color: white;
}
th.style34 {
  vertical-align: bottom;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  color: #000000;
  font-family: "Arial";
  font-size: 10pt;
  background-color: white;
}
td.style35 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #ff0000;
  font-family: "Arial Narrow";
  font-size: 8pt;
  background-color: white;
  white-space: nowrap;
  padding: 3px;
}
th.style35 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #ff0000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
td.style36 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px dotted #969696 !important;
  border-top: 1px dotted #969696 !important;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
th.style36 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px dotted #969696 !important;
  border-top: 1px dotted #969696 !important;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
td.style37 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px dotted #969696 !important;
  border-top: 1px dotted #969696 !important;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
th.style37 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px dotted #969696 !important;
  border-top: 1px dotted #969696 !important;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
td.style38 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 1px dotted #969696 !important;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
th.style38 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 1px dotted #969696 !important;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
td.style39 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 1px dotted #969696 !important;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
th.style39 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 1px dotted #969696 !important;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
td.style40 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px dotted #969696 !important;
  border-top: none #000000;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
th.style40 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px dotted #969696 !important;
  border-top: none #000000;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
td.style41 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px dotted #969696 !important;
  border-top: none #000000;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
th.style41 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px dotted #969696 !important;
  border-top: none #000000;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
td.style42 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px dotted #969696 !important;
  border-top: none #000000;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 8pt;
  background-color: white;
}
th.style42 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px dotted #969696 !important;
  border-top: none #000000;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 8pt;
  background-color: white;
}
td.style43 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px dotted #969696 !important;
  border-top: none #000000;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
th.style43 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px dotted #969696 !important;
  border-top: none #000000;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
td.style44 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px dotted #969696 !important;
  border-top: none #000000;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 8pt;
  background-color: white;
}
th.style44 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px dotted #969696 !important;
  border-top: none #000000;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 8pt;
  background-color: white;
}
td.style45 {
  vertical-align: middle;
  text-align: right;
  padding-right: 0px;
  border-bottom: 1px dotted #969696 !important;
  border-top: none #000000;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
th.style45 {
  vertical-align: middle;
  text-align: right;
  padding-right: 0px;
  border-bottom: 1px dotted #969696 !important;
  border-top: none #000000;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
td.style46 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px dotted #969696 !important;
  border-top: 1px dotted #969696 !important;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
th.style46 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px dotted #969696 !important;
  border-top: 1px dotted #969696 !important;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
td.style47 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: #c0c0c0;
}
th.style47 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: #c0c0c0;
}
td.style48 {
  vertical-align: middle;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: #c0c0c0;
}
th.style48 {
  vertical-align: middle;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: #c0c0c0;
}
td.style49 {
  vertical-align: middle;
  text-align: right;
  padding-right: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: #ff99cc;
}
th.style49 {
  vertical-align: middle;
  text-align: right;
  padding-right: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: #ff99cc;
}

td.style50 {
  vertical-align: middle;
  text-align: right;
  padding-right: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
th.style50 {
  vertical-align: middle;
  text-align: right;
  padding-right: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
td.style51 {
  vertical-align: middle;
  text-align: right;
  padding-right: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
th.style51 {
  vertical-align: middle;
  text-align: right;
  padding-right: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
td.style52 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: #ffffff;
}
th.style52 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: #ffffff;
}
td.style53 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: #ccffff;
}
th.style53 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: #ccffff;
}
td.style54 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: #ccffff;
}
th.style54 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: #ccffff;
}
td.style55 {
  vertical-align: top;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: #c0c0c0;
}
th.style55 {
  vertical-align: top;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: #c0c0c0;
}
td.style56 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  color: #000000;
  font-family: "Arial";
  font-size: 10pt;
  background-color: white;
}
th.style56 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  color: #000000;
  font-family: "Arial";
  font-size: 10pt;
  background-color: white;
}
td.style57 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  color: #000000;
  font-family: "Arial";
  font-size: 10pt;
  background-color: white;
}
th.style57 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  color: #000000;
  font-family: "Arial";
  font-size: 10pt;
  background-color: white;
}
td.style58 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: #c0c0c0;
}
th.style58 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: #c0c0c0;
}
td.style59 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: #c0c0c0;
}
th.style59 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: #c0c0c0;
}
td.style60 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
th.style60 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
td.style61 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: #ff99cc;
}
th.style61 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: #ff99cc;
}
td.style62 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: #c0c0c0;
}
th.style62 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: #c0c0c0;
}
td.style63 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: #c0c0c0;
}
th.style63 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: none #000000;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: #c0c0c0;
}
td.style64 {
  vertical-align: middle;
  text-align: right;
  padding-right: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: #c0c0c0;
}
th.style64 {
  vertical-align: middle;
  text-align: right;
  padding-right: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: #c0c0c0;
}
td.style65 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
th.style65 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
td.style66 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
th.style66 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
td.style67 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px dotted #969696 !important;
  border-top: 1px dotted #969696 !important;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  font-weight: bold;
  text-decoration: underline;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
th.style67 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px dotted #969696 !important;
  border-top: 1px dotted #969696 !important;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  font-weight: bold;
  text-decoration: underline;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
td.style68 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: #c0c0c0;
}
th.style68 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: #c0c0c0;
}
td.style69 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
th.style69 {
  vertical-align: middle;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
td.style70 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
th.style70 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
td.style71 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
th.style71 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
td.style72 {
  vertical-align: top;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #ffffff;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: #ffffff;
}
th.style72 {
  vertical-align: top;
  text-align: center;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #ffffff;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: #ffffff;
}
td.style73 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
th.style73 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
td.style74 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
th.style74 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
td.style75 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
th.style75 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: none #000000;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
th.boxheader {
  vertical-align: middle;
  text-align: center;
  /* padding-left: 0px; */
  border: 1px thin #000000 !important;
  /* border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: 1px solid #000000 !important; */
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 8pt;
  background-color: #c0c0c0;
}
td.tablegreyborder {
  vertical-align: top;
  text-align: center;
  border: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: #c0c0c0;
}
th.header {
  border: none;
  vertical-align: middle;
  text-align: center;
  white-space: nowrap;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}
td.tabletext {
  border-bottom: 1px dotted #969696 !important;
  border-top: none #000000;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  vertical-align: middle;
  text-align: center;
  white-space: nowrap;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 8pt;
  background-color: white;
}
td.tabletotalyellow {
  border-bottom: 1px dotted #969696 !important;
  border-top: none #000000;
  border-left: 1px dotted #969696 !important;
  border-right: 1px dotted #969696 !important;
  vertical-align: middle;
  text-align: center;
  font-weight: bold;
  white-space: nowrap;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 8pt;
  background-color: #ffff00;
}
td.tabletotalpink {
  vertical-align: middle;
  text-align: center;
  padding-right: 0px;
  border-bottom: 2px solid #000000 !important;
  border-top: 2px solid #000000 !important;
  border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: #ff99cc;
}
th.headerborder {
  border: 1px solid #000000 !important;
  vertical-align: middle;
  text-align: center;

  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  font-size: 10pt;
  background-color: white;
}

table.sheet0 col.col0 {
  width: 21.01111087pt;
}
table.sheet0 col.col1 {
  width: 46.76666613pt;
}
table.sheet0 col.col2 {
  width: 39.31111066pt;
}
table.sheet0 col.col3 {
  width: 57.61111045pt;
}
table.sheet0 col.col4 {
  width: 56.93333268pt;
}
table.sheet0 col.col5 {
  width: 43.37777728pt;
}
table.sheet0 col.col6 {
  width: 46.76666613pt;
}
table.sheet0 col.col7 {
  width: 46.76666613pt;
}
table.sheet0 col.col8 {
  width: 71.84444362pt;
}
table.sheet0 col.col9 {
  width: 51.51111052pt;
}
table.sheet0 col.col10 {
  width: 36.59999958pt;
}
table.sheet0 col.col11 {
  width: 32.53333296pt;
}
table.sheet0 col.col12 {
  width: 35.92222181pt;
}
table.sheet0 col.col13 {
  width: 35.92222181pt;
}
table.sheet0 col.col14 {
  width: 32.53333296pt;
}
table.sheet0 col.col15 {
  width: 30.49999965pt;
}
table.sheet0 col.col16 {
  width: 32.53333296pt;
}
table.sheet0 col.col17 {
  width: 45.41111059pt;
}
table.sheet0 col.col18 {
  width: 13.5555554pt;
}
table.sheet0 tr {
  height: 21pt;
}
table.sheet0 tr.row0 {
  height: 21pt;
}
table.sheet0 tr.row1 {
  height: 8.5pt;
}
table.sheet0 tr.row2 {
  height: 21pt;
}
table.sheet0 tr.row3 {
  height: 11.15pt;
}
table.sheet0 tr.row4 {
  height: 10pt;
}
table.sheet0 tr.row5 {
  height: 21pt;
}
table.sheet0 tr.row6 {
  height: 21pt;
}
table.sheet0 tr.row7 {
  height: 21pt;
}
table.sheet0 tr.row8 {
  height: 21pt;
}
table.sheet0 tr.row9 {
  height: 21pt;
}
table.sheet0 tr.row10 {
  height: 21pt;
}
table.sheet0 tr.row11 {
  height: 21pt;
}
table.sheet0 tr.row12 {
  height: 21pt;
}
table.sheet0 tr.row13 {
  height: 21pt;
}
table.sheet0 tr.row14 {
  height: 21pt;
}
table.sheet0 tr.row15 {
  height: 21pt;
}
table.sheet0 tr.row16 {
  height: 21pt;
}
table.sheet0 tr.row17 {
  height: 21pt;
}
table.sheet0 tr.row18 {
  height: 21pt;
}
table.sheet0 tr.row19 {
  height: 21pt;
}
table.sheet0 tr.row20 {
  height: 21pt;
}
table.sheet0 tr.row21 {
  height: 21pt;
}
table.sheet0 tr.row22 {
  height: 21pt;
}
table.sheet0 tr.row23 {
  height: 21pt;
}
table.sheet0 tr.row24 {
  height: 21pt;
}
table.sheet0 tr.row25 {
  height: 21pt;
}
table.sheet0 tr.row26 {
  height: 21pt;
}
table.sheet0 tr.row27 {
  height: 21pt;
}
table.sheet0 tr.row28 {
  height: 21pt;
}
table.sheet0 tr.row29 {
  height: 21pt;
}
table.sheet0 tr.row30 {
  height: 21pt;
}
table.sheet0 tr.row31 {
  height: 21pt;
}
table.sheet0 tr.row32 {
  height: 21pt;
}
table.sheet0 tr.row33 {
  height: 21pt;
}
table.sheet0 tr.row34 {
  height: 21pt;
}
table.sheet0 tr.row35 {
  height: 21pt;
}
table.sheet0 tr.row36 {
  height: 21pt;
}
table.sheet0 tr.row37 {
  height: 21pt;
}
table.sheet0 tr.row38 {
  height: 21pt;
}
table.sheet0 tr.row39 {
  height: 21pt;
}
table.sheet0 tr.row40 {
  height: 21pt;
}
table.sheet0 tr.row41 {
  height: 21pt;
}
table.sheet0 tr.row42 {
  height: 21pt;
}
table.sheet0 tr.row43 {
  height: 12pt;
}
table.sheet0 tr.row44 {
  height: 21pt;
}
table.sheet0 tr.row45 {
  height: 21pt;
}
table.sheet0 tr.row46 {
  height: 21pt;
}
table.sheet0 tr.row47 {
  height: 21pt;
}
table.sheet0 tr.row48 {
  height: 21pt;
}
table.sheet0 tr.row49 {
  height: 21pt;
}
table.sheet0 tr.row50 {
  height: 21pt;
}
table.sheet0 tr.row51 {
  height: 21pt;
}
table.sheet0 tr.row52 {
  height: 21pt;
}
</style>
