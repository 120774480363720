<template>
  <v-container fluid style="width: 80%">
    <div>
      <v-data-table
        dense
        :headers="headers"
        :items="members"
        :footer-props="{
          showFirstLastPage: true,
          'items-per-page-options': [10, 20, 30, 40, 50, 100, -1],
        }"
        :items-per-page="50"
        :search="search"
        class="elevation-1"
        mobile-breakpoint="1100"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-dialog v-model="dialog" max-width="600px" persistent>
              <template v-slot:activator="{ on: dialog, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      fab
                      small
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="{ ...tooltip, ...dialog }"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>ADD USER</span>
                </v-tooltip>
              </template>

              <v-card tile>
                <v-card-title class="justify-center text-h5 grey lighten-2">
                  <span>{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.username"
                            :rules="[(v) => !!v || 'Name is required']"
                            label="Name"
                            required
                          >
                            <template #label>
                              <span class="red--text"><strong>* </strong></span
                              >Name
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            :rules="[
                              (v) =>
                                /^\d+$/.test(v) ||
                                'This field is required and only accept numbers',
                            ]"
                            type="number"
                            hide-spin-buttons
                            v-model="editedItem.nric"
                            label="NRIC"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.fullname"
                            label="Fullname as per NRIC"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.email"
                            :rules="editedItem.emailRules"
                            label="Email"
                            required
                            type="email"
                          >
                            <template #label>
                              <span class="red--text"><strong>* </strong></span
                              >Email
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select
                            dense
                            v-model="editedItem.roles"
                            :rules="[(v) => !!v || 'Role is required']"
                            :items="access"
                            item-text="name"
                            item-value="id"
                            :menu-props="{ maxHeight: '400' }"
                            label="USER LEVEL"
                            hint="Set the user access"
                            persistent-hint
                            multiple
                          ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select
                            dense
                            v-model="editedItem.branches"
                            :rules="[(v) => !!v || 'Branch is required']"
                            :items="branch"
                            item-text="name"
                            item-value="id"
                            :menu-props="{ maxHeight: '400' }"
                            label="BRANCH"
                            hint="Set the user branch"
                            persistent-hint
                            multiple
                          ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select
                            dense
                            v-model="editedItem.depts"
                            :rules="[(v) => !!v || 'Department is required']"
                            :items="department"
                            item-text="description"
                            item-value="id"
                            :menu-props="{ maxHeight: '400' }"
                            label="DEPARTMENT"
                            hint="Set the user department"
                            multiple
                          ></v-select>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.telnumber"
                            label="Telephone"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                          <template>
                            <div>
                              <v-menu
                                v-model="menu"
                                :close-on-content-click="false"
                                max-width="290"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="ComputedDate"
                                    persistent-hint
                                    label="Date Joined"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    @click:clear="date = null"
                                  ></v-text-field>
                                </template>

                                <v-date-picker
                                  v-model="editedItem.datejoined"
                                  @change="menu = false"
                                ></v-date-picker>
                              </v-menu>
                            </div>
                          </template>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.position"
                            label="Position"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.empid"
                            label="Employment ID"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.usercode"
                            label="User Employment Code"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12">
                          <v-textarea
                            v-model="editedItem.address"
                            label="Home Address"
                          ></v-textarea>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.emcontactname"
                            label="Emergency Contact Name"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.emcontactno"
                            label="Emergency Contact Number"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-checkbox
                    v-model="editedItem.active"
                    :label="`Active`"
                    input-value="editedItem.active"
                  ></v-checkbox>
                  <v-spacer></v-spacer>
                  <v-btn outlined small tile @click="close"> Cancel </v-btn>
                  <v-btn
                    :disabled="isAddButtonDisabled"
                    small
                    tile
                    class="success"
                    @click="save"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-divider class="mx-4" inset vertical></v-divider>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="justify-center text-h5 grey lighten-2"
                  >Confirm delete?
                  <!-- {{ editedItem.username }}? -->
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn outlined small tile @click="closeDelete">Cancel</v-btn>
                  <v-btn small tile class="error" @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              clearable
            ></v-text-field>
          </v-toolbar>
        </template>
        <template slot="item.numbering" slot-scope="props">
          {{ props.index + 1 }}
        </template>
        <template v-slot:[`item.datejoined`]="{ item }">
          {{ formatDate(item.datejoined) }}
        </template>
        <template v-slot:[`item.active`]="{ item }">
          <v-chip :color="getStatusColor(item.active)" outlined x-small label>
            <tr>
              {{
                item.active ? "ACTIVE" : "INACTIVE"
              }}
            </tr>
          </v-chip>
        </template>

        <template v-slot:[`item.depts`]="{ item }">
          <span
            v-for="allDepts in item.depts"
            :key="allDepts.id"
            v-bind="allDepts.item"
          >
            <v-chip outlined x-small label color="primary">
              {{ allDepts.name.toUpperCase() }}
            </v-chip>
          </span>
        </template>

        <template v-slot:[`item.branches`]="{ item }">
          <span
            v-for="allBranches in item.branches"
            :key="allBranches.id"
            v-bind="allBranches.item"
          >
            <v-chip outlined x-small label color="warning">
              {{ allBranches.brCode.toUpperCase() }}
            </v-chip>
          </span>
        </template>

        <template v-slot:[`item.roles`]="{ item }">
          <span
            v-for="allRoles in item.roles"
            :key="allRoles.id"
            v-bind="allRoles.item"
          >
            <v-chip
              :color="
                allRoles.name === 'ADMIN'
                  ? 'grey'
                  : allRoles.name === 'MANAGER'
                  ? 'red'
                  : allRoles.name === 'BRANCH MANAGER'
                  ? 'blue'
                  : allRoles.name === 'ADJUSTER'
                  ? 'magenta'
                  : allRoles.name === 'CLERK'
                  ? 'red lighten-3'
                  : allRoles.name === 'BRANCH CLERK'
                  ? 'blue lighten-3'
                  : allRoles.name === 'EDITOR'
                  ? 'brown lighten-1'
                  : allRoles.name === 'DIRECTOR'
                  ? 'blue-grey darken-1'
                  : allRoles.name === 'ACCOUNT'
                  ? 'green'
                  : 'black'
              "
              outlined
              x-small
              label
            >
              {{ allRoles.name.toUpperCase() }}
              <!-- </div> -->
            </v-chip>
          </span>
        </template>

        <!-- Trick to truncate text and show elipsis at the end  -->
        <!-- <template v-slot:[`item.name`]="{ value }">
          <div class="text-truncate" style="max-width: 40px">
            {{ value }}
          </div>
        </template> -->

        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left transition="fab-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span> edit</span>
          </v-tooltip>
          <v-tooltip right transition="fab-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                v-on="on"
                color="red darken-1"
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <span> delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import MemberDataService from "../../services/MemberDataService";
import AccessDataService from "../../services/AccessDataService";
import BranchDataService from "../../services/BranchDataService";
import DeptDataService from "../../services/DeptDataService";

// to deny access to admin pages START
// import UserService from "../../services/user.service";
// import EventBus from "../../common/EventBus";
// to deny access to admin pages END
import moment from "moment";

export default {
  data: () => ({
    content: "",
    access: [],
    branch: [],
    department: [],

    dialog: false,
    dialogDelete: false,
    members: [],
    search: "",
    show1: false,

    // date: null,
    menu: false,

    defaultItem: {
      id: null,
      username: "",
      fullname: "",
      nric: "",
      email: "",
      telnumber: "",
      datejoined: "",
      position: "",
      empid: "",
      usercode: "",
      address: "",
      emcontactname: "",
      emcontactno: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      // password: "",
      active: "",
      roles: "",
      branches: "",
      depts: "",
    },

    editedIndex: -1,

    editedItem: {
      id: null,
      username: "",
      fullname: "",
      nric: "",
      email: "",
      telnumber: "",
      datejoined: "",
      position: "",
      empid: "",
      usercode: "",
      address: "",
      emcontactname: "",
      emcontactno: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      // password: "",
      active: "",
      roles: "",
      branches: "",
      depts: "",
    },

    headers: [
      {
        text: "NO.",
        align: "start",
        sortable: false,
        value: "numbering",
        // class: "blue lighten-5",
      },
      {
        text: "NAME",
        align: "start",
        sortable: false,
        value: "username",
        // class: "blue lighten-5",
      },
      {
        text: "EMAIL",
        value: "email",
        sortable: false,
        // class: "blue lighten-5",
      },
      {
        text: "DEPARTMENT",
        align: "center",
        value: "depts",
        sortable: false,
        // class: "blue lighten-5",
      },
      {
        text: "BRANCH",
        align: "center",
        value: "branches",
        sortable: false,
        // class: "blue lighten-5",
      },
      {
        text: "STATUS",
        align: "center",
        value: "active",
        sortable: false,
        // class: "blue lighten-5",
      },
      {
        text: "ACCESS",
        align: "center",
        value: "roles",
        sortable: false,
        // class: "blue lighten-5",
      },
      {
        text: "ACTIONS",
        align: "center",
        value: "actions",
        sortable: false,
        // class: "blue lighten-5",
      },
    ],
  }),

  computed: {
    ComputedDate() {
      return this.formatDateMethod(this.editedItem.datejoined);
    },
    formTitle() {
      return this.editedIndex === -1 ? "Add User" : "Update User";
    },
    isAddButtonDisabled() {
      return !(
        (this.editedItem.username && this.editedItem.email)
        // this.editedItem.datejoined &&
        // this.editedItem.password &&
        // this.editedItem.roles &&
        // this.editedItem.branches &&
        // this.editedItem.depts
      );
    },

    computedDateFormattedMomentjs() {
      // return this.date ? moment(this.date).format("yyyy-MM-dd") : "";
      return this.editedItem.datejoined
        ? moment(this.editedItem.datejoined).format("DD/MM/YYYY")
        : "";
    },

    // memberRoles: function() {
    //   // return this.members.filter(function(item) {
    //   //   return item.id == 2;
    //   // });

    //   return this.members.filter((item) => [2].includes(item.id));
    // },

    // One condition filter by branch
    // filteredMembers: function() {
    //   return this.members.filter((member) => {
    //     // return member.branch.match("KEL");
    //     return member.branch.match(this.$store.state.auth.user.branch);
    //   });
    // },

    // Two condition filter by branch
    // filteredMembers: function() {
    //   return this.members.filter((member) => {
    //     // return member.branch.match("KEL");

    //     return (
    //       member.branch == this.$store.state.auth.user.branch &&
    //       member.depts == this.$store.state.auth.user.depts
    //     );
    //   });
    // },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    formatDateMethod(date) {
      if (!date) return null;
      return moment(date).format("DD/MM/YYYY");
    },
    formatDate(value) {
      return moment(value).format("DD/MM/YYYY");
    },
    retrieveAccess() {
      AccessDataService.getAll()
        .then((response) => {
          this.access = response.data.map(this.getDisplayAccess);
          // console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    getDisplayAccess(role) {
      return {
        id: role.id,
        name: role.name,
        roleCode: role.roleCode,
      };
    },

    retrieveBranch() {
      BranchDataService.getAll()
        .then((response) => {
          this.branch = response.data.map(this.getDisplayBranch);
          // console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    getDisplayBranch(branch) {
      return {
        id: branch.id,
        name: branch.name,
        brCode: branch.brCode,
      };
    },

    retrieveDept() {
      DeptDataService.getAll()
        .then((response) => {
          this.department = response.data.map(this.getDisplayDept);
          // console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    getDisplayDept(dept) {
      return {
        id: dept.id,
        name: dept.name,
        description: dept.description,
      };
    },

    retrieveMembers() {
      MemberDataService.getAll()
        .then((response) => {
          this.members = response.data.map(this.getDisplayMember);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    getDisplayMember(member) {
      return {
        id: member.id,
        username:
          member.username.length > 30
            ? member.username.substr(0, 30) + "..."
            : member.username,
        fullname: member.fullname,
        nric: member.nric,
        email: member.email,
        // email:
        //   member.email.length > 30
        //     ? member.email.substr(0, 30) + "..."
        //     : member.email,
        telnumber: member.telnumber,
        datejoined: member.datejoined,
        position: member.position,
        empid: member.empid,
        usercode: member.usercode,
        address: member.address,
        emcontactname: member.emcontactname,
        emcontactno: member.emcontactno,

        active: member.active ? 1 : 0,
        activatelink: member.activatelink,
        resetlink: member.resetlink,
        roles: member.roles,
        branches: member.branches,
        depts: member.depts,
      };
    },

    refreshList() {
      this.retrieveMembers();
    },

    getRolesColor(roles) {
      if (roles[0].name == "ADMIN") return "red";
      else if (roles[0].name == "MANAGER") return "orange";
      else if (roles[0].name == "BRANCH MANAGER") return "indigo";
      else return "grey";
    },

    getStatusColor(active) {
      if (active == 0) return "green lighten-2";
      else return "green darken-3";
    },

    editItem(item) {
      this.editedIndex = this.members.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.roles = Object.assign(
        [],
        item.roles.map((a) => a.user_roles.roleId)
      );
      this.editedItem.branches = Object.assign(
        [],
        item.branches.map((a) => a.user_branches.brId)
      );
      this.editedItem.depts = Object.assign(
        [],
        item.depts.map((a) => a.user_depts.deptId)
      );
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.members.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      MemberDataService.delete(this.editedItem.id)
        .then((response) => {
          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "error",
            text:
              (response && response.data && response.data.message) ||
              toString(),
          });

          this.refreshList();
        })
        .catch((e) => {
          console.log(e);
        });

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        MemberDataService.update(this.editedItem.id, this.editedItem)
          .then((response) => {
            this.close();
            this.$store.dispatch("setSnackbar", {
              showing: true,
              color: "warning",
              text:
                (response && response.data && response.data.message) ||
                toString(),
            });
            this.refreshList();
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        var data = {
          username: this.editedItem.username,
          fullname: this.editedItem.fullname,
          nric: this.editedItem.nric,
          email: this.editedItem.email,

          telnumber: this.editedItem.telnumber,
          datejoined: this.editedItem.datejoined,
          position: this.editedItem.position,
          empid: this.editedItem.empid,
          usercode: this.editedItem.usercode,
          address: this.editedItem.address,
          emcontactname: this.editedItem.emcontactname,
          emcontactno: this.editedItem.emcontactno,

          // password: this.editedItem.password,
          active: this.editedItem.active ? 1 : 0,
          roles: this.editedItem.roles,
          branches: this.editedItem.branches,
          depts: this.editedItem.depts,
        };
        console.log(this.editedItem.roles);
        MemberDataService.create(data)
          .then((response) => {
            this.editedItem.id = response.data.id;
            this.close();
            this.$store.dispatch("setSnackbar", {
              showing: true,
              color: "success",
              text:
                (response && response.data && response.data.message) ||
                toString(),
            });
            this.refreshList();
          })
          .catch((e) => {
            this.$store.dispatch("setSnackbar", {
              showing: true,
              text:
                (e.response && e.response.data && e.response.data.message) ||
                e.message ||
                toString(),
            });
          });
      }
      this.close();
    },
  },
  mounted() {
    this.retrieveMembers();
    this.retrieveAccess();
    this.retrieveBranch();
    this.retrieveDept();
    // to deny access to admin pages START
    // UserService.getAdminBoard().then(
    //   (response) => {
    //     this.content = response.data;
    //   },
    //   (error) => {
    //     this.content =
    //       (error.response &&
    //         error.response.data &&
    //         error.response.data.message) ||
    //       error.message ||
    //       error.toString();

    //     if (error.response && error.response.status === 403) {
    //       EventBus.dispatch("logout");
    //     }
    //   }
    // );
    // to deny access to admin pages END
  },
};
</script>
