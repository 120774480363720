<template>
  <v-container fluid style="width: 80%">
    <div>
      <v-data-table
        dense
        :headers="headers"
        :items="handlers"
        :search="search"
        class="elevation-1"
        :footer-props="{
          showFirstLastPage: true,
          'items-per-page-options': [10, 20, 30, 40, 50, 100, -1],
        }"
        :items-per-page="50"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-dialog v-model="dialog" max-width="500px" persistent>
              <template v-slot:activator="{ on: dialog, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      fab
                      small
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="{ ...tooltip, ...dialog }"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>ADD HANDLER</span>
                </v-tooltip>
              </template>

              <v-card tile>
                <v-card-title class="justify-center text-h5 grey lighten-2">
                  <span>{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-select
                          v-model="editedItem.insId"
                          :items="insurers"
                          item-text="name"
                          item-value="id"
                          label="Insurer"
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          v-model="editedItem.deptId"
                          :items="departments"
                          item-text="name"
                          item-value="id"
                          label="Department"
                        ></v-select>
                      </v-col>

                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedItem.name"
                          label="Name"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedItem.offTel"
                          label="Telephone Number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedItem.mobile"
                          label="Mobile Number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedItem.email"
                          label="Email"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn outlined small tile @click="close"> Cancel </v-btn>
                  <v-btn
                    :disabled="isAddButtonDisabled"
                    small
                    tile
                    class="success"
                    @click="save"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-divider class="mx-4" inset vertical></v-divider>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="justify-center text-h5 grey lighten-2"
                  >Confirm delete?
                  <!-- {{ editedItem.name }}? -->
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn outlined small tile @click="closeDelete">No</v-btn>
                  <v-btn small tile class="error" @click="deleteItemConfirm"
                    >Yes</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              clearable
            ></v-text-field>
          </v-toolbar>
        </template>

        <template slot="item.numbering" slot-scope="props">
          {{ props.index + 1 }}
        </template>

        <template v-slot:[`item.insId`]="{ item }">
          <span
            v-for="ins in filterIns(item.insId)"
            :key="ins.id"
            v-bind="ins.item"
          >
            <div class="text-truncate" style="max-width: 80px">
              {{ ins.name }}
            </div>
          </span>
        </template>

        <template v-slot:[`item.deptId`]="{ item }">
          <span
            v-for="dept in filterDept(item.deptId)"
            :key="dept.id"
            v-bind="dept.item"
          >
            {{ dept.name }}
          </span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left transition="fab-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span> edit</span>
          </v-tooltip>
          <v-tooltip right transition="fab-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                v-on="on"
                color="red darken-1"
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <span> delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import HandlerDataService from "../../services/HandlerDataService";
import InsDataService from "../../services/InsDataService";
import DeptDataService from "../../services/DeptDataService";
export default {
  name: "handlers",
  data: () => ({
    dialog: false,
    dialogDelete: false,
    handlers: [],
    // branches: [],
    insurers: [],
    departments: [],
    search: "",
    defaultItem: {
      id: null,
      insId: "",
      deptId: "",
      name: "",
      offTel: "",
      mobile: "",
      email: "",
    },
    editedIndex: -1,
    editedItem: {
      id: null,
      insId: "",
      deptId: "",
      name: "",
      offTel: "",
      mobile: "",
      email: "",
    },
    headers: [
      {
        text: "NO.",
        align: "start",
        sortable: false,
        value: "numbering",
      },
      {
        text: "INSURER",
        align: "start",
        sortable: true,
        value: "insId",
      },
      {
        text: "DEPARTMENT",
        align: "start",
        sortable: true,
        value: "deptId",
      },
      {
        text: "HANDLER",
        align: "start",
        sortable: true,
        value: "name",
      },
      {
        text: "TELEPHONE",
        align: "center",
        value: "offTel",
        sortable: true,
      },
      {
        text: "MOBILE",
        align: "center",
        value: "mobile",
        sortable: true,
      },
      {
        text: "EMAIL",
        align: "center",
        value: "email",
        sortable: true,
      },
      {
        text: "ACTIONS",
        align: "center",
        value: "actions",
        sortable: false,
      },
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add Handler" : "Update Handler";
    },
    isAddButtonDisabled() {
      return !(
        this.editedItem.insId &&
        this.editedItem.deptId &&
        this.editedItem.name &&
        this.editedItem.email
      );
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    filterBranch(item) {
      return this.branches.filter((branch) => branch.id === item);
    },
    filterIns(item) {
      return this.insurers.filter((ins) => ins.id === item);
    },

    filterDept(item) {
      return this.departments.filter((dept) => dept.id === item);
    },

    retrieveHandlers() {
      HandlerDataService.getAll()
        .then((response) => {
          this.handlers = response.data.map(this.getDisplayHandler);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    getDisplayHandler(handler) {
      return {
        id: handler.id,
        insId: handler.insId,
        deptId: handler.deptId,
        name:
          handler.name.length > 30
            ? handler.name.substr(0, 30) + "..."
            : handler.name,
        offTel: handler.offTel,
        mobile: handler.mobile,
        email: handler.email,
      };
    },

    retrieveInsurers() {
      InsDataService.getAll()
        .then((response) => {
          this.insurers = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    retrieveDepartments() {
      DeptDataService.getAll()
        .then((response) => {
          this.departments = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    refreshList() {
      this.retrieveHandlers();
      this.retrieveInsurers();
      this.retrieveDepartments();
    },

    editItem(item) {
      this.editedIndex = this.handlers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.handlers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      HandlerDataService.delete(this.editedItem.id)
        .then((response) => {
          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "error",
            text:
              (response && response.data && response.data.message) ||
              toString(),
          });

          this.refreshList();
        })
        .catch((e) => {
          console.log(e);
        });

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        HandlerDataService.update(this.editedItem.id, this.editedItem)
          .then((response) => {
            this.close();
            this.$store.dispatch("setSnackbar", {
              showing: true,
              color: "warning",
              text:
                (response && response.data && response.data.message) ||
                toString(),
            });
            this.refreshList();
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        var data = {
          insId: this.editedItem.insId,
          deptId: this.editedItem.deptId,
          name: this.editedItem.name,
          offTel: this.editedItem.offTel,
          mobile: this.editedItem.mobile,
          email: this.editedItem.email,
        };

        HandlerDataService.create(data)
          .then((response) => {
            this.editedItem.id = response.data.id;
            this.close();
            this.$store.dispatch("setSnackbar", {
              showing: true,
              color: "success",
              text:
                (response && response.data && response.data.message) ||
                toString(),
            });
            this.refreshList();
          })
          .catch((e) => {
            this.$store.dispatch("setSnackbar", {
              showing: true,
              text:
                (e.response && e.response.data && e.response.data.message) ||
                e.message ||
                toString(),
            });
          });
      }
      this.close();
    },
  },
  mounted() {
    this.retrieveHandlers();
    this.retrieveInsurers();
    this.retrieveDepartments();
  },
};
</script>
