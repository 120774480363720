import CasefileDataService from "../services/CasefileDataService";

export const fileModule = {
  namespaced: true,
  state: {
    casefile: {
      id: null,
      refType: "",
      subRefType: "",
      branch: "",
      insurer: "",
      handler: "",
      claimNo: "",
      dateOfAssign: "",
      vehicleNo: "",
      dateOfLoss: "",
      stateOfLoss: "",
      placeOfLoss: "",
      insuredName: "",
      insComment: "",
      createdBy: "",
      adjuster: "",
      roles: "",
      fileStatus: "",
    },
  },
  mutations: {
    GET_CASEFILES_SUCCESS: function (state, payload) {
      state.casefile = payload.casefile;
    },
  },
  actions: {
    getCasefiles: async function ({ commit }) {
      commit("GET_CASEFILES_SUCCESS", { casefile: response.data });
      let response = await CasefileDataService.get(this.$route.params.id);
    },
  },
};
