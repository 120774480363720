import http from "../http-common";
// const qs = require("qs");

class MemberDataService {
  getAll() {
    return http.get("/members");
  }

  get(id) {
    return http.get(`/members/${id}`);
  }

  create(data) {
    return http.post("/members", data);
  }

  activationlink(data) {
    return http.post("/members/activation", data);
  }

  resetlink(data) {
    // console.log(qs.stringify(data));
    // return http.post("/members/reset/sendlink", qs.stringify(data));
    return http.post("/members/reset/sendlink", data);
  }

  verifyUser(data) {
    return http.post("/members/reset/verifyuser", data);
  }
  update(id, data) {
    return http.put(`/members/${id}`, data);
  }

  changepass(id, data) {
    return http.put(`/members/changepass/${id}`, data);
  }

  updatePhoto(id, data) {
    return http.put(`/members/updatePhoto/${id}`, data);
  }

  updateProfile(id, data) {
    return http.put(`/members/updateProfile/${id}`, data);
  }

  // upload(id, data) {
  //   return http.put(`/members/upload/${id}`, data);
  // }

  delete(id) {
    return http.delete(`/members/${id}`);
  }

  deleteAll() {
    return http.delete(`/members`);
  }

  findByUsername(username) {
    return http.get(`/members?username=${username}`);
  }

  getAllRoles() {
    return http.get("/members/userLevel/all");
  }
}

export default new MemberDataService();
