<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-data-table
          v-model="currentDropboxFile"
          dense
          :headers="headers"
          :items="filteredCasefiles"
          :search="search"
          class="elevation-1"
          :loading="loadTable"
          loading-text="Working on it ......"
          @click:row="(item) => viewItem(item)"
          style="cursor: pointer"
          @current-items="currentItems"
          show-select
          :footer-props="{
            'items-per-page-options': [10, 20, 30, 40, 50, 100],
          }"
          :items-per-page="100"
          sort-by="id"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-dialog v-model="addDialog" max-width="600px">
                <template v-slot:activator="{ on: dialog, attrs }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn
                        fab
                        small
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="{ ...tooltip, ...dialog }"
                        :disabled="addCasefileDisabled"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>ADD CASEFILE</span>
                  </v-tooltip>
                </template>

                <v-card tile>
                  <v-card-title class="justify-center text-h5 grey lighten-2">
                    <span class="text-h5">ADD NEW CASE</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-card width="100%" class="mt-2" tile>
                          <v-card-title>Department</v-card-title>
                          <v-col cols="12">
                            <v-select
                              v-model="newItem.refType"
                              :items="refType"
                              item-text="name"
                              item-value="id"
                              :menu-props="{ maxHeight: '400' }"
                              hint="Select Department"
                              persistent-hint
                              label="Department"
                            >
                              <template #label>
                                <span class="red--text"
                                  ><strong>* </strong></span
                                >Department
                              </template>
                            </v-select>
                          </v-col>
                          <v-col cols="12">
                            <v-select
                              v-model="newItem.subRefType"
                              :items="filtersubRefType(newItem.refType)"
                              item-text="subCode"
                              item-value="id"
                              no-data-text="There is no file-Classification option for selected department above"
                              :menu-props="{ maxHeight: '400' }"
                              label="File-Classification"
                              hint="Select File-Classification"
                              persistent-hint
                            >
                              <template #label>
                                <span class="red--text"
                                  ><strong>* </strong></span
                                >File-Classification
                              </template>
                            </v-select>
                          </v-col>
                        </v-card>
                        <v-col cols="12">
                          <v-autocomplete
                            v-model="newItem.insurer"
                            :items="insurer"
                            item-text="name"
                            item-value="id"
                            :menu-props="{ maxHeight: '400' }"
                            hint="Set the casefile insurer"
                            persistent-hint
                          >
                            <template #label>
                              <span class="red--text"><strong>* </strong></span
                              >Insurer
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                          <v-select
                            v-model="newItem.handler"
                            :items="filterHandler(newItem.insurer)"
                            item-text="name"
                            item-value="id"
                            no-data-text="There is no handler option for selected insurer above"
                            :menu-props="{ maxHeight: '400' }"
                            label="Claim Handler"
                            hint="Select Handler"
                            persistent-hint
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            v-model="newItem.claimNo"
                            item-text="Insurer's Reference"
                            label="Insurers Reference Number"
                            :rules="[
                              (v) => !!v || 'Insurers reference is required',
                            ]"
                          >
                            <template #label>
                              <span class="red--text"><strong>* </strong></span
                              >Insurers Reference
                            </template></v-text-field
                          >
                        </v-col>

                        <v-col cols="12" sm="6" md="6">
                          <template>
                            <div>
                              <v-menu
                                v-model="menu"
                                :close-on-content-click="false"
                                max-width="290"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="newItem.dateOfAssign"
                                    :value="computedDateFormattedMomentjs"
                                    clearable
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    @click:clear="date = null"
                                  >
                                    <template #label>
                                      <span class="red--text"
                                        ><strong>* </strong></span
                                      >Date Of Assignment
                                    </template>
                                  </v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="newItem.dateOfAssign"
                                  :value="computedDateFormattedMomentjs"
                                  @change="menu = false"
                                ></v-date-picker>
                              </v-menu>
                            </div>
                          </template>
                        </v-col>

                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            v-model="newItem.vehicleNo"
                            label="Vehicle Number"
                            hint="Vehicle Number"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <template>
                            <div>
                              <v-menu
                                v-model="menu1"
                                :close-on-content-click="false"
                                max-width="290"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="newItem.dateOfLoss"
                                    :value="computedDateFormattedMomentjs"
                                    clearable
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    @click:clear="date = null"
                                  >
                                    <template #label>
                                      <span class="red--text"
                                        ><strong>* </strong></span
                                      >Date Of Loss
                                    </template>
                                  </v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="newItem.dateOfLoss"
                                  :value="computedDateFormattedMomentjs"
                                  @change="menu1 = false"
                                ></v-date-picker>
                              </v-menu>
                            </div>
                          </template>
                        </v-col>

                        <v-col cols="12" sm="12" md="12">
                          <v-select
                            v-model="newItem.fileStatus"
                            :items="selectItems"
                            item-text="description"
                            item-value="code"
                            :menu-props="{ maxHeight: '400' }"
                            label="FILE STATUS"
                            hint="Set File Status - Will be removed on Production version"
                            persistent-hint
                            single-line
                          ></v-select>
                        </v-col>

                        <v-col cols="12" sm="6" md="6">
                          <v-spacer></v-spacer>
                          <v-btn
                            outlined
                            small
                            tile
                            @click="closeAddDialog"
                            class="mt-3 mr-2"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            :disabled="isAddFileButtonDisabled"
                            small
                            tile
                            class="mt-3 success"
                            @click="addCasefile"
                            >Submit</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-dialog>

              <v-dialog v-model="editDialog" max-width="600px">
                <v-card tile>
                  <v-card-title class="justify-center text-h5 grey lighten-2">
                    <span>EDIT CASEFILE</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-card width="100%" class="mt-2" tile>
                          <v-card-title>Department</v-card-title>
                          <v-col cols="12">
                            <v-select
                              v-model="editItem.refType"
                              :items="refType"
                              item-text="name"
                              item-value="id"
                              :menu-props="{ maxHeight: '400' }"
                              hint="Select Department"
                              persistent-hint
                              label="Department"
                            >
                              <template #label>
                                <span class="red--text"
                                  ><strong>* </strong></span
                                >Department
                              </template>
                            </v-select>
                          </v-col>
                          <v-col cols="12">
                            <v-select
                              v-model="editItem.subRefType"
                              :items="filtersubRefType(editItem.refType)"
                              item-text="subCode"
                              item-value="id"
                              no-data-text="There is no file-Classification option for selected department above"
                              :menu-props="{ maxHeight: '400' }"
                              label="File-Classification"
                              hint="Select File-Classification"
                              persistent-hint
                            ></v-select>
                          </v-col>
                        </v-card>
                        <v-col cols="12">
                          <v-autocomplete
                            v-model="editItem.insurer"
                            :items="insurer"
                            item-text="name"
                            item-value="id"
                            :menu-props="{ maxHeight: '400' }"
                            hint="Set the casefile insurer"
                            persistent-hint
                          >
                            <template #label>
                              <span class="red--text"><strong>* </strong></span
                              >Insurer
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                          <v-select
                            v-model="editItem.handler"
                            :items="filterHandler(editItem.insurer)"
                            item-text="name"
                            item-value="id"
                            no-data-text="There is no handler option for selected insurer above"
                            :menu-props="{ maxHeight: '400' }"
                            label="Claim Handler"
                            hint="Select Handler"
                            persistent-hint
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            v-model="editItem.claimNo"
                            item-text="Insurer's Reference"
                            label="Insurers Reference Number"
                            :rules="[
                              (v) => !!v || 'Insurers reference is required',
                            ]"
                          >
                            <template #label>
                              <span class="red--text"><strong>* </strong></span
                              >Insurers Reference
                            </template></v-text-field
                          >
                        </v-col>

                        <v-col cols="12" sm="6" md="6">
                          <template>
                            <div>
                              <v-menu
                                v-model="menu"
                                :close-on-content-click="false"
                                max-width="290"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="editItem.dateOfAssign"
                                    :value="computedDateFormattedMomentjs"
                                    clearable
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    @click:clear="date = null"
                                  >
                                    <template #label>
                                      <span class="red--text"
                                        ><strong>* </strong></span
                                      >Date Of Assignment
                                    </template>
                                  </v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="editItem.dateOfAssign"
                                  :value="computedDateFormattedMomentjs"
                                  @change="menu = false"
                                ></v-date-picker>
                              </v-menu>
                            </div>
                          </template>
                        </v-col>

                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            v-model="editItem.vehicleNo"
                            label="Vehicle Number"
                            hint="Vehicle Number"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <template>
                            <div>
                              <v-menu
                                v-model="menu1"
                                :close-on-content-click="false"
                                max-width="290"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="editItem.dateOfLoss"
                                    :value="computedDateFormattedMomentjs"
                                    clearable
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    @click:clear="date = null"
                                  >
                                    <template #label>
                                      <span class="red--text"
                                        ><strong>* </strong></span
                                      >Date Of Loss
                                    </template>
                                  </v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="editItem.dateOfLoss"
                                  :value="computedDateFormattedMomentjs"
                                  @change="menu1 = false"
                                ></v-date-picker>
                              </v-menu>
                            </div>
                          </template>
                        </v-col>

                        <v-col cols="12" sm="12" md="12">
                          <v-select
                            v-model="editItem.fileStatus"
                            :items="selectItems"
                            item-text="description"
                            item-value="code"
                            :menu-props="{ maxHeight: '400' }"
                            label="FILE STATUS"
                            hint="Set File Status - Will be removed on Production version"
                            persistent-hint
                            single-line
                          ></v-select>
                        </v-col>

                        <v-col cols="12" sm="6" md="6">
                          <v-spacer></v-spacer>
                          <v-btn
                            outlined
                            small
                            tile
                            @click="closeEditDialog"
                            class="mt-3 mr-2"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            :disabled="isEditFileButtonDisabled"
                            small
                            tile
                            class="warning mt-3"
                            @click="editCasefile"
                            >Update</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="justify-center text-h5 grey lighten-2"
                    >Confirm delete?
                    <!-- {{ editItem.id }}? -->
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn outlined small tile @click="closeDeleteDialog"
                      >No</v-btn
                    >
                    <v-btn small tile class="error" @click="deleteItemConfirm"
                      >Yes</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-divider v-if="addcase" class="mx-4" inset vertical></v-divider>
              <v-dialog
                :disabled="currentDropboxFile.length == 0"
                v-model="deleteSelectedDialog"
                width="500"
              >
                <template v-slot:activator="{ on: dialog, attrs }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn
                        fab
                        small
                        class="error"
                        v-bind="attrs"
                        v-on="{ ...tooltip, ...dialog }"
                      >
                        <v-icon>mdi-delete-alert</v-icon>
                      </v-btn>
                    </template>
                    <span>DELETE SELECTED</span>
                  </v-tooltip>
                </template>

                <v-card>
                  <v-card-title class="headline grey lighten-2" primary-title>
                    Confirm delete??
                  </v-card-title>

                  <v-card-text> You won't be able to revert back. </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="success" tile small @click="hideDeleteDialog">
                      No
                    </v-btn>
                    <v-btn class="error" tile small @click="deleteSelectedItem">
                      Yes
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-file-input
                label="Click here to upload"
                dense
                hide-details
                @change="selectFile"
                v-model="currentFile"
                name="file"
                outlined
                clearable
              ></v-file-input>
              <v-btn
                :disabled="isUploadDisabled"
                small
                tile
                class="success ml-2"
                @click="uploadConfirm"
              >
                Upload
              </v-btn>
              <v-divider class="mx-4" inset vertical></v-divider>
              <!-- <v-spacer></v-spacer> -->
              <!-- Search Field -->
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                clearable
              ></v-text-field>
            </v-toolbar>
          </template>

          <template slot="item.numbering" slot-scope="props">
            {{ props.index + 1 }}
          </template>

          <template v-slot:[`item.insurer`]="{ item }">
            <div v-for="ins in filterIns(item.insurer)" :key="ins.id">
              <div class="text-truncate" style="max-width: 150px">
                {{ ins.name }}
              </div>
            </div>
          </template>

          <template v-slot:[`item.dateOfLoss`]="{ item }">
            {{ formatDate(item.dateOfLoss) }}
          </template>

          <!-- <template v-slot:[`item.fileStatus`]="{ item }">
              <span
                v-for="allStatus in filterStages(item.fileStatus)"
                :key="allStatus.id"
                v-bind="allStatus.item"
              >
                <v-chip label outlined block x-small>
                  {{ allStatus.name }}
                </v-chip>
              </span>
            </template> -->

          <template v-slot:[`item.fileStatus`]="{ item }">
            <v-chip label outlined block x-small>
              {{
                item.fileStatus === "INC"
                  ? "MERIMEN-INCOMING"
                  : "MERIMEN-CANCELLED"
              }}
            </v-chip>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <!-- <v-tooltip left transition="fab-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2"
                  @click="viewItem(item)"
                >
                  mdi-eye
                </v-icon>
              </template>
              <span> View</span>
            </v-tooltip> -->

            <v-tooltip left transition="fab-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2"
                  v-if="management"
                  @click.stop="updateItem(item)"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span> edit</span>
            </v-tooltip>
            <v-tooltip right transition="fab-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  color="red darken-1"
                  v-if="management"
                  @click.stop="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
              <span> delete</span>
            </v-tooltip>
          </template>

          <!-- <template v-slot:[`body.append`]>
            <v-col>
              <v-btn small class="success" @click="onClick()"
                >Download Excel Template</v-btn
              >
            </v-col>
          </template> -->
        </v-data-table>
      </v-col>
      <v-col>
        <v-btn small class="success" @click="onClick()"
          >Download Excel Template</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DeptDataService from "../../services/DeptDataService";
import SubRefTypeDataService from "../../services/SubDeptDataService";
import InsDataService from "../../services/InsDataService";
import DropboxfileDataService from "../../services/DropboxfileDataService";
import StagesDataService from "../../services/StageDataService";
import HandlerDataService from "../../services/HandlerDataService";

import UploadService from "../../services/UploadDocsService";

import moment from "moment";
import axios from "axios";

export default {
  name: "incomingList",
  data: () => ({
    currentFile: undefined,
    currentDropboxFile: [],
    sampleExcel: {
      url: "../assets/Sample-Bulk-Create.xlsx",
      label: "Download",
    },
    // sampleExcelUrl: "assets/Sample-Bulk-Create.xlsx",
    // sampleExcelLabel: "Download",
    addDialog: false,
    editDialog: false,
    dialogDelete: false,
    dialogAcceptCase: false,
    deleteSelectedDialog: false,
    dropboxfiles: [],
    search: "",
    management: false,
    addcase: false,
    loadTable: true,

    date: null,
    menu: false,
    menu1: false,

    selectItems: [
      { code: "INC", description: "MERIMEN-INCOMING" },
      { code: "CINC", description: "MERIMEN-CANCELLED" },
    ],

    branch: [],
    state: [],
    regions: [],
    refType: [],
    subRefTypes: [],
    stages: [],
    insurer: [],
    handlers: [],

    newItem: {
      id: null,
      refType: "",
      subRefType: "",
      branch: "",
      insurer: "",
      handler: "",
      claimNo: "",
      dateOfAssign: "",
      vehicleNo: "",
      dateOfLoss: "",
      stateOfLoss: "",
      placeOfLoss: "",
      insuredName: "",
      insComment: "",
      createdBy: "",
      fileStatus: "",
      dateStartInv: "",
    },
    editItem: {
      id: null,
      refType: "",
      subRefType: "",
      branch: "",
      insurer: "",
      handler: "",
      claimNo: "",
      dateOfAssign: "",
      vehicleNo: "",
      dateOfLoss: "",
      stateOfLoss: "",
      placeOfLoss: "",
      insuredName: "",
      insComment: "",
      createdBy: "",
      fileStatus: "",
      dateStartInv: "",
    },
    headers: [
      {
        text: "NO.",
        align: "start",
        sortable: false,
        value: "numbering",
      },
      {
        text: "INSURER",
        align: "center",
        value: "insurer",
        sortable: false,
      },
      {
        text: "VEHICLE NO.",
        align: "center",
        value: "vehicleNo",
        sortable: false,
      },
      {
        text: "CLAIM NO.",
        align: "start",
        sortable: false,
        value: "claimNo",
      },
      {
        text: "DATE OF LOSS",
        align: "start",
        sortable: false,
        value: "dateOfLoss",
      },

      {
        text: "STATUS",
        align: "center",
        value: "fileStatus",
        sortable: false,
      },

      {
        text: "ACTIONS",
        align: "center",
        value: "actions",
        sortable: false,
      },
    ],
  }),

  computed: {
    isUploadDisabled() {
      return !this.currentFile;
    },
    addCasefileDisabled() {
      return !this.addcase;
    },

    currentUser() {
      return this.$store.state.auth.user;
    },
    filteredCasefiles() {
      if (
        this.$store.state.auth.user.roles.includes("MANAGER") ||
        this.$store.state.auth.user.roles.includes("ADMIN") ||
        this.$store.state.auth.user.roles.includes("CLERK")
      ) {
        return this.dropboxfiles;
      }

      // else if (
      //   this.$store.state.auth.user.roles.includes("BRANCHCLERK") ||
      //   this.$store.state.auth.user.roles.includes("BRANCHMANAGER")
      // )
      //  {
      //   return this.dropboxfiles.filter(
      //     (x) =>
      //       this.$store.state.auth.user.dept.includes(x.refType) &&
      //       this.$store.state.auth.user.branches.includes(x.branch)
      //   );
      // }
      // else if (this.$store.state.auth.user.roles.includes("ADJUSTER")) {
      // return this.dropboxfiles.filter(
      //   (x) =>
      //     this.$store.state.auth.user.dept.includes(x.refType) &&
      //     this.$store.state.auth.user.branches.includes(x.branch) &&
      //     this.$store.state.auth.user.id.toString().includes(x.adjuster)
      // );
      // return [];
      //  }
      // else if (this.$store.state.auth.user.roles.includes("EDITOR")) {
      // return this.dropboxfiles.filter(
      //   (x) =>
      //     this.$store.state.auth.user.dept.includes(x.refType) &&
      //     this.$store.state.auth.user.branches.includes(x.branch) &&
      //     this.$store.state.auth.user.id.toString().includes(x.editor)
      // );
      // return [];
      // }
      // return this.dropboxfiles;
      else return [];
    },
    isAddFileButtonDisabled() {
      return !(
        this.newItem.refType &&
        this.newItem.subRefType &&
        this.newItem.insurer &&
        this.newItem.claimNo &&
        this.newItem.dateOfAssign &&
        this.newItem.dateOfLoss
      );
    },
    isEditFileButtonDisabled() {
      return !(
        this.editItem.refType &&
        this.editItem.subRefType &&
        this.editItem.insurer &&
        this.editItem.claimNo &&
        this.editItem.dateOfAssign &&
        this.editItem.dateOfLoss
      );
    },
    computedDateFormattedMomentjs() {
      return this.date ? moment(this.date).format("DD/MM/YY") : "";
    },
  },

  watch: {
    addDialog(val) {
      val || this.closeAddDialog();
    },
    editDialog(val) {
      val || this.closeEditDialog();
    },
    dialogDelete(val) {
      val || this.closeDeleteDialog();
    },
    acceptCase(val) {
      val || this.closeAcceptCase();
    },
  },

  methods: {
    hideDeleteDialog() {
      this.deleteSelectedDialog = false;
    },
    currentItems: function (value) {
      return (this.currentDropboxFile = value);
    },
    onClick() {
      axios({
        // url: "http://localhost:8080/docs/Sample-Bulk-Create.xlsx", // Development
        url: "/docs/Sample-Bulk-Create.xlsx", // Production
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fURL = document.createElement("a");

        fURL.href = fileURL;
        // fURL.setAttribute("download", "file.png");
        fURL.setAttribute("download", "Sample-Bulk-Create.xlsx");
        document.body.appendChild(fURL);

        fURL.click();
      });
    },

    selectFile(file) {
      this.currentFile = file;
    },
    // filterStages(item) {
    //   return this.stages.filter((stage) => stage.stageCode === item);
    // },

    filterRegions(item) {
      return this.regions.filter((region) => region.branchId === item);
    },
    filtersubRefType(item) {
      return this.subRefTypes.filter(
        (subRefType) => subRefType.deptId === item
      );
    },
    filterHandler(item) {
      return this.handlers.filter((handler) => handler.insId === item);
    },
    filterStates(item) {
      return this.branch.filter((branch) => branch.id === item);
    },
    filterIns(item) {
      return this.insurer.filter((insurer) => insurer.id === item);
    },
    filterDept(item) {
      return this.refType.filter((dept) => dept.id === item);
    },
    filterSubDept(item) {
      return this.subRefTypes.filter((subDept) => subDept.id === item);
    },
    checkManagement() {
      if (
        this.$store.state.auth.user.roles.includes(`ADMIN`) ||
        this.$store.state.auth.user.roles.includes(`MANAGER`)
      ) {
        this.management = true;
      }
    },
    checkAddCase() {
      if (
        this.$store.state.auth.user.roles.includes(`ADMIN`) ||
        this.$store.state.auth.user.roles.includes(`MANAGER`) ||
        this.$store.state.auth.user.roles.includes(`BRANCHMANAGER`) ||
        this.$store.state.auth.user.roles.includes(`BRANCHCLERK`)
      ) {
        this.addcase = true;
      }
    },
    viewItem(id) {
      this.$router.push({
        name: "dropboxDetail",
        params: { id: id.id },
      });
    },
    formatDate(value) {
      return moment(value).format("DD/MM/YYYY");
    },
    yearOnly(value) {
      // return moment(value).format("YYYY");
      return moment(value).format("YY");
    },
    retrieveCasefiles() {
      DropboxfileDataService.getAllInc()
        .then((response) => {
          this.dropboxfiles = response.data;
          this.loadTable = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // getDisplayCasefile(casefile) {
    //   return {
    //     id: casefile.id,
    //     name:
    //       casefile.name.length > 30
    //         ? casefile.name.substr(0, 30) + "..."
    //         : casefile.name,
    //     id:
    //       casefile.id.length > 30
    //         ? casefile.id.substr(0, 30) + "..."
    //         : casefile.id,
    //   };
    // },
    refreshList() {
      this.retrieveCasefiles();
    },
    retrieveDept() {
      DeptDataService.getAll()
        .then((response) => {
          this.refType = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveSubRefType() {
      SubRefTypeDataService.getAll()
        .then((response) => {
          this.subRefTypes = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveStages() {
      StagesDataService.getAll()
        .then((response) => {
          this.stages = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveIns() {
      InsDataService.getAll()
        .then((response) => {
          this.insurer = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveHandlers() {
      HandlerDataService.getAll()
        .then((response) => {
          this.handlers = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    updateItem(item) {
      this.editItem = Object.assign({}, item);
      this.editDialog = true;
    },
    deleteItem(item) {
      this.editItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    closeAddDialog() {
      this.addDialog = false;
      this.$nextTick(() => {
        this.newItem = Object.assign({});
        // this.editedIndex = -1;
      });
    },
    closeEditDialog() {
      this.editDialog = false;
    },
    closeDeleteDialog() {
      this.dialogDelete = false;
      // this.$nextTick(() => {
      //   this.editedItem = Object.assign({}, this.defaultItem);
      //   this.editedIndex = -1;
      // });
    },
    addCasefile() {
      var data = {
        refType: this.newItem.refType,
        subRefType: this.newItem.subRefType,
        insurer: this.newItem.insurer,
        handler: this.newItem.handler,
        claimNo: this.newItem.claimNo,
        dateOfAssign: this.newItem.dateOfAssign,
        vehicleNo: this.newItem.vehicleNo,
        dateOfLoss: this.newItem.dateOfLoss,
        fileStatus: `INC`,
        caseFrom: `ORGANIC`,
      };

      DropboxfileDataService.create(data)
        .then((response) => {
          this.newItem.id = response.data.id;
          this.closeAddDialog();
          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "success",
            text:
              (response && response.data && response.data.message) ||
              toString(),
          });
          this.refreshList();
        })
        .catch((e) => {
          this.$store.dispatch("setSnackbar", {
            showing: true,
            text:
              (e.response && e.response.data && e.response.data.message) ||
              e.message ||
              toString(),
          });
        });

      this.closeAddDialog();
    },
    editCasefile() {
      DropboxfileDataService.update(this.editItem.id, this.editItem)
        .then((response) => {
          this.closeEditDialog();
          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "warning",
            text:
              (response && response.data && response.data.message) ||
              toString(),
          });
          this.refreshList();
        })
        .catch((e) => {
          console.log(e);
        });

      this.closeEditDialog();
    },

    deleteItemConfirm() {
      DropboxfileDataService.delete(this.editItem.id)
        .then((response) => {
          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "error",
            text:
              (response && response.data && response.data.message) ||
              toString(),
          });

          this.refreshList();
        })
        .catch((e) => {
          console.log(e);
        });

      this.closeDeleteDialog();
    },

    deleteSelectedItem() {
      const selectedItemIds = this.currentDropboxFile.map((item) => item.id);

      DropboxfileDataService.deleteids({ data: { ids: selectedItemIds } })

        .then((response) => {
          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "error",
            text:
              (response && response.data && response.data.message) ||
              toString(),
          });

          this.refreshList();
          this.hideDeleteDialog();
        })
        .catch((error) => {
          console.error(error);
          this.hideDeleteDialog();
        });
    },

    uploadConfirm() {
      if (!this.currentFile) {
        this.message = "Please select a file!";
        return;
      }
      if (this.currentFile) {
        this.message = "";
        UploadService.uploadExcel(this.currentFile, (event) => {
          this.progress = Math.round((100 * event.loaded) / event.total);
        })
          .then((response) => {
            this.$store.dispatch("setSnackbar", {
              showing: true,
              color: "success",
              text:
                (response && response.data && response.data.message) ||
                toString(),
            });
            this.currentFile = undefined;
            this.refreshList();

            return UploadService.getFiles();
          })

          .catch((e) => {
            this.progress = 0;
            // this.message = "Could not upload the file!";
            this.uploadDialog = false;
            this.$store.dispatch("setSnackbar", {
              showing: true,
              color: "error",
              // text: "Could not upload the file!",
              text:
                (e.response && e.response.data && e.response.data.message) ||
                e.message ||
                toString(),
            });
            this.currentFile = undefined;
          });
      }
    },
  },
  mounted() {
    this.retrieveDept();
    this.retrieveSubRefType();
    this.retrieveStages();
    this.retrieveIns();
    this.retrieveCasefiles();
    this.checkManagement();
    this.checkAddCase();
    this.retrieveHandlers();
  },
};
</script>
