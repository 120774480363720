<template>
  <v-container fluid style="width: 80%" class="text-center">
    <template>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center">DESCRIPTION</th>
              <th class="text-center">NUMBER OF FILES</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="stage in stages"
              :key="stage.id"
              style="cursor: pointer"
              @click="
                viewItem(
                  filterCase(stage.stageCode)[0].fileStatus,
                  filterCase(stage.stageCode)[0].refType
                )
              "
            >
              <td>
                {{ stage.name }}
              </td>

              <td v-if="filterCase(stage.stageCode).length">
                {{ filterCase(stage.stageCode).length }}
              </td>
              <td v-else>0</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
  </v-container>
</template>

<script>
import CasefileDataService from "../../services/CasefileDataService";
import StageDataService from "../../services/StageDataService";
// import DeptDataService from "../../services/DeptDataService";

export default {
  name: "refTabDetail",
  components: {},

  data() {
    return {
      casefiles: [],
      stages: [],
      // departments: [],
    };
  },
  mounted() {
    this.retrievecasefiles(this.$route.params.id);
    // this.retrieveDepts(this.$route.params.id);
    this.retrieveStages();
  },

  computed: {
    filteredCasefiles() {
      if (
        this.$store.state.auth.user.roles.includes("MANAGER") ||
        this.$store.state.auth.user.roles.includes("ADMIN")
      ) {
        return this.casefiles;
      } else if (
        this.$store.state.auth.user.roles.includes("BRANCHCLERK") ||
        this.$store.state.auth.user.roles.includes("BRANCHMANAGER")
      ) {
        return this.casefiles.filter(
          (x) =>
            this.$store.state.auth.user.dept.includes(x.refType) &&
            this.$store.state.auth.user.branches.includes(x.branch)
        );
      } else if (this.$store.state.auth.user.roles.includes("ADJUSTER")) {
        return this.casefiles.filter(
          (x) =>
            this.$store.state.auth.user.dept.includes(x.refType) &&
            this.$store.state.auth.user.branches.includes(x.branch) &&
            this.$store.state.auth.user.id.toString().includes(x.adjuster)
        );
      } else if (this.$store.state.auth.user.roles.includes("EDITOR")) {
        return this.casefiles.filter(
          (x) =>
            this.$store.state.auth.user.dept.includes(x.refType) &&
            this.$store.state.auth.user.branches.includes(x.branch) &&
            this.$store.state.auth.user.id.toString().includes(x.editor)
        );
      } else return this.casefiles;
    },
  },

  methods: {
    filterCase(item) {
      return this.filteredCasefiles.filter((a) => a.fileStatus === item);
    },
    retrievecasefiles(id) {
      CasefileDataService.getAllRefType(id)
        .then((response) => {
          this.casefiles = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    retrieveStages() {
      StageDataService.getAll()
        .then((response) => {
          this.stages = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    // retrieveDepts(id) {
    //   DeptDataService.get(id)
    //     .then((response) => {
    //       this.departments = response.data;
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //     });
    // },

    viewItem(fileStatus, refType) {
      this.$router.push({
        name: "detail-by-ref",
        // params: { fileStatus: fileStatus },
        params: { fileStatus: fileStatus, refType: refType },
      });
    },
  },
};
</script>
