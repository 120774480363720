import { render, staticRenderFns } from "./flag.vue?vue&type=template&id=c3d64826&scoped=true&"
import script from "./flag.vue?vue&type=script&lang=js&"
export * from "./flag.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3d64826",
  null
  
)

export default component.exports