<template>
  <div>
    <v-card-title class="pb-0">CLAIM DETAILS</v-card-title>
    <v-divider></v-divider>
    <v-data-table
      dense
      :headers="headers"
      :items="claims"
      class="elevation-1"
      @click:row="(item) => showItem(item)"
      style="cursor: pointer"
    >
      <template v-slot:top>
        <v-dialog v-model="approveDialog" max-width="500px" persistent>
          <v-card>
            <v-card-title class="justify-center text-h5 grey lighten-2"
              >Claim Details</v-card-title
            >
            <v-card-text>
              <v-container>
                <v-form :disabled="management === false">
                  <v-row>
                    <v-col
                      cols="12"
                      class="display-3 text-start font-weight-bold"
                    >
                      MYR {{ newTotal }}
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col class="subtitle-1 text-start" cols="6"
                      >Travelling
                    </v-col>
                    <v-col class="subtitle-1 text-center" cols="6"
                      >{{ selectedClaim.travMileage }}
                    </v-col>

                    <v-col class="subtitle-1 text-start" cols="6"
                      >Additional
                    </v-col>
                    <v-col class="subtitle-1 text-center" cols="6"
                      >{{ selectedClaim.additional }}
                    </v-col>

                    <v-col class="subtitle-1 text-start" cols="6"
                      >SD & Stamps
                    </v-col>
                    <v-col class="subtitle-1 text-center" cols="6"
                      >{{ selectedClaim.sdAndStamps }}
                    </v-col>

                    <v-col class="subtitle-1 text-start" cols="6"
                      >Medical Reprot
                    </v-col>
                    <v-col class="subtitle-1 text-center" cols="6"
                      >{{ selectedClaim.medicalReport }}
                    </v-col>

                    <v-col class="subtitle-1 text-start" cols="6"
                      >Police Document
                    </v-col>
                    <v-col class="subtitle-1 text-center" cols="6"
                      >{{ selectedClaim.policeDoc }}
                    </v-col>

                    <v-col class="subtitle-1 text-start" cols="6"
                      >JPJ Document
                    </v-col>
                    <v-col class="subtitle-1 text-center" cols="6"
                      >{{ selectedClaim.jpjDoc }}
                    </v-col>

                    <v-col class="subtitle-1 text-start" cols="6"
                      >Miscellaneous
                    </v-col>
                    <v-col class="subtitle-1 text-center" cols="6"
                      >{{ selectedClaim.misc }}
                    </v-col>

                    <v-col class="subtitle-1 text-start" cols="6"
                      >Typing
                    </v-col>
                    <v-col class="subtitle-1 text-center" cols="6"
                      >{{ selectedClaim.typing }}
                    </v-col>

                    <v-col
                      v-if="selectedClaim.isApprovedComment"
                      class="subtitle-1 text-start"
                      cols="12"
                      >Comment: {{ selectedClaim.isApprovedComment }}
                    </v-col>

                    <v-card-actions>
                      <v-btn outlined small tile @click="closeApproveDialog"
                        >CLOSE</v-btn
                      >
                    </v-card-actions>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </template>

      <template slot="item.numbering" slot-scope="props">
        {{ props.index + 1 }}
      </template>
      <template v-slot:[`item.caseId`]="{ item }">
        <tr class="d-flex">
          AA

          <div
            v-for="(dept, index) in filterDept(caseState.casefile.refType)"
            :key="index"
          >
            /{{ dept.name
            }}<span
              v-for="(subDept, index) in filterSubDept(
                caseState.casefile.subRefType
              )"
              :key="index"
              >/{{ subDept.subCode }}</span
            ><span
              >/{{ item.caseId }}/{{
                yearOnly(caseState.casefile.createdAt)
              }}</span
            ><span
              v-for="branch in filterBranches(caseState.casefile.branch)"
              :key="branch.id"
              >/{{ branch.brCode }}</span
            >
          </div>
        </tr>
      </template>
      <template v-slot:[`item.adjId`]="{ item }">
        <div v-for="adjuster in filterUser(item.adjId)" :key="adjuster.id">
          {{ adjuster.username }}
        </div>
      </template>
      <template v-slot:[`item.sumAll`]="{ item }">
        <span>MYR {{ total(item.id) }}</span>
      </template>

      <template v-slot:[`item.createdAt`]="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>
      <template v-slot:[`item.isApproved`]="{ item }">
        {{ getClaimStatus(item.isApproved) }}
      </template>

      <!-- <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left transition="fab-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              v-bind="attrs"
              v-on="on"
              class="mr-2"
              @click="showItem(item)"
            >
              mdi-eye
            </v-icon>
          </template>
          <span> View</span>
        </v-tooltip>
      </template> -->
    </v-data-table>
  </div>
</template>

<script>
import ClaimDataService from "../../services/ClaimDataService";
import { mapState } from "vuex";
import moment from "moment";

export default {
  name: "detailClaims",
  props: ["branches", "members", "refType", "subRefTypes"],
  data: () => ({
    management: false,
    dialog: false,
    dialogDelete: false,
    approveDialog: false,
    claims: [],
    selectedClaim: [],

    search: "",
  }),

  components: {},

  computed: {
    ...mapState(["caseState"]),
    headers() {
      return [
        {
          text: "NO.",
          align: "start",
          sortable: false,
          value: "numbering",
        },
        {
          text: "AASB REFERENCE",
          align: "start",
          sortable: true,
          value: "caseId",
        },
        {
          text: "ADJUSTER",
          align: "start",
          sortable: false,
          value: "adjId",
        },

        {
          text: "SUBMITTED ON",
          align: "start",
          sortable: false,
          value: "createdAt",
        },

        {
          text: "CLAIMS TOTAL",
          align: "start",
          sortable: true,
          value: "sumAll",
        },
        {
          text: "STATUS",
          align: "start",
          sortable: true,
          value: "isApproved",
        },
      ];
    },

    newTotal() {
      const total =
        this.selectedClaim.travMileage * 1 +
        this.selectedClaim.additional * 1 +
        this.selectedClaim.sdAndStamps * 1 +
        this.selectedClaim.medicalReport * 1 +
        this.selectedClaim.policeDoc * 1 +
        this.selectedClaim.jpjDoc * 1 +
        this.selectedClaim.misc * 1 +
        this.selectedClaim.typing * 1;
      return total.toFixed(2);
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    getClaimStatus(item) {
      if (item == null) return "PENDING";
      else if (item == 0) return "NEW";
      else if (item == 1) return "QUERY";
      else if (item == 2) return "APPROVED";
      else return "";
    },
    checkManagement() {
      if (
        this.$store.state.auth.user.roles.includes(`ADMIN`) ||
        this.$store.state.auth.user.roles.includes(`MANAGER`)
      ) {
        this.management = true;
      }
    },
    total(item) {
      const thisclaim = this.claims.filter((claim) => claim.id === item);
      const total = thisclaim.reduce(
        (sum, row) =>
          sum +
          row.travMileage * 1 +
          row.additional * 1 +
          row.sdAndStamps * 1 +
          row.medicalReport * 1 +
          row.policeDoc * 1 +
          row.jpjDoc * 1 +
          row.misc * 1 +
          row.typing * 1,
        0
      );
      return total.toFixed(2);
    },

    formatDate(value) {
      return moment(value).format("DD/MM/YYYY LT");
    },
    yearOnly(value) {
      return moment(value).format("YY");
    },
    // filterCase(item) {
    //   console.log(item);
    //   console.log(this.caseState.casefile);
    //   let newCase = this.caseState;
    //   let result = newCase.reduce((a) => a);
    //   // return result;
    //   console.log(result);
    //   // return this.caseState.filter((casefile) => casefile.id === item);
    // },
    filterDept(item) {
      return this.refType.filter((dept) => dept.id === item);
    },
    filterSubDept(item) {
      return this.subRefTypes.filter((subDept) => subDept.id === item);
    },
    filterBranches(item) {
      return this.branches.filter((branch) => branch.id === item);
    },
    filterUser(item) {
      return this.members.filter((member) => member.id === item);
    },

    retrieveClaims() {
      ClaimDataService.getAll()
        .then((response) => {
          // this.claims = response.data;
          this.claims = response.data.filter(
            (claim) => claim.caseId === this.caseState.casefile.id
          );
        })
        .catch((e) => {
          console.log(e);
        });
    },

    refreshList() {},

    showItem(item) {
      this.selectedClaim = Object.assign({}, item);
      this.approveDialog = true;
    },
    closeApproveDialog() {
      this.selectedClaim = Object.assign({});
      this.approveDialog = false;
    },
  },
  mounted() {
    this.checkManagement();
    this.retrieveClaims();
  },
};
</script>
