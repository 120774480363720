<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on: tooltip }">
        <v-btn
          :disabled="completeEditingDisabled"
          class="success mr-1"
          fab
          x-small
          v-on="{ ...tooltip }"
          @click="completeEdi()"
        >
          <v-icon>mdi-alarm-check</v-icon>
        </v-btn>
      </template>
      <span>COMPLETE EDITING</span>
    </v-tooltip>

    <!-- THE DIALOG BEGINS HERE -->
    <v-dialog v-model="dialogCompleteEdi" max-width="750px">
      <v-card>
        <v-card-title class="justify-center text-h5 grey lighten-2">
          <span class="text-h5">COMPLETE EDITING</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-card width="100%" class="mt-3 mb-3 text-start">
                <v-card-title
                  >I have completed editing the case report</v-card-title
                >
                <!-- <v-list subheader two-line flat dense>
                  <v-subheader class="text-h6">I confirm that:</v-subheader>

                  <v-list-item-group multiple>
                    <v-list-item>
                      <template v-slot:default="{ active }">
                        <v-list-item-action>
                          <v-checkbox
                            :input-value="active"
                            color="primary"
                            v-model="checkboxCompEdi"
                          ></v-checkbox>
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title>Editing</v-list-item-title>
                          <v-list-item-subtitle
                            >I have completed editing the case
                            report.</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                    <v-divider inset></v-divider>
                    <v-list-item>
                      <template v-slot:default="{ active }">
                        <v-list-item-action>
                          <v-checkbox
                            :input-value="active"
                            color="primary"
                            v-model="checkboxCompEdi1"
                          ></v-checkbox>
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title>Documents</v-list-item-title>
                          <v-list-item-subtitle
                            >I have uploaded the edited case
                            report.</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </v-list-item-group>
                </v-list> -->
                <v-card-actions>
                  <v-btn
                    tile
                    dark
                    small
                    @click="closeCompleteEdiDialog"
                    class="mt-3 mr-2 error"
                  >
                    Cancel
                  </v-btn>
                  <v-btn tile small class="mt-3 success" @click="compEditing"
                    >Submit</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CasefileDataService from "../../services/CasefileDataService";
import MailDataService from "../../services/MailDataService";

import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "compEditing",
  props: ["branches", "insurer", "refType", "subRefTypes", "clerks", "members"],
  components: {},

  data() {
    return {
      dialogCompleteEdi: false,
      // checkboxCompEdi: false,
      // checkboxCompEdi1: false,
    };
  },

  computed: {
    completeEditingDisabled() {
      return !(
        this.caseState.casefile.fileStatus == `EDI` &&
        this.caseState.casefile.editor == this.$store.state.auth.user.id
      );
    },

    ...mapState(["caseState"]),

    // isFileButtonDisabled() {
    //   return !(this.checkboxCompEdi && this.checkboxCompEdi1);
    // },
  },
  watch: {
    completeEdi(val) {
      val || this.closeCompleteEdiDialog();
    },
  },

  methods: {
    // moment: function (date) {
    //   return moment(date);
    // },

    filterBranchName(item) {
      if (!item) return "TBA";
      const filteredbranch = this.branches.filter(
        (branch) => branch.id === item
      );
      return filteredbranch[0].name;
    },
    filterBranches(item) {
      return this.branches.filter((branch) => branch.id === item);
    },

    findBrcode(item) {
      if (!item) return "TBA";
      const filteredbranch = this.branches.filter(
        (branch) => branch.id === item
      );
      return filteredbranch.map((b) => b.brCode);
    },

    filterDept(item) {
      return this.refType.filter((dept) => dept.id === item);
    },
    filterSubDept(item) {
      return this.subRefTypes.filter((subRef) => subRef.id === item);
    },
    filterIns(item) {
      return this.insurer.filter((insurer) => insurer.id === item);
    },
    filterManagerkByBranch(item) {
      const filter1 = this.clerks.filter(
        (a) =>
          a.branches.some((b) => b.brCode == item) &&
          a.roles.some(
            (c) => c.roleCode === "manager" || c.roleCode === "branchmanager"
          ) &&
          a.active
      );
      const email = filter1.map((d) => d.email);
      return email;
    },

    filterPicID(item) {
      if (!item) return "TBA";
      const filteredDept = this.refType.filter((dept) => dept.id === item);
      // console.log(filteredDept);
      return filteredDept[0].picID;
    },
    findUserEmail(item) {
      const user = this.members.filter((member) => member.id === item);
      return user[0] ? user[0].email : "sys.admin@associatedadjusters.com";
      // return user.map((b) => b.email);
    },

    completeEdi() {
      this.dialogCompleteEdi = true;
    },

    compEditing() {
      CasefileDataService.update(
        this.caseState.casefile.id,
        this.caseState.casefile,
        (this.caseState.casefile.fileStatus = `PAPP`),
        (this.caseState.casefile.dateEndEdi = moment().format())
        // (this.caseState.casefile.registerBy = this.$store.state.auth.user.id),
        // (this.caseState.casefile.dateOfReg = moment().format())
      )
        .then((response) => {
          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "warning",
            text:
              (response && response.data && response.data.message) ||
              toString(),
          });
        })

        .then(() => {
          const assignedBranch = this.caseState.casefile.branch;
          const branchManager = this.filterManagerkByBranch(
            this.findBrcode(assignedBranch)
          );
          // console.log(JSON.stringify(branchManager));
          // const assignedDept = this.caseState.casefile.refType;
          // const personInCharge = this.findUserEmail(this.filterPicID(assignedDept));
          // console.log(JSON.stringify(personInCharge));

          //  recipient: this.findUserEmail(this.filterPicID(assignedDept)),

          var dataEmail = {
            id: this.caseState.casefile.id,
            refType: this.filterDept(this.caseState.casefile.refType)[0]
              .description,
            refTypeCode: this.filterDept(this.caseState.casefile.refType)[0]
              .name,
            subRefType: this.filterSubDept(
              this.caseState.casefile.subRefType
            )[0].subCodeDesc,
            subRefCode: this.filterSubDept(
              this.caseState.casefile.subRefType
            )[0].subCode,
            branch: this.filterBranchName(this.caseState.casefile.branch),
            branchCode: this.filterBranches(this.caseState.casefile.branch)[0]
              .brCode,
            insurer: this.filterIns(this.caseState.casefile.insurer)[0].name,
            vehicleNo: this.caseState.casefile.vehicleNo
              .toLowerCase()
              .replace(/\s/g, ""),
            recipient: branchManager,
            // recipient: branchManager + "," + personInCharge,

            // recipient: this.filterManagerkByBranch(
            //   this.findBrcode(assignedBranch)
            // ),
            createdBy: this.$store.state.auth.user.id,
          };
          MailDataService.managerApproval(dataEmail).then((response) => {
            this.$store.dispatch("setSnackbar", {
              showing: true,
              color: "warning",
              text:
                (response && response.data && response.data.message) ||
                toString(),
            });
          });
          // console.log(JSON.stringify(dataEmail.recipient));
          this.dataEmail = "";
        })

        .catch((e) => {
          console.log(e);
        });
      this.closeCompleteEdiDialog();
    },

    closeCompleteEdiDialog() {
      this.dialogCompleteEdi = false;
      this.$nextTick(() => {
        // this.caseState.casefile.fileStatus = "ADAC";
      });
    },
  },
};
</script>
