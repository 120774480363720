import http from "../http-common";
class UploadDocsService {
  upload(formData, onUploadProgress) {
    return http.post("/docs/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }

  uploadPhoto(file, onUploadProgress) {
    let formData = new FormData();
    formData.append("file", file);
    return http.post(
      `/members/upload`,
      formData,

      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
      }
    );
  }

  uploadExcel(file, onUploadProgress) {
    let formData = new FormData();
    formData.append("file", file);
    return http.post(
      `/dropboxfiles/upload`,
      formData,

      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
      }
    );
  }
  getFiles() {
    return http.get("../docs");
  }
  getPhotos() {
    return http.get("../files");
  }
}
export default new UploadDocsService();
