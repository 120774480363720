<template>
  <!--Acknowledgement Letter Start -->
  <v-dialog v-model="acknowledgement" width="800px">
    <template #activator="{ on: dialog }">
      <v-tooltip bottom>
        <template #activator="{ on: tooltip }">
          <v-btn v-on="{ ...tooltip, ...dialog }" icon
            ><v-icon>mdi-file-pdf-box</v-icon></v-btn
          >
        </template>
        <span>Acknowledgement Letter</span>
      </v-tooltip>
    </template>

    <v-card>
      <!-- <acknowledgement /> -->
      <div class="ma-5">
        <div class="pt-2" align="end">
          <v-btn outlined small tile @click="closeDownloadAck" class="mr-1">
            Cancel
          </v-btn>
          <download-button dom="#letter" :name="vehicleNumber" />
        </div>
        <div id="letter">
          <div class="title01">ASSOCIATED</div>
          <div class="title01">
            ADJUSTERS SDN BHD<span class="caption01">( CO. NO. 30757-A)</span>
          </div>
          <v-row no-gutters>
            <v-col cols="2">
              <div class="para01">YOUR REF</div>
            </v-col>
            <v-col>
              <div class="para01">: {{ casefile.claimNo }}</div>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="2">
              <div class="para01">OUR REF</div>
            </v-col>
            <v-col>
              <div class="para01 d-flex">
                : AA/
                <span
                  v-for="(dept, index) in filterDept(casefile.refType)"
                  :key="index"
                >
                  {{ dept.name
                  }}<span
                    v-for="(subDept, index) in filterSubDept(
                      casefile.subRefType
                    )"
                    :key="index"
                    >/{{ subDept.subCode }}</span
                  > </span
                >/{{ casefile.id }}/{{ yearOnly(casefile.createdAt) }}/<span
                  v-for="branch in filterBranches(casefile.branch)"
                  :key="branch.id"
                  >{{ branch.brCode }}
                </span>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="2">
              <div class="para01">DATE</div>
            </v-col>
            <v-col>
              <div class="para01">
                <!-- : <span>{{ moment().format("DD/MM/YYYY") }}</span> -->
                : {{ formatDate(casefile.createdAt) }}
              </div>
            </v-col>
          </v-row>

          <div class="para02">THE CLAIMS MANAGER,</div>
          <div
            v-for="(insurer, index) in filterIns(casefile.insurer)"
            :key="index"
          >
            <span class="para03">{{ insurer.name }}</span>
            <br />
            <span class="para04">{{ insurer.insAddLine01 }}</span>
            <br />
            <span class="para04">{{ insurer.insAddLine02 }}</span>
            <br />
            <span class="para04">{{ insurer.insPostcode }}</span> &nbsp;
            <span class="para04">{{ insurer.insTown }}</span>
            <br />
            <span class="para04">{{ insurer.insState }}</span>
          </div>

          <div class="para05">DEAR SIR/ MADAM,</div>

          <div class="para03">RE : ACKNOWLEDGEMENT OF ASSIGNMENT</div>
          <br />
          <v-row no-gutters>
            <v-col cols="3">
              <div class="para06">VEHICLE NO</div>
            </v-col>
            <v-col>
              <div v-if="casefile.vehicleNo" class="para06">
                : {{ casefile.vehicleNo.toUpperCase() }}
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="3">
              <div class="para06">DATE OF LOSS</div>
            </v-col>
            <v-col>
              <div class="para06">: {{ formatDate(casefile.dateOfLoss) }}</div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="3">
              <div class="para06">PLACE OF LOSS</div>
            </v-col>
            <v-col>
              <div v-if="casefile.placeOfLoss" class="para06">
                : {{ casefile.placeOfLoss }},
                <span
                  v-for="state in filterStates(casefile.stateOfLoss)"
                  :key="state.id"
                >
                  {{ state.name }}
                </span>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="3">
              <div class="para06">INSURED</div>
            </v-col>
            <v-col>
              <div class="para06">: {{ casefile.insuredName }}</div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="3">
              <div class="para06">ASSIGNMENT DATE</div>
            </v-col>
            <v-col>
              <div class="para06">
                : {{ formatDate(casefile.dateOfAssign) }}
              </div>
            </v-col>
          </v-row>
          <!-- <v-row no-gutters>
            <v-col cols="3">
              <div class="para06">VEHICLE MODEL</div>
            </v-col>
            <v-col>
              <div class="para06 red--text">: VEHICLE MODEL</div>
            </v-col>
          </v-row> -->
          <v-divider class="para07"></v-divider>

          <p class="para11">
            We acknowledge receipt of your assignment pertaining to the above
            matter.
          </p>
          <p class="para11">
            We thank you for your support and shall forward our report in
            connection with the above as soon as we finalised the same.
          </p>
          <p class="para11">In the meantime, kindly bear with us a while.</p>
          <p class="para10">
            &quot; Ensuring you of our best services at all time &quot;.
          </p>
          <p class="para04">Thank you.</p>

          <p class="para08">ASSOCIATED ADJUSTERS SDN BHD</p>
          <div class="para09">
            *No signature required as it is computer generated.
          </div>
          <br />

          <v-row no-gutters>
            <v-col cols="1">
              <div class="para12">HEAD OFFICE</div>
            </v-col>
            <v-col>
              <div class="para12">
                : C-9-1, BLOCK C, 9TH FLOOR, MEGAN PHILEO AVENUE II, NO.12,
                JALAN YAP KWAN SENG, 50450 KUALA LUMPUR
              </div>
              <div class="para12">
                &nbsp; TEL : 03 - 21 666 330 (5 LINES) FAX : 03 - 21 666 110
                E-MAIL : info@associatedadjusters.com
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="1">
              <div class="para12">JOHOR BAHRU</div>
            </v-col>
            <v-col>
              <div class="para12">
                : O.53-03,BLOK B, JALAN PERMAS 11, BANDAR BARU PERMAS JAYA,
                81750 JOHOR BAHRU, JOHOR DARUL TAKZIM.
              </div>
              <div class="para12">
                &nbsp; TEL : 07 - 386 1165 FAX : 07 - 386 1175 E-MAIL :
                johor@associatedadjusters.com
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="1">
              <div class="para12">SG. PETANI</div>
            </v-col>
            <v-col>
              <div class="para12">
                : NO.99A, 1ST FLOOR, JALAN PENGKALAN, TAMAN PEKAN BARU, 08000
                SG. PETANI, KEDAH DARUL AMAN.
              </div>
              <div class="para12">
                &nbsp; TEL : 04 - 4210100 FAX : 04 - 4252100.
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="1">
              <div class="para12">KUANTAN</div>
            </v-col>
            <v-col>
              <div class="para12">
                : NO.25A,2ND FLOOR, JALAN TUN ISMAIL 9, JALAN TUN ISMAIL, 25000
                KUANTAN, PAHANG DARUL MAKMUR.
              </div>
              <div class="para12">
                &nbsp; TEL : 09 - 5138001 FAX : 09 - 5148001
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="1">
              <div class="para12">KOTA BHARU</div>
            </v-col>
            <v-col>
              <div class="para12">
                : Lot 235, 1st Floor, Kompleks Yakin, Jalan Gajah Mati, 15000
                Kota Bahru, Kelantan Darul Naim.
              </div>
              <div class="para12">&nbsp; TEL : 09-7482002 FAX : 09-7483008</div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="1">
              <div class="para12">MELAKA:</div>
            </v-col>
            <v-col>
              <div class="para12">
                : NO.9-1,Jalan KSB 16, Taman Kota Syahbandar 75200, Melaka
              </div>
              <div class="para12">&nbsp; TEL: 06-2644389</div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="1">
              <div class="para12">KOTA KINABALU</div>
            </v-col>
            <v-col>
              <div class="para12">
                : B-3-6,3rd Floor, Tanjung Aru Plaza, Jalan Mat Salleh, 88100
                Kota Kinabalu, Sabah.
              </div>
              <div class="para12">
                &nbsp; Tel : 088-261 330 Fax : 088-267 3307
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="1">
              <div class="para12">KUCHING</div>
            </v-col>
            <v-col>
              <div class="para12">
                : 2ND FLOOR, NO 9 BANGUNAN AL-IDRUS COMMERCIAL CENTRE, JALAN
                SATOK, 93400 KUCHING SARAWAK.
              </div>
              <div class="para12">&nbsp; Tel : 082-236330 Fax : 082-237330</div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="1">
              <div class="para12">IPOH</div>
            </v-col>
            <v-col>
              <div class="para12">
                : No.3B GreenTown Utama Jalan Dato Seri Ahmad Said,30450, Ipoh
                Perak.
              </div>
              <div class="para12">&nbsp; Tel : 05-241 13580</div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="1">
              <div class="para12">PENANG</div>
            </v-col>
            <v-col>
              <div class="para12">
                : No.88-01-03 Second Floor, Elite Commercial Centre, Jalan
                Jelutong, 11600 Georgetown Penang.
              </div>
              <div class="para12">&nbsp; Tel : 04-288 9100</div>
            </v-col>
          </v-row>
        </div>
        <br />
      </div>
    </v-card>
  </v-dialog>
  <!-- Acknowledgement Letter End -->
  <!-- Action Row Continue -->
</template>
<script>
import DownloadButton from "../../components/DownloadButton";

import moment from "moment";

export default {
  name: "acknowledgement",
  props: [
    "casefile",
    "refType",
    "subRefTypes",
    "insurer",
    "branches",
    "states",
  ],
  components: {
    DownloadButton,
  },
  data() {
    return {
      acknowledgement: false,
    };
  },
  computed: {
    vehicleNumber() {
      return this.casefile.vehicleNo;
    },
  },

  methods: {
    moment: function () {
      return moment();
    },
    yearOnly(value) {
      // return moment(value).format("YYYY");
      return moment(value).format("YY");
    },
    formatDate: function (date) {
      return moment(date).format("DD/MM/YYYY");
    },
    filterDept(item) {
      return this.refType.filter((dept) => dept.id === item);
    },
    filterSubDept(item) {
      return this.subRefTypes.filter((subRef) => subRef.id === item);
    },
    filterIns(item) {
      return this.insurer.filter((insurer) => insurer.id === item);
    },
    filterBranches(item) {
      return this.branches.filter((branch) => branch.id === item);
    },
    filterStates(item) {
      return this.states.filter((state) => state.id === item);
    },
    closeDownloadAck() {
      this.acknowledgement = false;
      this.$nextTick(() => {});
    },
  },
};
</script>

<style scoped>
/* .title-box {
  background-color: green;
  padding: 0;
  margin: 0;
} */

.title01 {
  color: black;
  font-family: "Times New Roman", serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 19pt;
}
.caption01 {
  color: black;
  font-family: "Times New Roman", serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 7.5pt;
}
.para01 {
  padding-top: 3pt;
  /* padding-left: 6pt; */
  /* text-indent: 0pt; */
  text-align: left;
  font-size: 10pt;
}
.para02 {
  padding-top: 10pt;
  /* padding-left: 6pt; */
  /* text-indent: 0pt; */
  text-align: left;
  font-size: 11pt;
}
.para03 {
  text-indent: 0pt;
  text-align: left;
  font-weight: bold;
  font-size: 11pt;
}
.para04 {
  padding-top: 0pt;
  text-align: left;
  font-size: 11pt;
}
.para05 {
  padding-top: 20pt;
  padding-bottom: 5pt;
  text-align: left;
  font-size: 11pt;
}
.para06 {
  text-align: left;
  font-size: 10pt;
}
.para07 {
  border-width: 1px;
  border-color: black;
  height: 100%;
}
.para08 {
  text-indent: 0pt;
  text-align: left;
  font-weight: bold;
  font-size: 11pt;
  margin: 0;
}
.para09 {
  margin-top: 0px;
  padding-top: 0px;
  color: black;
  font-size: 8pt;
}
.para10 {
  margin-top: 10pt;
  margin-bottom: 10pt;
  text-align: left;
  font-weight: bold;
  font-size: 11pt;
}
.para11 {
  margin-top: 10pt;
  margin-bottom: 10pt;
  /* padding-top: 0pt; */
  text-align: left;
  font-size: 11pt;
}
.para12 {
  text-align: left;
  font-size: 6pt;
}
</style>
