import http from "../http-common";

class StateDataService {
  getAll() {
    return http.get("/states");
  }

  get(id) {
    return http.get(`/states/${id}`);
  }

  create(data) {
    return http.post("/states", data);
  }

  update(id, data) {
    return http.put(`/states/${id}`, data);
  }

  delete(id) {
    return http.delete(`/states/${id}`);
  }

  deleteAll() {
    return http.delete(`/states`);
  }

  findByBranch(name) {
    return http.get(`/states?name=${name}`);
  }
}

export default new StateDataService();
