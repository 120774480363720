<template>
  <v-container fluid style="width: 80%">
    <div>
      <v-data-table
        dense
        :headers="headers"
        :items="tatcharts"
        :search="search"
        class="elevation-1"
        :footer-props="{
          showFirstLastPage: true,
          'items-per-page-options': [10, 20, 30, 40, 50, 100, -1],
        }"
        :items-per-page="50"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-dialog v-model="dialog" max-width="500px" persistent>
              <template v-slot:activator="{ on: dialog, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      fab
                      small
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="{ ...tooltip, ...dialog }"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>ADD TAT</span>
                </v-tooltip>
              </template>

              <v-card tile>
                <v-card-title class="justify-center text-h5 grey lighten-2">
                  <span>{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-select
                          v-model="editedItem.insId"
                          :items="insurer"
                          item-text="name"
                          item-value="id"
                          label="Insurer"
                        ></v-select>
                      </v-col>

                      <v-col cols="12">
                        <v-select
                          v-model="editedItem.deptId"
                          :items="department"
                          item-text="name"
                          item-value="id"
                          label="Department"
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          v-model="editedItem.subDeptId"
                          :items="filterSubDeptByDept(editedItem.deptId)"
                          item-text="subCode"
                          item-value="id"
                          label="Sub-Department"
                        ></v-select>
                      </v-col>

                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedItem.tatAlert"
                          label="TAT ALERT"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedItem.tatMax"
                          label="TAT MAX"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn outlined small tile @click="close"> Cancel </v-btn>
                  <v-btn
                    :disabled="isAddButtonDisabled"
                    small
                    tile
                    class="success"
                    @click="save"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-divider class="mx-4" inset vertical></v-divider>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="justify-center text-h5 grey lighten-2"
                  >Confirm delete?
                  <!-- {{ editedItem.name }}? -->
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn outlined small tile @click="closeDelete">No</v-btn>
                  <v-btn small tile class="error" @click="deleteItemConfirm"
                    >Yes</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              clearable
            ></v-text-field>
          </v-toolbar>
        </template>

        <template slot="item.numbering" slot-scope="props">
          {{ props.index + 1 }}
        </template>

        <!-- <template v-slot:[`item.insId`]="{ item }">
          <span
            v-for="branch in filterBranch(item.insId)"
            :key="branch.id"
            v-bind="branch.item"
          >
            {{ branch.name }}
          </span>
        </template> -->
        <template v-slot:[`item.insId`]="{ item }">
          <span
            v-for="ins in filterIns(item.insId)"
            :key="ins.id"
            v-bind="ins.item"
          >
            {{ ins.name }}
          </span>
        </template>

        <!-- <template v-slot:[`item.deptId`]="{ item }">
          <span
            v-for="dept in filterDept(item.deptId)"
            :key="dept.id"
            v-bind="dept.item"
          >
            {{ dept.name }}
          </span>
        </template> -->

        <template v-slot:[`item.subDeptId`]="{ item }">
          <span
            v-for="subDept in filterSubDept(item.subDeptId)"
            :key="subDept.id"
            v-bind="subDept.item"
          >
            ({{ filterDept(subDept.deptId)[0].name }}) {{ subDept.subCode }}
          </span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left transition="fab-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span> edit</span>
          </v-tooltip>
          <v-tooltip right transition="fab-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                v-on="on"
                color="red darken-1"
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <span> delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import TatChartDataService from "../../services/TatChartDataService";
// import StateDataService from "../../services/StateDataService";
import DeptDataService from "../../services/DeptDataService";
import SubDeptDataService from "../../services/SubDeptDataService";
import InsDataService from "../../services/InsDataService";
export default {
  name: "tatcharts",
  data: () => ({
    dialog: false,
    dialogDelete: false,
    tatcharts: [],
    // states: [],
    insurer: [],
    department: [],
    subDept: [],
    search: "",
    defaultItem: {
      id: null,
      insId: "",
      deptId: "",
      tatAlert: "",
      tatMax: "",
      // insId: "",
      // name: "",
      // tatAlert: "",
    },
    editedIndex: -1,
    editedItem: {
      id: null,
      // insId: "",
      // name: "",
      // tatAlert: "",
      insId: "",
      deptId: "",
      subDeptId: "",
      tatAlert: "",
      tatMax: "",
    },
    headers: [
      {
        text: "NO.",
        align: "start",
        sortable: false,
        value: "numbering",
      },
      {
        text: "INSURER",
        align: "start",
        sortable: true,
        value: "insId",
      },
      {
        text: "SUB-DEPARTMENT",
        align: "start",
        sortable: true,
        value: "subDeptId",
      },
      {
        text: "TAT ALERT(Days)",
        align: "center",
        value: "tatAlert",
        sortable: true,
      },
      {
        text: "TAT MAX(Days)",
        align: "center",
        value: "tatMax",
        sortable: true,
      },
      {
        text: "ACTIONS",
        align: "center",
        value: "actions",
        sortable: false,
      },
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add TAT" : "Update TAT";
    },
    isAddButtonDisabled() {
      return !(
        this.editedItem.insId &&
        this.editedItem.deptId &&
        this.editedItem.subDeptId &&
        this.editedItem.tatAlert &&
        this.editedItem.tatMax
      );
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    // filterAdjByBranch(item) {
    //   // if (!item) return "TBA";
    //   return this.adjusters.filter((r) =>
    //     r.branches.some((i) => i.name === item)
    //   );
    // },
    filterSubDeptByDept(item) {
      // if (!item) return "TBA";
      return this.subDept.filter((a) => a.deptId === item);
    },

    // showInsurerName(item) {
    //   const myArray = this.insurer.filter((insurer) => insurer.id === item);
    //   let result = myArray.map((a) => a.name);
    //   return result;
    // },
    // filterSubDeptByDept(item) {
    //   const myArray = this.subDept.filter((a) => a.deptId === item);
    //   let result = myArray.map((b) => b.subCode);
    //   return result;
    // },
    // filterBranch(item) {
    //   return this.states.filter((branch) => branch.id === item);
    // },
    filterIns(item) {
      return this.insurer.filter((ins) => ins.id === item);
    },
    filterDept(item) {
      return this.department.filter((dept) => dept.id === item);
    },
    filterSubDept(item) {
      // console.log(item);
      return this.subDept.filter((subDept) => subDept.id === item);
    },

    retrieveTatCharts() {
      TatChartDataService.getAll()
        .then((response) => {
          // this.tatcharts = response.data.map(this.getDisplayRegion);
          this.tatcharts = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveInss() {
      InsDataService.getAll()
        .then((response) => {
          this.insurer = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveDepts() {
      DeptDataService.getAll()
        .then((response) => {
          this.department = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveSubDepts() {
      SubDeptDataService.getAll()
        .then((response) => {
          this.subDept = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    // getDisplayRegion(region) {
    //   return {
    //     id: region.id,
    //     insId: region.insId,
    //     name:
    //       region.name.length > 30
    //         ? region.name.substr(0, 30) + "..."
    //         : region.name,
    //     tatAlert:
    //       region.tatAlert.length > 30
    //         ? region.tatAlert.substr(0, 30) + "..."
    //         : region.tatAlert,
    //   };
    // },

    // retrieveStates() {
    //   StateDataService.getAll()
    //     .then((response) => {
    //       this.states = response.data.map(this.getDisplayState);
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //     });
    // },

    // getDisplayState(state) {
    //   return {
    //     id: state.id,
    //     name:
    //       state.name.length > 30
    //         ? state.name.substr(0, 30) + "..."
    //         : state.name,
    //     branchCode:
    //       state.stCode.length > 30
    //         ? state.stCode.substr(0, 30) + "..."
    //         : state.stCode,
    //   };
    // },

    refreshList() {
      this.retrieveTatCharts();
      this.retrieveInss();
      this.retrieveDepts();
      this.retrieveSubDepts();
      // this.retrieveStates();
    },

    editItem(item) {
      this.editedIndex = this.tatcharts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.tatcharts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      TatChartDataService.delete(this.editedItem.id)
        .then((response) => {
          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "error",
            text:
              (response && response.data && response.data.message) ||
              toString(),
          });

          this.refreshList();
        })
        .catch((e) => {
          console.log(e);
        });

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        TatChartDataService.update(this.editedItem.id, this.editedItem)
          .then((response) => {
            this.close();
            this.$store.dispatch("setSnackbar", {
              showing: true,
              color: "warning",
              text:
                (response && response.data && response.data.message) ||
                toString(),
            });
            this.refreshList();
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        var data = {
          insId: this.editedItem.insId,
          subDeptId: this.editedItem.subDeptId,
          tatAlert: this.editedItem.tatAlert,
          tatMax: this.editedItem.tatMax,
        };
        TatChartDataService.create(data)
          .then((response) => {
            this.editedItem.id = response.data.id;
            this.close();
            this.$store.dispatch("setSnackbar", {
              showing: true,
              color: "success",
              text:
                (response && response.data && response.data.message) ||
                toString(),
            });
            this.refreshList();
          })
          .catch((e) => {
            this.$store.dispatch("setSnackbar", {
              showing: true,
              text:
                (e.response && e.response.data && e.response.data.message) ||
                e.message ||
                toString(),
            });
          });
      }
      this.close();
    },
  },
  mounted() {
    this.retrieveTatCharts();
    this.retrieveInss();
    this.retrieveDepts();
    this.retrieveSubDepts();
    // this.retrieveStates();
  },
};
</script>
