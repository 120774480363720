<template>
  <body>
    <v-card class="transparent justify-center align-center text-center" flat>
      <v-row>
        <v-col cols="2"></v-col>
        <v-col cols="8" color="white" class="white--text text-center">
          <v-card-text
            class="ma-3 pa-3 font-weight-bold"
            :class="{
              'text-h4': $vuetify.breakpoint.mdAndDown,
              'text-h1': $vuetify.breakpoint.lgAndUp,
            }"
            >Forgot your password? <br />
            Please provide emaill address for assistance.</v-card-text
          >
        </v-col>
        <v-col cols="2"></v-col>
      </v-row>
    </v-card>
    <v-card
      class="fill-height pa-0 ma-auto"
      max-width="1000"
      outlined
      tile
      color="transparent"
    >
      <div>
        <v-row class="d-flex justify-center align-center ma-auto">
          <v-col
            cols="12"
            lg="12"
            md="12"
            sm="12"
            class="d-flex justify-center align-center pa-0"
            v-if="message"
          >
            <v-alert width="90%" v-model="message.text" type="error">
              {{ message }}
            </v-alert>
          </v-col>
          <v-col
            cols="12"
            lg="12"
            md="12"
            sm="12"
            class="d-flex justify-center align-center pa-0"
            v-if="message2"
          >
            <v-alert width="90%" v-model="message2.text" type="success">
              {{ message2 }}
            </v-alert>
          </v-col>

          <v-col
            cols="12"
            lg="4"
            md="12"
            sm="12"
            class="d-flex justify-center align-center pa-0"
          >
            <v-card tile max-width="350px" class="pa-10 text-center">
              <div>
                <img width="100%" src="../../assets/aasb-logo.png" />
                <form ref="form" @submit.prevent="resetlink">
                  <v-row no-gutters>
                    <v-col cols="12" class="ma-0">
                      <v-text-field
                        v-model="user.email"
                        :rules="rules.emailRules"
                        name="email"
                        value=""
                        type="email"
                        label="Email"
                        autocomplete="email"
                      >
                      </v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-btn
                        :disabled="isEmptyForm"
                        tile
                        block
                        color="blue"
                        type="submit"
                      >
                        <!-- <span
                        v-show="loading"
                        class="spinner-border spinner-border-sm"
                      ></span> -->
                        Help me
                      </v-btn>
                    </v-col>
                  </v-row>
                </form>
                <v-row class="mt-1">
                  <v-card-text class="text-right ma-0 pa-0">
                    <v-btn plain text small to="/login"> Back to Login </v-btn>
                  </v-card-text>
                </v-row>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </body>
</template>

<script>
import MemberDataService from "../../services/MemberDataService";
// import axios from "axios";
export default {
  name: "forgot",
  data() {
    return {
      message: "",
      message2: "",
      user: {
        email: "",
      },
      rules: {
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+/.test(v) || "E-mail must be valid",
        ],
      },
      loading: false,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    isEmptyForm() {
      return !this.user.email;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/profile");
    }
  },
  methods: {
    resetlink() {
      this.message = "";
      this.message2 = "";
      // this.loading = true;
      var data = {
        email: this.user.email,
      };

      MemberDataService.resetlink(data)
        // axios.post("/members/reset/sendlink", data)

        .then(
          (response) => {
            this.message2 =
              (response && response.data && response.data.message) ||
              toString();
            // this.$store.dispatch("setSnackbar", {
            //   showing: true,
            //   color: "success",
            //   text:
            //     (response && response.data && response.data.message) ||
            //     toString(),
            // });
            // setTimeout(() => {
            //   this.$router.push("/login");
            // }, 2500);
          },
          (error) => {
            // this.loading = false;
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              toString();
            // this.$store.dispatch("setSnackbar", {
            //   showing: true,
            //   text:
            //     (error.response &&
            //       error.response.data &&
            //       error.response.data.message) ||
            //     error.message ||
            //     toString(),
            // });
          }
        );

      this.$refs.form.reset(); // This will clear that form
      // this.user.email = "";
    },
  },
  mounted() {},
};
</script>

<style scoped>
body {
  background: url(../../assets/login-bg.jpg) no-repeat;
  width: 100%;
  height: 100vh;
  background-size: cover;
}
/* body {
  height: 100vh;
  width: 100%;
  background-image: url(../assets/login-bg.jpg);
  background-size: cover;
} */
</style>
