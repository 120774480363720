<template>
  <body>
    <v-row>
      <v-col cols="12" color="white" class="white--text text-center">
        <!-- <h1 class="ma-3 pa-3">Activate User Account!</h1> -->
        <v-card-text
          class="ma-3 pa-3 font-weight-bold"
          :class="{
            'text-h4': $vuetify.breakpoint.mdAndDown,
            'text-h1': $vuetify.breakpoint.lgAndUp,
          }"
          >Activate User Account!</v-card-text
        >
      </v-col>
    </v-row>
    <v-card
      max-width="600"
      class="pa-0 ma-auto"
      outlined
      tile
      color="transparent"
    >
      <div>
        <v-row class="d-flex justify-center align-center ma-auto">
          <v-col cols="12" class="d-flex justify-center align-center pa-0">
            <v-card tile width="95%" class="pa-10 text-center">
              <div>
                <img width="300px" src="../../assets/aasb-logo.png" />

                <v-stepper v-model="stepper">
                  <v-stepper-header>
                    <v-stepper-step :complete="stepper > 1" step="1">
                      Verify the token
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="stepper > 2" step="2">
                      Choose a Strong Password
                    </v-stepper-step>
                    <v-divider></v-divider>

                    <v-stepper-step step="3"> Done </v-stepper-step>
                  </v-stepper-header>
                  <div v-if="message">
                    <v-alert v-model="message.text" type="error">
                      {{ message }}
                    </v-alert>
                  </div>

                  <v-stepper-items>
                    <v-stepper-content step="1">
                      <v-form ref="form">
                        <v-card>
                          <v-card-text>
                            Please key in your email and the token received in
                            your email.
                          </v-card-text>

                          <v-text-field
                            solo
                            autofocus
                            hint="Email"
                            v-model="user.email"
                            :rules="rules.emailRules"
                            name="email"
                            value=""
                            type="email"
                            label="Email"
                            autocomplete="email"
                          >
                          </v-text-field>
                          <v-text-field
                            solo
                            v-model="user.token"
                            name="token"
                            value=""
                            type="text"
                            label="Token"
                          >
                          </v-text-field>
                          <v-card-actions>
                            <!-- <v-btn color="primary" @click="stepper = 2"> -->
                            <v-btn
                              :disabled="isEmptyForm"
                              color="primary"
                              @click="verify"
                            >
                              Verify
                            </v-btn>
                            <v-btn text to="/forgot"> Request Token </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-form>

                      <!-- <v-card
                        class="mb-12"
                        color="grey lighten-1"
                        height="200px"
                      ></v-card>

                      <v-btn color="primary" @click="stepper = 2">
                        Continue
                      </v-btn>
                      <v-btn text> Cancel </v-btn> -->
                    </v-stepper-content>

                    <v-stepper-content step="2">
                      <v-form ref="form1" v-model="valid">
                        <v-card>
                          <v-card-text> Key in new password </v-card-text>

                          <!-- <v-text-field
                            solo
                            hint="Email"
                            v-model="user.password"
                            name="password"
                            value=""
                            type="password"
                            label="Password"
                          >
                          </v-text-field>
                          <v-text-field
                            solo
                            v-model="user.confPassword"
                            name="confPassword"
                            value=""
                            type="password"
                            label="Confirm Password"
                          >
                          </v-text-field> -->

                          <v-col cols="12">
                            <v-text-field
                              v-model="user.password"
                              label="Password"
                              class="mb-6"
                              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                              :rules="[
                                rules.required,
                                rules.min,
                                rules.strength,
                              ]"
                              validate-on-blur
                              :type="show1 ? 'text' : 'password'"
                              @click:append="show1 = !show1"
                              hint="Minimum strength 75%"
                              persistent-hint
                            ></v-text-field>
                            <v-progress-linear
                              :background-opacity="opacity"
                              :color="score.color"
                              :value="score.value"
                              height="25"
                              v-model="score.value"
                            >
                              <template v-slot:default="{ value }">
                                <strong>{{ Math.ceil(value) }}%</strong>
                              </template>
                            </v-progress-linear>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                              :rules="[
                                rules.required,
                                rules.min,
                                user.password === user.confPassword ||
                                  'Password must match',
                              ]"
                              :type="show2 ? 'text' : 'password'"
                              @click:append="show2 = !show2"
                              v-model="user.confPassword"
                              label="Confirm Password*"
                              autocomplete="confPassword"
                              id="user.confPassword"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-card-actions>
                            <!-- <v-btn color="primary" @click="stepper = 2"> -->
                            <v-btn
                              :disabled="!valid"
                              color="primary"
                              @click="updatePass"
                            >
                              Submit
                            </v-btn>
                            <!-- <v-btn text to="/forgot"> Request Token </v-btn> -->
                          </v-card-actions>
                        </v-card>
                      </v-form>
                    </v-stepper-content>
                    <v-stepper-content step="3">
                      <v-col class="ma-auto" v-if="message2">
                        <v-alert v-model="message2.text" type="success">
                          {{ message2 }}
                        </v-alert>
                        <v-card-text>
                          Congratulations. You can login to your account with
                          the updated password.
                        </v-card-text>
                      </v-col>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>

                <v-row class="mt-1">
                  <v-card-text class="text-right ma-0 pa-0">
                    <v-btn plain text small to="/login"> Back to Login </v-btn>
                  </v-card-text>
                </v-row>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </body>
</template>

<script>
import MemberDataService from "../../services/MemberDataService";
import zxcvbn from "zxcvbn";
export default {
  name: "activate",
  data() {
    return {
      valid: false,
      opacity: "",
      show1: false,
      show2: false,
      message: "",
      message2: "",
      stepper: 1,
      user: {
        id: null,
        email: "",
        token: "",
        password: "",
        confPassword: "",
      },
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        strength: (v) =>
          zxcvbn(v).score >= 3 ||
          "Please choose a stronger password. Try a mix of letters, numbers, and symbols.",

        emailMatch: () => `The password you entered don't match`,
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+/.test(v) || "E-mail must be valid",
        ],
      },
      loading: false,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    score() {
      const result = zxcvbn(this.user.password);

      switch (result.score) {
        case 4:
          return {
            color: "light-blue",
            value: 100,
          };
        case 3:
          return {
            color: "light-green",
            value: 75,
          };
        case 2:
          return {
            color: "yellow",
            value: 50,
          };
        case 1:
          return {
            color: "orange",
            value: 25,
          };
        default:
          return {
            color: "red",
            value: 0,
          };
      }
    },
    isEmptyForm() {
      return !(this.user.email && this.user.token);
    },
    // isEmptyForm1() {
    //   return !(this.user.password && this.user.confPassword);
    // },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/profile");
    }
  },
  methods: {
    verify() {
      this.message = "";
      this.message2 = "";
      var data = {
        email: this.user.email,
        token: this.user.token,
      };

      MemberDataService.verifyUser(data).then(
        (response) => {
          // this.message2 =
          //   (response && response.data && response.data.message) || toString();
          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "success",
            text:
              (response && response.data && response.data.message) ||
              toString(),
          });
          this.user.id = response.data.id;
          this.stepper = 2;
          // setTimeout(() => {
          //   this.$router.push("/login");
          // }, 2500);
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            toString();
          // this.$store.dispatch("setSnackbar", {
          //   showing: true,
          //   text:
          //     (error.response &&
          //       error.response.data &&
          //       error.response.data.message) ||
          //     error.message ||
          //     toString(),
          // });
        }
      );

      // this.$refs.form.reset(); // This will clear that form
      // this.user.email = "";
    },
    updatePass() {
      this.message2 = "";
      var data = {
        password: this.user.password,
      };
      MemberDataService.changepass(this.user.id, data)
        .then((response) => {
          this.message2 =
            (response && response.data && response.data.message) || toString();
          // this.$store.dispatch("setSnackbar", {
          //   showing: true,
          //   color: "success",
          //   text:
          //     (response && response.data && response.data.message) ||
          //     toString(),
          // });
          // this.$refs.form1.reset(); // This will clear that form
          // this.user.password = "";
          // this.user.confPassword = "";
          this.stepper = 3;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {},
};
</script>

<style scoped>
body {
  background: url(../../assets/login-bg.jpg) no-repeat;
  width: 100%;
  height: 100vh;
  background-size: cover;
}
</style>
