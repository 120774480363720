var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',{staticStyle:{"width":"80%"},attrs:{"fluid":""}},[_c('v-tabs',[_c('v-tab',{on:{"click":function($event){_vm.incoming = true;
          _vm.pending = false;
          _vm.query = false;
          _vm.approved = false;}}},[_vm._v("New")]),_c('v-tab',{on:{"click":function($event){_vm.pending = true;
          _vm.incoming = false;
          _vm.query = false;
          _vm.approved = false;}}},[_vm._v("Pending")]),_c('v-tab',{on:{"click":function($event){_vm.query = true;
          _vm.pending = false;
          _vm.incoming = false;
          _vm.approved = false;}}},[_vm._v("Query")]),_c('v-tab',{on:{"click":function($event){_vm.approved = true;
          _vm.pending = false;
          _vm.query = false;
          _vm.incoming = false;}}},[_vm._v("Approved")])],1),(_vm.incoming)?_c('incTab'):_vm._e(),(_vm.pending)?_c('pendTab'):_vm._e(),(_vm.query)?_c('cancTab'):_vm._e(),(_vm.approved)?_c('approvedTab'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }