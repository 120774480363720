<template>
  <v-dialog v-model="show" max-width="800px" persistent>
    <v-card>
      <v-card-title class="red lighten-4">
        Welcome {{ currentUserName }}</v-card-title
      >
      <v-card-text>
        <!-- TAT Alert Cases -->
        <template v-if="overdueAlertData.length > 0">
          <div class="warning--text ma-2">
            <v-btn
              depressed
              elevation="2"
              fab
              icon
              outlined
              raised
              rounded
              text
              tile
              x-small
              class="mr-2"
            >
              <v-card-title>{{ overdueAlertData.length }}</v-card-title></v-btn
            >
            pending case(s) that require your urgent attention. Please submit
            your report within the stipulated TAT.
          </div>
          <v-data-table
            dense
            :headers="headers"
            :items="overdueAlertData"
            class="elevation-1 tat-overdue"
          >
            <!-- <template
              v-for="(_, slotName) in $scopedSlots"
              v-slot:[slotName]="props"
            >
              <slot :name="slotName" v-bind="props" />
            </template> -->

            <template v-slot:[`item.id`]="{ item }">
              AA/{{ getDeptName(item.refType) }}/{{
                getSubDeptName(item.subRefType)
              }}/{{ item.id }}/{{ yearOnly(item.dateOfAssign) }}/{{
                getBranchName(item.branch)
              }}
            </template>
            <template v-slot:[`item.dateOfAssign`]="{ item }">
              {{ formatDate(item.dateOfAssign) }}
            </template>
            <template v-slot:[`item.days`]="{ item }">
              {{ item.days }} days
            </template>
            <template v-slot:[`item.vehicleNo`]="{ item }">
              <span v-if="item.vehicleNo">
                <div v-if="item.vehicleNo">
                  {{ item.vehicleNo.toUpperCase() }}
                </div>
              </span>
              <span v-else>TBA</span>
            </template>
            <template v-slot:[`item.fileStatus`]="{ item }">
              {{ getFileStatus(item.fileStatus) }}
            </template>
          </v-data-table>
        </template>

        <!-- TAT Max Cases -->
        <template v-if="overdueMaxData.length > 0">
          <div class="error--text ma-2">
            <v-btn
              depressed
              elevation="2"
              fab
              icon
              outlined
              raised
              rounded
              text
              tile
              x-small
              class="mr-2"
            >
              <v-card-title>{{ overdueMaxData.length }}</v-card-title></v-btn
            >
            case(s) exceeding the TAT, to avoid disciplinary action, please
            submit your report urgently.
          </div>
          <v-data-table
            dense
            :headers="headers"
            :items="overdueMaxData"
            class="elevation-1 tat-overdue"
          >
            <!-- <template
              v-for="(_, slotName) in $scopedSlots"
              v-slot:[slotName]="props"
            >
              <slot :name="slotName" v-bind="props" />
            </template> -->

            <template v-slot:[`item.id`]="{ item }">
              AA/{{ getDeptName(item.refType) }}/{{
                getSubDeptName(item.subRefType)
              }}/{{ item.id }}/{{ yearOnly(item.dateOfAssign) }}/{{
                getBranchName(item.branch)
              }}
            </template>
            <template v-slot:[`item.dateOfAssign`]="{ item }">
              {{ formatDate(item.dateOfAssign) }}
            </template>
            <template v-slot:[`item.days`]="{ item }">
              {{ item.days }} days
            </template>
            <template v-slot:[`item.vehicleNo`]="{ item }">
              <span v-if="item.vehicleNo">
                <div v-if="item.vehicleNo">
                  {{ item.vehicleNo.toUpperCase() }}
                </div>
              </span>
              <span v-else>TBA</span>
            </template>
            <template v-slot:[`item.fileStatus`]="{ item }">
              {{ getFileStatus(item.fileStatus) }}
            </template>
          </v-data-table>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="$emit('close')">
          Acknowledge
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";

export default {
  name: "OverdueAlert",
  props: {
    show: Boolean,
    // overdueCases: Array,
    overdueAlertData: Array,
    overdueMaxData: Array,
    refType: Array,
    subRefTypes: Array,
    branch: Array,
    stages: Array,
    currentUser: Object,
  },
  data: () => ({
    headers: [
      { text: "AA REF", value: "id" },
      //   { text: "VEHICLE NO.", value: "vehicleNo" },
      { text: "DATE ASSIGNED", value: "dateOfAssign" },
      { text: "OUTSTANDING", value: "days" },
      { text: "STATUS", value: "fileStatus" },
    ],
  }),
  computed: {
    currentUserName() {
      return this.currentUser?.username || "User";
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    yearOnly(value) {
      return moment(value).format("YY");
    },
    getDeptName(refTypeId) {
      const dept = this.refType.find((dept) => dept.id === refTypeId);
      return dept ? dept.name : "";
    },
    getSubDeptName(subRefTypeId) {
      const subDept = this.subRefTypes.find(
        (subDept) => subDept.id === subRefTypeId
      );
      return subDept ? subDept.subCode : "";
    },
    getBranchName(branchId) {
      const branch = this.branch.find((branch) => branch.id === branchId);
      return branch ? branch.brCode : "";
    },
    getFileStatus(statusId) {
      const status = this.stages.find(
        (status) => status.stageCode === statusId
      );
      return status ? status.name : "";
    },
  },
};
</script>
