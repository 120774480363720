<template>
  <div class="soonbox">
    <H1>COMING SOON</H1>
  </div>
</template>

<script>
export default {
  name: "soon",
};
</script>
<style>
.soonbox {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
