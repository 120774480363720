import http from "../http-common";

class HandlerDataService {
  getAll() {
    return http.get("/handlers");
  }

  get(id) {
    return http.get(`/handlers/${id}`);
  }

  create(data) {
    return http.post("/handlers", data);
  }

  update(id, data) {
    return http.put(`/handlers/${id}`, data);
  }

  delete(id) {
    return http.delete(`/handlers/${id}`);
  }

  deleteAll() {
    return http.delete(`/handlers`);
  }

  // findByHandler(name) {
  //   return http.get(`/handlers?name=${name}`);
  // }
}

export default new HandlerDataService();
