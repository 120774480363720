<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on: tooltip }">
        <v-btn
          :disabled="!approvingManager"
          class="warning mr-1"
          fab
          x-small
          v-on="{ ...tooltip }"
          @click="transferBranch()"
        >
          <v-icon>mdi-folder-arrow-up-down-outline</v-icon>
        </v-btn>
      </template>
      <span>TRANSFER BRANCH</span>
    </v-tooltip>

    <!-- THE DIALOG BEGINS HERE -->

    <v-dialog v-model="dialogTransferBranch" max-width="750px">
      <v-card>
        <v-card-title class="justify-center text-h5 grey lighten-2">
          <span class="text-h5">TRANSFER BRANCH</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="caseState.casefile.branch"
                  :items="branches"
                  item-text="name"
                  item-value="id"
                  :menu-props="{ maxHeight: '400' }"
                  hint="Set the casefile branch"
                  persistent-hint
                >
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>Branch
                  </template>
                </v-select>
              </v-col>

              <v-col cols="12">
                <v-spacer></v-spacer>
                <v-btn
                  tile
                  dark
                  small
                  @click="closeTransferBranchDialog"
                  class="mt-3 mr-2 error"
                >
                  Cancel
                </v-btn>
                <v-btn
                  tile
                  small
                  :disabled="isAddFileButtonDisabled"
                  class="mt-3 success"
                  @click="transferBranchSubmit"
                  >Submit</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CasefileDataService from "../../services/CasefileDataService";
import MailDataService from "../../services/MailDataService";
import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "transferBranch",
  props: [
    "branches",
    // "branch",
    "approvingManager",
    "insurer",
    "refType",
    "subRefTypes",
    "members",
    // "handlers",
    "states",
    "regions",
  ],
  components: {},

  data() {
    return {
      dialogTransferBranch: false,
      // currentEditor: "",
      menu: false,
      menu1: false,
    };
  },

  computed: {
    ...mapState(["caseState"]),

    isAddFileButtonDisabled() {
      return !(
        // this.caseState.casefile.refType &&
        this.caseState.casefile.branch
        // this.caseState.casefile.insurer &&
        // this.caseState.casefile.claimNo
        // this.caseState.casefile.vehicleNo
        // this.caseState.casefile.dateOfLoss
        // this.caseState.casefile.insComment
      );
    },

    computedDateFormattedMomentjs() {
      return this.date ? moment(this.date).format("DD/MM/YY") : "";
    },

    // newPendDisabled() {
    //   return !(
    //     (this.caseState.casefile.fileStatus == `NEW` ||
    //       this.caseState.casefile.fileStatus == `PEND`) &&
    //     this.management
    //   );
    // },
  },
  watch: {
    transferBranch(val) {
      val || this.closeTransferBranchDialog();
    },
  },

  methods: {
    // moment: function (date) {
    //   return moment(date);
    // },
    // filtersubRefType(item) {
    //   return this.subRefTypes.filter(
    //     (subRefType) => subRefType.deptId === item
    //   );
    // },
    // filterHandler(item) {
    //   return this.handlers.filter((handler) => handler.insId === item);
    // },
    filterBranchName(item) {
      if (!item) return "TBA";
      const filteredbranch = this.branches.filter(
        (branch) => branch.id === item
      );
      return filteredbranch[0].name;
    },
    filterBranches(item) {
      return this.branches.filter((branch) => branch.id === item);
    },

    // filterRegions(item) {
    //   return this.regions.filter((region) => region.branchId === item);
    // },
    filterPicID(item) {
      // console.log("assignedDept" + item);
      if (!item) return "TBA";
      const filteredDept = this.refType.filter((dept) => dept.id === item);
      return filteredDept[0].picID;
    },

    filterDept(item) {
      return this.refType.filter((dept) => dept.id === item);
    },
    filterSubDept(item) {
      return this.subRefTypes.filter((subRef) => subRef.id === item);
    },
    filterIns(item) {
      return this.insurer.filter((insurer) => insurer.id === item);
    },

    findUserEmail(item) {
      // console.log(item);
      const user = this.members.filter((member) => member.id === item);
      return user[0] ? user[0].email : "sys.admin@associatedadjusters.com";
      // return user.map((b) => b.email);
    },
    transferBranch() {
      this.dialogTransferBranch = true;
    },

    transferBranchSubmit() {
      // const assignedBranch = this.caseState.casefile.branch;
      const assignedDept = this.caseState.casefile.refType;
      var dataEmail = {
        id: this.caseState.casefile.id,
        refType: this.filterDept(this.caseState.casefile.refType)[0]
          .description,
        refTypeCode: this.filterDept(this.caseState.casefile.refType)[0].name,
        subRefType: this.filterSubDept(this.caseState.casefile.subRefType)[0]
          .subCodeDesc,
        subRefCode: this.filterSubDept(this.caseState.casefile.subRefType)[0]
          .subCode,
        branch: this.filterBranchName(this.caseState.casefile.branch),
        branchCode: this.filterBranches(this.caseState.casefile.branch)[0]
          .brCode,
        insurer: this.filterIns(this.caseState.casefile.insurer)[0].name,
        recipient: this.findUserEmail(this.filterPicID(assignedDept)),
        createdBy: this.$store.state.auth.user.id,
      };

      CasefileDataService.update(
        this.caseState.casefile.id,
        this.caseState.casefile,
        (this.caseState.casefile.vehicleNo = this.caseState.casefile.vehicleNo
          .toLowerCase()
          .replace(/\s/g, "")),
        (this.caseState.casefile.fileStatus = `BRAC`),
        (this.caseState.casefile.assignBranchBy =
          this.$store.state.auth.user.id),
        (this.caseState.casefile.dateOfReg = moment().format()) // dateOfReg -- need confirmation. Most probably dateOfReg = assignBranchDate
      )
        .then(() => {
          MailDataService.assignBranch(dataEmail)
            .then((response) => {
              this.closeTransferBranchDialog();
              this.$store.dispatch("setSnackbar", {
                showing: true,
                color: "warning",
                text:
                  (response && response.data && response.data.message) ||
                  toString(),
              });
              // this.refreshList();
            })
            .catch((e) => {
              console.log(e);
            });
          this.dataEmail = "";
        })
        .catch((e) => {
          this.$store.dispatch("setSnackbar", {
            showing: true,
            text:
              (e.response && e.response.data && e.response.data.message) ||
              e.message ||
              toString(),
          });
        });
      this.closeTransferBranchDialog();
    },

    closeTransferBranchDialog() {
      this.dialogTransferBranch = false;
      this.$nextTick(() => {
        // this.caseState.casefile = Object.assign({});
        // this.caseState.casefile.fileStatus = "INC";
      });
    },
  },
};
</script>
