<template>
  <v-container fluid style="width: 80%">
    <!-- TOP CARD -->
    <v-row>
      <!-- Profile Picture -->
      <v-col cols="12" md="3" class="text-center ma-auto">
        <v-card v-if="members.profile" tile class="pa-2">
          <img :src="members.profile" width="150px" alt="" />
          <!-- For Production -->
          <!-- :src="members.profile" /> -->
          <!-- For Development -->
          <!-- :src="'http://localhost:8080/files/' + this.members.profile" /> -->
        </v-card>
        <v-card v-else tile class="pa-2">
          <img width="150px" src="../assets/Default-profile.png" alt="" />
        </v-card>
      </v-col>

      <v-col cols="12" md="9">
        <v-row style="border-bottom: 1px solid">
          <!-- Username and Email -->
          <v-col cols="12" style="border-bottom: 1px solid">
            <v-card-title class="justify-center pb-0">
              <strong>{{ members.username }}</strong>
            </v-card-title>
            <v-card-text class="text-caption text-center pt-0">{{
              members.email
            }}</v-card-text>
          </v-col>
          <!-- Buttons -->
          <v-col cols="12" style="border">
            <v-row class="text-center">
              <v-divider vertical></v-divider>
              <v-col>
                <v-card-text>Update Profile</v-card-text>
                <!-- EDIT PROFILE -->
                <v-dialog v-model="editDialog" persistent max-width="600px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      fab
                      v-bind="attrs"
                      v-on="on"
                      class="ma-1"
                      dark
                      color="cyan"
                      @click="editDialog"
                    >
                      <v-icon dark> mdi-pencil </v-icon>
                    </v-btn>
                  </template>
                  <v-card tile>
                    <v-card-title class="justify-center text-h5 grey lighten-2">
                      <span>Edit Profile</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-form>
                          <v-row align="center" no-gutters>
                            <v-col cols="12" md="3" sm="6">
                              <h4>Fullname:</h4>
                            </v-col>

                            <v-col cols="12" md="9" sm="6">
                              <v-text-field
                                dense
                                v-model="members.fullname"
                                label="Fullname*"
                                :rules="[rules.required]"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row align="center" no-gutters>
                            <v-col cols="12" md="3" sm="6">
                              <h4>NRIC:</h4>
                            </v-col>

                            <v-col cols="12" md="9" sm="6">
                              <v-text-field
                                dense
                                type="number"
                                hide-spin-buttons
                                v-model="members.nric"
                                label="NRIC*"
                                :rules="[rules.number, rules.required]"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row align="center" no-gutters>
                            <v-col cols="12" md="3" sm="6">
                              <h4>Tel:</h4>
                            </v-col>

                            <v-col cols="12" md="9" sm="6">
                              <v-text-field
                                dense
                                v-model="members.telnumber"
                                label="Tel. Number"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row align="center" no-gutters>
                            <v-col cols="12" md="3" sm="6">
                              <h4>Address:</h4>
                            </v-col>

                            <v-col cols="12" md="9" sm="6">
                              <v-textarea
                                dense
                                auto-grow
                                v-model="members.address"
                                label="Address"
                              ></v-textarea>
                            </v-col>
                          </v-row>
                          <v-row align="center" no-gutters>
                            <v-col cols="12" md="4" sm="6">
                              <h4>Emergency Contact:</h4>
                            </v-col>

                            <v-col cols="12" md="8" sm="6">
                              <v-text-field
                                dense
                                v-model="members.emcontactname"
                                label="Emergency Contact"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row align="center" no-gutters>
                            <v-col cols="12" md="4" sm="6">
                              <h4>Emergency Contact No:</h4>
                            </v-col>

                            <v-col cols="12" md="8" sm="6">
                              <v-text-field
                                dense
                                v-model="members.emcontactno"
                                label="Emergency Contact Number"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-container>
                      <small>*indicates required field</small>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn outlined small tile @click="closeEditDialog">
                        Close
                      </v-btn>
                      <v-btn
                        :disabled="isEditDisabled"
                        small
                        tile
                        class="warning"
                        @click="save"
                      >
                        Update
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col>
                <v-card-text>Change Password</v-card-text>
                <!-- CHANGE PASSWORD -->
                <v-dialog v-model="passDialog" persistent max-width="600px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      v-bind="attrs"
                      v-on="on"
                      class="ma-1"
                      fab
                      dark
                      color="blue"
                      @click="passDialog"
                    >
                      <v-icon dark> mdi-form-textbox-password </v-icon>
                    </v-btn>
                  </template>
                  <v-card tile>
                    <v-form>
                      <v-card-title
                        class="justify-center text-h5 grey lighten-2"
                      >
                        <span>Change Password</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-form ref="form" v-model="valid">
                            <v-row>
                              <v-col cols="12">
                                <v-text-field
                                  disabled
                                  v-model="members.email"
                                  type="text"
                                  name="username"
                                  autoComplete="username"
                                ></v-text-field>
                              </v-col>

                              <v-col cols="12">
                                <v-text-field
                                  v-model="password"
                                  label="Password"
                                  class="mb-6"
                                  :append-icon="
                                    show1 ? 'mdi-eye' : 'mdi-eye-off'
                                  "
                                  :rules="[
                                    rules.required,
                                    rules.min,
                                    rules.strength,
                                  ]"
                                  validate-on-blur
                                  :type="show1 ? 'text' : 'password'"
                                  @click:append="show1 = !show1"
                                  hint="Minimum strength 75%"
                                  persistent-hint
                                ></v-text-field>
                                <v-progress-linear
                                  :background-opacity="opacity"
                                  :color="score.color"
                                  :value="score.value"
                                  height="25"
                                  v-model="score.value"
                                >
                                  <template v-slot:default="{ value }">
                                    <strong>{{ Math.ceil(value) }}%</strong>
                                  </template>
                                </v-progress-linear>
                              </v-col>
                              <v-col cols="12">
                                <v-text-field
                                  :append-icon="
                                    show2 ? 'mdi-eye' : 'mdi-eye-off'
                                  "
                                  :rules="[
                                    rules.required,
                                    rules.min,
                                    password === confPassword ||
                                      'Password must match',
                                  ]"
                                  :type="show2 ? 'text' : 'password'"
                                  @click:append="show2 = !show2"
                                  v-model="confPassword"
                                  label="Confirm Password*"
                                  autocomplete="confPassword"
                                  id="confPassword"
                                  required
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-container>
                        <small>*indicates required field</small>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn outlined small tile @click="passDialog = false">
                          Close
                        </v-btn>
                        <v-btn
                          :disabled="!valid"
                          small
                          tile
                          class="warning"
                          @click="updatePass"
                        >
                          Change
                        </v-btn>
                      </v-card-actions>
                    </v-form>
                  </v-card>
                </v-dialog>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col>
                <v-card-text>Upload Picture</v-card-text>
                <!-- UPLOAD IMAGE -->
                <v-dialog v-model="uploadDialog" persistent max-width="600px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      v-bind="attrs"
                      v-on="on"
                      class="ma-1"
                      fab
                      dark
                      color="green"
                      @click="uploadDialog"
                    >
                      <v-icon dark> mdi-cloud-upload-outline </v-icon>
                    </v-btn>
                  </template>
                  <v-card tile>
                    <v-card-title class="justify-center text-h5 grey lighten-2">
                      <span>Change Profile Image</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-form>
                          <v-row>
                            <v-col cols="12">
                              <v-file-input
                                show-size
                                required
                                :rules="profileRules"
                                accept="image/png, image/jpeg, image/bmp"
                                placeholder="Pick a profile picture"
                                prepend-icon="mdi-camera"
                                label="Profile Picture*"
                                @change="selectFile"
                                v-model="currentFile"
                                name="file"
                              ></v-file-input>
                            </v-col>
                            <v-col cols="12">
                              <v-alert
                                width="100%"
                                v-if="message"
                                border="left"
                                color="blue-grey"
                                dark
                              >
                                {{ message }}
                              </v-alert>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-container>
                      <small>*indicates required field</small> <br />
                      <small>*max size: 2MB</small>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn outlined small tile @click="closeUploadDialog">
                        Close
                      </v-btn>
                      <v-btn small tile class="success" @click="uploadConfirm">
                        Upload
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- <v-divider class="mt-4"></v-divider> -->

        <!-- Cards -->
        <v-row no-gutters>
          <v-col cols="12" md="4" sm="12" v-if="currentUser.roles">
            <v-card-title class="justify-center"> Authorities: </v-card-title>
            <v-card-subtitle class="text-center">
              <v-list dense class="ma-0 pa-0">
                <v-list-item-group
                  class="justify-center"
                  v-for="(role, index) in currentUser.roles"
                  :key="index"
                >
                  {{ role }}
                </v-list-item-group>
              </v-list>
            </v-card-subtitle>
          </v-col>
          <v-divider class="my-9" vertical inset></v-divider>

          <v-col cols="12" md="4" sm="12" v-if="currentUser.branches">
            <v-card-title class="justify-center"> Branch: </v-card-title>
            <v-card-subtitle class="text-center">
              <v-list dense class="ma-0 pa-0">
                <v-list-item-group
                  class="justify-center"
                  v-for="(j, index) in currentUser.branches"
                  :key="index"
                >
                  <div v-for="(k, index) in filterStates(j)" :key="index">
                    {{ k.name.toUpperCase() }}
                  </div>
                </v-list-item-group>
              </v-list>
            </v-card-subtitle>
          </v-col>
          <v-divider class="my-9" vertical inset></v-divider>

          <v-col cols="12" md="4" sm="12" v-if="currentUser.dept">
            <v-card-title class="justify-center"> Department: </v-card-title>
            <v-card-subtitle class="text-center">
              <v-list dense class="ma-0 pa-0">
                <v-list-item-group
                  class="justify-center"
                  v-for="(i, index) in currentUser.dept"
                  :key="index"
                >
                  <div v-for="(m, index) in filterDept(i)" :key="index">
                    {{ m.name.toUpperCase() }}
                  </div>
                </v-list-item-group>
              </v-list>
            </v-card-subtitle>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- DETAIL CARD -->
    <v-card outlined tile class="text-center pa-3 mt-3">
      <v-row align="center" no-gutters>
        <v-col cols="12" md="3" sm="6">
          <h4>Fullname:</h4>
        </v-col>

        <v-col cols="12" md="9" sm="6">
          <v-text-field
            dense
            disabled
            v-model="members.fullname"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" no-gutters>
        <v-col cols="12" md="3" sm="6">
          <h4>NRIC:</h4>
        </v-col>

        <v-col cols="12" md="9" sm="6">
          <v-text-field dense disabled v-model="members.nric"></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" no-gutters>
        <v-col cols="12" md="3" sm="6">
          <h4>Tel:</h4>
        </v-col>

        <v-col cols="12" md="3" sm="6">
          <v-text-field
            dense
            disabled
            v-model="members.telnumber"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="3" sm="6">
          <h4>Date Joined:</h4>
        </v-col>

        <v-col cols="12" md="3" sm="6">
          <v-text-field dense disabled v-model="computedDate"></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" no-gutters>
        <v-col cols="12" md="3" sm="6">
          <h4>Position:</h4>
        </v-col>

        <v-col cols="12" md="3" sm="6">
          <v-text-field
            dense
            disabled
            v-model="members.position"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="3" sm="6">
          <h4>Employment ID:</h4>
        </v-col>

        <v-col cols="12" md="3" sm="6">
          <v-text-field dense disabled v-model="members.empid"></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" no-gutters>
        <v-col cols="12" md="3" sm="6">
          <h4>User Code:</h4>
        </v-col>
        <v-col cols="12" md="9" sm="6">
          <v-text-field
            dense
            disabled
            v-model="members.usercode"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" no-gutters>
        <v-col cols="12" md="3" sm="6">
          <h4>Address:</h4>
        </v-col>

        <v-col cols="12" md="9" sm="6">
          <v-textarea
            disabled
            dense
            auto-grow
            v-model="members.address"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row align="center" no-gutters>
        <v-col cols="12" md="4" sm="6">
          <h4>Emergency Contact:</h4>
        </v-col>

        <v-col cols="12" md="8" sm="6">
          <v-text-field
            dense
            disabled
            v-model="members.emcontactname"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" no-gutters>
        <v-col cols="12" md="4" sm="6">
          <h4>Emergency Contact No:</h4>
        </v-col>

        <v-col cols="12" md="8" sm="6">
          <v-text-field
            dense
            disabled
            v-model="members.emcontactno"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import MemberDataService from "../services/MemberDataService";
import BranchDataService from "../services/BranchDataService";
import DeptDataService from "../services/DeptDataService";
import UploadService from "../services/UploadDocsService";
import zxcvbn from "zxcvbn";
import moment from "moment";
export default {
  name: "Profile",
  components: {
    // UploadFiles,
    // UploadService,
  },
  data: () => ({
    opacity: "",
    show1: false,
    show2: false,
    rules: {
      number: (v) =>
        /^\d+$/.test(v) || "This field is required and only accept numbers",
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
      strength: (v) =>
        zxcvbn(v).score >= 3 ||
        "Please choose a stronger password. Try a mix of letters, numbers, and symbols.",

      emailMatch: () => `The password you entered don't match`,
    },
    password: "",
    confPassword: "",
    editValid: false,
    valid: false,
    editDialog: false,
    passDialog: false,
    uploadDialog: false,
    currentFile: undefined,
    progress: 0,
    message: "",
    fileInfos: [],
    profileRules: [
      (value) =>
        !value ||
        value.size < 2000000 ||
        "Avatar size should be less than 2 MB!",
    ],
    // members: "",
    // disabled: false,

    members: {
      id: null,
      username: "",
      fullname: "",
      profile: "",
      nric: "",
      email: "",
      telnumber: "",
      datejoined: "",
      position: "",
      empid: "",
      usercode: "",
      address: "",
      emcontactname: "",
      emcontactno: "",
      password: "",
      active: "",
      roles: "",
      branches: "",
      depts: "",
    },
    branches: [],
    departments: [],
  }),

  computed: {
    isEditDisabled() {
      return !this.members.nric;
    },
    score() {
      const result = zxcvbn(this.password);

      switch (result.score) {
        case 4:
          return {
            color: "light-blue",
            value: 100,
          };
        case 3:
          return {
            color: "light-green",
            value: 75,
          };
        case 2:
          return {
            color: "yellow",
            value: 50,
          };
        case 1:
          return {
            color: "orange",
            value: 25,
          };
        default:
          return {
            color: "red",
            value: 0,
          };
      }
    },
    // feedItemIcon() {
    //   return require(`../app/uploads/${this.members.profile}`);
    // },
    editDialogDisabled() {
      return !(
        this.members.fullname &&
        this.members.nric &&
        this.members.telnumber &&
        this.members.address &&
        this.members.emcontactname &&
        this.members.emcontactno
      );
    },
    currentUser() {
      return this.$store.state.auth.user;
    },

    computedDate() {
      return this.members.datejoined
        ? moment(this.members.datejoined).format("DD/MM/YYYY")
        : "";
    },
  },

  watch: {
    disabled(val) {
      val;
    },
  },
  methods: {
    closeEditDialog() {
      this.editDialog = false;
      this.$nextTick(() => {
        // this.members = Object.assign({});
      });
    },
    formatDate(value) {
      return moment(value).format("DD/MM/YYYY");
    },
    filterStates(item) {
      return this.branches.filter((branch) => branch.id === item);
    },
    retrieveBranches() {
      BranchDataService.getAll()
        .then((response) => {
          this.branches = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    filterDept(item) {
      return this.departments.filter((dept) => dept.id === item);
    },
    retrieveDepts() {
      DeptDataService.getAll()
        .then((response) => {
          this.departments = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    retrieveMembers() {
      MemberDataService.get(this.$store.state.auth.user.id)
        .then((response) => {
          this.members = response.data;
          // this.members = response.data.map(this.getDisplayMember);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    // getDisplayMember(member) {
    //   return {
    //     id: member.id,
    //     username:
    //       member.username.length > 30
    //         ? member.username.substr(0, 30) + "..."
    //         : member.username,
    //     fullname: member.fullname,
    //     nric: member.nric,
    //     email:
    //       member.email.length > 30
    //         ? member.email.substr(0, 30) + "..."
    //         : member.email,
    //     telnumber: member.telnumber,
    //     datejoined: member.datejoined,
    //     position: member.position,
    //     empid: member.empid,
    //     usercode: member.usercode,
    //     address: member.address,
    //     emcontactname: member.emcontactname,
    //     emcontactno: member.emcontactno,

    //     active: member.active ? "Active" : "Inactive",
    //     roles: member.roles,
    //     branches: member.branches,
    //     depts: member.depts,
    //   };
    // },
    // getProfilePhoto() {
    //   // return require(`${this.members.profile}`);
    //   return require(`${this.members.profile}`);
    // },

    //     getProfilePhoto() {
    //   let baseDirectory = `http://localhost:8090/`;
    //   // return require(path.join(baseURL, `${this.members.profile}`));
    //   // return require('@/app/uploads/'+`${this.members.profile}`)
    //   // return require(path.join('@/app/uploads/'+`${this.members.profile}`));
    //   return require(path.join(baseDirectory+`${this.members.profile}`));
    // },

    selectFile(file) {
      // this.progress = 0;
      this.currentFile = file;
    },

    refreshList() {
      this.retrieveMembers();
    },

    save() {
      var editdata = {
        fullname: this.members.fullname,
        nric: this.members.nric,
        telnumber: this.members.telnumber,
        address: this.members.address,
        emcontactname: this.members.emcontactname,
        emcontactno: this.members.emcontactno,
      };
      MemberDataService.updateProfile(this.members.id, editdata)
        .then((response) => {
          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "success",
            text:
              (response && response.data && response.data.message) ||
              toString(),
          });
          this.editDialog = false;
          this.refreshList();
        })
        .catch((e) => {
          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "error",
            text: e || toString(),
          });
          this.editDialog = false;
          this.refreshList();
        });
    },

    closeUploadDialog() {
      this.uploadDialog = false;
      this.message = "";
    },

    uploadConfirm() {
      if (!this.currentFile) {
        this.message = "Please select a file!";
        return;
      }
      if (this.currentFile) {
        this.message = "";
        UploadService.uploadPhoto(this.currentFile, (event) => {
          this.progress = Math.round((100 * event.loaded) / event.total);
        })
          .then((response) => {
            this.$store.dispatch("setSnackbar", {
              showing: true,
              color: "success",
              text:
                (response && response.data && response.data.message) ||
                toString(),
            });
            return UploadService.getPhotos();
          })
          .then((files) => {
            this.fileInfos = files.data;
            this.members.profile = files.data.slice(-1)[0].name;
            this.currentFile = undefined;

            var data = {
              profile: this.members.profile,
            };

            MemberDataService.updatePhoto(this.members.id, data)
              .then((response) => {
                setTimeout(() => {
                  this.$store.dispatch("setSnackbar", {
                    showing: true,
                    color: "success",
                    text:
                      (response && response.data && response.data.message) ||
                      toString(),
                  });
                }, 2500);
                this.uploadDialog = false;
                this.refreshList();
              })
              .catch((e) => {
                this.$store.dispatch("setSnackbar", {
                  showing: true,
                  color: "error",
                  text: e || toString(),
                });
                this.refreshList();
              });
          })
          .catch((e) => {
            this.progress = 0;
            // this.message = "Could not upload the file!";
            this.uploadDialog = false;
            this.$store.dispatch("setSnackbar", {
              showing: true,
              color: "error",
              // text: "Could not upload the file!",
              text:
                (e.response && e.response.data && e.response.data.message) ||
                e.message ||
                toString(),
            });
            this.currentFile = undefined;
          });
      }
    },

    updatePass() {
      var data = {
        password: this.password,
      };
      MemberDataService.changepass(this.members.id, data)
        .then((response) => {
          this.passDialog = false;
          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "success",
            text:
              (response && response.data && response.data.message) ||
              toString(),
          });
          this.refreshList();
        })
        .catch((e) => {
          console.log(e);
        });

      this.passDialog = false;
    },
  },

  mounted() {
    this.retrieveBranches();
    this.retrieveDepts();
    UploadService.getPhotos().then((response) => {
      this.fileInfos = response.data;
    });

    if (!this.currentUser) {
      this.$router.push("/login");
    }
    this.retrieveMembers();
  },
};
</script>
