<template>
  <v-container fluid style="width: 80%">
    <div>
      <v-data-table
        dense
        :headers="headers"
        :items="subDepts"
        :search="search"
        class="elevation-1"
        :footer-props="{
          showFirstLastPage: true,
          'items-per-page-options': [10, 20, 30, 40, 50, 100, -1],
        }"
        :items-per-page="50"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-dialog v-model="dialog" max-width="500px" persistent>
              <template v-slot:activator="{ on: dialog, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      fab
                      small
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="{ ...tooltip, ...dialog }"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>ADD FILE CLASSIFICATION</span>
                </v-tooltip>
              </template>

              <v-card>
                <v-card-title class="justify-center text-h5 grey lighten-2">
                  <span>{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-select
                          v-model="editedItem.deptId"
                          :items="departments"
                          item-text="name"
                          item-value="id"
                          label="Department"
                        ></v-select>
                      </v-col>

                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedItem.subCode"
                          label="Code"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedItem.subCodeDesc"
                          label="Description"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn outlined small tile @click="close"> Cancel </v-btn>
                  <v-btn
                    :disabled="isAddButtonDisabled"
                    small
                    tile
                    class="success"
                    @click="save"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-divider class="mx-4" inset vertical></v-divider>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="justify-center text-h5 grey lighten-2"
                  >Confirm delete?
                  <!-- {{ editedItem.subCode }}? -->
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn outlined small tile @click="closeDelete">No</v-btn>
                  <v-btn small tile class="error" @click="deleteItemConfirm"
                    >Yes</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              clearable
            ></v-text-field>
          </v-toolbar>
        </template>

        <template slot="item.numbering" slot-scope="props">
          {{ props.index + 1 }}
        </template>

        <template v-slot:[`item.deptId`]="{ item }">
          <span
            v-for="subDept in filterSubDept(item.deptId)"
            :key="subDept.id"
            v-bind="subDept.item"
          >
            {{ subDept.name }}
          </span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left transition="fab-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span> edit</span>
          </v-tooltip>
          <v-tooltip right transition="fab-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                v-on="on"
                color="red darken-1"
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <span> delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import SubDeptDataService from "../../services/SubDeptDataService";
import DeptDataService from "../../services/DeptDataService";
export default {
  name: "subDepts",
  data: () => ({
    dialog: false,
    dialogDelete: false,
    subDepts: [],
    departments: [],
    search: "",
    defaultItem: {
      id: null,
      deptId: "",
      subCode: "",
      subCodeDesc: "",
    },
    editedIndex: -1,
    editedItem: {
      id: null,
      deptId: "",
      subCode: "",
      subCodeDesc: "",
    },
    headers: [
      {
        text: "NO.",
        align: "start",
        sortable: false,
        value: "numbering",
      },
      {
        text: "DEPARTMENT",
        align: "start",
        sortable: true,
        value: "deptId",
      },
      {
        text: "FILE CLASSIFICATION",
        align: "start",
        sortable: true,
        value: "subCode",
      },
      {
        text: "DESCRIPTION",
        align: "center",
        value: "subCodeDesc",
        sortable: true,
      },
      {
        text: "ACTIONS",
        align: "center",
        value: "actions",
        sortable: false,
      },
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Add File Classification"
        : "Update File Classification";
    },
    isAddButtonDisabled() {
      return !(
        this.editedItem.deptId &&
        this.editedItem.subCode &&
        this.editedItem.subCodeDesc
      );
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    filterSubDept(item) {
      return this.departments.filter((dept) => dept.id === item);
    },

    retrieveSubDepts() {
      SubDeptDataService.getAll()
        .then((response) => {
          // this.subDepts = response.data.map(this.geDisplaySubDept);
          this.subDepts = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    geDisplaySubDept(subDept) {
      return {
        id: subDept.id,
        deptId: subDept.deptId,
        subCode:
          subDept.subCode.length > 30
            ? subDept.subCode.substr(0, 30) + "..."
            : subDept.subCode,
        subCodeDesc:
          subDept.subCodeDesc.length > 30
            ? subDept.subCodeDesc.substr(0, 30) + "..."
            : subDept.subCodeDesc,
      };
    },

    retrieveDepts() {
      DeptDataService.getAll()
        .then((response) => {
          // this.departments = response.data.map(this.getDisplayDept);
          this.departments = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    getDisplayDept(dept) {
      return {
        id: dept.id,
        name:
          dept.name.length > 30 ? dept.name.substr(0, 30) + "..." : dept.name,
        branchCode:
          dept.stCode.length > 30
            ? dept.stCode.substr(0, 30) + "..."
            : dept.stCode,
      };
    },

    refreshList() {
      this.retrieveSubDepts();
      this.retrieveDepts();
    },

    editItem(item) {
      this.editedIndex = this.subDepts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.subDepts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      SubDeptDataService.delete(this.editedItem.id)
        .then((response) => {
          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "error",
            text:
              (response && response.data && response.data.message) ||
              toString(),
          });

          this.refreshList();
        })
        .catch((e) => {
          console.log(e);
        });

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        SubDeptDataService.update(this.editedItem.id, this.editedItem)
          .then((response) => {
            this.close();
            this.$store.dispatch("setSnackbar", {
              showing: true,
              color: "warning",
              text:
                (response && response.data && response.data.message) ||
                toString(),
            });
            this.refreshList();
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        var data = {
          deptId: this.editedItem.deptId,
          subCode: this.editedItem.subCode,
          subCodeDesc: this.editedItem.subCodeDesc,
        };

        SubDeptDataService.create(data)
          .then((response) => {
            this.editedItem.id = response.data.id;
            this.close();
            this.$store.dispatch("setSnackbar", {
              showing: true,
              color: "success",
              text:
                (response && response.data && response.data.message) ||
                toString(),
            });
            this.refreshList();
          })
          .catch((e) => {
            this.$store.dispatch("setSnackbar", {
              showing: true,
              text:
                (e.response && e.response.data && e.response.data.message) ||
                e.message ||
                toString(),
            });
          });
      }
      this.close();
    },
  },
  mounted() {
    this.retrieveSubDepts();
    this.retrieveDepts();
  },
};
</script>
