<template>
  <v-btn small tile class="success" @mousedown="downloadFile">Download</v-btn>
</template>

<script>
import html2pdf from "html2pdf.js";

export default {
  props: ["dom", "name"],
  methods: {
    downloadFile() {
      const me = this;

      const letter = document.querySelector(me.dom);
      var opt = {
        margin: 5,
        filename: me.name,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: "a4",
          putOnlyUsedFonts: true,
          floatPrecision: "smart",
          orientation: "landscape",
        },
      };
      html2pdf().from(letter).set(opt).save();
    },
  },
};
</script>
