<template>
  <!-- <v-footer :absolute="!fixed" app> -->
  <v-footer app bottom fixed padless>
    <v-card
      flat
      tile
      height="20px"
      width="100%"
      class="green lighten-3 text-right"
    >
      <v-row>
        <v-col cols="2">
          <v-card-text
            v-if="currentUser"
            class="pt-0 text-start d-sm-flex d-xs-none d-none"
          >
            {{ currentUser.fullname }}
          </v-card-text>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="10">
          <v-card-text class="pt-0">
            Licenced Loss Adjusters & Investigation &copy;
            {{ new Date().getFullYear() }}
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "staticFooter",
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>
