import http from "../http-common";

class BranchDataService {
  getAll() {
    return http.get("/branches");
  }

  get(id) {
    return http.get(`/branches/${id}`);
  }

  create(data) {
    return http.post("/branches", data);
  }

  update(id, data) {
    return http.put(`/branches/${id}`, data);
  }

  delete(id) {
    return http.delete(`/branches/${id}`);
  }

  deleteAll() {
    return http.delete(`/branches`);
  }

  findByBranch(name) {
    return http.get(`/branches?name=${name}`);
  }
}

export default new BranchDataService();
