<template>
  <div>
    <v-container fluid style="width: 80%">
      <div v-if="caseState.casefile.id">
        <v-row>
          <v-toolbar dense color="indigo" dark>
            <v-spacer></v-spacer>

            <v-chip v-if="dateDiff" outlined small class="purple lighten-3"
              >{{
                dateDiff(
                  moment(caseState.casefile.createdAt).format(),
                  moment().format()
                )
              }}
              Day(s)</v-chip
            >

            <acknowledgement
              :casefile="caseState.casefile"
              :refType="refType"
              :subRefTypes="subRefTypes"
              :insurer="insurer"
              :branches="branches"
              :states="states"
            />

            <flag :casefile="caseState.casefile" />
          </v-toolbar>

          <!-- Go To Top -->
          <v-btn
            v-scroll="onScroll"
            v-show="fab"
            fab
            dark
            fixed
            bottom
            right
            color="indigo"
            @click="toTop"
          >
            <v-icon>mdi-apple-keyboard-shift</v-icon>
          </v-btn>
        </v-row>
        <!-- TOOLBAR END -->
        <v-row>
          <v-col cols="12">
            <!-- Status Row -->
            <v-row no-gutters justify="center" align="center">
              <v-col align="center">
                <v-chip
                  v-if="caseState.casefile.fileStatus == `ADAC`"
                  small
                  class="ml-2 yellow"
                  >PENDING INVESTIGAIION
                </v-chip>
                <v-chip
                  v-if="caseState.casefile.fileStatus == `PEDI`"
                  small
                  dark
                  class="ml-2 red"
                  >PENDING EDITOR
                </v-chip>
              </v-col>
            </v-row>

            <!-- Card Box -->
            <v-row justify="center" align="center">
              <v-col cols="12" lg="2" sm="12">
                <v-card>
                  <v-card-text class="font-weight-black"> STATUS: </v-card-text>
                  <v-card-text
                    class="pt-0"
                    v-for="fileStatus in filterStages(
                      caseState.casefile.fileStatus
                    )"
                    :key="fileStatus.id"
                    v-bind="fileStatus.casefile"
                  >
                    {{ fileStatus.name }}
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" lg="2" md="6" sm="12">
                <v-card v-if="caseState.casefile.branch">
                  <v-card-text class="font-weight-black"> BRANCH: </v-card-text>
                  <v-card-text
                    class="pt-0"
                    v-for="branch in filterBranches(caseState.casefile.branch)"
                    :key="branch.id"
                    v-bind="branch.casefile"
                  >
                    {{ branch.name }}
                  </v-card-text>
                </v-card>
                <v-card v-else>
                  <v-card-text class="font-weight-black"> BRANCH: </v-card-text>
                  <v-card-text class="pt-0 font-italic text-caption">
                    to be assigned
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" lg="2" md="6" sm="12">
                <v-card v-if="caseState.casefile.adjuster">
                  <v-card-text class="font-weight-black">
                    ADJUSTER:
                  </v-card-text>
                  <v-card-text
                    class="pt-0"
                    v-for="adjuster in filterAdj(caseState.casefile.adjuster)"
                    :key="adjuster.id"
                    v-bind="adjuster.casefile"
                  >
                    {{ adjuster.username }}
                  </v-card-text>
                </v-card>
                <v-card v-else>
                  <v-card-text class="font-weight-black">
                    ADJUSTER:
                  </v-card-text>
                  <v-card-text class="pt-0 font-italic text-caption">
                    to be assigned
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" lg="2" md="6" sm="12">
                <v-card v-if="caseState.casefile.editor">
                  <v-card-text class="font-weight-black"> EDITOR: </v-card-text>
                  <v-card-text class="red--text pt-0">
                    {{ caseState.casefile.editor }}
                  </v-card-text>
                </v-card>
                <v-card v-else>
                  <v-card-text class="font-weight-black"> EDITOR: </v-card-text>
                  <v-card-text class="pt-0 font-italic text-caption">
                    to be assigned
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" lg="2" md="6" sm="12">
                <v-card>
                  <v-card-text class="font-weight-black">
                    APPROVER:
                  </v-card-text>
                  <v-card-text class="pt-0 font-italic text-caption red--text">
                    Handler?
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <!-- Details Row -->
            <v-card class="mt-3 pa-3">
              <v-card-title class="pb-0">CASE DETAILS</v-card-title>
              <v-divider></v-divider>

              <!--ROW 1 -->
              <v-row no-gutters class="d-flex">
                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Vehicle No.:</v-card-text
                  >
                </v-col>

                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0">{{
                    caseState.casefile.vehicleNo
                  }}</v-card-text>
                </v-col>

                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Our Ref.:</v-card-text
                  >
                </v-col>
                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0">
                    AA/<span
                      v-for="(dept, index) in filterDept(
                        caseState.casefile.refType
                      )"
                      :key="index"
                      >{{ dept.name
                      }}<span
                        v-for="(subDept, index) in filterSubDept(
                          caseState.casefile.subRefType
                        )"
                        :key="index"
                        >/{{ subDept.subCode }}</span
                      ></span
                    >/{{ caseState.casefile.id }}/{{
                      yearOnly(caseState.casefile.createdAt)
                    }}/<span
                      v-for="branch in filterBranches(
                        caseState.casefile.branch
                      )"
                      :key="branch.id"
                      >{{ branch.brCode }}
                    </span></v-card-text
                  >
                </v-col>
              </v-row>
              <!--ROW 2 -->
              <v-row no-gutters class="d-flex">
                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Date of Loss:</v-card-text
                  >
                </v-col>

                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0">{{
                    formatDate(caseState.casefile.dateOfLoss)
                  }}</v-card-text>
                </v-col>

                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Place of Loss:</v-card-text
                  >
                </v-col>
                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text
                    class="ma-0 pa-0"
                    v-if="caseState.casefile.placeOfLoss"
                    >{{ caseState.casefile.placeOfLoss }},
                    <span
                      v-for="state in filterStates(
                        caseState.casefile.stateOfLoss
                      )"
                      :key="state.id"
                      >{{ state.name }}
                    </span></v-card-text
                  >

                  <v-card-text
                    v-else
                    class="ma-0 pa-0 font-italic text-caption"
                  >
                    to be assigned
                  </v-card-text>
                </v-col>
              </v-row>
              <!--ROW 3 -->
              <v-row no-gutters class="d-flex">
                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Claim Type:</v-card-text
                  >
                </v-col>

                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text
                    class="ma-0 pa-0"
                    v-for="(dept, index) in filterDept(
                      caseState.casefile.refType
                    )"
                    :key="index"
                    >{{ dept.name }}</v-card-text
                  >
                </v-col>

                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Assignment Date:</v-card-text
                  >
                </v-col>
                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0">{{
                    formatDate(caseState.casefile.dateOfAssign)
                  }}</v-card-text>
                </v-col>
              </v-row>
              <!--ROW 3a -->
              <v-row no-gutters class="d-flex">
                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text
                    class="ma-0 pa-0 font-weight-black"
                  ></v-card-text>
                </v-col>

                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0"></v-card-text>
                </v-col>

                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Acknowledgement Date:</v-card-text
                  >
                </v-col>
                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0">{{
                    formatDate(caseState.casefile.createdAt)
                  }}</v-card-text>
                </v-col>
              </v-row>
              <!--ROW 3-b -->
              <v-row no-gutters class="d-flex">
                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Case Creator:</v-card-text
                  >
                </v-col>

                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text
                    class="ma-0 pa-0"
                    v-for="(creator, index) in filterUser(
                      caseState.casefile.createdBy
                    )"
                    :key="index"
                    >{{ creator.username }}</v-card-text
                  >
                </v-col>

                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Creator Branch:</v-card-text
                  >
                </v-col>
                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text
                    class="ma-0 pa-0"
                    v-for="(creator, index) in filterUser(
                      caseState.casefile.createdBy
                    )"
                    :key="index"
                    ><span v-for="(b, index) in creator.branches" :key="index"
                      >{{ b.name }}
                    </span></v-card-text
                  >
                </v-col>
              </v-row>
              <!--INSURED DETAILS-->
              <v-row no-gutters class="d-flex">
                <v-col
                  class="my-2"
                  cols="12"
                  lg="12"
                  md="12"
                  sm="12"
                  align="left"
                >
                  <span class="font-weight-black"> INSURED DETAILS: </span>
                </v-col>
              </v-row>
              <!--ROW 4 -->
              <v-row no-gutters class="d-flex">
                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Insured:</v-card-text
                  >
                </v-col>

                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0">{{
                    caseState.casefile.insuredName
                  }}</v-card-text>
                </v-col>

                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >NRIC:</v-card-text
                  >
                </v-col>
                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0">{{
                    caseState.casefile.insuredIC
                  }}</v-card-text>
                </v-col>
              </v-row>
              <!--ROW 5 -->
              <v-row no-gutters class="d-flex">
                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Tel:</v-card-text
                  >
                </v-col>

                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0">{{
                    caseState.casefile.insuredTel
                  }}</v-card-text>
                </v-col>

                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Email:</v-card-text
                  >
                </v-col>
                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0"
                    >{{ caseState.casefile.insuredEmail }}
                  </v-card-text>
                </v-col>
              </v-row>
              <!--INSURED DRIVER -->
              <v-row no-gutters class="d-flex">
                <v-col
                  class="my-2"
                  cols="12"
                  lg="12"
                  md="12"
                  sm="12"
                  align="left"
                >
                  <span class="font-weight-black">
                    INSURED DRIVER DETAILS:
                  </span>
                </v-col>
              </v-row>
              <!--ROW 6 -->
              <v-row no-gutters class="d-flex">
                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Insured Driver:</v-card-text
                  >
                </v-col>

                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0">{{
                    caseState.casefile.insuredDriverName
                  }}</v-card-text>
                </v-col>

                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >NRIC:</v-card-text
                  >
                </v-col>
                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0">{{
                    caseState.casefile.insuredDriverIc
                  }}</v-card-text>
                </v-col>
              </v-row>
              <!--ROW 7 -->
              <v-row no-gutters class="d-flex">
                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Tel:
                  </v-card-text>
                </v-col>

                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0"
                    >{{ caseState.casefile.insuredDriverTel }}
                  </v-card-text>
                </v-col>

                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Email:
                  </v-card-text>
                </v-col>
                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0"
                    >{{ caseState.casefile.insuredDriverEmail }}
                  </v-card-text>
                </v-col>
              </v-row>
              <!--ROW 7a -->
              <v-row no-gutters class="d-flex">
                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Police Report No.
                  </v-card-text>
                </v-col>

                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0"
                    >{{ caseState.casefile.policeReportNo }}
                  </v-card-text>
                </v-col>

                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Date:
                  </v-card-text>
                </v-col>
                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0"
                    >{{ formatDate(caseState.casefile.policeReportDate) }}
                  </v-card-text>
                </v-col>
              </v-row>
              <!--ROW 8 -->
              <v-row no-gutters class="d-flex">
                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Time:
                  </v-card-text>
                </v-col>

                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="9"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0"
                    >{{ formatTime(caseState.casefile.policeReportDate) }}
                  </v-card-text>
                </v-col>
              </v-row>
              <!--INSURER DETAILS -->
              <v-row no-gutters class="d-flex">
                <v-col
                  class="my-2"
                  cols="12"
                  lg="12"
                  md="12"
                  sm="12"
                  align="left"
                >
                  <span class="font-weight-black"> INSURER DETAILS: </span>
                </v-col>
              </v-row>
              <!--ROW 9 -->
              <v-row no-gutters class="d-flex">
                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Insurer:
                  </v-card-text>
                </v-col>

                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text
                    class="ma-0 pa-0"
                    v-for="insurer in filterIns(caseState.casefile.insurer)"
                    :key="insurer.id"
                  >
                    {{ insurer.name }}
                  </v-card-text>
                </v-col>

                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Claim No.:
                  </v-card-text>
                </v-col>
                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0"
                    >{{ caseState.casefile.claimNo }}
                  </v-card-text>
                </v-col>
              </v-row>
              <!--ROW 10 -->
              <v-row no-gutters class="d-flex">
                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Policy/ Cover No:
                  </v-card-text>
                </v-col>

                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0"
                    >{{ caseState.casefile.policyNo }}
                  </v-card-text>
                </v-col>

                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Coverage:
                  </v-card-text>
                </v-col>
                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0"
                    >{{ formatDate(caseState.casefile.coverFrom) }} –
                    {{ formatDate(caseState.casefile.coverTo) }}
                  </v-card-text>
                </v-col>
              </v-row>
              <!--ROW 11 -->
              <v-row no-gutters class="d-flex">
                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Claims Handler:
                  </v-card-text>
                </v-col>

                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="9"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text
                    class="ma-0 pa-0"
                    v-for="handler in filterHandler(caseState.casefile.handler)"
                    :key="handler.id"
                    >{{ handler.name }}
                  </v-card-text>
                </v-col>

                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Tel:
                  </v-card-text>
                </v-col>
                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text
                    v-for="handler in filterHandler(caseState.casefile.handler)"
                    :key="handler.id"
                    class="ma-0 pa-0"
                    >{{ handler.offTel }}
                  </v-card-text>
                </v-col>
                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Mobile:
                  </v-card-text>
                </v-col>
                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text
                    v-for="handler in filterHandler(caseState.casefile.handler)"
                    :key="handler.id"
                    class="ma-0 pa-0"
                    >{{ handler.mobile }}
                  </v-card-text>
                </v-col>
              </v-row>
              <!--ROW 12 -->
              <v-row no-gutters class="d-flex">
                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Email:
                  </v-card-text>
                </v-col>
                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="9"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text
                    v-for="handler in filterHandler(caseState.casefile.handler)"
                    :key="handler.id"
                    class="ma-0 pa-0"
                    >{{ handler.email }}
                  </v-card-text>
                </v-col>
              </v-row>
              <!--ROW 12-a -->
              <v-row no-gutters class="d-flex">
                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Insurer’s remarks:
                  </v-card-text>
                </v-col>
                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="9"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0"
                    >{{ caseState.casefile.insComment }}
                  </v-card-text>
                </v-col>
              </v-row>
              <!--INSURER SOLICITOR DETAILS -->
              <v-row no-gutters class="d-flex">
                <v-col
                  class="my-2"
                  cols="12"
                  lg="12"
                  md="12"
                  sm="12"
                  align="left"
                >
                  <span class="font-weight-black">
                    INSURER SOLICITOR DETAILS:
                  </span>
                </v-col>
              </v-row>
              <!--ROW 13 -->
              <v-row no-gutters class="d-flex">
                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Insurer’s Solicitor:
                  </v-card-text>
                </v-col>

                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0"
                    >{{ caseState.casefile.solicitorName }}
                  </v-card-text>
                </v-col>

                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Tel:
                  </v-card-text>
                </v-col>
                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0"
                    >{{ caseState.casefile.solicitorTel }}
                  </v-card-text>
                </v-col>
              </v-row>
              <!--ROW 13-a -->
              <v-row no-gutters class="d-flex">
                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black">
                  </v-card-text>
                </v-col>

                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 red--text"> </v-card-text>
                </v-col>

                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Email:
                  </v-card-text>
                </v-col>
                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0"
                    >{{ caseState.casefile.solicitorEmail }}
                  </v-card-text>
                </v-col>
              </v-row>
              <!--ROW 14-b -->
              <v-row no-gutters class="d-flex">
                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Attending Counsel:
                  </v-card-text>
                </v-col>

                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0"
                    >{{ caseState.casefile.counselName }}
                  </v-card-text>
                </v-col>

                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Tel:
                  </v-card-text>
                </v-col>
                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0"
                    >{{ caseState.casefile.counselTel }}
                  </v-card-text>
                </v-col>
              </v-row>
              <!--ROW 14-b -->
              <v-row no-gutters class="d-flex">
                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black">
                  </v-card-text>
                </v-col>

                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 red--text"> </v-card-text>
                </v-col>

                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Email:
                  </v-card-text>
                </v-col>
                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0"
                    >{{ caseState.casefile.counselEmail }}
                  </v-card-text>
                </v-col>
              </v-row>

              <!--THIRD PARTY CLAIMANT -->
              <v-row no-gutters class="d-flex">
                <v-col
                  class="my-2"
                  cols="12"
                  lg="12"
                  md="12"
                  sm="12"
                  align="left"
                >
                  <span class="font-weight-black">
                    THIRD PARTY CLAIMANT DETAILS:
                  </span>
                </v-col>
              </v-row>
              <!--ROW 15 -->
              <v-row no-gutters class="d-flex">
                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Third Party Claimant:
                  </v-card-text>
                </v-col>

                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0"
                    >{{ caseState.casefile.thirdPartyClaimantName }}
                  </v-card-text>
                </v-col>

                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >NRIC:
                  </v-card-text>
                </v-col>
                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0"
                    >{{ caseState.casefile.thirdPartyClaimantIc }}
                  </v-card-text>
                </v-col>
              </v-row>
              <!--ROW 16 -->
              <v-row no-gutters class="d-flex">
                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Tel:
                  </v-card-text>
                </v-col>

                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0"
                    >{{ caseState.casefile.thirdPartyClaimantTel }}
                  </v-card-text>
                </v-col>

                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Email:
                  </v-card-text>
                </v-col>
                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0"
                    >{{ caseState.casefile.thirdPartyClaimantEmail }}
                  </v-card-text>
                </v-col>
              </v-row>
              <!--ROW 17 -->
              <v-row no-gutters class="d-flex">
                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Vehicle Number:
                  </v-card-text>
                </v-col>

                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0"
                    >{{ caseState.casefile.thirdPartyClaimantVehicleNo }}
                  </v-card-text>
                </v-col>
                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Vehicle Model:
                  </v-card-text>
                </v-col>

                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0"
                    >{{ caseState.casefile.thirdPartyClaimantVehicleModel }}
                  </v-card-text>
                </v-col>
              </v-row>
              <!--ROW 18 -->
              <v-row no-gutters class="d-flex">
                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Claimant Solicitor:
                  </v-card-text>
                </v-col>

                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0"
                    >{{ caseState.casefile.thirdPartyClaimantSolicitor }}
                  </v-card-text>
                </v-col>

                <v-col
                  class="main-column"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0 font-weight-black"
                    >Email:
                  </v-card-text>
                </v-col>
                <v-col
                  class="bottom-border"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                  align="center"
                >
                  <v-card-text class="ma-0 pa-0"
                    >{{ caseState.casefile.thirdPartyClaimantSolicitorEmail }}
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-layout class="justify-center mt-5">
          No result(s) to Display. Did you refresh the page?</v-layout
        >
      </div>
    </v-container>
  </div>
</template>

<script>
import CasefileDataService from "../../services/CasefileDataService";
import MemberDataService from "../../services/MemberDataService";
import StagesDataService from "../../services/StageDataService";
import BranchDataService from "../../services/BranchDataService";
import StateDataService from "../../services/StateDataService";
import RegionDataService from "../../services/RegionDataService";
import DeptDataService from "../../services/DeptDataService";
import SubRefTypeDataService from "../../services/SubDeptDataService";
import InsDataService from "../../services/InsDataService";
import HandlerDataService from "../../services/HandlerDataService";
import Acknowledgement from "../../components/pdf/acknowledgement";

import moment from "moment";
import Flag from "../../components/flag/flagViewOnly.vue";
import { mapState } from "vuex";

export default {
  name: "findfile",
  components: {
    Acknowledgement,
    Flag,
  },

  data() {
    return {
      stages: [],
      adjusters: [],
      members: [],
      branches: [],
      regions: [],
      refType: [],
      subRefTypes: [],
      insurer: [],
      handlers: [],
      branch: {
        name: "",
        brCode: "",
      },
      states: [],
      acknowledgement: false,
      fab: false,
    };
  },

  computed: {
    ...mapState(["caseState"]),

    computedDateFormattedMomentjs() {
      return this.date ? moment(this.date).format("DD/MM/YY") : "";
    },
  },
  watch: {},

  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },

    getDeptCode(item) {
      return `${item.name} - (${item.subCode})`;
    },

    filterRegions(item) {
      return this.regions.filter((region) => region.branchId === item);
    },
    filtersubRefType(item) {
      return this.subRefTypes.filter(
        (subRefType) => subRefType.deptId === item
      );
    },

    moment: function (date) {
      return moment(date);
    },

    yearOnly(value) {
      return moment(value).format("YY");
    },

    filterAdjByBranch(item) {
      return this.adjusters.filter((r) =>
        r.branches.some((i) => i.brCode === item)
      );
    },

    filterStages(item) {
      return this.stages.filter((stage) => stage.stageCode === item);
    },

    filterDept(item) {
      return this.refType.filter((dept) => dept.id === item);
    },

    filterSubDept(item) {
      return this.subRefTypes.filter((subRef) => subRef.id === item);
    },

    filterIns(item) {
      return this.insurer.filter((insurer) => insurer.id === item);
    },

    filterAdj(item) {
      return this.adjusters.filter((adjuster) => adjuster.id === item);
    },

    filterBranches(item) {
      return this.branches.filter((branch) => branch.id === item);
    },

    filterStates(item) {
      return this.states.filter((state) => state.id === item);
    },

    filterHandler(item) {
      return this.handlers.filter((handler) => handler.id === item);
    },

    filterUser(item) {
      return this.members.filter((member) => member.id === item);
    },

    formatDate: function (date) {
      return moment(date).format("DD/MM/YYYY");
    },
    formatTime: function (date) {
      return moment(date).format("h:mm:ss a");
    },

    dateDiff(startDateString, endDateString) {
      // Method 01
      let start = moment(startDateString);
      let end = moment(endDateString);
      let duration = moment.duration(end.diff(start));
      return Math.round(duration.asDays());
    },

    refreshList() {
      this.retrieveMembers();
      this.retrieveUsers();
      this.retrieveBranches();
      this.retrieveStates();
      this.retrieveCasefiles(this.$route.params.id);
    },

    retrieveDept() {
      DeptDataService.getAll()
        .then((response) => {
          this.refType = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveSubRefType() {
      SubRefTypeDataService.getAll()
        .then((response) => {
          this.subRefTypes = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveIns() {
      InsDataService.getAll()
        .then((response) => {
          this.insurer = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveHandler() {
      HandlerDataService.getAll()
        .then((response) => {
          this.handlers = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveStages() {
      StagesDataService.getAll()
        .then((response) => {
          this.stages = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveMembers() {
      MemberDataService.getAll()
        .then((response) => {
          this.adjusters = response.data.filter((item) =>
            item.roles.some((b) => b.roleCode === "adjuster")
          );
          // this.adjusters = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveUsers() {
      MemberDataService.getAll()
        .then((response) => {
          this.members = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveBranches() {
      BranchDataService.getAll()
        .then((response) => {
          this.branches = response.data.map(this.getDisplayBranch);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getDisplayBranch(branch) {
      return {
        id: branch.id,
        name:
          branch.name.length > 30
            ? branch.name.substr(0, 30) + "..."
            : branch.name,
        brCode:
          branch.brCode.length > 30
            ? branch.brCode.substr(0, 30) + "..."
            : branch.brCode,
      };
    },
    retrieveStates() {
      StateDataService.getAll()
        .then((response) => {
          this.states = response.data.map(this.getDisplayStates);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getDisplayStates(state) {
      return {
        id: state.id,
        name:
          state.name.length > 30
            ? state.name.substr(0, 30) + "..."
            : state.name,
        stCode:
          state.stCode.length > 30
            ? state.stCode.substr(0, 30) + "..."
            : state.stCode,
      };
    },
    retrieveRegions() {
      RegionDataService.getAll()
        .then((response) => {
          this.regions = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // getDisplayMember(member) {
    //   return {
    //     id: member.id,
    //     username: member.username,
    //     roles: member.roles,
    //   };
    // },
    // getDisplayMember(member) {
    //   return {
    //     id: member.id,
    //     username:
    //       member.username.length > 30
    //         ? member.username.substr(0, 30) + "..."
    //         : member.username,
    //     fullname: member.fullname,
    //     nric: member.nric,
    //     email:
    //       member.email.length > 30
    //         ? member.email.substr(0, 30) + "..."
    //         : member.email,
    //     telnumber: member.telnumber,
    //     datejoined: member.datejoined,
    //     position: member.position,
    //     empid: member.empid,
    //     usercode: member.usercode,
    //     address: member.address,
    //     emcontactname: member.emcontactname,
    //     emcontactno: member.emcontactno,

    //     active: member.active ? 1 : 0,
    //     roles: member.roles,
    //     branches: member.branches,
    //     depts: member.depts,
    //   };
    // },
    // retrieveAllCase() {
    //   CasefileDataService.getAll()
    //     .then((response) => {
    //       // this.casefiles = response.data.map(this.getDisplayCasefile);
    //       this.casefile = response.data;
    //       // console.log(response.data);
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //     });
    // },

    retrieveCasefiles(id) {
      CasefileDataService.get(id)
        .then((response) => {
          this.caseState.casefile = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {
    this.retrieveCasefiles(this.$route.params.id);
    this.retrieveMembers();
    this.retrieveUsers();
    this.retrieveBranches();
    this.retrieveStates();
    this.retrieveRegions();
    this.retrieveDept();
    this.retrieveSubRefType();
    this.retrieveIns();
    this.retrieveHandler();
    this.retrieveStages();
  },
};
</script>
<style scoped>
.main-column {
  /* border-right: 1px solid green; */
  /* border-left: 1px solid green; */
  border-bottom: 1px solid grey;
  /* border-right: 1px solid grey; */
  /* border-left: 1px solid green; */
  /* background-color: rgb(214, 232, 233); */
  background-color: aliceblue;
}

.bottom-border {
  /* border-left: 1px solid blue; */
  border-bottom: 1px solid grey;
}
</style>
